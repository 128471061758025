// src/components/dashboardNew/components/NewCurrencyCard.tsx

import React from 'react';
import styled from 'styled-components';
import { Plus } from 'lucide-react';

const Card = styled.div`
  background-color: #F5F5F5;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 180px; /* Match the height of CurrencyCard */
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #E5E7EB;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #10B981;
  }
`;

const AddContent = styled.div`
  text-align: center;
  color: #6B7280;
`;

const PlusIcon = styled(Plus)`
  margin-bottom: 8px;
`;

interface NewCurrencyCardProps {
  onAddCurrency: () => void;
}

export default function NewCurrencyCard({ onAddCurrency }: NewCurrencyCardProps) {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onAddCurrency();
    }
  };

  return (
    <Card 
      onClick={onAddCurrency} 
      role="button" 
      aria-label="Add Currency" 
      tabIndex={0} 
      onKeyPress={handleKeyPress}
    >
      <AddContent>
        <PlusIcon size={24} />
        <div>Add Currency</div>
      </AddContent>
    </Card>
  );
}
