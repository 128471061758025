// src/components/CRMHeader.tsx

import React, { useState, useEffect, useContext } from "react";
import {
  Search as SearchIcon,
  Menu,
  Bell,
} from "lucide-react";
import { Input } from "./crmui/input";
import { Avatar, AvatarFallback, AvatarImage } from "./crmui/avatar";
import { Button } from "./crmui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./crmui/dropdown-menu"; // Only for notifications
import logo from './logo.png';
import { useModal } from '../../contexts/ModalContext';
import { useAuth } from '../../contexts/AuthContext';
import { useSidebar } from '../../contexts/SidebarContext';
import { useNavigate } from 'react-router-dom';
import BankSettingsModalComponent from './settings/bank-settings-modal';
import RightSideMenuContext from '@/contexts/RightSideMenuContext'; // Import the RightSideMenuContext

const ProfileModalContent: React.FC = () => {
  return <div>Profile Modal Content</div>;
};

export function CRMHeader() {
  const { events = [], fetchEvents, logout, user, loading, error } = useAuth(); // Provide default empty array
  const { openModal } = useModal();
  const { toggleSidebar } = useSidebar();
  const navigate = useNavigate();
  const { isOpen, openMenu, closeMenu, toggleMenu } = useContext(RightSideMenuContext); // Consume RightSideMenuContext

  // Local state to manage retry attempts
  const [retryCount, setRetryCount] = useState<number>(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 5000; // 5 seconds

  // Log when the CRMHeader mounts
  useEffect(() => {
    console.log("CRMHeader: Mounted");
  }, []);

  // Log authentication context values
  useEffect(() => {
    console.log("CRMHeader: Auth Context Values:", { events, user, loading, error });
  }, [events, user, loading, error]);

  // Fetch events when events are empty and no error has occurred
  useEffect(() => {
    const hasNoEventsPlaceholder =
      events.length === 1 && events[0].id === 'no_events';

    if ((events.length === 0 || hasNoEventsPlaceholder) && !error) {
      console.log("CRMHeader: No events or 'no_events' present. Initiating fetchEvents.");
      fetchEvents();
    }
  }, [events.length, events, fetchEvents, error]);

  // Handle retries on error
  useEffect(() => {
    if (error && retryCount < MAX_RETRIES) {
      console.log(`CRMHeader: Error detected (${error}). Scheduling retry ${retryCount + 1}/${MAX_RETRIES} in ${RETRY_DELAY / 1000} seconds.`);
      const retryTimer = setTimeout(() => {
        console.log(`CRMHeader: Retry attempt ${retryCount + 1} to fetch events.`);
        fetchEvents();
        setRetryCount((prev) => prev + 1);
      }, RETRY_DELAY);

      return () => clearTimeout(retryTimer);
    } else if (error && retryCount >= MAX_RETRIES) {
      console.warn('CRMHeader: Maximum retry attempts reached. Stopping further retries.');
      // Optionally, display an error message to the user
    }
  }, [error, retryCount, fetchEvents]);

  // Log events whenever they change
  useEffect(() => {
    console.log("CRMHeader: Current Events:", events);
  }, [events]);

  const openProfileModal = () => {
    console.log("CRMHeader: Opening Profile Modal");
    openModal({
      title: 'Edit Profile',
      content: <ProfileModalContent />,
    });
  };

  const openSettingsModal = () => {
    console.log("CRMHeader: Opening Settings Modal");
    openModal({
      title: 'Settings',
      content: <BankSettingsModalComponent />,
      steps: [
        { label: 'Profile', status: 'current' },
        { label: 'Security', status: 'upcoming' },
        { label: 'Activity', status: 'upcoming' },
      ],
    });
  };

  const handleLogout = async () => {
    console.log("CRMHeader: Initiating logout");
    try {
      await logout();
      console.log("CRMHeader: Logout successful. Navigating to login.");
      navigate('/login');
    } catch (error) {
      console.error('CRMHeader: Logout failed:', error);
      // Optionally, display an error message to the user
    }
  };

  // Updated headerClasses without fixed positioning
  const headerClasses = `bg-white shadow-none w-full flex justify-between items-center px-4 sm:px-6 lg:px-8 transition-all duration-300`;

  return (
    <header className={headerClasses} style={{ height: '72px' }}>
      <div className="flex items-center justify-between h-full w-full">
        {/* Left Section */}
        <div className="flex items-center">
          {/* Sidebar Toggle Button */}
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
            className="mr-4 lg:hidden"
          >
            <Menu className="h-6 w-6" />
          </Button>
          {/* Logo */}
          <img
            className="h-10 hidden lg:block" // Set fixed height
            src={logo}
            alt="EBANNC Logo"
            style={{ objectFit: 'contain' }}
          />
        </div>

        {/* Center Section */}
        <div className="flex-1 flex justify-center px-2 lg:ml-6">
          <div className="max-w-lg w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="relative">
              <Input 
                id="search"
                type="search" 
                placeholder="Search..." 
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none" >
                <SearchIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex items-center space-x-4">
          {/* Notifications Dropdown */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" aria-label="Notifications" className="relative">
                <Bell className="h-5 w-5" />
                {/* Only show notification count if events exist and are not the placeholder */}
                {Array.isArray(events) && events.length > 0 && events[0]?.id !== 'no_events' && (
                  <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center">
                    {events.length}
                  </span>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-80 bg-white shadow-lg rounded-md">
              <DropdownMenuLabel>Notifications</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {loading ? (
                <div className="p-4 text-center text-sm text-gray-500">
                  Loading notifications...
                </div>
              ) : (
                <>
                  {!Array.isArray(events) || events.length === 0 || (events.length === 1 && events[0]?.id === 'no_events') ? (
                    <div className="p-4 text-center text-sm text-gray-500">
                      No new notifications
                    </div>
                  ) : (
                    events.map((event, index) => (
                      <DropdownMenuItem 
                        key={`${event?.id || index}-${event?.time || index}`}
                        className="flex flex-col items-start p-4"
                      >
                        <span className="font-medium">{event?.message || event?.event || 'No message'}</span>
                        <span className="text-sm text-gray-500">
                          {event?.time ? new Date(event.time).toLocaleString() : 'Date not available'}
                        </span>
                      </DropdownMenuItem>
                    ))
                  )}
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>

          {/* User Button to Open Right Side Menu */}
          <Button
            variant="ghost"
            size="sm"
            className="flex items-center space-x-2"
            onClick={openMenu} // Directly open the right sidebar
            aria-label="Open Right Sidebar"
          >
            <Avatar className="h-8 w-8">
              <AvatarImage src={user?.avatar || "/placeholder.svg"} alt={user?.name || "User"} />
              <AvatarFallback>{user?.name ? user.name.charAt(0).toUpperCase() : 'U'}</AvatarFallback>
            </Avatar>
            <span className="hidden sm:inline-block font-medium">{user?.name || 'User'}</span>
            {/* Removed ChevronDown since it's no longer a dropdown */}
          </Button>
        </div>
      </div>

      {/* Error Message Display */}
      {error && retryCount >= MAX_RETRIES && (
        <div className="mt-2 p-4 bg-red-100 text-red-700 rounded-md">
          <p>Failed to load notifications. Please try again later.</p>
        </div>
      )}
    </header>
  );
}

export default CRMHeader;
