import React from 'react';
import { FinancialAuditReports } from '../components/financialAudit/financial-audit-reports';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "../components/cardManagement/uicustomizer/button";

export function FinancialAuditPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <FinancialAuditReports />
    </div>
  );
}

export default FinancialAuditPage;
    