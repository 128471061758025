import * as React from "react"
import { ChevronDown, ChevronLeft, ChevronRight, X, Filter, BarChart3, Download, ChevronRightIcon, LayoutDashboard } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAuth } from '@/contexts/AuthContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export function BalancesPage() {
  const { user, wallets, statements } = useAuth();
  const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("Overview");
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = React.useState(false);
  const [isAnalyzeOpen, setIsAnalyzeOpen] = React.useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = React.useState(false);
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  const [selectedRange, setSelectedRange] = React.useState("1 week");
  const [dateRangeText, setDateRangeText] = React.useState("16 Nov-23 Nov");

  const ranges = ["1 day", "1 week", "1 month", "3 months", "1 year", "Custom"];

  const filterOptions = [
    { id: "date", label: "Date" },
    { id: "type", label: "Type" },
    { id: "currency", label: "Currency" },
    { id: "balanceType", label: "Balance type" },
  ];

  const analyzeOptions = [
    {
      title: "Daily account balance",
      description: "Overview of account balance and net transactions",
      icon: LayoutDashboard,
    },
    {
      title: "Monthly overview",
      description: "Overview of sales, refunds, gross revenue, and net activity",
      icon: LayoutDashboard,
    },
    {
      title: "Itemized balance change from activity report",
      description: "Detailed breakdown of balance transactions categorized by report type",
      icon: LayoutDashboard,
    },
  ];

  const handleApplyDateRange = () => {
    // Implement actual date formatting based on selected dates
    if (date) {
      // Example: Update dateRangeText based on selected date range
      // This should be replaced with actual logic based on your date selection
      setDateRangeText("16 Nov-23 Nov");
    }
    setIsCalendarOpen(false);
  };

  const generatePDF = () => {
    try {
      setIsDownloadLoading(true);
      const doc = new jsPDF();

      // Document Title
      doc.setFontSize(18);
      doc.text('Transactions Report', 14, 22);

      // Table Headers
      const tableColumn = ["Type", "Date", "Description", "Paid In", "Paid Out", "Balance"];

      // Table Rows sourced from statements
      const tableRows: Array<Array<string | number>> = statements.flatMap(statement =>
        statement.transactions.map(tx => [
          tx.type,
          new Date(tx.date).toLocaleDateString(),
          tx.description,
          tx.paidIn || '-',
          tx.paidOut || '-',
          `${tx.balance.amount} ${tx.balance.currency}`
        ])
      );

      // Add AutoTable
      (doc as any).autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: { fontSize: 10 },
        headStyles: { fillColor: [36, 236, 126] }, // Green header
        alternateRowStyles: { fillColor: [240, 248, 255] }, // Light blue
      });

      // Save the PDF
      doc.save('transactions_report.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. Please try again.');
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const transactions = React.useMemo(() => {
    return statements.flatMap(statement => statement.transactions);
  }, [statements]);

  const renderOverviewContent = () => (
    <div className="space-y-8">
      <h1 className="text-2xl font-semibold mb-4">Balances Overview</h1>

      {/* Dynamically render wallet balance cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {wallets.map((wallet) => (
          <div
            key={wallet.id}
            className={`p-4 border rounded-lg ${
              wallet.currency.toUpperCase() === user?.activeWallet.toUpperCase()
                ? "border-[#24EC7E] bg-[#F0FDF4]"
                : "border-gray-200 bg-white"
            }`}
          >
            <div
              className={`text-sm mb-1 ${
                wallet.currency.toUpperCase() === user?.activeWallet.toUpperCase()
                  ? "text-[#059669]"
                  : "text-gray-500"
              }`}
            >
              {wallet.currency.toUpperCase()}
            </div>
            <div
              className={`text-xl font-medium ${
                wallet.currency.toUpperCase() === user?.activeWallet.toUpperCase()
                  ? "text-[#059669]"
                  : "text-gray-900"
              }`}
            >
              {wallet.currency === 'GBP' ? '£' : wallet.currency === 'USD' ? '$' : wallet.currency === 'NIS' ? '₪' : '-'}
              {wallet.balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          </div>
        ))}
      </div>

      {/* Summary Cards */}
      <div className="bg-[#F9FAFB] rounded-lg p-6">
        <h2 className="text-base font-medium mb-4">Balance Summary</h2>
        <div className="space-y-4">
          {wallets.map((wallet) => (
            <div key={wallet.id} className="flex justify-between py-1">
              <span className="text-gray-600">{wallet.currency.toUpperCase()} Available funds</span>
              <span className="font-medium">
                {wallet.currency === 'GBP' ? '£' : wallet.currency === 'USD' ? '$' : wallet.currency === 'NIS' ? '₪' : ''}
                {wallet.balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </span>
            </div>
          ))}
          {/* Example for scheduled payouts and total balance */}
          <div className="flex justify-between pt-4 border-t border-gray-200">
            <span className="font-medium">Total Balance</span>
            <span className="font-medium">
              {wallets.reduce((acc, wallet) => acc + wallet.balance, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-[#F9FAFB] rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-medium">Upcoming to your bank</h2>
            <div className="flex gap-2">
              <DropdownMenu open={isFilterOpen} onOpenChange={setIsFilterOpen}>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" className="gap-2">
                    {selectedRange} <ChevronDown className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[200px] p-0" align="end">
                  {ranges.map((range) => (
                    <DropdownMenuItem
                      key={range}
                      className="py-3 px-4 cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        setSelectedRange(range)
                        if (range === "Custom") {
                          setIsCalendarOpen(true)
                        }
                        setIsFilterOpen(false)
                      }}
                    >
                      {range}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>

              <DropdownMenu open={isDateRangeOpen} onOpenChange={setIsDateRangeOpen}>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" className="gap-2">
                    {dateRangeText} <ChevronDown className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[200px] p-0" align="end">
                  {ranges.map((range) => (
                    <DropdownMenuItem
                      key={range}
                      className="py-3 px-4 cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        if (range === "Custom") {
                          setIsCalendarOpen(true)
                        } else {
                          setDateRangeText(range)
                        }
                        setIsDateRangeOpen(false)
                      }}
                    >
                      {range}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          
          <div className="text-sm text-gray-500 mb-6">
            The amounts below are approximate as transactions are still being processed. Payouts will be deposited into your bank account on each business day.
          </div>

          <div className="space-y-4">
            {[
              { date: "17 November 2024", amount: "£27.00" },
              { date: "18 November 2024", amount: "£1,090.00" },
              { date: "22 November 2024", amount: "£3,090.00" },
              { date: "10 December 2024", amount: "£7,090.00" },
              { date: "16 December 2024", amount: "£1,590.00" },
            ].map((payment) => (
              <div key={payment.date} className="flex justify-between py-1">
                <span className="text-gray-600">Payment expected on {payment.date}</span>
                <span className="font-medium">{payment.amount}</span>
              </div>
            ))}
            <div className="flex justify-between pt-4 border-t border-gray-200">
              <span className="font-medium">Total</span>
              <span className="font-medium">£13,160.00</span>
            </div>
          </div>
        </div>

        <div className="bg-[#F9FAFB] rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-medium">Instant payouts</h2>
            <Button className="bg-[#24EC7E] text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-[#20D870]">
              Payout now
            </Button>
          </div>
          <div className="flex justify-between items-center py-1">
            <span className="text-gray-600">Available Payout now: £9,500.00</span>
            <span className="font-medium">£1,160.00</span>
          </div>
        </div>

        <div className="bg-[#F9FAFB] rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-medium">Currently being processed</h2>
          </div>
          <div className="flex justify-between py-1">
            <span className="font-medium">Total</span>
            <span className="font-medium">£0.00</span>
          </div>
        </div>

        <div className="bg-[#F9FAFB] rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-medium">Recently completed</h2>
            <Button variant="link" className="text-sm text-gray-500 hover:text-gray-700">
              See more
            </Button>
          </div>
          <div className="space-y-4">
            {[
              { date: "16 November 2024", amount: "£27.00" },
              { date: "11 November 2024", amount: "£27.00" },
              { date: "2 November 2024", amount: "£27.00" },
              { date: "28 October 2024", amount: "£27.00" },
            ].map((payment) => (
              <div key={payment.date} className="flex justify-between py-1">
                <div className="flex items-center gap-2">
                  <span className="text-gray-600">Paid on {payment.date}</span>
                  <span className="text-xs bg-[#ECFDF5] text-[#059669] px-2 py-0.5 rounded">Paid</span>
                </div>
                <span className="font-medium">{payment.amount}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-[#F9FAFB] rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-medium">Issuing balance</h2>
            <Button className="bg-[#F5F5F5] text-[#666666] px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-200">
              Add to balance
            </Button>
          </div>
          <div className="space-y-4">
            <div className="flex justify-between py-1">
              <span className="text-gray-600">Funds available for spending on your issued card</span>
              <span className="font-medium">£0.00</span>
            </div>
            <div className="flex justify-between py-1">
              <span className="text-gray-600">Available soon on your issued card</span>
              <span className="font-medium">£1,090.00</span>
            </div>
            <div className="flex justify-between pt-4 border-t border-gray-200">
              <span className="font-medium">Total</span>
              <span className="font-medium">£1,090.00</span>
            </div>
          </div>
        </div>
    </div>
  );

  const renderAllActivityContent = () => (
    <div className="bg-white border rounded-lg p-6 mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-base font-medium">Transactions</h2>
        <button
          onClick={generatePDF}
          className="bg-[#24EC7E] text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-[#20D870] flex items-center gap-2"
        >
          <Download className="h-4 w-4" />
          {isDownloadLoading ? 'Generating PDF...' : 'Download PDF'}
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid In</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid Out</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactions.map((tx) => (
              <tr key={tx.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.type}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{new Date(tx.date).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.description}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.paidIn !== undefined ? tx.paidIn.toFixed(2) : '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.paidOut !== undefined ? tx.paidOut.toFixed(2) : '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{`${tx.balance.amount.toFixed(2)} ${tx.balance.currency}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {transactions.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No transactions available.
          </div>
        )}
      </div>
    </div>
  );

  const renderTopUpsContent = () => {
    return (
      <div className="space-y-6">
        <div className="bg-white border rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">Top up your account</h2>
          <Button className="bg-[#24EC7E] text-white hover:bg-[#20D870]">Add funds</Button>
        </div>

        <div className="bg-white border rounded-lg">
          <div className="grid grid-cols-3 gap-4 p-4 border-b bg-gray-50 text-sm font-medium text-gray-500">
            <div>Amount</div>
            <div>Status</div>
            <div>Date</div>
          </div>

          {[...Array(5)].map((_, index) => (
            <div key={index} className="grid grid-cols-3 gap-4 p-4 border-b last:border-b-0">
              <div className="font-medium">£1,000.00 GBP</div>
              <div className="flex items-center gap-2">
                <span className="text-xs bg-[#ECFDF5] text-[#059669] px-2 py-0.5 rounded">Completed</span>
              </div>
              <div className="text-gray-600">Nov 16, 2024</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="w-full min-h-screen bg-white p-8">
      <div className="max-w-[1200px] mx-auto space-y-6">
        <h1 className="text-2xl font-medium text-[#111827]">Balances</h1>
        
        <div className="flex justify-between items-center">
          <div className="border-b border-gray-200 flex-1">
            <nav className="flex gap-8">
              {["Overview", "All activity", "Top-ups"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`py-4 text-sm font-medium ${
                    activeTab === tab
                      ? "border-b-2 border-[#24EC7E] text-gray-900"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>
          
          {activeTab !== "Overview" && (
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                size="sm" 
                className="gap-2"
                onClick={() => setIsFilterDialogOpen(true)}
              >
                <Filter className="h-4 w-4" />
                Filter
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                className="gap-2"
                onClick={() => setIsAnalyzeOpen(true)}
              >
                <BarChart3 className="h-4 w-4" />
                Analyse
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                className="gap-2"
                onClick={generatePDF}
                disabled={isDownloadLoading}
              >
                <Download className="h-4 w-4" />
                {isDownloadLoading ? 'Generating...' : 'Export Transactions'}
              </Button>
            </div>
          )}
        </div>

        {activeTab === "Overview" && renderOverviewContent()}
        {activeTab === "All activity" && renderAllActivityContent()}
        {activeTab === "Top-ups" && renderTopUpsContent()}

        {/* Filter Dialog */}
        <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
          <DialogContent className="sm:max-w-[400px]">
            <DialogHeader>
              <div className="flex justify-end">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsFilterDialogOpen(false)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </DialogHeader>
            <div className="space-y-4">
              {filterOptions.map((option) => (
                <div key={option.id} className="flex items-center space-x-2 py-2">
                  <Checkbox id={option.id} />
                  <label
                    htmlFor={option.id}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
              <Button 
                className="w-full bg-[#24EC7E] text-white hover:bg-[#20D870]"
                onClick={() => setIsFilterDialogOpen(false)}
              >
                Filter
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* Analyze Dialog */}
        <Dialog open={isAnalyzeOpen} onOpenChange={setIsAnalyzeOpen}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <div className="flex justify-between items-start">
                <div>
                  <DialogTitle className="text-2xl font-semibold mb-2">
                    Analyze your data with AI and SQL
                  </DialogTitle>
                  <DialogDescription className="text-base text-gray-600">
                    Use Sigma to explore your data effortlessly, leveraging AI or custom SQL queries. 
                    Select a report to begin analyzing your transactions date.
                  </DialogDescription>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsAnalyzeOpen(false)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </DialogHeader>
            <div className="space-y-4 mt-4">
              {analyzeOptions.map((option, index) => (
                <button
                  key={index}
                  className="w-full p-4 bg-white border rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-between group"
                  onClick={() => setIsAnalyzeOpen(false)}
                >
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                      <option.icon className="h-6 w-6 text-gray-600" />
                    </div>
                    <div className="text-left">
                      <h3 className="font-medium text-gray-900">{option.title}</h3>
                      <p className="text-sm text-gray-600">{option.description}</p>
                    </div>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-600" />
                </button>
              ))}
            </div>
          </DialogContent>
        </Dialog>

        {/* Calendar Dialog */}
        <Dialog open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
          <DialogContent className="sm:max-w-[400px]">
            <DialogHeader>
              <div className="flex items-center justify-between">
                <DialogTitle>Select date range</DialogTitle>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsCalendarOpen(false)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </DialogHeader>
            <div className="p-4">
              <Calendar
                mode="range"
                selected={date}
                onSelect={setDate}
                className="rounded-md border"
                styles={{
                  months: {
                    padding: '16px',
                  },
                  head_cell: {
                    width: '40px',
                    height: '40px',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    color: '#059669',
                  },
                  cell: {
                    width: '40px',
                    height: '40px',
                    fontSize: '14px',
                    color: '#374151',
                  },
                  day: {
                    margin: '0',
                    width: '40px',
                    height: '40px',
                    fontSize: '14px',
                  },
                  day_range_start: {
                    backgroundColor: '#24EC7E',
                    color: 'white',
                  },
                  day_range_end: {
                    backgroundColor: '#24EC7E',
                    color: 'white',
                  },
                  day_range_middle: {
                    backgroundColor: '#ECFDF5',
                    color: '#059669',
                  },
                  day_today: {
                    backgroundColor: '#F3F4F6',
                    color: '#374151',
                  },
                }}
                components={{
                  IconLeft: () => <ChevronLeft className="h-4 w-4" />,
                  IconRight: () => <ChevronRight className="h-4 w-4" />,
                }}
              />
            </div>
            <DialogFooter>
              <Button 
                className="w-full bg-[#24EC7E] text-white hover:bg-[#20D870]"
                onClick={handleApplyDateRange}
              >
                Apply
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}

export default BalancesPage;