import React from 'react';
import CustomModal from './ui/CustomModal';
import CustomButton from './ui/CustomButton';
import { useAuth } from '../../contexts/AuthContext';

interface SessionExpiredModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({ isOpen, onClose }) => {
  const { logout } = useAuth();

  const handleClose = async () => {
    try {
      await logout();
      onClose();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <CustomModal 
      isOpen={isOpen} 
      onClose={handleClose} 
      centered
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4">
        <CustomModal.Header onClose={handleClose}>
          <h5 className="text-xl font-semibold">Session Expired</h5>
        </CustomModal.Header>

        <CustomModal.Body>
          <p className="text-gray-700">Your session has expired. Please log in again.</p>
        </CustomModal.Body>

        <CustomModal.Footer>
          <CustomButton
            variant="primary"
            onClick={handleClose}
            className="bg-green-500 text-white rounded-full px-4 py-2 hover:bg-green-600 transition-colors"
          >
            OK
          </CustomButton>
        </CustomModal.Footer>
      </div>
    </CustomModal>
  );
};

export default SessionExpiredModal;
