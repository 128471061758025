import { useState } from 'react'
import { CreditCard, Building2, QrCode, Wallet, ChevronRight } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

type PaymentMethod = 'card' | 'link' | 'qr' | 'wallet'
type PaymentStep = 'method' | 'details' | 'summary'

interface PaymentDetails {
  amount: string
  cardHolder: string
  cardNumber: string
  expiry: string
  cvv: string
  currency: string
  description: string
  frequency: 'one-time' | 'recurring'
}

export function PaymentFlowPage() {
  const [step, setStep] = useState<PaymentStep>('method')
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    amount: '',
    cardHolder: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
    currency: 'GBP',
    description: '',
    frequency: 'one-time'
  })

  const paymentMethods = [
    { id: 'card' as PaymentMethod, title: 'Credit/Debit card', description: 'Accept payment with a credit or debit card', icon: CreditCard },
    { id: 'link' as PaymentMethod, title: 'Payment link', description: 'Accept payment by generating a unique payment link', icon: Building2 },
    { id: 'qr' as PaymentMethod, title: 'QR Code', description: 'Accept payment by scanning a qr code', icon: QrCode },
    { id: 'wallet' as PaymentMethod, title: 'Mobile wallet', description: 'Accept payment with mobile wallet like Apple Pay and Google Pay', icon: Wallet }
  ]

  const handleMethodSelect = (method: PaymentMethod) => {
    if (method === 'card') {
      setStep('details')
    }
  }

  const handleDetailsSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setStep('summary')
  }

  const handlePaymentAccept = () => {
    // Handle payment acceptance logic here
    console.log('Payment accepted:', paymentDetails)
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 max-w-3xl">
      {step === 'method' && (
        <>
          <h1 className="text-3xl font-semibold mb-6">Accept payment</h1>
          <Card className="w-full">
            <CardHeader>
              <CardTitle className="text-xl">Select payment method</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              {paymentMethods.map((method) => (
                <button
                  key={method.id}
                  onClick={() => handleMethodSelect(method.id)}
                  className="w-full flex items-center justify-between p-4 bg-white hover:bg-gray-50 rounded-lg border transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                      <method.icon className="w-6 h-6 text-gray-600" />
                    </div>
                    <div className="text-left">
                      <div className="font-medium text-lg text-gray-900">{method.title}</div>
                      <div className="text-sm text-gray-500">{method.description}</div>
                    </div>
                  </div>
                  <ChevronRight className="w-6 h-6 text-gray-400" />
                </button>
              ))}
            </CardContent>
          </Card>
        </>
      )}

      {step === 'details' && (
        <>
          <h1 className="text-3xl font-semibold mb-6">Credit/Debit card details</h1>
          <form onSubmit={handleDetailsSubmit} className="space-y-6">
            <Card className="w-full">
              <CardContent className="pt-6">
                <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-6">
                  <p className="text-sm text-green-800">
                    Enter card details to complete the payment process. Information entered is encrypted and
                    protected with advanced security measures to ensure safe transaction.
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <Label htmlFor="amount">Amount</Label>
                    <Input
                      id="amount"
                      value={paymentDetails.amount}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, amount: e.target.value })}
                      placeholder="000"
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label htmlFor="cardHolder">Card holder name</Label>
                    <Input
                      id="cardHolder"
                      value={paymentDetails.cardHolder}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, cardHolder: e.target.value })}
                      placeholder="Enter name of card holder"
                      className="mt-1"
                    />
                  </div>
                  <div className="md:col-span-2">
                    <Label htmlFor="cardNumber">Credit/Debit card number</Label>
                    <Input
                      id="cardNumber"
                      value={paymentDetails.cardNumber}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, cardNumber: e.target.value })}
                      placeholder="0000 0000 0000 0000"
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label htmlFor="expiry">Expiry</Label>
                    <Input
                      id="expiry"
                      value={paymentDetails.expiry}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, expiry: e.target.value })}
                      placeholder="MM/YY"
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label htmlFor="cvv">CVV</Label>
                    <Input
                      id="cvv"
                      value={paymentDetails.cvv}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, cvv: e.target.value })}
                      placeholder="000"
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label>Select currency</Label>
                    <Select
                      value={paymentDetails.currency}
                      onValueChange={(value) => setPaymentDetails({ ...paymentDetails, currency: value })}
                    >
                      <SelectTrigger className="mt-1">
                        <SelectValue placeholder="Select currency" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="GBP">🇬🇧 GBP</SelectItem>
                        <SelectItem value="USD">🇺🇸 USD</SelectItem>
                        <SelectItem value="EUR">🇪🇺 EUR</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="description">Description or Reference (Optional)</Label>
                    <Input
                      id="description"
                      value={paymentDetails.description}
                      onChange={(e) => setPaymentDetails({ ...paymentDetails, description: e.target.value })}
                      placeholder="Enter a product description or reference"
                      className="mt-1"
                    />
                  </div>
                  <div className="md:col-span-2">
                    <RadioGroup
                      value={paymentDetails.frequency}
                      onValueChange={(value: 'one-time' | 'recurring') =>
                        setPaymentDetails({ ...paymentDetails, frequency: value })
                      }
                      className="flex gap-4"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="one-time" id="one-time" />
                        <Label htmlFor="one-time">One-Time payment</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="recurring" id="recurring" />
                        <Label htmlFor="recurring">Recurring payment</Label>
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Button
              type="submit"
              className="w-full"
              style={{ backgroundColor: '#24EC7E', borderRadius: '100px' }}
            >
              Proceed
            </Button>
          </form>
        </>
      )}

      {step === 'summary' && (
        <>
          <h1 className="text-3xl font-semibold mb-6">Payment summary</h1>
          <Card className="w-full rounded-lg border border-gray-200 shadow-sm">
            <CardContent className="p-6 space-y-6">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Transfer summary</h2>
                  <button className="text-sm text-gray-500 hover:text-gray-700 hover:underline">Edit</button>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Amount</span>
                    <span className="font-medium">{paymentDetails.amount} {paymentDetails.currency}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Exchange rate</span>
                    <span className="font-medium">0.79 GBP</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Processing fees</span>
                    <span className="font-medium">1.87 GBP</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Amount to be received</span>
                    <span className="font-medium">198.13 GBP</span>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 pt-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Recipient details</h2>
                  <button className="text-sm text-gray-500 hover:text-gray-700 hover:underline">Change</button>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Name</span>
                    <span className="font-medium">{paymentDetails.cardHolder}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Credit/Debit card number</span>
                    <span className="font-medium">{paymentDetails.cardNumber}</span>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 pt-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Supplement</h2>
                  <button className="text-sm text-gray-500 hover:text-gray-700 hover:underline">Edit</button>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Reference</span>
                    <span className="font-medium">{paymentDetails.description || '-'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Frequency</span>
                    <span className="font-medium">
                      {paymentDetails.frequency === 'one-time' ? 'One-Time payment' : 'Recurring payment'}
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Button
            onClick={handlePaymentAccept}
            className="w-full mt-6 h-12 text-lg font-medium"
            style={{ backgroundColor: '#24EC7E', borderRadius: '100px' }}
          >
            Accept payment
          </Button>
        </>
      )}
    </div>
  )
}

export default PaymentFlowPage;