import { Send, ArrowUp, Plus, ArrowUpDown } from "lucide-react"
import { useModal } from '../../../contexts/ModalContext';
import TransferModal from '../sendModal/transfer-modal';
import TopUpModal from '../TopupModal/top-up-modal';
import ConvertCurrencyModalComponent from '../convertModal/convert-currency-modal';
import RequestModal from '../RequestMoney/request-money-modal';

const buttonStyles = {
  action: {
    backgroundColor: '#F5F5F5',
    color: '#111827',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    height: '36px'
  }
}

export function ActionButtonsComponent() {
  const { openModal } = useModal();

  return (
    <div style={{ display: 'flex', gap: '8px', marginBottom: '24px' }}>
      <button
        style={buttonStyles.action}
        onClick={() => openModal({ content: <TransferModal /> })}
      >
        <Send size={16} />
        Send
      </button>
      <button
        style={buttonStyles.action}
        onClick={() => openModal({ content: <TopUpModal /> })}
      >
        <ArrowUp size={16} />
        Top-up
      </button>
      <button
        style={buttonStyles.action}
        onClick={() => openModal({ content: <RequestModal /> })}
      >
        <Plus size={16} />
        Request
      </button>
      <button
        style={buttonStyles.action}
        onClick={() => openModal({ content: <ConvertCurrencyModalComponent /> })}
      >
        <ArrowUpDown size={16} />
        Convert
      </button>
    </div>
  )
}