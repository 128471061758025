import React from 'react';
import { CardsListComponent } from '../components/cardPages/cards-list';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "../components/cardManagement/uicustomizer/button";

export function CardListPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <CardsListComponent />
    </div>
  );
}

export default CardListPage;
  