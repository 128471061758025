// src/components/sidebars/RightSideMenu.tsx

import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  Bell,
  X,
  User as UserIcon,
  Settings as SettingsIcon,
  LogOut,
  HelpCircle,
  Cog,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import RightSideMenuContext from '@/contexts/RightSideMenuContext';
import { useModal } from '@/contexts/ModalContext';
import BankSettingsModalComponent from '../headers/settings/bank-settings-modal'; // Adjust the path as needed
import HelpCenter from '@/components/help-center';
import { NotificationsModalComponent } from '@/components/notifications-modal';

// Profile Modal Content Component
const ProfileModalContent: React.FC = () => {
  return (
    <div className="p-4">
      {/* Replace this with your actual Profile Modal Content */}
      <h2 className="text-xl font-semibold mb-2">Edit Profile</h2>
      <p>Profile modal content goes here.</p>
    </div>
  );
};

const RightSideMenu: React.FC = () => {
  const { isOpen, toggleMenu, closeMenu } = useContext(RightSideMenuContext);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLElement | null>(null); // To store the element that triggered the sidebar

  const { openModal } = useModal(); // Destructure the openModal function

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, display an error message to the user
    }
  };

  // Function to open Profile Modal
  const openProfileModal = () => {
    openModal({
      title: 'Edit Profile',
      content: <ProfileModalContent />, // Ensure you have this component defined
    });
    closeMenu();
  };

  // Function to open Settings Modal
  const openSettingsModal = () => {
    openModal({
      title: 'Settings',
      content: <BankSettingsModalComponent />, // Ensure you have this component imported
      // Optionally, include steps or other configurations
    });
    closeMenu();
  };

  // Function to open Notifications Modal
  const openNotificationsModal = () => {
    openModal({
      title: 'Notifications',
      content: <NotificationsModalComponent />, // Ensure this component is imported
    });
    closeMenu();
  };

  // Function to open Help Center Modal
  const openHelpCenterModal = () => {
    openModal({
      title: 'Help Center',
      content: <HelpCenter />, // Use the imported default export
    });
    closeMenu();
  };

  // Close sidebar on Esc key press
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeMenu();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Save the element that triggered the sidebar
      triggerRef.current = document.activeElement as HTMLElement;

      // Prevent background scrolling
      document.body.style.overflow = 'hidden';

      // Add event listener for Esc key
      document.addEventListener('keydown', handleKeyDown);

      // Shift focus to the sidebar
      sidebarRef.current?.focus();
    } else {
      // Restore background scrolling
      document.body.style.overflow = 'auto';

      // Remove event listener
      document.removeEventListener('keydown', handleKeyDown);

      // Restore focus to the triggering element
      triggerRef.current?.focus();
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, closeMenu]);

  const menuContent = (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={closeMenu}
          aria-hidden="true"
        />
      )}

      {/* Slide-in Menu */}
      <div
        className={`fixed top-0 right-0 w-full md:w-80 h-full bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out flex flex-col ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="right-side-menu-title"
        ref={sidebarRef}
        tabIndex={-1}
      >
        {/* Menu Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 id="right-side-menu-title" className="text-lg font-semibold text-gray-900">
            Profile
          </h2>
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeMenu();
            }}
            className="p-2 hover:bg-gray-100 rounded-full"
            aria-label="Close Menu"
          >
            <X className="w-5 h-5 text-gray-600" />
          </button>
        </div>

        {/* Profile Section */}
        <div className="p-4 border-b">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-12 h-12 bg-green-600 rounded-full flex items-center justify-center text-white text-lg">
              {user?.name ? user.name.charAt(0).toUpperCase() : 'U'}
            </div>
            <div>
              <h3 className="font-medium text-gray-900">{user?.name || 'User'}</h3>
              <p className="text-sm text-gray-500">Business Account</p>
            </div>
          </div>
          <div className="mt-2 p-3 bg-gray-50 rounded-lg">
            <p className="text-sm text-gray-600">Total Account Balance</p>
            <p className="text-xl font-semibold text-gray-900">GBP 652.75</p>
          </div>
        </div>

        {/* Navigation Links and Sign Out Button */}
        <nav className="p-4 flex flex-col flex-1">
          {/* Scrollable Navigation Links */}
          <div className="flex-1 overflow-y-auto space-y-1">
            <button
              onClick={openProfileModal}
              className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 rounded-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-green-600"
            >
              <UserIcon className="w-4 h-4 text-gray-600" />
              <span className="text-gray-700">Profile</span>
            </button>
            <button
              onClick={openSettingsModal}
              className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 rounded-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-green-600"
            >
              <SettingsIcon className="w-4 h-4 text-gray-600" />
              <span className="text-gray-700">Settings</span>
            </button>
            <button
              onClick={openSettingsModal}
              className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 rounded-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-green-600"
            >
              <Cog className="w-4 h-4 text-gray-600" />
              <span className="text-gray-700">Account Settings</span>
            </button>
            <button
              onClick={openNotificationsModal}
              className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 rounded-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-green-600"
            >
              <Bell className="w-4 h-4 text-gray-600" />
              <span className="text-gray-700">Notifications</span>
            </button>
            <button
              onClick={openHelpCenterModal}
              className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 rounded-lg w-full text-left focus:outline-none focus:ring-2 focus:ring-green-600"
            >
              <HelpCircle className="w-4 h-4 text-gray-600" />
              <span className="text-gray-700">Help Center</span>
            </button>
          </div>

          {/* Sign Out Button */}
          <div className="mt-4 pb-4">
            <button
              onClick={handleLogout}
              className="flex items-center gap-3 px-4 py-3 bg-[rgb(0,134,100)] hover:bg-[rgb(0,160,120)] rounded-full text-white w-full text-left transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-600 mb-4"
            >
              <LogOut className="w-4 h-4 text-white" />
              <span>Sign Out</span>
            </button>
          </div>
        </nav>
      </div>
    </>
  );

  return typeof window !== 'undefined' ? createPortal(menuContent, document.body) : null;
};

export default RightSideMenu;
