import React from 'react';
import { BulkPayments } from '../components/bulk-payments';
import { useParams, useNavigate } from 'react-router-dom';


export function BulkPaymentsPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <BulkPayments />
    </div>
  );
}

export default BulkPaymentsPage;
  