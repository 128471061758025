import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { ScrollArea } from './ui/scroll-area';
import { User, Mail, Phone, Upload, Lock, MapPin, Globe } from 'lucide-react';
import { useModal } from '../../../contexts/ModalContext';
import { useDropzone } from 'react-dropzone';

type Step = {
  label: string;
  status: 'current' | 'completed' | 'upcoming';
};

const ProfileTabContent: React.FC = () => {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfilePicture(event.target?.result as string);
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
  });

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="picture" className="text-[#00423C]">Profile Picture</Label>
        <div
          {...getRootProps()}
          className={`border-2 rounded-lg p-4 text-center cursor-pointer transition-colors ${
            isDragActive ? 'border-[#097152] bg-[#AFF8C8]' : 'border-[#AFF8C8]'
          }`}
        >
          <input {...getInputProps()} />
          {profilePicture ? (
            <img src={profilePicture} alt="Profile" className="w-24 h-24 rounded-full mx-auto" />
          ) : (
            <div className="text-[#097152]">
              <Upload className="w-12 h-12 mx-auto mb-2" />
              <p>Drag & drop or click to upload</p>
            </div>
          )}
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="name" className="text-[#00423C]">Name</Label>
        <div className="relative">
          <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#097152]" />
          <Input id="name" placeholder="Your name" className="pl-10 rounded-lg border-[#AFF8C8]" />
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="email" className="text-[#00423C]">Email</Label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#097152]" />
          <Input id="email" type="email" placeholder="Your email" className="pl-10 rounded-lg border-[#AFF8C8]" />
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="mobile" className="text-[#00423C]">Mobile</Label>
        <div className="relative">
          <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#097152]" />
          <Input id="mobile" type="tel" placeholder="Your mobile number" className="pl-10 rounded-lg border-[#AFF8C8]" />
        </div>
      </div>
    </div>
  );
};

const SecurityTabContent: React.FC = () => {
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const passwordsMatch = password === confirmPassword;

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Switch 
          id="2fa" 
          checked={twoFAEnabled} 
          onCheckedChange={setTwoFAEnabled}
          className="data-[state=checked]:bg-[#097152]"
        />
        <Label htmlFor="2fa" className="text-[#00423C]">Enable Two-Factor Authentication</Label>
      </div>
      <div className="space-y-2">
        <Label htmlFor="password" className="text-[#00423C]">Change Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#097152]" />
          <Input
            id="password"
            type="password"
            placeholder="New password"
            className="pl-10 rounded-lg border-[#AFF8C8]"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="confirmPassword" className="text-[#00423C]">Confirm Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#097152]" />
          <Input
            id="confirmPassword"
            type="password"
            placeholder="Confirm new password"
            className={`pl-10 rounded-lg ${!passwordsMatch && confirmPassword ? 'border-red-500' : 'border-[#AFF8C8]'}`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {!passwordsMatch && confirmPassword && (
          <p className="text-red-500 text-sm mt-1">Passwords do not match</p>
        )}
      </div>
    </div>
  );
};

const ActivityTabContent: React.FC = () => {
  const [ipInfo, setIpInfo] = useState({ ip: '', city: '', country: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const { ip } = await ipResponse.json();

        const geoResponse = await fetch(`https://ipapi.co/${ip}/json/`);
        const geoData = await geoResponse.json();

        setIpInfo({
          ip,
          city: geoData.city,
          country: geoData.country_name,
        });
      } catch (error) {
        console.error('Error fetching IP info:', error);
        setIpInfo({ ip: 'Error fetching IP', city: 'Unknown', country: 'Unknown' });
      } finally {
        setLoading(false);
      }
    };

    fetchIpInfo();
  }, []);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label className="text-[#00423C]">Last Login</Label>
        {loading ? (
          <div className="text-[#097152]">Loading...</div>
        ) : (
          <>
            <div className="flex items-center space-x-2 text-[#00423C]">
              <MapPin className="h-4 w-4 text-[#097152]" />
              <span>{`${ipInfo.city}, ${ipInfo.country}`}</span>
            </div>
            <div className="flex items-center space-x-2 text-[#00423C]">
              <Globe className="h-4 w-4 text-[#097152]" />
              <span>{`IP: ${ipInfo.ip}`}</span>
            </div>
          </>
        )}
      </div>
      <Button variant="outline" className="w-full rounded-lg border-[#097152] text-[#097152] hover:bg-[#AFF8C8]">
        <span className="mr-2">📄</span>
        Request Account Data
      </Button>
    </div>
  );
};

export function BankSettingsModalComponent() {
  const { updateSteps, closeModal } = useModal();
  const [activeTab, setActiveTab] = useState<'profile' | 'security' | 'activity'>('profile');

  useEffect(() => {
    const newSteps: Step[] = [
      { label: 'Profile', status: activeTab === 'profile' ? 'current' : 'completed' },
      { label: 'Security', status: activeTab === 'security' ? 'current' : activeTab === 'activity' ? 'completed' : 'upcoming' },
      { label: 'Activity', status: activeTab === 'activity' ? 'current' : 'upcoming' },
    ];
    updateSteps(newSteps);
  }, [activeTab, updateSteps]);

  const handleSaveChanges = () => {
    // Implement save functionality here
    closeModal();
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid w-full grid-cols-3 mb-6 p-1 bg-[#AFF8C8] rounded-full">
          <TabsTrigger
            value="profile"
            className={`rounded-full transition-colors ${
              activeTab === 'profile' ? 'bg-[#097152] text-white' : 'bg-transparent text-[#00423C]'
            }`}
          >
            Profile
          </TabsTrigger>
          <TabsTrigger
            value="security"
            className={`rounded-full transition-colors ${
              activeTab === 'security' ? 'bg-[#097152] text-white' : 'bg-transparent text-[#00423C]'
            }`}
          >
            Security
          </TabsTrigger>
          <TabsTrigger
            value="activity"
            className={`rounded-full transition-colors ${
              activeTab === 'activity' ? 'bg-[#097152] text-white' : 'bg-transparent text-[#00423C]'
            }`}
          >
            Activity
          </TabsTrigger>
        </TabsList>
        <div className="h-[400px]">
          <ScrollArea className="h-full rounded-lg border border-[#AFF8C8] p-4">
            <TabsContent value="profile" className="mt-0">
              <ProfileTabContent />
            </TabsContent>
            <TabsContent value="security" className="mt-0">
              <SecurityTabContent />
            </TabsContent>
            <TabsContent value="activity" className="mt-0">
              <ActivityTabContent />
            </TabsContent>
          </ScrollArea>
        </div>
      </Tabs>
      <Button
        onClick={handleSaveChanges}
        className="w-full mt-6 rounded-lg bg-[#097152] hover:bg-[#00423C] text-white transition-colors"
      >
        Save Changes
      </Button>
    </div>
  );
}

export default BankSettingsModalComponent;