// src/App.tsx

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context Providers
import { AuthProvider } from './contexts/AuthContext';
import { WalletProvider } from './contexts/WalletContext';
import { ModalProvider } from './contexts/ModalContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { CRMProvider } from './contexts/CRMContext'; // Added CRMProvider
import { ToastProvider } from './contexts/ToastContext'; // Added ToastProvider
import { TransferModal } from './components/dashboard/components/sendModal/transfer-modal';
import { ModalPageProvider } from './contexts/ModalPageContext';
import TransferPage from './pages/TransferPage';

// Layouts
import MainLayout from './layouts/MainLayout';

// Pages
import Login from './pages/Login';
import Home from './pages/Home';
import OrderCard from './pages/OrderCard';
import Transactions from './pages/Transactions';
import Dashboard from './components/dashboard/dashboard';
import CRM from './pages/crm'; // Ensure 'crm.tsx' is lowercase
import Contacts from './pages/contacts';
import Dispute from './pages/Dispute';
import WalletOverview from './pages/balances';
import Statements from './pages/Statements';
import CalendarPage from './pages/Calendar';
import TaxGeneration from './pages/taxGeneration'; // Ensure 'taxGeneration.tsx' is correctly cased
import PageInDevelopment from './pages/PageInDevelopment';
import CRMPageInDevelopment from './pages/CRMPageInDevelopment';
import Contracts from './pages/contracts';

import TicketsPage from './pages/tickets';

import Credit from './pages/Credit';
import CardManagementPage from './pages/cardManagement';
import CardCustomizationPage from './pages/cardCustomization'; // Ensure 'cardCustomization.tsx' is correctly cased
import CardListPage from './pages/cardList';
import Subscriptions from './pages/subscriptions';
import Clients from './pages/clients';
import Payees from './pages/Payees';

import BlockPage from './components/dashboardNew/app-page';
// Components

import Invoices from './pages/invoices';
import ErrorBoundary from './ErrorBoundary';
import AuthListener from './contexts/AuthListener';
import ProtectedRoute from './components/ProtectedRoute';
import CreatePaymentLinkPage from './pages/createPaymentLink';
import PaymentLinkPage from './components/payment-links';

import ProjectDetails from './pages/ProjectDetails';
import ProjectDetailsPage from './pages/ProjectDetails'; // Verify if the file name is 'ProjectDetails.tsx' or 'projectDetails.tsx'
import Payments from './pages/payments';
import CreateInvoices from './pages/createInvoices';
import InstantPay from './pages/InstantPay';
import ViewPaymentLinks from './pages/viewPaymentLinks';
import ScheduleTransfer from './pages/ScheduleTransfer';
import AddSchedulePayment from './pages/AddSchedulePayment';
import SendPayment from './pages/sendPayment';
import CardOrderFlowPage from './pages/cardOrderFlow';
import BulkPaymentsPage from './pages/bulkPayments';
import DirectDebitsPage from './pages/directDebits';
import { CardManagementComponent } from './components/cardPages/CardManagement';
import FinancialAuditPage from './pages/financialAudit';
import PaymentFlowPage from './pages/payment-flow';
import StatementsPagesNew from './components/statements-pages';

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('_api_token');
      const userData = localStorage.getItem('user');
      
      if (token && userData) {
        console.log('User is authenticated');
        if (location.pathname === '/login') {
          console.log('Redirecting to home from login page');
          navigate('/home', { replace: true });
        }
      } else {
        console.log('User is not authenticated');
        if (location.pathname !== '/login') {
          console.log('Redirecting to login page');
          navigate('/login', { replace: true });
        }
      }
    };

    checkAuth();
  }, [navigate, location.pathname]);

  return (
    <ErrorBoundary>
      <ModalProvider>
        <AuthProvider>
          <Routes>
            {/* Public Route */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <WalletProvider>
                    <SidebarProvider>
                      <CRMProvider>
                        <ToastProvider>
                          <ModalPageProvider>
                            <MainLayout />
                          </ModalPageProvider>
                        </ToastProvider>
                      </CRMProvider>
                    </SidebarProvider>
                  </WalletProvider>
                </ProtectedRoute>
              }
            >
              {/* Index Route for '/' */}
              <Route index element={<BlockPage />} />

              {/* Define routes for implemented pages */}
              <Route path="home" element={<BlockPage />} />
              <Route path="dashboard" element={<BlockPage />} />
              <Route path="ordercard" element={<OrderCard />} />
              <Route path="card-customizer" element={<CardCustomizationPage />} />
              <Route path="card-management/:cardId" element={<CardManagementPage />} />
              <Route path="transactions" element={<Transactions />} />
       
              <Route path="balances" element={<WalletOverview />} />
              <Route path="taxGeneration" element={<TaxGeneration />} />
              <Route path="invoicing" element={<Invoices />} />
              <Route path="payees" element={<Payees />} />
              <Route path="create-payment-link" element={<CreatePaymentLinkPage />} />
              <Route path="payment-link" element={<PaymentLinkPage />} />
              <Route path="DashboardNew" element={<BlockPage />} />
              <Route path="payments" element={<Payments />} />
              <Route path="create-invoice" element={<CreateInvoices />} />
              <Route path="instant-pay" element={<InstantPay />} />
              <Route path="bulk-payments" element={<BulkPaymentsPage />} />
              <Route path="financial-audit" element={<FinancialAuditPage />} />
              <Route path="view-payment-links" element={<ViewPaymentLinks />} />
              <Route path="scheduled-payments" element={<ScheduleTransfer />} />
              <Route path="send-payment" element={<SendPayment />} />
              <Route path="direct-debits" element={<DirectDebitsPage />} />
              <Route path="statements" element={<StatementsPagesNew />} />
              {/* Update the pay-transfer route */}
              <Route path="pay-transfer" element={<TransferPage />} />
              <Route path="add-schedule-payment" element={<AddSchedulePayment />} />
              {/* Routes for non-CRM pages not yet implemented */}
              <Route path="fingerPay" element={<PageInDevelopment />} />
     
              <Route path="card-machine" element={<PageInDevelopment />} />
              <Route path="connected" element={<PageInDevelopment />} />
              <Route path="cards" element={<CardListPage />} />
              <Route path="card-order-flow" element={<CardOrderFlowPage />} />
              <Route path="payment-flow" element={<PaymentFlowPage />} />
              <Route path="accept-payment" element={<PaymentFlowPage />} />
              <Route path="cards-management" element={<CardManagementPage />} />
    

              {/* Projects Routes */}

              {/* CRM Nested Routes */}
              <Route
                path="crm/*" // Removed leading slash
                element={
                  <ProtectedRoute>
                    <CRMProvider>
                      <CRM />
                    </CRMProvider>
                  </ProtectedRoute>
                }
              >
                {/* Redirect root CRM path to main */}
                <Route index element={<Navigate to="main" replace />} />

              </Route>

              {/* Redirect unknown paths within protected routes */}
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Route>

            {/* Redirect any other unknown paths */}
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </AuthProvider>
      </ModalProvider>
      <ToastContainer />
    </ErrorBoundary>
  );
};

const AppContent = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default AppContent;
