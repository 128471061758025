import React from 'react';
import { AlertCircle, Code, Coffee } from 'lucide-react';

export default function PageInDevelopment() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <div className="text-center max-w-2xl">
        <div className="flex justify-center mb-6">
          <div className="relative">
            <Code size={80} className="text-[#097152]" />
            <Coffee size={40} className="text-[#24EC7E] absolute bottom-0 right-0" />
          </div>
        </div>
        <h1 className="text-4xl font-bold mb-4 text-[#00423C]">Page Under Development</h1>
        <p className="text-xl mb-8 text-gray-600">
          We're working hard to bring you an amazing new feature. Please check back soon!
        </p>
        <div className="flex items-center justify-center p-4 bg-[#AFF8C8] rounded-lg">
          <AlertCircle size={24} className="text-[#097152] mr-2" />
          <p className="text-[#00423C] font-medium">Expected completion: Coming Soon</p>
        </div>
      </div>
    </div>
  );
}

