import React from 'react';
// - import './CustomButton.css'; // Removed CSS import

interface CustomButtonProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger';
  onClick: () => void;
  children: React.ReactNode;
  className?: string; // Add this line to accept className
}

const CustomButton: React.FC<CustomButtonProps> = ({ variant = 'primary', onClick, children, className }) => {
  // Define styles based on variant
  const variantStyles: { [key: string]: React.CSSProperties } = {
    primary: {
      backgroundColor: '#007bff',
      color: 'white',
    },
    secondary: {
      backgroundColor: '#6c757d',
      color: 'white',
    },
    success: {
      backgroundColor: '#28a745',
      color: 'white',
    },
    danger: {
      backgroundColor: '#dc3545',
      color: 'white',
    },
  };

  const baseStyles: React.CSSProperties = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    margin: '0 5px',
  };

  return (
    <button
      style={{ ...baseStyles, ...variantStyles[variant] }}
      onClick={onClick}
      className={className}
    >
      {children}
    </button>
  );
};

export default CustomButton;
