import React, { useState } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom';

interface PaymentLink {
  currency: string
  amount: number
  expired: string
  countryCode: string
}

interface PaymentLinks {
  [month: string]: PaymentLink[]
}

export function ViewPaymentLinks() {
  const [hasLinks] = useState(true) // Set to false to show the empty state
  const [activeTab, setActiveTab] = useState('active')
  const navigate = useNavigate();
  const paymentLinks: PaymentLinks = {
    November: [
      { currency: 'GBP', amount: 2000, expired: '2nd November', countryCode: 'gb' },
      { currency: 'USD', amount: 2000, expired: '2nd November', countryCode: 'us' },
      { currency: 'nis', amount: 2000, expired: '2nd November', countryCode: 'il' }
    ],
    September: [
      { currency: 'GBP', amount: 2000, expired: '2nd November', countryCode: 'gb' },
      { currency: 'USD', amount: 2000, expired: '2nd November', countryCode: 'us' }
    ]
  }

  const buttonStyles = {
    primary: {
      backgroundColor: '#24EC7E',
      color: 'white',
      border: 'none',
      padding: '8px 16px',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer'
    },
    secondary: {
      backgroundColor: '#F5F5F5',
      color: '#666666',
      border: 'none',
      padding: '8px 16px',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer'
    }
  }

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Payment link</h1>
        <button style={buttonStyles.primary} onClick={() => navigate('/create-payment-link')}>
          Create New Link
          <ChevronDown className="h-4 w-4" />
        </button>
      </div>

      <div className="mb-8 inline-flex rounded-full bg-gray-100 p-1">
        <button
          className={`rounded-full px-8 py-2 text-sm font-medium transition-colors ${
            activeTab === 'active' ? 'bg-white text-gray-900' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('active')}
        >
          Active
        </button>
        <button
          className={`rounded-full px-8 py-2 text-sm font-medium transition-colors ${
            activeTab === 'inactive' ? 'bg-white text-gray-900' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('inactive')}
        >
          Inactive
        </button>
      </div>

      {hasLinks ? (
        <div className="space-y-8">
          {Object.entries(paymentLinks).map(([month, links]) => (
            <div key={month}>
              <h2 className="mb-4 text-xl font-semibold">{month}</h2>
              <div className="space-y-3">
                {links.map((link, index) => (
                  <div
                    key={index}
                    className="flex cursor-pointer items-center justify-between rounded-lg border border-gray-100 bg-white p-4 transition-shadow hover:shadow-sm"
                  >
                    <div className="flex items-center gap-3">
                      <CircleFlag countryCode={link.countryCode} height="30" width="30" />
                      <div>
                        <div className="font-medium">
                          {link.amount} {link.currency}
                        </div>
                        <div className="text-sm text-gray-500">Expired on {link.expired}</div>
                      </div>
                    </div>
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-32 text-center">
          <h3 className="mb-4 text-4xl font-medium text-gray-600">Oops!</h3>
          <p className="mb-8 text-lg text-gray-500">You currently have no active payment links.</p>
          <button style={buttonStyles.primary}>
            Create a new payment link to receive payments
          </button>
        </div>
      )}
    </div>
  )
}
export default ViewPaymentLinks;