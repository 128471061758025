// src/components/login/components/login-form.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Button } from "./ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Checkbox } from "./ui/checkbox";
import googlelogo from './images/google.svg';
import applelogo from './images/apple.svg';
import logo from './images/logo.png';
import GlobeWithoutRings from './ui/GlobeWithoutRings';
import GlobeFallback from './ui/GlobeFallback';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircleFlag } from 'react-circle-flags';
import { isWebGLAvailable } from '../../../utils/checkWebGL';
import { countries, Country } from './countries';
import CountrySelect from './ui/CountrySelect';
interface LoginFormProps {
  onLoginSuccess: (userData: any) => void;
}

interface Country {
  id: number;
  iso2: string;
  short_name: string;
}

export function LoginForm({ onLoginSuccess }: LoginFormProps) {
  const [isLoginView, setIsLoginView] = useState<boolean>(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Sign Up states
  const [company, setCompany] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [country, setCountry] = useState<number>(235);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const { login, loading, error, requiresTwoFactor } = useAuth();
  const navigate = useNavigate(); // For navigation after login or signup

  // Country selection states
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // WebGL state
  const [webGLSupported, setWebGLSupported] = useState<boolean>(true);
  const [webGLError, setWebGLError] = useState<boolean>(false);

  // Add at the top of your component
  const [loginError, setLoginError] = useState<string | null>(null);


  useEffect(() => {
    if (!isWebGLAvailable()) {
      setWebGLSupported(false);
      console.warn('WebGL is not supported by this browser.');
    }

    console.log(countries.data);
  }, []);



  const toggleView = () => {
    setIsLoginView(!isLoginView);
    // Reset form states when toggling
    setEmail('');
    setPassword('');
    setCompany('');
    setFirstName('');
    setLastName('');
    setSignupEmail('');
    setCountry(235);
    setPasswordConfirm('');
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

 const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    try {
      setLoginError(null); // Clear any previous errors
      const response = await login({ email, password });
      
      if (response?.success && response?.data) {
        onLoginSuccess(response.data);
        navigate('/home', { replace: true });
      } else {
        setLoginError('Login failed. Please check your credentials.');
      }
    } catch (error: any) {
      // Handle axios errors
      if (error.response) {
        // Server responded with an error status
        switch (error.response.status) {
          case 422:
            setLoginError('Invalid email or password format');
            break;
          case 401:
            setLoginError('Invalid credentials');
            break;
          case 403:
            setLoginError('Account is locked. Please contact support');
            break;
          default:
            setLoginError(`Login failed: ${error.response.data?.message || 'Please try again later'}`);
        }
      } else if (error.request) {
        // Request was made but no response received
        setLoginError('Network error. Please check your connection');
      } else {
        // Something else went wrong
        setLoginError('An error occurred during login');
      }
    }
  };

  const handleSignupSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Sign Up data:', { company, firstName, lastName, signupEmail, country, password, passwordConfirm });
    // Implement signup logic here
    try {
      alert('Signup functionality is not yet implemented.');
    } catch (error) {
      console.error('Signup error:', error);
    }
  };

  const handleWebGLError = useCallback(() => {
    setWebGLError(true);
    // Only log in development
    if (process.env.NODE_ENV === 'development') {
      console.warn('WebGL initialization failed - falling back to static globe.');
    }
  }, []);

  useEffect(() => {
    let globeCleanup: (() => void) | undefined;

    if (webGLSupported && !webGLError) {
      globeCleanup = () => {
        const canvas = document.querySelector('canvas');
        if (canvas) {
          const gl = canvas.getContext('webgl') as WebGLRenderingContext | null;
          if (gl) {
            const ext = gl.getExtension('WEBGL_lose_context');
            ext?.loseContext();
          }
        }
      };
    }

    return () => {
      if (globeCleanup) {
        globeCleanup();
      }
    };
  }, [webGLSupported, webGLError]);

  return (
    <div className="relative w-screen h-screen overflow-hidden bg-white">
      {/* Globe */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-[80vh] z-0 overflow-hidden flex items-center justify-center">
        {webGLSupported && !webGLError ? (
          <GlobeWithoutRings 
            className="w-full h-full"
            onError={handleWebGLError}
          />
        ) : (
          <GlobeFallback className="w-full h-full" />
        )}
      </div>

      {/* Logo */}
      <div className="absolute top-4 left-4 z-10">
        <img src={logo} alt="BANNC Logo" className="h-8" />
      </div>

      {/* Content */}
      <div className="flex items-center justify-center w-full h-full relative z-10 px-4">
        {/* Login Form */}
        <Card className="w-full max-w-md p-8 bg-white bg-opacity-90 backdrop-blur-md shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl font-bold">
              {isLoginView ? "Sign in to your account" : "Create an Account"}
            </CardTitle>
            <CardDescription>
              {isLoginView
                ? "Enter your credentials to access your account"
                : "Fill in the details to create a new account"}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoginView ? (
              <form onSubmit={handleLoginSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="bg-gray-100"
                    autoComplete="email"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="bg-gray-100"
                    autoComplete="current-password"
                  />
                </div>
                <div className="flex items-center justify-between flex-wrap">
                  <div className="flex items-center space-x-2">
                    <Checkbox id="remember" />
                    <label htmlFor="remember" className="text-sm text-gray-500">
                      Stay signed in for a week
                    </label>
                  </div>
                  <Button variant="link" className="text-sm p-0">
                    Forgot your password?
                  </Button>
                </div>
                {error && (
                  <div className="text-red-500 text-sm">
                    {loginError}
                  </div>
                )}
                {requiresTwoFactor && (
                  <div className="text-yellow-500 text-sm">
                    Two-Factor Authentication is required. Please contact support.
                  </div>
                )}
                <Button
                  className="w-full bg-[#24EC7E] hover:bg-[#20D870] text-black"
                  type="submit"
                  disabled={!email || !password || loading}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <div className="w-5 h-5 border-2 border-black border-t-transparent rounded-full animate-spin mr-2"></div>
                      Signing in...
                    </div>
                  ) : (
                    'Sign in'
                  )}
                </Button>
              </form>
            ) : (
              <form onSubmit={handleSignupSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="company">Company</Label>
                  <Input
                    id="company"
                    type="text"
                    placeholder="Your Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                    className="bg-gray-100"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="firstname">First Name</Label>
                  <Input
                    id="firstname"
                    type="text"
                    placeholder="John"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="bg-gray-100"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="lastname">Last Name</Label>
                  <Input
                    id="lastname"
                    type="text"
                    placeholder="Doe"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="bg-gray-100"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="signup-email">Email</Label>
                  <Input
                    id="signup-email"
                    type="email"
                    placeholder="example@gmail.com"
                    value={signupEmail}
                    onChange={(e) => setSignupEmail(e.target.value)}
                    required
                    className="bg-gray-100"
                    autoComplete="email"
                  />
                </div>
                <div className="space-y-2 relative">
                  <Label htmlFor="country">Country</Label>
                  <CountrySelect countries={countries} onCountryChange={(selectedCountry) => setCountry(selectedCountry?.value || 235)} />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="signup-password">Password</Label>
                  <Input
                    id="signup-password"
                    type="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="bg-gray-100"
                    autoComplete="new-password"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password-confirm">Confirm Password</Label>
                  <Input
                    id="password-confirm"
                    type="password"
                    placeholder="********"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    required
                    className="bg-gray-100"
                    autoComplete="new-password"
                  />
                </div>
                {error && (
                  <div className="text-red-500 text-sm">
                    {loginError}
                  </div>
                )}
                <Button
                  className="w-full bg-[#24EC7E] hover:bg-[#20D870] text-black"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Registering...' : 'Register'}
                </Button>
              </form>
            )}
          </CardContent>
          <CardFooter className="flex flex-col space-y-4">
            <div className="relative w-full">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm uppercase">
                <span className="bg-white px-2 text-gray-500">Or continue with</span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 w-full">
              <Button variant="outline" className="w-full">
                <img src={googlelogo} alt="Google logo" className="mr-2 h-4 w-4" />
                Google
              </Button>
              <Button variant="outline" className="w-full">
                <img src={applelogo} alt="Apple logo" className="mr-2 h-4 w-4" />
                Apple
              </Button>
            </div>
            <div className="text-center text-sm">
              {isLoginView ? (
                <>
                  Don't have an account?{' '}
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={toggleView}
                  >
                    Sign up
                  </Button>
                </>
              ) : (
                <>
                  Already have an account?{' '}
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={toggleView}
                  >
                    Sign in
                  </Button>
                </>
              )}
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

export default LoginForm;
