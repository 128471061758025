import React from 'react';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  centered?: boolean;
  className?: string;
}

// Main CustomModal Component
const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  centered = false,
  className = '',
}) => {
  if (!isOpen) return null;

  const modalClasses = `
    fixed inset-0 z-50
    ${centered ? 'flex items-center justify-center' : ''}
    ${className}
  `;

  return (
    <div className={modalClasses}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full mx-4">
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
