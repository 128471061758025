import React from 'react';
import { CardDetailsComponent } from '../components/cardPages/card-details';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "../components/cardManagement/uicustomizer/button";

export function CardManagementPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <CardDetailsComponent />
    </div>
  );
}

export default CardManagementPage;
