// src/layouts/MainLayout.tsx

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useModal } from '../contexts/ModalContext';
import { useModalPage } from '../contexts/ModalPageContext';
import { useSidebar } from '../contexts/SidebarContext';
import MainHeader from '../components/headers/MainHeader';
import ModalHeader from '../components/headers/ModalHeader';
import CRMHeader from '../components/headers/CRMHeader';
import ModernSidebarMenu from '../components/sidebar/Modern-sidebar-menu';
import eventEmitter from '../utils/eventEmitter';
import SessionExpiredModal from '../components/modals/SessionExpiredModal';
import RightSideMenu from '../components/RightSideMenu';
import { RightSideMenuProvider } from '../contexts/RightSideMenuContext';


const MainLayout: React.FC = () => {
  const { isOpen, content, title, steps } = useModal();
  const { isOpen: isPageOpen, content: pageContent, title: pageTitle } = useModalPage();
  const { isSidebarOpen } = useSidebar();
  const [isRightSideMenuOpen, setIsRightSideMenuOpen] = useState(false);

  const openMenu = () => setIsRightSideMenuOpen(true);
  const closeMenu = () => setIsRightSideMenuOpen(false);
  const toggleMenu = () => setIsRightSideMenuOpen(prev => !prev);

  const location = useLocation();
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const isCRMPage = location.pathname === '/crm' || location.pathname.startsWith('/crm/');

  useEffect(() => {
    const handleTokenExpired = (message: string) => {
      setShowSessionExpired(true);
    };

    eventEmitter.on('tokenExpired', handleTokenExpired);
    return () => {
      eventEmitter.off('tokenExpired', handleTokenExpired);
    };
  }, []);

  return (
    <RightSideMenuProvider>
      <RightSideMenu />
      
      <div className="flex flex-col min-h-screen">
        {/* Header */}
        <div className="flex-none">
          {isOpen ? (
            <ModalHeader title={title} steps={steps} />
          ) : isCRMPage ? (
            <MainHeader />
          ) : (
            <CRMHeader />
          )}
        </div>

        {/* Sidebar and Content */}
        <div className="flex flex-grow overflow-x-visible">
          {/* Sidebar */}
          <div className="flex-none">
            <ModernSidebarMenu />
          </div>

          {/* Content Area */}
          <main className="flex-grow relative">
            {isOpen ? (
              // Regular Modal Content
              <div className="flex justify-center h-full">
                <div className="w-full h-full md:h-auto md:max-w-[640px] md:max-h-[850px] bg-white overflow-auto" style={{ overflowY: 'hidden' }}>
                  {content}
                </div>
              </div>
            ) : isPageOpen ? (
              // Modal Page Content
              <div className="w-full h-full bg-white">
                {pageTitle && (
                  <div className="border-b border-gray-200">
                    <h1 className="text-2xl font-bold p-6">{pageTitle}</h1>
                  </div>
                )}
                <div className="p-6">
                  {pageContent}
                </div>
              </div>
            ) : (
              // Regular Page Content
              <Outlet />
            )}
          </main>
        </div>
      </div>

      {/* Session Expired Modal */}
      {showSessionExpired && (
        <SessionExpiredModal 
          isOpen={showSessionExpired} 
          onClose={() => setShowSessionExpired(false)} 
        />
      )}
    </RightSideMenuProvider>
  );
};

export default React.memo(MainLayout);
