// AuthContextData/useAuthUser.tsx

import { useState, useCallback, useEffect } from 'react';
import { User } from './types';
import api, { resetSession } from '../../services/api';
import Cookies from 'js-cookie';
import { logAuthEvent } from './utils';
import { useNavigate } from 'react-router-dom';

interface UseAuthUserProps {
  clearContext: () => void;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

export const useAuthUser = ({ clearContext, setUser }: UseAuthUserProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [requiresTwoFactor, setRequiresTwoFactor] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);

  const login = useCallback(
    async (credentials: { email: string; password: string }) => {
      console.log('=== AUTH USER LOGIN START ===');
      try {
        setAuthLoading(true);
        setError(null);
        
        const response = await api.login(credentials);
        
        if (response?.success && response?.data) {
          // Store token first
          if (response.data.token) {
            localStorage.setItem('_api_token', response.data.token);
          }

          const normalizedUser: User = {
            id: response.data.userid,
            email: credentials.email,
            name: credentials.email.split('@')[0],
            avatar: null,
            activeWallet: 'GBP',
            requiresTwoFactor: false,
            customer_id: response.data.customer_id,
            is_verified: response.data.is_verified
          };

          // Set cookie and user data
          Cookies.set('activeWallet', 'GBP', { expires: 365 });
          setUser(normalizedUser);
          localStorage.setItem('user', JSON.stringify(normalizedUser));

          return { success: true, data: normalizedUser };
        }

        const errorMsg = response?.message || 'Login failed';
        setError(errorMsg);
        return { success: false, message: errorMsg };
      } catch (error: any) {
        console.error('Login error:', error);
        const errorMsg = error?.message || 'Login failed';
        setError(errorMsg);
        return { success: false, message: errorMsg };
      } finally {
        setAuthLoading(false);
      }
    },
    [setUser]
  );

  const logout = useCallback(
    async (reason?: string) => {
      logAuthEvent('Logout Initiated', { reason });
      try {
        await api.logout();
        logAuthEvent('Logout Successful', null, 'success');
      } catch (error) {
        logAuthEvent('Logout Error', error, 'error');
      } finally {
        clearContext();
        localStorage.removeItem('_api_token');
        navigate('/login');
      }
    },
    [clearContext, navigate]
  );

  // Add a function to check if the token is valid
  const checkToken = useCallback(async () => {
    const token = localStorage.getItem('_api_token');
    if (!token) {
      return false;
    }
    try {
      await api.checkSession();
      return true;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }, []);

  // Add this effect to validate token on mount
  useEffect(() => {
    const validateToken = async () => {
      const isValid = await checkToken();
      if (!isValid) {
        clearContext();
        localStorage.removeItem('_api_token');
      }
    };
    validateToken();
  }, [checkToken, clearContext]);

  return {
    login,
    logout,
    error,
    requiresTwoFactor,
    authLoading,
    checkToken,  // Export this function
  };
};
