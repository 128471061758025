import React, { useState } from 'react'
import { Input } from "./ui/input"
import { Button } from "./ui/button"
import { Textarea } from "./ui/textarea"
import { Label } from "./ui/label"
import { Search, Filter, Download, Eye, Mail, Plus, X, Calendar as CalendarIcon } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "./ui/context-menu"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"
import { Card, CardContent } from "./ui/card"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Calendar } from "./ui/calendar"
import { format, addDays } from "date-fns"
import { cn } from "@/lib/utils"

// Dummy data for invoices
const dummyInvoices = [
  { id: "1", customerName: "Acme Corp", invoiceNumber: "INV-001", date: "2023-05-01", dueDate: "2023-05-31", amount: 5000.00, status: "Paid", currency: "USD" },
  { id: "2", customerName: "TechStart Inc", invoiceNumber: "INV-002", date: "2023-06-15", dueDate: "2023-07-15", amount: 7500.00, status: "Pending", currency: "EUR" },
  { id: "3", customerName: "Global Services Ltd", invoiceNumber: "INV-003", date: "2023-07-01", dueDate: "2023-07-31", amount: 10000.00, status: "Overdue", currency: "GBP" },
  { id: "4", customerName: "Local Business Co", invoiceNumber: "INV-004", date: "2023-07-10", dueDate: "2023-08-10", amount: 2500.00, status: "Pending", currency: "USD" },
  { id: "5", customerName: "Innovative Solutions", invoiceNumber: "INV-005", date: "2023-08-01", dueDate: "2023-08-31", amount: 15000.00, status: "Paid", currency: "EUR" },
]

const invoiceStatuses = ["All", "Paid", "Pending", "Overdue"]

const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'USD': return '$';
    case 'EUR': return '€';
    case 'GBP': return '£';
    default: return '$';
  }
}

export function InvoiceManagementComponent() {
  const [searchTerm, setSearchTerm] = useState('')
  const [invoices, setInvoices] = useState(dummyInvoices)
  const [filterStatus, setFilterStatus] = useState("All")
  const [isNewInvoiceModalOpen, setIsNewInvoiceModalOpen] = useState(false)

  // New Invoice State
  const [newInvoice, setNewInvoice] = useState({
    customerName: '',
    invoiceNumber: '',
    amount: 0,
    currency: 'USD',
    dueDate: addDays(new Date(), 30),
    status: 'Pending'
  })

  const filteredInvoices = invoices.filter(invoice => 
    (invoice.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.invoiceNumber.includes(searchTerm)) &&
    (filterStatus === "All" || invoice.status === filterStatus)
  )

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return format(date, "MMM d, yyyy")
  }

  const handleDownload = (invoiceId: string) => {
    console.log(`Downloading invoice ${invoiceId}`)
    // Implement invoice download logic here
  }

  const handleView = (invoiceId: string) => {
    console.log(`Viewing invoice ${invoiceId}`)
    // Implement invoice view logic here
  }

  const handleCreateInvoice = (e: React.FormEvent) => {
    e.preventDefault()
    const newInvoiceWithId = { ...newInvoice, id: (invoices.length + 1).toString(), date: format(new Date(), "yyyy-MM-dd") }
    setInvoices([...invoices, newInvoiceWithId])
    setIsNewInvoiceModalOpen(false)
    setNewInvoice({
      customerName: '',
      invoiceNumber: '',
      amount: 0,
      currency: 'USD',
      dueDate: addDays(new Date(), 30),
      status: 'Pending'
    })
  }

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="max-w-6xl mx-auto space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold text-[#008664]">Invoices</h1>
          <Dialog open={isNewInvoiceModalOpen} onOpenChange={setIsNewInvoiceModalOpen}>
            <DialogTrigger asChild>
              <Button className="bg-[#008664] text-white hover:bg-[#24EC7E]">
                <Plus className="mr-2 h-4 w-4" />
                New Invoice
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Create New Invoice</DialogTitle>
              </DialogHeader>
              <form onSubmit={handleCreateInvoice} className="space-y-4">
                <div>
                  <Label htmlFor="customerName">Customer Name</Label>
                  <Input
                    id="customerName"
                    value={newInvoice.customerName}
                    onChange={(e) => setNewInvoice({...newInvoice, customerName: e.target.value})}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="invoiceNumber">Invoice Number</Label>
                  <Input
                    id="invoiceNumber"
                    value={newInvoice.invoiceNumber}
                    onChange={(e) => setNewInvoice({...newInvoice, invoiceNumber: e.target.value})}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="amount">Amount</Label>
                  <Input
                    id="amount"
                    type="number"
                    value={newInvoice.amount}
                    onChange={(e) => setNewInvoice({...newInvoice, amount: parseFloat(e.target.value)})}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="currency">Currency</Label>
                  <Select
                    value={newInvoice.currency}
                    onValueChange={(value) => setNewInvoice({...newInvoice, currency: value})}
                  >
                    <SelectTrigger id="currency">
                      <SelectValue placeholder="Select currency" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="USD">USD</SelectItem>
                      <SelectItem value="EUR">EUR</SelectItem>
                      <SelectItem value="GBP">GBP</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="dueDate">Due Date</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !newInvoice.dueDate && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {newInvoice.dueDate ? format(newInvoice.dueDate, "PPP") : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={newInvoice.dueDate}
                        onSelect={(date) => setNewInvoice({...newInvoice, dueDate: date || addDays(new Date(), 30)})}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <Button type="submit" className="w-full">Create Invoice</Button>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="Search invoices"
              className="pl-10 pr-4 py-2 w-full border-[#AFF8C8] focus:border-[#008664] focus:ring-[#008664] bg-[#F0FFF4]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Select onValueChange={setFilterStatus} defaultValue={filterStatus}>
            <SelectTrigger className="w-[180px] border-[#AFF8C8] focus:border-[#008664] focus:ring-[#008664]">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>
            <SelectContent>
              {invoiceStatuses.map((status) => (
                <SelectItem key={status} value={status}>{status}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button className="bg-[#00423C] text-white hover:bg-[#008664] rounded-full">
            <Filter className="mr-2 h-4 w-4" />
            More Filters
          </Button>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4 text-[#008664]">Your Invoices</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Invoice Number</TableHead>
                <TableHead>Customer</TableHead>
                <TableHead>Date</TableHead>
                <TableHead>Due Date</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredInvoices.map(invoice => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.invoiceNumber}</TableCell>
                  <TableCell>{invoice.customerName}</TableCell>
                  <TableCell>{formatDate(invoice.date)}</TableCell>
                  <TableCell>
                    <ContextMenu>
                      <ContextMenuTrigger>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              {formatDate(invoice.dueDate)}
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Due in: {Math.ceil((new Date(invoice.dueDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))} days</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </ContextMenuTrigger>
                      <ContextMenuContent>
                        <ContextMenuItem>
                          Due in: {Math.ceil((new Date(invoice.dueDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))} days
                        </ContextMenuItem>
                      </ContextMenuContent>
                    </ContextMenu>
                  </TableCell>
                  <TableCell>{getCurrencySymbol(invoice.currency)}{invoice.amount.toFixed(2)}</TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      invoice.status === 'Paid' ? 'bg-green-100 text-green-800' :
                      invoice.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {invoice.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        onClick={() => handleDownload(invoice.id)}
                        className="bg-[#008664] text-white hover:bg-[#24EC7E] p-2 rounded-full w-8 h-8 flex items-center justify-center"
                      >
                        <Download className="h-4 w-4" />
                      </Button>
                      <Button
                        onClick={() => handleView(invoice.id)}
                        className="bg-[#00423C] text-white hover:bg-[#008664] p-2 rounded-full w-8 h-8 flex items-center justify-center"
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                      <Button
                        onClick={() => console.log(`Email invoice ${invoice.id}`)}
                        className="bg-[#AFF8C8] text-[#008664] hover:bg-[#24EC7E] hover:text-white p-2 rounded-full w-8 h-8 flex items-center justify-center"
                      >
                        <Mail className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="flex justify-center">
          <Button className="bg-[#008664] text-white hover:bg-[#24EC7E]">
            Load More
          
          </Button>
        </div>
      </div>
    </div>
  )
}
export default InvoiceManagementComponent;