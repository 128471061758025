interface Country {
    country_id: number;
    iso2: string;
    short_name: string;
    long_name: string;
    iso3: string;
    numcode: number;
    un_member: "yes" | "no" | "some" | "former";
    calling_code: string;
    cctld: string;
}

export const countries: { data: Country[] } = {
    "data": [
        {
            "country_id": 1,
            "iso2": "AF",
            "short_name": "Afghanistan",
            "long_name": "Islamic Republic of Afghanistan",
            "iso3": "AFG",
            "numcode": 4,
            "un_member": "yes",
            "calling_code": "93",
            "cctld": ".af"
        },
        {
            "country_id": 2,
            "iso2": "AX",
            "short_name": "Aland Islands",
            "long_name": "&Aring;land Islands",
            "iso3": "ALA",
            "numcode": 248,
            "un_member": "no",
            "calling_code": "358",
            "cctld": ".ax"
        },
        {
            "country_id": 3,
            "iso2": "AL",
            "short_name": "Albania",
            "long_name": "Republic of Albania",
            "iso3": "ALB",
            "numcode": 8,
            "un_member": "yes",
            "calling_code": "355",
            "cctld": ".al"
        },
        {
            "country_id": 4,
            "iso2": "DZ",
            "short_name": "Algeria",
            "long_name": "People's Democratic Republic of Algeria",
            "iso3": "DZA",
            "numcode": 12,
            "un_member": "yes",
            "calling_code": "213",
            "cctld": ".dz"
        },
        {
            "country_id": 5,
            "iso2": "AS",
            "short_name": "American Samoa",
            "long_name": "American Samoa",
            "iso3": "ASM",
            "numcode": 16,
            "un_member": "no",
            "calling_code": "1+684",
            "cctld": ".as"
        },
        {
            "country_id": 6,
            "iso2": "AD",
            "short_name": "Andorra",
            "long_name": "Principality of Andorra",
            "iso3": "AND",
            "numcode": 20,
            "un_member": "yes",
            "calling_code": "376",
            "cctld": ".ad"
        },
        {
            "country_id": 7,
            "iso2": "AO",
            "short_name": "Angola",
            "long_name": "Republic of Angola",
            "iso3": "AGO",
            "numcode": 24,
            "un_member": "yes",
            "calling_code": "244",
            "cctld": ".ao"
        },
        {
            "country_id": 8,
            "iso2": "AI",
            "short_name": "Anguilla",
            "long_name": "Anguilla",
            "iso3": "AIA",
            "numcode": 660,
            "un_member": "no",
            "calling_code": "1+264",
            "cctld": ".ai"
        },
        {
            "country_id": 9,
            "iso2": "AQ",
            "short_name": "Antarctica",
            "long_name": "Antarctica",
            "iso3": "ATA",
            "numcode": 10,
            "un_member": "no",
            "calling_code": "672",
            "cctld": ".aq"
        },
        {
            "country_id": 10,
            "iso2": "AG",
            "short_name": "Antigua and Barbuda",
            "long_name": "Antigua and Barbuda",
            "iso3": "ATG",
            "numcode": 28,
            "un_member": "yes",
            "calling_code": "1+268",
            "cctld": ".ag"
        },
        {
            "country_id": 11,
            "iso2": "AR",
            "short_name": "Argentina",
            "long_name": "Argentine Republic",
            "iso3": "ARG",
            "numcode": 32,
            "un_member": "yes",
            "calling_code": "54",
            "cctld": ".ar"
        },
        {
            "country_id": 12,
            "iso2": "AM",
            "short_name": "Armenia",
            "long_name": "Republic of Armenia",
            "iso3": "ARM",
            "numcode": 51,
            "un_member": "yes",
            "calling_code": "374",
            "cctld": ".am"
        },
        {
            "country_id": 13,
            "iso2": "AW",
            "short_name": "Aruba",
            "long_name": "Aruba",
            "iso3": "ABW",
            "numcode": 533,
            "un_member": "no",
            "calling_code": "297",
            "cctld": ".aw"
        },
        {
            "country_id": 14,
            "iso2": "AU",
            "short_name": "Australia",
            "long_name": "Commonwealth of Australia",
            "iso3": "AUS",
            "numcode": 36,
            "un_member": "yes",
            "calling_code": "61",
            "cctld": ".au"
        },
        {
            "country_id": 15,
            "iso2": "AT",
            "short_name": "Austria",
            "long_name": "Republic of Austria",
            "iso3": "AUT",
            "numcode": 40,
            "un_member": "yes",
            "calling_code": "43",
            "cctld": ".at"
        },
        {
            "country_id": 16,
            "iso2": "AZ",
            "short_name": "Azerbaijan",
            "long_name": "Republic of Azerbaijan",
            "iso3": "AZE",
            "numcode": 31,
            "un_member": "yes",
            "calling_code": "994",
            "cctld": ".az"
        },
        {
            "country_id": 17,
            "iso2": "BS",
            "short_name": "Bahamas",
            "long_name": "Commonwealth of The Bahamas",
            "iso3": "BHS",
            "numcode": 44,
            "un_member": "yes",
            "calling_code": "1+242",
            "cctld": ".bs"
        },
        {
            "country_id": 18,
            "iso2": "BH",
            "short_name": "Bahrain",
            "long_name": "Kingdom of Bahrain",
            "iso3": "BHR",
            "numcode": 48,
            "un_member": "yes",
            "calling_code": "973",
            "cctld": ".bh"
        },
        {
            "country_id": 19,
            "iso2": "BD",
            "short_name": "Bangladesh",
            "long_name": "People's Republic of Bangladesh",
            "iso3": "BGD",
            "numcode": 50,
            "un_member": "yes",
            "calling_code": "880",
            "cctld": ".bd"
        },
        {
            "country_id": 20,
            "iso2": "BB",
            "short_name": "Barbados",
            "long_name": "Barbados",
            "iso3": "BRB",
            "numcode": 52,
            "un_member": "yes",
            "calling_code": "1+246",
            "cctld": ".bb"
        },
        {
            "country_id": 21,
            "iso2": "BY",
            "short_name": "Belarus",
            "long_name": "Republic of Belarus",
            "iso3": "BLR",
            "numcode": 112,
            "un_member": "yes",
            "calling_code": "375",
            "cctld": ".by"
        },
        {
            "country_id": 22,
            "iso2": "BE",
            "short_name": "Belgium",
            "long_name": "Kingdom of Belgium",
            "iso3": "BEL",
            "numcode": 56,
            "un_member": "yes",
            "calling_code": "32",
            "cctld": ".be"
        },
        {
            "country_id": 23,
            "iso2": "BZ",
            "short_name": "Belize",
            "long_name": "Belize",
            "iso3": "BLZ",
            "numcode": 84,
            "un_member": "yes",
            "calling_code": "501",
            "cctld": ".bz"
        },
        {
            "country_id": 24,
            "iso2": "BJ",
            "short_name": "Benin",
            "long_name": "Republic of Benin",
            "iso3": "BEN",
            "numcode": 204,
            "un_member": "yes",
            "calling_code": "229",
            "cctld": ".bj"
        },
        {
            "country_id": 25,
            "iso2": "BM",
            "short_name": "Bermuda",
            "long_name": "Bermuda Islands",
            "iso3": "BMU",
            "numcode": 60,
            "un_member": "no",
            "calling_code": "1+441",
            "cctld": ".bm"
        },
        {
            "country_id": 26,
            "iso2": "BT",
            "short_name": "Bhutan",
            "long_name": "Kingdom of Bhutan",
            "iso3": "BTN",
            "numcode": 64,
            "un_member": "yes",
            "calling_code": "975",
            "cctld": ".bt"
        },
        {
            "country_id": 27,
            "iso2": "BO",
            "short_name": "Bolivia",
            "long_name": "Plurinational State of Bolivia",
            "iso3": "BOL",
            "numcode": 68,
            "un_member": "yes",
            "calling_code": "591",
            "cctld": ".bo"
        },
        {
            "country_id": 28,
            "iso2": "BQ",
            "short_name": "Bonaire, Sint Eustatius and Saba",
            "long_name": "Bonaire, Sint Eustatius and Saba",
            "iso3": "BES",
            "numcode": 535,
            "un_member": "no",
            "calling_code": "599",
            "cctld": ".bq"
        },
        {
            "country_id": 29,
            "iso2": "BA",
            "short_name": "Bosnia and Herzegovina",
            "long_name": "Bosnia and Herzegovina",
            "iso3": "BIH",
            "numcode": 70,
            "un_member": "yes",
            "calling_code": "387",
            "cctld": ".ba"
        },
        {
            "country_id": 30,
            "iso2": "BW",
            "short_name": "Botswana",
            "long_name": "Republic of Botswana",
            "iso3": "BWA",
            "numcode": 72,
            "un_member": "yes",
            "calling_code": "267",
            "cctld": ".bw"
        },

        {
            "country_id": 32,
            "iso2": "BR",
            "short_name": "Brazil",
            "long_name": "Federative Republic of Brazil",
            "iso3": "BRA",
            "numcode": 76,
            "un_member": "yes",
            "calling_code": "55",
            "cctld": ".br"
        },
        {
            "country_id": 33,
            "iso2": "IO",
            "short_name": "British Indian Ocean Territory",
            "long_name": "British Indian Ocean Territory",
            "iso3": "IOT",
            "numcode": 86,
            "un_member": "no",
            "calling_code": "246",
            "cctld": ".io"
        },
        {
            "country_id": 34,
            "iso2": "BN",
            "short_name": "Brunei",
            "long_name": "Brunei Darussalam",
            "iso3": "BRN",
            "numcode": 96,
            "un_member": "yes",
            "calling_code": "673",
            "cctld": ".bn"
        },
        {
            "country_id": 35,
            "iso2": "BG",
            "short_name": "Bulgaria",
            "long_name": "Republic of Bulgaria",
            "iso3": "BGR",
            "numcode": 100,
            "un_member": "yes",
            "calling_code": "359",
            "cctld": ".bg"
        },
        {
            "country_id": 36,
            "iso2": "BF",
            "short_name": "Burkina Faso",
            "long_name": "Burkina Faso",
            "iso3": "BFA",
            "numcode": 854,
            "un_member": "yes",
            "calling_code": "226",
            "cctld": ".bf"
        },
        {
            "country_id": 37,
            "iso2": "BI",
            "short_name": "Burundi",
            "long_name": "Republic of Burundi",
            "iso3": "BDI",
            "numcode": 108,
            "un_member": "yes",
            "calling_code": "257",
            "cctld": ".bi"
        },
        {
            "country_id": 38,
            "iso2": "KH",
            "short_name": "Cambodia",
            "long_name": "Kingdom of Cambodia",
            "iso3": "KHM",
            "numcode": 116,
            "un_member": "yes",
            "calling_code": "855",
            "cctld": ".kh"
        },
        {
            "country_id": 39,
            "iso2": "CM",
            "short_name": "Cameroon",
            "long_name": "Republic of Cameroon",
            "iso3": "CMR",
            "numcode": 120,
            "un_member": "yes",
            "calling_code": "237",
            "cctld": ".cm"
        },
        {
            "country_id": 40,
            "iso2": "CA",
            "short_name": "Canada",
            "long_name": "Canada",
            "iso3": "CAN",
            "numcode": 124,
            "un_member": "yes",
            "calling_code": "1",
            "cctld": ".ca"
        },
        {
            "country_id": 41,
            "iso2": "CV",
            "short_name": "Cape Verde",
            "long_name": "Republic of Cape Verde",
            "iso3": "CPV",
            "numcode": 132,
            "un_member": "yes",
            "calling_code": "238",
            "cctld": ".cv"
        },
        {
            "country_id": 42,
            "iso2": "KY",
            "short_name": "Cayman Islands",
            "long_name": "The Cayman Islands",
            "iso3": "CYM",
            "numcode": 136,
            "un_member": "no",
            "calling_code": "1+345",
            "cctld": ".ky"
        },
        {
            "country_id": 43,
            "iso2": "CF",
            "short_name": "Central African Republic",
            "long_name": "Central African Republic",
            "iso3": "CAF",
            "numcode": 140,
            "un_member": "yes",
            "calling_code": "236",
            "cctld": ".cf"
        },
        {
            "country_id": 44,
            "iso2": "TD",
            "short_name": "Chad",
            "long_name": "Republic of Chad",
            "iso3": "TCD",
            "numcode": 148,
            "un_member": "yes",
            "calling_code": "235",
            "cctld": ".td"
        },
        {
            "country_id": 45,
            "iso2": "CL",
            "short_name": "Chile",
            "long_name": "Republic of Chile",
            "iso3": "CHL",
            "numcode": 152,
            "un_member": "yes",
            "calling_code": "56",
            "cctld": ".cl"
        },
        {
            "country_id": 46,
            "iso2": "CN",
            "short_name": "China",
            "long_name": "People's Republic of China",
            "iso3": "CHN",
            "numcode": 156,
            "un_member": "yes",
            "calling_code": "86",
            "cctld": ".cn"
        },
        {
            "country_id": 47,
            "iso2": "CX",
            "short_name": "Christmas Island",
            "long_name": "Christmas Island",
            "iso3": "CXR",
            "numcode": 162,
            "un_member": "no",
            "calling_code": "61",
            "cctld": ".cx"
        },
        {
            "country_id": 48,
            "iso2": "CC",
            "short_name": "Cocos (Keeling) Islands",
            "long_name": "Cocos (Keeling) Islands",
            "iso3": "CCK",
            "numcode": 166,
            "un_member": "no",
            "calling_code": "61",
            "cctld": ".cc"
        },
        {
            "country_id": 49,
            "iso2": "CO",
            "short_name": "Colombia",
            "long_name": "Republic of Colombia",
            "iso3": "COL",
            "numcode": 170,
            "un_member": "yes",
            "calling_code": "57",
            "cctld": ".co"
        },
        {
            "country_id": 50,
            "iso2": "KM",
            "short_name": "Comoros",
            "long_name": "Union of the Comoros",
            "iso3": "COM",
            "numcode": 174,
            "un_member": "yes",
            "calling_code": "269",
            "cctld": ".km"
        },
        {
            "country_id": 51,
            "iso2": "CG",
            "short_name": "Congo",
            "long_name": "Republic of the Congo",
            "iso3": "COG",
            "numcode": 178,
            "un_member": "yes",
            "calling_code": "242",
            "cctld": ".cg"
        },
        {
            "country_id": 52,
            "iso2": "CK",
            "short_name": "Cook Islands",
            "long_name": "Cook Islands",
            "iso3": "COK",
            "numcode": 184,
            "un_member": "some",
            "calling_code": "682",
            "cctld": ".ck"
        },
        {
            "country_id": 53,
            "iso2": "CR",
            "short_name": "Costa Rica",
            "long_name": "Republic of Costa Rica",
            "iso3": "CRI",
            "numcode": 188,
            "un_member": "yes",
            "calling_code": "506",
            "cctld": ".cr"
        },
        {
            "country_id": 54,
            "iso2": "CI",
            "short_name": "Cote d'ivoire (Ivory Coast)",
            "long_name": "Republic of C&ocirc;te D'Ivoire (Ivory Coast)",
            "iso3": "CIV",
            "numcode": 384,
            "un_member": "yes",
            "calling_code": "225",
            "cctld": ".ci"
        },
        {
            "country_id": 55,
            "iso2": "HR",
            "short_name": "Croatia",
            "long_name": "Republic of Croatia",
            "iso3": "HRV",
            "numcode": 191,
            "un_member": "yes",
            "calling_code": "385",
            "cctld": ".hr"
        },
        {
            "country_id": 56,
            "iso2": "CU",
            "short_name": "Cuba",
            "long_name": "Republic of Cuba",
            "iso3": "CUB",
            "numcode": 192,
            "un_member": "yes",
            "calling_code": "53",
            "cctld": ".cu"
        },
        {
            "country_id": 57,
            "iso2": "CW",
            "short_name": "Curacao",
            "long_name": "Cura&ccedil;ao",
            "iso3": "CUW",
            "numcode": 531,
            "un_member": "no",
            "calling_code": "599",
            "cctld": ".cw"
        },
        {
            "country_id": 58,
            "iso2": "CY",
            "short_name": "Cyprus",
            "long_name": "Republic of Cyprus",
            "iso3": "CYP",
            "numcode": 196,
            "un_member": "yes",
            "calling_code": "357",
            "cctld": ".cy"
        },
        {
            "country_id": 59,
            "iso2": "CZ",
            "short_name": "Czech Republic",
            "long_name": "Czech Republic",
            "iso3": "CZE",
            "numcode": 203,
            "un_member": "yes",
            "calling_code": "420",
            "cctld": ".cz"
        },
        {
            "country_id": 60,
            "iso2": "CD",
            "short_name": "Democratic Republic of the Congo",
            "long_name": "Democratic Republic of the Congo",
            "iso3": "COD",
            "numcode": 180,
            "un_member": "yes",
            "calling_code": "243",
            "cctld": ".cd"
        },
        {
            "country_id": 61,
            "iso2": "DK",
            "short_name": "Denmark",
            "long_name": "Kingdom of Denmark",
            "iso3": "DNK",
            "numcode": 208,
            "un_member": "yes",
            "calling_code": "45",
            "cctld": ".dk"
        },
        {
            "country_id": 62,
            "iso2": "DJ",
            "short_name": "Djibouti",
            "long_name": "Republic of Djibouti",
            "iso3": "DJI",
            "numcode": 262,
            "un_member": "yes",
            "calling_code": "253",
            "cctld": ".dj"
        },
        {
            "country_id": 63,
            "iso2": "DM",
            "short_name": "Dominica",
            "long_name": "Commonwealth of Dominica",
            "iso3": "DMA",
            "numcode": 212,
            "un_member": "yes",
            "calling_code": "1+767",
            "cctld": ".dm"
        },
        {
            "country_id": 64,
            "iso2": "DO",
            "short_name": "Dominican Republic",
            "long_name": "Dominican Republic",
            "iso3": "DOM",
            "numcode": 214,
            "un_member": "yes",
            "calling_code": "1+809, 8",
            "cctld": ".do"
        },
        {
            "country_id": 65,
            "iso2": "EC",
            "short_name": "Ecuador",
            "long_name": "Republic of Ecuador",
            "iso3": "ECU",
            "numcode": 218,
            "un_member": "yes",
            "calling_code": "593",
            "cctld": ".ec"
        },
        {
            "country_id": 66,
            "iso2": "EG",
            "short_name": "Egypt",
            "long_name": "Arab Republic of Egypt",
            "iso3": "EGY",
            "numcode": 818,
            "un_member": "yes",
            "calling_code": "20",
            "cctld": ".eg"
        },
        {
            "country_id": 67,
            "iso2": "SV",
            "short_name": "El Salvador",
            "long_name": "Republic of El Salvador",
            "iso3": "SLV",
            "numcode": 222,
            "un_member": "yes",
            "calling_code": "503",
            "cctld": ".sv"
        },
        {
            "country_id": 68,
            "iso2": "GQ",
            "short_name": "Equatorial Guinea",
            "long_name": "Republic of Equatorial Guinea",
            "iso3": "GNQ",
            "numcode": 226,
            "un_member": "yes",
            "calling_code": "240",
            "cctld": ".gq"
        },
        {
            "country_id": 69,
            "iso2": "ER",
            "short_name": "Eritrea",
            "long_name": "State of Eritrea",
            "iso3": "ERI",
            "numcode": 232,
            "un_member": "yes",
            "calling_code": "291",
            "cctld": ".er"
        },
        {
            "country_id": 70,
            "iso2": "EE",
            "short_name": "Estonia",
            "long_name": "Republic of Estonia",
            "iso3": "EST",
            "numcode": 233,
            "un_member": "yes",
            "calling_code": "372",
            "cctld": ".ee"
        },
        {
            "country_id": 71,
            "iso2": "ET",
            "short_name": "Ethiopia",
            "long_name": "Federal Democratic Republic of Ethiopia",
            "iso3": "ETH",
            "numcode": 231,
            "un_member": "yes",
            "calling_code": "251",
            "cctld": ".et"
        },
        {
            "country_id": 72,
            "iso2": "FK",
            "short_name": "Falkland Islands (Malvinas)",
            "long_name": "The Falkland Islands (Malvinas)",
            "iso3": "FLK",
            "numcode": 238,
            "un_member": "no",
            "calling_code": "500",
            "cctld": ".fk"
        },
        {
            "country_id": 73,
            "iso2": "FO",
            "short_name": "Faroe Islands",
            "long_name": "The Faroe Islands",
            "iso3": "FRO",
            "numcode": 234,
            "un_member": "no",
            "calling_code": "298",
            "cctld": ".fo"
        },
        {
            "country_id": 74,
            "iso2": "FJ",
            "short_name": "Fiji",
            "long_name": "Republic of Fiji",
            "iso3": "FJI",
            "numcode": 242,
            "un_member": "yes",
            "calling_code": "679",
            "cctld": ".fj"
        },
        {
            "country_id": 75,
            "iso2": "FI",
            "short_name": "Finland",
            "long_name": "Republic of Finland",
            "iso3": "FIN",
            "numcode": 246,
            "un_member": "yes",
            "calling_code": "358",
            "cctld": ".fi"
        },
        {
            "country_id": 76,
            "iso2": "FR",
            "short_name": "France",
            "long_name": "French Republic",
            "iso3": "FRA",
            "numcode": 250,
            "un_member": "yes",
            "calling_code": "33",
            "cctld": ".fr"
        },
        {
            "country_id": 77,
            "iso2": "GF",
            "short_name": "French Guiana",
            "long_name": "French Guiana",
            "iso3": "GUF",
            "numcode": 254,
            "un_member": "no",
            "calling_code": "594",
            "cctld": ".gf"
        },
        {
            "country_id": 78,
            "iso2": "PF",
            "short_name": "French Polynesia",
            "long_name": "French Polynesia",
            "iso3": "PYF",
            "numcode": 258,
            "un_member": "no",
            "calling_code": "689",
            "cctld": ".pf"
        },
        {
            "country_id": 79,
            "iso2": "TF",
            "short_name": "French Southern Territories",
            "long_name": "French Southern Territories",
            "iso3": "ATF",
            "numcode": 260,
            "un_member": "no",
            "calling_code": null,
            "cctld": ".tf"
        },
        {
            "country_id": 80,
            "iso2": "GA",
            "short_name": "Gabon",
            "long_name": "Gabonese Republic",
            "iso3": "GAB",
            "numcode": 266,
            "un_member": "yes",
            "calling_code": "241",
            "cctld": ".ga"
        },
        {
            "country_id": 81,
            "iso2": "GM",
            "short_name": "Gambia",
            "long_name": "Republic of The Gambia",
            "iso3": "GMB",
            "numcode": 270,
            "un_member": "yes",
            "calling_code": "220",
            "cctld": ".gm"
        },
        {
            "country_id": 82,
            "iso2": "GE",
            "short_name": "Georgia",
            "long_name": "Georgia",
            "iso3": "GEO",
            "numcode": 268,
            "un_member": "yes",
            "calling_code": "995",
            "cctld": ".ge"
        },
        {
            "country_id": 83,
            "iso2": "DE",
            "short_name": "Germany",
            "long_name": "Federal Republic of Germany",
            "iso3": "DEU",
            "numcode": 276,
            "un_member": "yes",
            "calling_code": "49",
            "cctld": ".de"
        },
        {
            "country_id": 84,
            "iso2": "GH",
            "short_name": "Ghana",
            "long_name": "Republic of Ghana",
            "iso3": "GHA",
            "numcode": 288,
            "un_member": "yes",
            "calling_code": "233",
            "cctld": ".gh"
        },
        {
            "country_id": 85,
            "iso2": "GI",
            "short_name": "Gibraltar",
            "long_name": "Gibraltar",
            "iso3": "GIB",
            "numcode": 292,
            "un_member": "no",
            "calling_code": "350",
            "cctld": ".gi"
        },
        {
            "country_id": 86,
            "iso2": "GR",
            "short_name": "Greece",
            "long_name": "Hellenic Republic",
            "iso3": "GRC",
            "numcode": 300,
            "un_member": "yes",
            "calling_code": "30",
            "cctld": ".gr"
        },
        {
            "country_id": 87,
            "iso2": "GL",
            "short_name": "Greenland",
            "long_name": "Greenland",
            "iso3": "GRL",
            "numcode": 304,
            "un_member": "no",
            "calling_code": "299",
            "cctld": ".gl"
        },
        {
            "country_id": 88,
            "iso2": "GD",
            "short_name": "Grenada",
            "long_name": "Grenada",
            "iso3": "GRD",
            "numcode": 308,
            "un_member": "yes",
            "calling_code": "1+473",
            "cctld": ".gd"
        },
        {
            "country_id": 89,
            "iso2": "GP",
            "short_name": "Guadaloupe",
            "long_name": "Guadeloupe",
            "iso3": "GLP",
            "numcode": 312,
            "un_member": "no",
            "calling_code": "590",
            "cctld": ".gp"
        },
        {
            "country_id": 90,
            "iso2": "GU",
            "short_name": "Guam",
            "long_name": "Guam",
            "iso3": "GUM",
            "numcode": 316,
            "un_member": "no",
            "calling_code": "1+671",
            "cctld": ".gu"
        },
        {
            "country_id": 91,
            "iso2": "GT",
            "short_name": "Guatemala",
            "long_name": "Republic of Guatemala",
            "iso3": "GTM",
            "numcode": 320,
            "un_member": "yes",
            "calling_code": "502",
            "cctld": ".gt"
        },
        {
            "country_id": 92,
            "iso2": "GG",
            "short_name": "Guernsey",
            "long_name": "Guernsey",
            "iso3": "GGY",
            "numcode": 831,
            "un_member": "no",
            "calling_code": "44",
            "cctld": ".gg"
        },
        {
            "country_id": 93,
            "iso2": "GN",
            "short_name": "Guinea",
            "long_name": "Republic of Guinea",
            "iso3": "GIN",
            "numcode": 324,
            "un_member": "yes",
            "calling_code": "224",
            "cctld": ".gn"
        },
        {
            "country_id": 94,
            "iso2": "GW",
            "short_name": "Guinea-Bissau",
            "long_name": "Republic of Guinea-Bissau",
            "iso3": "GNB",
            "numcode": 624,
            "un_member": "yes",
            "calling_code": "245",
            "cctld": ".gw"
        },
        {
            "country_id": 95,
            "iso2": "GY",
            "short_name": "Guyana",
            "long_name": "Co-operative Republic of Guyana",
            "iso3": "GUY",
            "numcode": 328,
            "un_member": "yes",
            "calling_code": "592",
            "cctld": ".gy"
        },
        {
            "country_id": 96,
            "iso2": "HT",
            "short_name": "Haiti",
            "long_name": "Republic of Haiti",
            "iso3": "HTI",
            "numcode": 332,
            "un_member": "yes",
            "calling_code": "509",
            "cctld": ".ht"
        },
   
        {
            "country_id": 98,
            "iso2": "HN",
            "short_name": "Honduras",
            "long_name": "Republic of Honduras",
            "iso3": "HND",
            "numcode": 340,
            "un_member": "yes",
            "calling_code": "504",
            "cctld": ".hn"
        },
        {
            "country_id": 99,
            "iso2": "HK",
            "short_name": "Hong Kong",
            "long_name": "Hong Kong",
            "iso3": "HKG",
            "numcode": 344,
            "un_member": "no",
            "calling_code": "852",
            "cctld": ".hk"
        },
        {
            "country_id": 100,
            "iso2": "HU",
            "short_name": "Hungary",
            "long_name": "Hungary",
            "iso3": "HUN",
            "numcode": 348,
            "un_member": "yes",
            "calling_code": "36",
            "cctld": ".hu"
        },
        {
            "country_id": 101,
            "iso2": "IS",
            "short_name": "Iceland",
            "long_name": "Republic of Iceland",
            "iso3": "ISL",
            "numcode": 352,
            "un_member": "yes",
            "calling_code": "354",
            "cctld": ".is"
        },
        {
            "country_id": 102,
            "iso2": "IN",
            "short_name": "India",
            "long_name": "Republic of India",
            "iso3": "IND",
            "numcode": 356,
            "un_member": "yes",
            "calling_code": "91",
            "cctld": ".in"
        },
        {
            "country_id": 103,
            "iso2": "ID",
            "short_name": "Indonesia",
            "long_name": "Republic of Indonesia",
            "iso3": "IDN",
            "numcode": 360,
            "un_member": "yes",
            "calling_code": "62",
            "cctld": ".id"
        },
        {
            "country_id": 104,
            "iso2": "IR",
            "short_name": "Iran",
            "long_name": "Islamic Republic of Iran",
            "iso3": "IRN",
            "numcode": 364,
            "un_member": "yes",
            "calling_code": "98",
            "cctld": ".ir"
        },
        {
            "country_id": 105,
            "iso2": "IQ",
            "short_name": "Iraq",
            "long_name": "Republic of Iraq",
            "iso3": "IRQ",
            "numcode": 368,
            "un_member": "yes",
            "calling_code": "964",
            "cctld": ".iq"
        },
        {
            "country_id": 106,
            "iso2": "IE",
            "short_name": "Ireland",
            "long_name": "Ireland",
            "iso3": "IRL",
            "numcode": 372,
            "un_member": "yes",
            "calling_code": "353",
            "cctld": ".ie"
        },
        {
            "country_id": 107,
            "iso2": "IM",
            "short_name": "Isle of Man",
            "long_name": "Isle of Man",
            "iso3": "IMN",
            "numcode": 833,
            "un_member": "no",
            "calling_code": "44",
            "cctld": ".im"
        },
        {
            "country_id": 108,
            "iso2": "IL",
            "short_name": "Israel",
            "long_name": "State of Israel",
            "iso3": "ISR",
            "numcode": 376,
            "un_member": "yes",
            "calling_code": "972",
            "cctld": ".il"
        },
        {
            "country_id": 109,
            "iso2": "IT",
            "short_name": "Italy",
            "long_name": "Italian Republic",
            "iso3": "ITA",
            "numcode": 380,
            "un_member": "yes",
            "calling_code": "39",
            "cctld": ".jm"
        },
        {
            "country_id": 110,
            "iso2": "JM",
            "short_name": "Jamaica",
            "long_name": "Jamaica",
            "iso3": "JAM",
            "numcode": 388,
            "un_member": "yes",
            "calling_code": "1+876",
            "cctld": ".jm"
        },
        {
            "country_id": 111,
            "iso2": "JP",
            "short_name": "Japan",
            "long_name": "Japan",
            "iso3": "JPN",
            "numcode": 392,
            "un_member": "yes",
            "calling_code": "81",
            "cctld": ".jp"
        },
        {
            "country_id": 112,
            "iso2": "JE",
            "short_name": "Jersey",
            "long_name": "The Bailiwick of Jersey",
            "iso3": "JEY",
            "numcode": 832,
            "un_member": "no",
            "calling_code": "44",
            "cctld": ".je"
        },
        {
            "country_id": 113,
            "iso2": "JO",
            "short_name": "Jordan",
            "long_name": "Hashemite Kingdom of Jordan",
            "iso3": "JOR",
            "numcode": 400,
            "un_member": "yes",
            "calling_code": "962",
            "cctld": ".jo"
        },
        {
            "country_id": 114,
            "iso2": "KZ",
            "short_name": "Kazakhstan",
            "long_name": "Republic of Kazakhstan",
            "iso3": "KAZ",
            "numcode": 398,
            "un_member": "yes",
            "calling_code": "7",
            "cctld": ".kz"
        },
        {
            "country_id": 115,
            "iso2": "KE",
            "short_name": "Kenya",
            "long_name": "Republic of Kenya",
            "iso3": "KEN",
            "numcode": 404,
            "un_member": "yes",
            "calling_code": "254",
            "cctld": ".ke"
        },
        {
            "country_id": 116,
            "iso2": "KI",
            "short_name": "Kiribati",
            "long_name": "Republic of Kiribati",
            "iso3": "KIR",
            "numcode": 296,
            "un_member": "yes",
            "calling_code": "686",
            "cctld": ".ki"
        },
        {
            "country_id": 117,
            "iso2": "XK",
            "short_name": "Kosovo",
            "long_name": "Republic of Kosovo",
            "iso3": "---",
            "numcode": 0,
            "un_member": "some",
            "calling_code": "381",
            "cctld": ""
        },
        {
            "country_id": 118,
            "iso2": "KW",
            "short_name": "Kuwait",
            "long_name": "State of Kuwait",
            "iso3": "KWT",
            "numcode": 414,
            "un_member": "yes",
            "calling_code": "965",
            "cctld": ".kw"
        },
        {
            "country_id": 119,
            "iso2": "KG",
            "short_name": "Kyrgyzstan",
            "long_name": "Kyrgyz Republic",
            "iso3": "KGZ",
            "numcode": 417,
            "un_member": "yes",
            "calling_code": "996",
            "cctld": ".kg"
        },
        {
            "country_id": 120,
            "iso2": "LA",
            "short_name": "Laos",
            "long_name": "Lao People's Democratic Republic",
            "iso3": "LAO",
            "numcode": 418,
            "un_member": "yes",
            "calling_code": "856",
            "cctld": ".la"
        },
        {
            "country_id": 121,
            "iso2": "LV",
            "short_name": "Latvia",
            "long_name": "Republic of Latvia",
            "iso3": "LVA",
            "numcode": 428,
            "un_member": "yes",
            "calling_code": "371",
            "cctld": ".lv"
        },
        {
            "country_id": 122,
            "iso2": "LB",
            "short_name": "Lebanon",
            "long_name": "Republic of Lebanon",
            "iso3": "LBN",
            "numcode": 422,
            "un_member": "yes",
            "calling_code": "961",
            "cctld": ".lb"
        },
        {
            "country_id": 123,
            "iso2": "LS",
            "short_name": "Lesotho",
            "long_name": "Kingdom of Lesotho",
            "iso3": "LSO",
            "numcode": 426,
            "un_member": "yes",
            "calling_code": "266",
            "cctld": ".ls"
        },
        {
            "country_id": 124,
            "iso2": "LR",
            "short_name": "Liberia",
            "long_name": "Republic of Liberia",
            "iso3": "LBR",
            "numcode": 430,
            "un_member": "yes",
            "calling_code": "231",
            "cctld": ".lr"
        },
        {
            "country_id": 125,
            "iso2": "LY",
            "short_name": "Libya",
            "long_name": "Libya",
            "iso3": "LBY",
            "numcode": 434,
            "un_member": "yes",
            "calling_code": "218",
            "cctld": ".ly"
        },
        {
            "country_id": 126,
            "iso2": "LI",
            "short_name": "Liechtenstein",
            "long_name": "Principality of Liechtenstein",
            "iso3": "LIE",
            "numcode": 438,
            "un_member": "yes",
            "calling_code": "423",
            "cctld": ".li"
        },
        {
            "country_id": 127,
            "iso2": "LT",
            "short_name": "Lithuania",
            "long_name": "Republic of Lithuania",
            "iso3": "LTU",
            "numcode": 440,
            "un_member": "yes",
            "calling_code": "370",
            "cctld": ".lt"
        },
        {
            "country_id": 128,
            "iso2": "LU",
            "short_name": "Luxembourg",
            "long_name": "Grand Duchy of Luxembourg",
            "iso3": "LUX",
            "numcode": 442,
            "un_member": "yes",
            "calling_code": "352",
            "cctld": ".lu"
        },
        {
            "country_id": 129,
            "iso2": "MO",
            "short_name": "Macao",
            "long_name": "The Macao Special Administrative Region",
            "iso3": "MAC",
            "numcode": 446,
            "un_member": "no",
            "calling_code": "853",
            "cctld": ".mo"
        },
        {
            "country_id": 130,
            "iso2": "MK",
            "short_name": "North Macedonia",
            "long_name": "Republic of North Macedonia",
            "iso3": "MKD",
            "numcode": 807,
            "un_member": "yes",
            "calling_code": "389",
            "cctld": ".mk"
        },
        {
            "country_id": 131,
            "iso2": "MG",
            "short_name": "Madagascar",
            "long_name": "Republic of Madagascar",
            "iso3": "MDG",
            "numcode": 450,
            "un_member": "yes",
            "calling_code": "261",
            "cctld": ".mg"
        },
        {
            "country_id": 132,
            "iso2": "MW",
            "short_name": "Malawi",
            "long_name": "Republic of Malawi",
            "iso3": "MWI",
            "numcode": 454,
            "un_member": "yes",
            "calling_code": "265",
            "cctld": ".mw"
        },
        {
            "country_id": 133,
            "iso2": "MY",
            "short_name": "Malaysia",
            "long_name": "Malaysia",
            "iso3": "MYS",
            "numcode": 458,
            "un_member": "yes",
            "calling_code": "60",
            "cctld": ".my"
        },
        {
            "country_id": 134,
            "iso2": "MV",
            "short_name": "Maldives",
            "long_name": "Republic of Maldives",
            "iso3": "MDV",
            "numcode": 462,
            "un_member": "yes",
            "calling_code": "960",
            "cctld": ".mv"
        },
        {
            "country_id": 135,
            "iso2": "ML",
            "short_name": "Mali",
            "long_name": "Republic of Mali",
            "iso3": "MLI",
            "numcode": 466,
            "un_member": "yes",
            "calling_code": "223",
            "cctld": ".ml"
        },
        {
            "country_id": 136,
            "iso2": "MT",
            "short_name": "Malta",
            "long_name": "Republic of Malta",
            "iso3": "MLT",
            "numcode": 470,
            "un_member": "yes",
            "calling_code": "356",
            "cctld": ".mt"
        },
        {
            "country_id": 137,
            "iso2": "MH",
            "short_name": "Marshall Islands",
            "long_name": "Republic of the Marshall Islands",
            "iso3": "MHL",
            "numcode": 584,
            "un_member": "yes",
            "calling_code": "692",
            "cctld": ".mh"
        },
        {
            "country_id": 138,
            "iso2": "MQ",
            "short_name": "Martinique",
            "long_name": "Martinique",
            "iso3": "MTQ",
            "numcode": 474,
            "un_member": "no",
            "calling_code": "596",
            "cctld": ".mq"
        },
        {
            "country_id": 139,
            "iso2": "MR",
            "short_name": "Mauritania",
            "long_name": "Islamic Republic of Mauritania",
            "iso3": "MRT",
            "numcode": 478,
            "un_member": "yes",
            "calling_code": "222",
            "cctld": ".mr"
        },
        {
            "country_id": 140,
            "iso2": "MU",
            "short_name": "Mauritius",
            "long_name": "Republic of Mauritius",
            "iso3": "MUS",
            "numcode": 480,
            "un_member": "yes",
            "calling_code": "230",
            "cctld": ".mu"
        },
        {
            "country_id": 141,
            "iso2": "YT",
            "short_name": "Mayotte",
            "long_name": "Mayotte",
            "iso3": "MYT",
            "numcode": 175,
            "un_member": "no",
            "calling_code": "262",
            "cctld": ".yt"
        },
        {
            "country_id": 142,
            "iso2": "MX",
            "short_name": "Mexico",
            "long_name": "United Mexican States",
            "iso3": "MEX",
            "numcode": 484,
            "un_member": "yes",
            "calling_code": "52",
            "cctld": ".mx"
        },
        {
            "country_id": 143,
            "iso2": "FM",
            "short_name": "Micronesia",
            "long_name": "Federated States of Micronesia",
            "iso3": "FSM",
            "numcode": 583,
            "un_member": "yes",
            "calling_code": "691",
            "cctld": ".fm"
        },
        {
            "country_id": 144,
            "iso2": "MD",
            "short_name": "Moldava",
            "long_name": "Republic of Moldova",
            "iso3": "MDA",
            "numcode": 498,
            "un_member": "yes",
            "calling_code": "373",
            "cctld": ".md"
        },
        {
            "country_id": 145,
            "iso2": "MC",
            "short_name": "Monaco",
            "long_name": "Principality of Monaco",
            "iso3": "MCO",
            "numcode": 492,
            "un_member": "yes",
            "calling_code": "377",
            "cctld": ".mc"
        },
        {
            "country_id": 146,
            "iso2": "MN",
            "short_name": "Mongolia",
            "long_name": "Mongolia",
            "iso3": "MNG",
            "numcode": 496,
            "un_member": "yes",
            "calling_code": "976",
            "cctld": ".mn"
        },
        {
            "country_id": 147,
            "iso2": "ME",
            "short_name": "Montenegro",
            "long_name": "Montenegro",
            "iso3": "MNE",
            "numcode": 499,
            "un_member": "yes",
            "calling_code": "382",
            "cctld": ".me"
        },
        {
            "country_id": 148,
            "iso2": "MS",
            "short_name": "Montserrat",
            "long_name": "Montserrat",
            "iso3": "MSR",
            "numcode": 500,
            "un_member": "no",
            "calling_code": "1+664",
            "cctld": ".ms"
        },
        {
            "country_id": 149,
            "iso2": "MA",
            "short_name": "Morocco",
            "long_name": "Kingdom of Morocco",
            "iso3": "MAR",
            "numcode": 504,
            "un_member": "yes",
            "calling_code": "212",
            "cctld": ".ma"
        },
        {
            "country_id": 150,
            "iso2": "MZ",
            "short_name": "Mozambique",
            "long_name": "Republic of Mozambique",
            "iso3": "MOZ",
            "numcode": 508,
            "un_member": "yes",
            "calling_code": "258",
            "cctld": ".mz"
        },
        {
            "country_id": 151,
            "iso2": "MM",
            "short_name": "Myanmar (Burma)",
            "long_name": "Republic of the Union of Myanmar",
            "iso3": "MMR",
            "numcode": 104,
            "un_member": "yes",
            "calling_code": "95",
            "cctld": ".mm"
        },
        {
            "country_id": 152,
            "iso2": "NA",
            "short_name": "Namibia",
            "long_name": "Republic of Namibia",
            "iso3": "NAM",
            "numcode": 516,
            "un_member": "yes",
            "calling_code": "264",
            "cctld": ".na"
        },
        {
            "country_id": 153,
            "iso2": "NR",
            "short_name": "Nauru",
            "long_name": "Republic of Nauru",
            "iso3": "NRU",
            "numcode": 520,
            "un_member": "yes",
            "calling_code": "674",
            "cctld": ".nr"
        },
        {
            "country_id": 154,
            "iso2": "NP",
            "short_name": "Nepal",
            "long_name": "Federal Democratic Republic of Nepal",
            "iso3": "NPL",
            "numcode": 524,
            "un_member": "yes",
            "calling_code": "977",
            "cctld": ".np"
        },
        {
            "country_id": 155,
            "iso2": "NL",
            "short_name": "Netherlands",
            "long_name": "Kingdom of the Netherlands",
            "iso3": "NLD",
            "numcode": 528,
            "un_member": "yes",
            "calling_code": "31",
            "cctld": ".nl"
        },
        {
            "country_id": 156,
            "iso2": "NC",
            "short_name": "New Caledonia",
            "long_name": "New Caledonia",
            "iso3": "NCL",
            "numcode": 540,
            "un_member": "no",
            "calling_code": "687",
            "cctld": ".nc"
        },
        {
            "country_id": 157,
            "iso2": "NZ",
            "short_name": "New Zealand",
            "long_name": "New Zealand",
            "iso3": "NZL",
            "numcode": 554,
            "un_member": "yes",
            "calling_code": "64",
            "cctld": ".nz"
        },
        {
            "country_id": 158,
            "iso2": "NI",
            "short_name": "Nicaragua",
            "long_name": "Republic of Nicaragua",
            "iso3": "NIC",
            "numcode": 558,
            "un_member": "yes",
            "calling_code": "505",
            "cctld": ".ni"
        },
        {
            "country_id": 159,
            "iso2": "NE",
            "short_name": "Niger",
            "long_name": "Republic of Niger",
            "iso3": "NER",
            "numcode": 562,
            "un_member": "yes",
            "calling_code": "227",
            "cctld": ".ne"
        },
        {
            "country_id": 160,
            "iso2": "NG",
            "short_name": "Nigeria",
            "long_name": "Federal Republic of Nigeria",
            "iso3": "NGA",
            "numcode": 566,
            "un_member": "yes",
            "calling_code": "234",
            "cctld": ".ng"
        },
        {
            "country_id": 161,
            "iso2": "NU",
            "short_name": "Niue",
            "long_name": "Niue",
            "iso3": "NIU",
            "numcode": 570,
            "un_member": "some",
            "calling_code": "683",
            "cctld": ".nu"
        },
        {
            "country_id": 162,
            "iso2": "NF",
            "short_name": "Norfolk Island",
            "long_name": "Norfolk Island",
            "iso3": "NFK",
            "numcode": 574,
            "un_member": "no",
            "calling_code": "672",
            "cctld": ".nf"
        },
        {
            "country_id": 163,
            "iso2": "KP",
            "short_name": "North Korea",
            "long_name": "Democratic People's Republic of Korea",
            "iso3": "PRK",
            "numcode": 408,
            "un_member": "yes",
            "calling_code": "850",
            "cctld": ".kp"
        },
        {
            "country_id": 164,
            "iso2": "MP",
            "short_name": "Northern Mariana Islands",
            "long_name": "Northern Mariana Islands",
            "iso3": "MNP",
            "numcode": 580,
            "un_member": "no",
            "calling_code": "1+670",
            "cctld": ".mp"
        },
        {
            "country_id": 165,
            "iso2": "NO",
            "short_name": "Norway",
            "long_name": "Kingdom of Norway",
            "iso3": "NOR",
            "numcode": 578,
            "un_member": "yes",
            "calling_code": "47",
            "cctld": ".no"
        },
        {
            "country_id": 166,
            "iso2": "OM",
            "short_name": "Oman",
            "long_name": "Sultanate of Oman",
            "iso3": "OMN",
            "numcode": 512,
            "un_member": "yes",
            "calling_code": "968",
            "cctld": ".om"
        },
        {
            "country_id": 167,
            "iso2": "PK",
            "short_name": "Pakistan",
            "long_name": "Islamic Republic of Pakistan",
            "iso3": "PAK",
            "numcode": 586,
            "un_member": "yes",
            "calling_code": "92",
            "cctld": ".pk"
        },
        {
            "country_id": 168,
            "iso2": "PW",
            "short_name": "Palau",
            "long_name": "Republic of Palau",
            "iso3": "PLW",
            "numcode": 585,
            "un_member": "yes",
            "calling_code": "680",
            "cctld": ".pw"
        },
        {
            "country_id": 169,
            "iso2": "PS",
            "short_name": "Palestine",
            "long_name": "State of Palestine (or Occupied Palestinian Territory)",
            "iso3": "PSE",
            "numcode": 275,
            "un_member": "some",
            "calling_code": "970",
            "cctld": ".ps"
        },
        {
            "country_id": 170,
            "iso2": "PA",
            "short_name": "Panama",
            "long_name": "Republic of Panama",
            "iso3": "PAN",
            "numcode": 591,
            "un_member": "yes",
            "calling_code": "507",
            "cctld": ".pa"
        },
        {
            "country_id": 171,
            "iso2": "PG",
            "short_name": "Papua New Guinea",
            "long_name": "Independent State of Papua New Guinea",
            "iso3": "PNG",
            "numcode": 598,
            "un_member": "yes",
            "calling_code": "675",
            "cctld": ".pg"
        },
        {
            "country_id": 172,
            "iso2": "PY",
            "short_name": "Paraguay",
            "long_name": "Republic of Paraguay",
            "iso3": "PRY",
            "numcode": 600,
            "un_member": "yes",
            "calling_code": "595",
            "cctld": ".py"
        },
        {
            "country_id": 173,
            "iso2": "PE",
            "short_name": "Peru",
            "long_name": "Republic of Peru",
            "iso3": "PER",
            "numcode": 604,
            "un_member": "yes",
            "calling_code": "51",
            "cctld": ".pe"
        },
        {
            "country_id": 174,
            "iso2": "PH",
            "short_name": "Philippines",
            "long_name": "Republic of the Philippines",
            "iso3": "PHL",
            "numcode": 608,
            "un_member": "yes",
            "calling_code": "63",
            "cctld": ".ph"
        },
 
        {
            "country_id": 176,
            "iso2": "PL",
            "short_name": "Poland",
            "long_name": "Republic of Poland",
            "iso3": "POL",
            "numcode": 616,
            "un_member": "yes",
            "calling_code": "48",
            "cctld": ".pl"
        },
        {
            "country_id": 177,
            "iso2": "PT",
            "short_name": "Portugal",
            "long_name": "Portuguese Republic",
            "iso3": "PRT",
            "numcode": 620,
            "un_member": "yes",
            "calling_code": "351",
            "cctld": ".pt"
        },
        {
            "country_id": 178,
            "iso2": "PR",
            "short_name": "Puerto Rico",
            "long_name": "Commonwealth of Puerto Rico",
            "iso3": "PRI",
            "numcode": 630,
            "un_member": "no",
            "calling_code": "1+939",
            "cctld": ".pr"
        },
        {
            "country_id": 179,
            "iso2": "QA",
            "short_name": "Qatar",
            "long_name": "State of Qatar",
            "iso3": "QAT",
            "numcode": 634,
            "un_member": "yes",
            "calling_code": "974",
            "cctld": ".qa"
        },
        {
            "country_id": 180,
            "iso2": "RE",
            "short_name": "Reunion",
            "long_name": "R&eacute;union",
            "iso3": "REU",
            "numcode": 638,
            "un_member": "no",
            "calling_code": "262",
            "cctld": ".re"
        },
        {
            "country_id": 181,
            "iso2": "RO",
            "short_name": "Romania",
            "long_name": "Romania",
            "iso3": "ROU",
            "numcode": 642,
            "un_member": "yes",
            "calling_code": "40",
            "cctld": ".ro"
        },
        {
            "country_id": 182,
            "iso2": "RU",
            "short_name": "Russia",
            "long_name": "Russian Federation",
            "iso3": "RUS",
            "numcode": 643,
            "un_member": "yes",
            "calling_code": "7",
            "cctld": ".ru"
        },
        {
            "country_id": 183,
            "iso2": "RW",
            "short_name": "Rwanda",
            "long_name": "Republic of Rwanda",
            "iso3": "RWA",
            "numcode": 646,
            "un_member": "yes",
            "calling_code": "250",
            "cctld": ".rw"
        },
        {
            "country_id": 184,
            "iso2": "BL",
            "short_name": "Saint Barthelemy",
            "long_name": "Saint Barth&eacute;lemy",
            "iso3": "BLM",
            "numcode": 652,
            "un_member": "no",
            "calling_code": "590",
            "cctld": ".bl"
        },
        {
            "country_id": 185,
            "iso2": "SH",
            "short_name": "Saint Helena",
            "long_name": "Saint Helena, Ascension and Tristan da Cunha",
            "iso3": "SHN",
            "numcode": 654,
            "un_member": "no",
            "calling_code": "290",
            "cctld": ".sh"
        },
        {
            "country_id": 186,
            "iso2": "KN",
            "short_name": "Saint Kitts and Nevis",
            "long_name": "Federation of Saint Christopher and Nevis",
            "iso3": "KNA",
            "numcode": 659,
            "un_member": "yes",
            "calling_code": "1+869",
            "cctld": ".kn"
        },
        {
            "country_id": 187,
            "iso2": "LC",
            "short_name": "Saint Lucia",
            "long_name": "Saint Lucia",
            "iso3": "LCA",
            "numcode": 662,
            "un_member": "yes",
            "calling_code": "1+758",
            "cctld": ".lc"
        },
        {
            "country_id": 188,
            "iso2": "MF",
            "short_name": "Saint Martin",
            "long_name": "Saint Martin",
            "iso3": "MAF",
            "numcode": 663,
            "un_member": "no",
            "calling_code": "590",
            "cctld": ".mf"
        },
        {
            "country_id": 189,
            "iso2": "PM",
            "short_name": "Saint Pierre and Miquelon",
            "long_name": "Saint Pierre and Miquelon",
            "iso3": "SPM",
            "numcode": 666,
            "un_member": "no",
            "calling_code": "508",
            "cctld": ".pm"
        },
        {
            "country_id": 190,
            "iso2": "VC",
            "short_name": "Saint Vincent and the Grenadines",
            "long_name": "Saint Vincent and the Grenadines",
            "iso3": "VCT",
            "numcode": 670,
            "un_member": "yes",
            "calling_code": "1+784",
            "cctld": ".vc"
        },
        {
            "country_id": 191,
            "iso2": "WS",
            "short_name": "Samoa",
            "long_name": "Independent State of Samoa",
            "iso3": "WSM",
            "numcode": 882,
            "un_member": "yes",
            "calling_code": "685",
            "cctld": ".ws"
        },
        {
            "country_id": 192,
            "iso2": "SM",
            "short_name": "San Marino",
            "long_name": "Republic of San Marino",
            "iso3": "SMR",
            "numcode": 674,
            "un_member": "yes",
            "calling_code": "378",
            "cctld": ".sm"
        },
        {
            "country_id": 193,
            "iso2": "ST",
            "short_name": "Sao Tome and Principe",
            "long_name": "Democratic Republic of S&atilde;o Tom&eacute; and Pr&iacute;ncipe",
            "iso3": "STP",
            "numcode": 678,
            "un_member": "yes",
            "calling_code": "239",
            "cctld": ".st"
        },
        {
            "country_id": 194,
            "iso2": "SA",
            "short_name": "Saudi Arabia",
            "long_name": "Kingdom of Saudi Arabia",
            "iso3": "SAU",
            "numcode": 682,
            "un_member": "yes",
            "calling_code": "966",
            "cctld": ".sa"
        },
        {
            "country_id": 195,
            "iso2": "SN",
            "short_name": "Senegal",
            "long_name": "Republic of Senegal",
            "iso3": "SEN",
            "numcode": 686,
            "un_member": "yes",
            "calling_code": "221",
            "cctld": ".sn"
        },
        {
            "country_id": 196,
            "iso2": "RS",
            "short_name": "Serbia",
            "long_name": "Republic of Serbia",
            "iso3": "SRB",
            "numcode": 688,
            "un_member": "yes",
            "calling_code": "381",
            "cctld": ".rs"
        },
        {
            "country_id": 197,
            "iso2": "SC",
            "short_name": "Seychelles",
            "long_name": "Republic of Seychelles",
            "iso3": "SYC",
            "numcode": 690,
            "un_member": "yes",
            "calling_code": "248",
            "cctld": ".sc"
        },
        {
            "country_id": 198,
            "iso2": "SL",
            "short_name": "Sierra Leone",
            "long_name": "Republic of Sierra Leone",
            "iso3": "SLE",
            "numcode": 694,
            "un_member": "yes",
            "calling_code": "232",
            "cctld": ".sl"
        },
        {
            "country_id": 199,
            "iso2": "SG",
            "short_name": "Singapore",
            "long_name": "Republic of Singapore",
            "iso3": "SGP",
            "numcode": 702,
            "un_member": "yes",
            "calling_code": "65",
            "cctld": ".sg"
        },
        {
            "country_id": 200,
            "iso2": "SX",
            "short_name": "Sint Maarten",
            "long_name": "Sint Maarten",
            "iso3": "SXM",
            "numcode": 534,
            "un_member": "no",
            "calling_code": "1+721",
            "cctld": ".sx"
        },
        {
            "country_id": 201,
            "iso2": "SK",
            "short_name": "Slovakia",
            "long_name": "Slovak Republic",
            "iso3": "SVK",
            "numcode": 703,
            "un_member": "yes",
            "calling_code": "421",
            "cctld": ".sk"
        },
        {
            "country_id": 202,
            "iso2": "SI",
            "short_name": "Slovenia",
            "long_name": "Republic of Slovenia",
            "iso3": "SVN",
            "numcode": 705,
            "un_member": "yes",
            "calling_code": "386",
            "cctld": ".si"
        },
        {
            "country_id": 203,
            "iso2": "SB",
            "short_name": "Solomon Islands",
            "long_name": "Solomon Islands",
            "iso3": "SLB",
            "numcode": 90,
            "un_member": "yes",
            "calling_code": "677",
            "cctld": ".sb"
        },
        {
            "country_id": 204,
            "iso2": "SO",
            "short_name": "Somalia",
            "long_name": "Somali Republic",
            "iso3": "SOM",
            "numcode": 706,
            "un_member": "yes",
            "calling_code": "252",
            "cctld": ".so"
        },
        {
            "country_id": 205,
            "iso2": "ZA",
            "short_name": "South Africa",
            "long_name": "Republic of South Africa",
            "iso3": "ZAF",
            "numcode": 710,
            "un_member": "yes",
            "calling_code": "27",
            "cctld": ".za"
        },
        {
            "country_id": 206,
            "iso2": "GS",
            "short_name": "South Georgia and the South Sandwich Islands",
            "long_name": "South Georgia and the South Sandwich Islands",
            "iso3": "SGS",
            "numcode": 239,
            "un_member": "no",
            "calling_code": "500",
            "cctld": ".gs"
        },
        {
            "country_id": 207,
            "iso2": "KR",
            "short_name": "South Korea",
            "long_name": "Republic of Korea",
            "iso3": "KOR",
            "numcode": 410,
            "un_member": "yes",
            "calling_code": "82",
            "cctld": ".kr"
        },
        {
            "country_id": 208,
            "iso2": "SS",
            "short_name": "South Sudan",
            "long_name": "Republic of South Sudan",
            "iso3": "SSD",
            "numcode": 728,
            "un_member": "yes",
            "calling_code": "211",
            "cctld": ".ss"
        },
        {
            "country_id": 209,
            "iso2": "ES",
            "short_name": "Spain",
            "long_name": "Kingdom of Spain",
            "iso3": "ESP",
            "numcode": 724,
            "un_member": "yes",
            "calling_code": "34",
            "cctld": ".es"
        },
        {
            "country_id": 210,
            "iso2": "LK",
            "short_name": "Sri Lanka",
            "long_name": "Democratic Socialist Republic of Sri Lanka",
            "iso3": "LKA",
            "numcode": 144,
            "un_member": "yes",
            "calling_code": "94",
            "cctld": ".lk"
        },
        {
            "country_id": 211,
            "iso2": "SD",
            "short_name": "Sudan",
            "long_name": "Republic of the Sudan",
            "iso3": "SDN",
            "numcode": 729,
            "un_member": "yes",
            "calling_code": "249",
            "cctld": ".sd"
        },
        {
            "country_id": 212,
            "iso2": "SR",
            "short_name": "Suriname",
            "long_name": "Republic of Suriname",
            "iso3": "SUR",
            "numcode": 740,
            "un_member": "yes",
            "calling_code": "597",
            "cctld": ".sr"
        },
        {
            "country_id": 213,
            "iso2": "SJ",
            "short_name": "Svalbard and Jan Mayen",
            "long_name": "Svalbard and Jan Mayen",
            "iso3": "SJM",
            "numcode": 744,
            "un_member": "no",
            "calling_code": "47",
            "cctld": ".sj"
        },
        {
            "country_id": 214,
            "iso2": "SZ",
            "short_name": "Swaziland",
            "long_name": "Kingdom of Swaziland",
            "iso3": "SWZ",
            "numcode": 748,
            "un_member": "yes",
            "calling_code": "268",
            "cctld": ".sz"
        },
        {
            "country_id": 215,
            "iso2": "SE",
            "short_name": "Sweden",
            "long_name": "Kingdom of Sweden",
            "iso3": "SWE",
            "numcode": 752,
            "un_member": "yes",
            "calling_code": "46",
            "cctld": ".se"
        },
        {
            "country_id": 216,
            "iso2": "CH",
            "short_name": "Switzerland",
            "long_name": "Swiss Confederation",
            "iso3": "CHE",
            "numcode": 756,
            "un_member": "yes",
            "calling_code": "41",
            "cctld": ".ch"
        },
        {
            "country_id": 217,
            "iso2": "SY",
            "short_name": "Syria",
            "long_name": "Syrian Arab Republic",
            "iso3": "SYR",
            "numcode": 760,
            "un_member": "yes",
            "calling_code": "963",
            "cctld": ".sy"
        },
        {
            "country_id": 218,
            "iso2": "TW",
            "short_name": "Taiwan",
            "long_name": "Republic of China (Taiwan)",
            "iso3": "TWN",
            "numcode": 158,
            "un_member": "former",
            "calling_code": "886",
            "cctld": ".tw"
        },
        {
            "country_id": 219,
            "iso2": "TJ",
            "short_name": "Tajikistan",
            "long_name": "Republic of Tajikistan",
            "iso3": "TJK",
            "numcode": 762,
            "un_member": "yes",
            "calling_code": "992",
            "cctld": ".tj"
        },
        {
            "country_id": 220,
            "iso2": "TZ",
            "short_name": "Tanzania",
            "long_name": "United Republic of Tanzania",
            "iso3": "TZA",
            "numcode": 834,
            "un_member": "yes",
            "calling_code": "255",
            "cctld": ".tz"
        },
        {
            "country_id": 221,
            "iso2": "TH",
            "short_name": "Thailand",
            "long_name": "Kingdom of Thailand",
            "iso3": "THA",
            "numcode": 764,
            "un_member": "yes",
            "calling_code": "66",
            "cctld": ".th"
        },
        {
            "country_id": 222,
            "iso2": "TL",
            "short_name": "Timor-Leste (East Timor)",
            "long_name": "Democratic Republic of Timor-Leste",
            "iso3": "TLS",
            "numcode": 626,
            "un_member": "yes",
            "calling_code": "670",
            "cctld": ".tl"
        },
        {
            "country_id": 223,
            "iso2": "TG",
            "short_name": "Togo",
            "long_name": "Togolese Republic",
            "iso3": "TGO",
            "numcode": 768,
            "un_member": "yes",
            "calling_code": "228",
            "cctld": ".tg"
        },
        {
            "country_id": 224,
            "iso2": "TK",
            "short_name": "Tokelau",
            "long_name": "Tokelau",
            "iso3": "TKL",
            "numcode": 772,
            "un_member": "no",
            "calling_code": "690",
            "cctld": ".tk"
        },
        {
            "country_id": 225,
            "iso2": "TO",
            "short_name": "Tonga",
            "long_name": "Kingdom of Tonga",
            "iso3": "TON",
            "numcode": 776,
            "un_member": "yes",
            "calling_code": "676",
            "cctld": ".to"
        },
        {
            "country_id": 226,
            "iso2": "TT",
            "short_name": "Trinidad and Tobago",
            "long_name": "Republic of Trinidad and Tobago",
            "iso3": "TTO",
            "numcode": 780,
            "un_member": "yes",
            "calling_code": "1+868",
            "cctld": ".tt"
        },
        {
            "country_id": 227,
            "iso2": "TN",
            "short_name": "Tunisia",
            "long_name": "Republic of Tunisia",
            "iso3": "TUN",
            "numcode": 788,
            "un_member": "yes",
            "calling_code": "216",
            "cctld": ".tn"
        },
        {
            "country_id": 228,
            "iso2": "TR",
            "short_name": "Turkey",
            "long_name": "Republic of Turkey",
            "iso3": "TUR",
            "numcode": 792,
            "un_member": "yes",
            "calling_code": "90",
            "cctld": ".tr"
        },
        {
            "country_id": 229,
            "iso2": "TM",
            "short_name": "Turkmenistan",
            "long_name": "Turkmenistan",
            "iso3": "TKM",
            "numcode": 795,
            "un_member": "yes",
            "calling_code": "993",
            "cctld": ".tm"
        },
        {
            "country_id": 230,
            "iso2": "TC",
            "short_name": "Turks and Caicos Islands",
            "long_name": "Turks and Caicos Islands",
            "iso3": "TCA",
            "numcode": 796,
            "un_member": "no",
            "calling_code": "1+649",
            "cctld": ".tc"
        },
        {
            "country_id": 231,
            "iso2": "TV",
            "short_name": "Tuvalu",
            "long_name": "Tuvalu",
            "iso3": "TUV",
            "numcode": 798,
            "un_member": "yes",
            "calling_code": "688",
            "cctld": ".tv"
        },
        {
            "country_id": 232,
            "iso2": "UG",
            "short_name": "Uganda",
            "long_name": "Republic of Uganda",
            "iso3": "UGA",
            "numcode": 800,
            "un_member": "yes",
            "calling_code": "256",
            "cctld": ".ug"
        },
        {
            "country_id": 233,
            "iso2": "UA",
            "short_name": "Ukraine",
            "long_name": "Ukraine",
            "iso3": "UKR",
            "numcode": 804,
            "un_member": "yes",
            "calling_code": "380",
            "cctld": ".ua"
        },
        {
            "country_id": 234,
            "iso2": "AE",
            "short_name": "United Arab Emirates",
            "long_name": "United Arab Emirates",
            "iso3": "ARE",
            "numcode": 784,
            "un_member": "yes",
            "calling_code": "971",
            "cctld": ".ae"
        },
        {
            "country_id": 235,
            "iso2": "GB",
            "short_name": "United Kingdom",
            "long_name": "United Kingdom of Great Britain and Nothern Ireland",
            "iso3": "GBR",
            "numcode": 826,
            "un_member": "yes",
            "calling_code": "44",
            "cctld": ".uk"
        },
        {
            "country_id": 236,
            "iso2": "US",
            "short_name": "United States",
            "long_name": "United States of America",
            "iso3": "USA",
            "numcode": 840,
            "un_member": "yes",
            "calling_code": "1",
            "cctld": ".us"
        },
 
        {
            "country_id": 238,
            "iso2": "UY",
            "short_name": "Uruguay",
            "long_name": "Eastern Republic of Uruguay",
            "iso3": "URY",
            "numcode": 858,
            "un_member": "yes",
            "calling_code": "598",
            "cctld": ".uy"
        },
        {
            "country_id": 239,
            "iso2": "UZ",
            "short_name": "Uzbekistan",
            "long_name": "Republic of Uzbekistan",
            "iso3": "UZB",
            "numcode": 860,
            "un_member": "yes",
            "calling_code": "998",
            "cctld": ".uz"
        },
        {
            "country_id": 240,
            "iso2": "VU",
            "short_name": "Vanuatu",
            "long_name": "Republic of Vanuatu",
            "iso3": "VUT",
            "numcode": 548,
            "un_member": "yes",
            "calling_code": "678",
            "cctld": ".vu"
        },
        {
            "country_id": 241,
            "iso2": "VA",
            "short_name": "Vatican City",
            "long_name": "State of the Vatican City",
            "iso3": "VAT",
            "numcode": 336,
            "un_member": "no",
            "calling_code": "39",
            "cctld": ".va"
        },
        {
            "country_id": 242,
            "iso2": "VE",
            "short_name": "Venezuela",
            "long_name": "Bolivarian Republic of Venezuela",
            "iso3": "VEN",
            "numcode": 862,
            "un_member": "yes",
            "calling_code": "58",
            "cctld": ".ve"
        },
        {
            "country_id": 243,
            "iso2": "VN",
            "short_name": "Vietnam",
            "long_name": "Socialist Republic of Vietnam",
            "iso3": "VNM",
            "numcode": 704,
            "un_member": "yes",
            "calling_code": "84",
            "cctld": ".vn"
        },
        {
            "country_id": 244,
            "iso2": "VG",
            "short_name": "Virgin Islands, British",
            "long_name": "British Virgin Islands",
            "iso3": "VGB",
            "numcode": 92,
            "un_member": "no",
            "calling_code": "1+284",
            "cctld": ".vg"
        },
        {
            "country_id": 245,
            "iso2": "VI",
            "short_name": "Virgin Islands, US",
            "long_name": "Virgin Islands of the United States",
            "iso3": "VIR",
            "numcode": 850,
            "un_member": "no",
            "calling_code": "1+340",
            "cctld": ".vi"
        },
        {
            "country_id": 246,
            "iso2": "WF",
            "short_name": "Wallis and Futuna",
            "long_name": "Wallis and Futuna",
            "iso3": "WLF",
            "numcode": 876,
            "un_member": "no",
            "calling_code": "681",
            "cctld": ".wf"
        },
        {
            "country_id": 247,
            "iso2": "EH",
            "short_name": "Western Sahara",
            "long_name": "Western Sahara",
            "iso3": "ESH",
            "numcode": 732,
            "un_member": "no",
            "calling_code": "212",
            "cctld": ".eh"
        },
        {
            "country_id": 248,
            "iso2": "YE",
            "short_name": "Yemen",
            "long_name": "Republic of Yemen",
            "iso3": "YEM",
            "numcode": 887,
            "un_member": "yes",
            "calling_code": "967",
            "cctld": ".ye"
        },
        {
            "country_id": 249,
            "iso2": "ZM",
            "short_name": "Zambia",
            "long_name": "Republic of Zambia",
            "iso3": "ZMB",
            "numcode": 894,
            "un_member": "yes",
            "calling_code": "260",
            "cctld": ".zm"
        },
        {
            "country_id": 250,
            "iso2": "ZW",
            "short_name": "Zimbabwe",
            "long_name": "Republic of Zimbabwe",
            "iso3": "ZWE",
            "numcode": 716,
            "un_member": "yes",
            "calling_code": "263",
            "cctld": ".zw"
        },

        //rest of the countries
    ]
};

export default countries;