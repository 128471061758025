import React, { useState } from 'react'
import { Search, CreditCard, User, HelpCircle, FileText, Mail, Phone, MapPin, ChevronLeft } from 'lucide-react'

interface SubIssues {
  'Payment Issues': string[];
  'Account Problems': string[];
  'Documentation': string[];
  'General Inquiry': string[];
}

export default function HelpCenter() {
  const [activeTab, setActiveTab] = useState('issue')
  const [issueStep, setIssueStep] = useState(0)
  const [selectedIssue, setSelectedIssue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const issueTypes = [
    { icon: <CreditCard className="w-6 h-6" />, label: 'Payment Issues' },
    { icon: <User className="w-6 h-6" />, label: 'Account Problems' },
    { icon: <FileText className="w-6 h-6" />, label: 'Documentation' },
    { icon: <HelpCircle className="w-6 h-6" />, label: 'General Inquiry' },
  ]

  const subIssues: SubIssues = {
    'Payment Issues': [
      'My payments are blocked',
      'My payments aren\'t working',
      'I see transactions I didn\'t make',
      'Other payment issue'
    ],
    'Account Problems': [
      'I can\'t log in',
      'I need to update my account information',
      'My account is locked',
      'Other account issue'
    ],
    'Documentation': [
      'I need help with forms',
      'I can\'t find specific documentation',
      'I need clarification on terms',
      'Other documentation issue'
    ],
    'General Inquiry': [
      'I have a question about fees',
      'I want to know about international transfers',
      'I need information about business accounts',
      'Other general inquiry'
    ]
  }

  const faqData = [
    { question: "How do I reset my password?", answer: "You can reset your password by clicking on the 'Forgot Password' link on the login page." },
    { question: "What are the fees for using PAAYCO?", answer: "Our fee structure varies depending on the type of account and services you use. Please check our pricing page for detailed information." },
    { question: "How long does it take to process a payment?", answer: "Most payments are processed instantly. In some cases, it may take up to 24 hours depending on the payment method and recipient's bank." },
    { question: "Is PAAYCO available outside the UK?", answer: "Currently, PAAYCO is only available in the United Kingdom. We're working on expanding to other countries in the future." },
    { question: "How secure is PAAYCO?", answer: "PAAYCO uses state-of-the-art encryption and security measures to protect your data and transactions. We are fully compliant with PCI-DSS standards." },
    { question: "Can I link multiple bank accounts to my PAAYCO account?", answer: "Yes, you can link multiple bank accounts to your PAAYCO account for added flexibility in managing your finances." },
    { question: "What should I do if I suspect fraudulent activity on my account?", answer: "If you suspect any unauthorized activity, please contact our support team immediately. We have dedicated fraud prevention measures in place to protect our customers." },
    { question: "How do I update my personal information?", answer: "You can update your personal information in the 'Account Settings' section of your PAAYCO dashboard. Some changes may require additional verification for security purposes." },
  ]

  const filteredFAQ = faqData.filter(item => 
    item.question.toLowerCase().includes(searchQuery.toLowerCase()) || 
    item.answer.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const resetIssueSelection = () => {
    setIssueStep(0)
    setSelectedIssue('')
  }

  return (
    <div className="bg-white rounded-lg w-full overflow-hidden flex flex-col">
      <div className="flex p-2 bg-[#F0FFF7]">
        {['issue', 'faq'].map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab)
              resetIssueSelection()
            }}
            className={`flex-1 py-4 px-6 font-medium text-sm uppercase tracking-wider transition-colors duration-300 rounded-full mx-2 ${
              activeTab === tab 
                ? 'bg-[#097152] text-white shadow-md' 
                : 'bg-white text-[#097152] hover:bg-[#AFF8C8]'
            }`}
          >
            {tab === 'issue' ? 'Get Help' : 'FAQ'}
          </button>
        ))}
      </div>
      <div className="p-4 overflow-y-auto flex-grow">
        {activeTab === 'issue' && (
          <div>
            {issueStep === 0 && (
              <div className="grid grid-cols-2 gap-6 p-4">
                {issueTypes.map((issue, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedIssue(issue.label)
                      setIssueStep(1)
                    }}
                    className="flex flex-col items-center justify-center p-6 rounded-lg transition-all duration-300 bg-white text-[#097152] hover:bg-[#F0FFF7] border border-[#AFF8C8] h-32"
                  >
                    {issue.icon}
                    <span className="mt-4 text-base font-medium">{issue.label}</span>
                  </button>
                ))}
              </div>
            )}
            {issueStep === 1 && (
              <div>
                <button
                  onClick={resetIssueSelection}
                  className="mb-4 flex items-center text-[#097152] hover:text-[#00423C]"
                >
                  <ChevronLeft size={20} />
                  <span className="ml-1">Back</span>
                </button>
                <h3 className="font-semibold text-[#00423C] mb-4">{selectedIssue}</h3>
                <div className="space-y-2">
                  {subIssues[selectedIssue].map((subIssue, index) => (
                    <button
                      key={index}
                      onClick={() => setIssueStep(2)}
                      className="w-full text-left p-3 rounded-lg transition-all duration-300 bg-white text-[#097152] hover:bg-[#F0FFF7] border border-[#AFF8C8]"
                    >
                      {subIssue}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {issueStep === 2 && (
              <div>
                <button
                  onClick={() => setIssueStep(1)}
                  className="mb-4 flex items-center text-[#097152] hover:text-[#00423C]"
                >
                  <ChevronLeft size={20} />
                  <span className="ml-1">Back</span>
                </button>
                <h3 className="font-semibold text-[#00423C] mb-4">Contact Us</h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-4 text-[#00423C]">
                    <Mail size={20} className="text-[#097152]" />
                    <span>support@paayco.com</span>
                  </div>
                  <div className="flex items-center space-x-4 text-[#00423C]">
                    <Phone size={20} className="text-[#097152]" />
                    <span>+44 (800) 123-4567</span>
                  </div>
                  <form className="space-y-4">
                    <input 
                      placeholder="Your Name" 
                      className="w-full px-4 py-2 rounded-lg border-2 border-[#AFF8C8] focus:border-[#24EC7E] focus:outline-none transition-colors duration-300"
                    />
                    <input 
                      placeholder="Your Email" 
                      type="email" 
                      className="w-full px-4 py-2 rounded-lg border-2 border-[#AFF8C8] focus:border-[#24EC7E] focus:outline-none transition-colors duration-300"
                    />
                    <textarea 
                      placeholder="Your Message" 
                      rows={4}
                      className="w-full px-4 py-2 rounded-lg border-2 border-[#AFF8C8] focus:border-[#24EC7E] focus:outline-none transition-colors duration-300 resize-none"
                    />
                    <button 
                      type="submit" 
                      className="w-full bg-[#097152] hover:bg-[#00423C] text-white rounded-lg py-2 font-semibold transition-colors duration-300"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        )}
        {activeTab === 'faq' && (
          <div className="space-y-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search FAQ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 pl-10 rounded-lg border-2 border-[#AFF8C8] focus:border-[#24EC7E] focus:outline-none transition-colors duration-300"
              />
              <Search className="absolute left-3 top-2.5 text-[#097152]" size={20} />
            </div>
            {filteredFAQ.map((item, index) => (
              <div key={index} className="border-b border-[#AFF8C8] pb-4 last:border-b-0">
                <h3 className="font-semibold text-[#00423C] mb-2">{item.question}</h3>
                <p className="text-[#097152] text-sm">{item.answer}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

