import { FileEdit, Link } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
export default function Component() {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
      minHeight: '80vh',
      backgroundColor: 'white'
    },
    heading: {
      fontSize: '2.5rem',
      fontWeight: '600',
      marginBottom: '40px',
      alignSelf: 'flex-start'
    },
    content: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      flex: 1,
      width: '100%',
      maxWidth: '800px',
      marginTop: '40px'
    },
    icon: {
      width: '120px',
      height: '120px',
      color: '#AFF8C8',
      marginBottom: '40px'
    },
    description: {
      fontSize: '1.25rem',
      color: '#666666',
      textAlign: 'center' as const,
      maxWidth: '800px',
      marginBottom: '40px',
      lineHeight: 1.5
    },
    button: {
      backgroundColor: '#24EC7E',
      color: 'black',
      border: 'none',
      padding: '16px 32px',
      borderRadius: '100px',
      fontSize: '1rem',
      fontWeight: '500',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      transition: 'background-color 0.2s',
      ':hover': {
        backgroundColor: '#1ed16f'
      }
    }
  }

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Invoices</h1>
      <div style={styles.content}>
        <FileEdit style={styles.icon} />
        <p style={styles.description}>
          Create and manage your invoices in minutes. Simplify your billings process while tracking overdue payments.
        </p>
  
          <button onClick={() => navigate('/invoicing')} style={styles.button}>
            Create invoice
          </button>
      
      </div>
    </div>
  )
}