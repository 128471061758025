import React from 'react'
import { ScrollArea } from "@/components/ui/scroll-area"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { ArrowRight, DollarSign, PoundSterling } from 'lucide-react'

interface Notification {
  event_id: string
  event: string
  created: string
}

const notifications: Notification[] = [
  {
    event_id: "evt_7345322f097ff5e53b4e2095",
    event: "Money added via top-up £10.00",
    created: "Nov 01, 2024 09:21 AM"
  },
  {
    event_id: "evt_bf7d59210da2b1a1969ca2f1",
    event: "Sell order of £2.00 from account acct_c0efdac1853a815f3fafca3d initiated",
    created: "Oct 02, 2024 11:13 AM"
  },
  {
    event_id: "evt_532bf31b0de554a165f587c9",
    event: "Exchange amount $3.85 transferred to acct_cc1d5b495b0b30809b96e74c completed.",
    created: "Oct 02, 2024 11:13 AM"
  },
  {
    event_id: "evt_0cd92579c6d56275b6ba4837",
    event: "Sell order of £5.00 from account acct_c0efdac1853a815f3fafca3d initiated",
    created: "Oct 02, 2024 11:24 AM"
  },
  {
    event_id: "evt_75627428c0adf6f765449dff",
    event: "Exchange amount $9.64 transferred to acct_cc1d5b495b0b30809b96e74c completed.",
    created: "Oct 02, 2024 11:24 AM"
  }
]

export function NotificationsModalComponent() {
  return (
    <ScrollArea className="h-[60vh] w-full rounded-md border border-[#AFF8C8] p-4">
      <div className="space-y-4">
        {notifications.map((notification) => (
          <div key={notification.event_id} className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 border border-[#AFF8C8]">
            <div className="flex-shrink-0">
              {notification.event.includes('£') ? (
                <div className="w-10 h-10 bg-[#097152] rounded-full flex items-center justify-center">
                  <PoundSterling className="h-6 w-6 text-white" />
                </div>
              ) : (
                <div className="w-10 h-10 bg-[#24EC7E] rounded-full flex items-center justify-center">
                  <DollarSign className="h-6 w-6 text-white" />
                </div>
              )}
            </div>
            <div className="flex-grow">
              <p className="text-sm font-medium text-[#00423C]">{notification.event}</p>
              <p className="text-xs text-[#097152]">{notification.created}</p>
            </div>
            <div className="flex-shrink-0">
              <ArrowRight className="h-5 w-5 text-[#097152]" />
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  )
}