// /home/thomas/projects/funddpayNew/funddpay-dashboard/resources/js/contexts/AuthContextData/utils.ts

// Console styling utilities for logging
const consoleStyles = {
    group: 'color: #4CAF50; font-weight: bold; font-size: 12px;',
    success: 'color: #4CAF50; font-weight: bold;',
    info: 'color: #2196F3; font-weight: bold;',
    warning: 'color: #FFC107; font-weight: bold;',
    error: 'color: #F44336; font-weight: bold;',
  };
  
  // Logging utility function
  export const logAuthEvent = (
    action: string,
    data?: any,
    type: 'success' | 'info' | 'warning' | 'error' = 'info'
  ) => {
    console.groupCollapsed(`%c🔐 Auth: ${action}`, consoleStyles.group);
    console.log(`%cTimestamp: ${new Date().toISOString()}`, consoleStyles[type]);
    if (data) {
      console.log('Details:', data);
    }
    console.groupEnd();
  };
  