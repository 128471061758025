import { useState } from 'react';
import {
  ChevronLeft,
  Eye,
  EyeOff,
  FileText,
  Lock,
  History,
  AlertCircle,
  BarChart3,
  Search,
  MapPin,
  TrendingUp,
  ChevronRight,
  Globe,
  CreditCardIcon as CardIcon,
  Smartphone,
  Grid,
  X
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Img as Image } from 'react-image';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Input } from '@/components/ui/input';

import paayco from './images/paayco.png';

interface ModalState {
  type:
    | 'pin'
    | 'details'
    | 'lock'
    | 'usage'
    | 'freeze'
    | 'replace'
    | 'spending'
    | null;
  isOpen: boolean;
}

export function CardDetailsComponent() {
  const [modalState, setModalState] = useState<ModalState>({
    type: null,
    isOpen: false
  });
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === 'ipower2019!') {
      setVerified(true);
      setError('');
    } else {
      setError('Incorrect password');
    }
  };

  const renderModalContent = () => {
    if (
      !verified &&
      ['pin', 'details', 'lock'].includes(modalState.type as string)
    ) {
      return (
        <div className="p-8 space-y-6">
          <DialogHeader className="p-0">
            <DialogTitle className="text-[24px] font-semibold">
              Verify your identity
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleVerify} className="space-y-6">
            <p className="text-gray-600">
              Please enter your password to verify your identity and access your
              PIN. This is a security measure to protect the safety of your
              account.
            </p>
            <div className="space-y-2">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="relative">
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="h-12 text-base rounded-lg border-gray-200"
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}
            </div>
            <Button
              type="submit"
              className="w-full h-12 bg-[#24EC7E] hover:bg-[#20d471] text-black font-medium rounded-lg"
            >
              Continue
            </Button>
            <div className="text-center">
              <button
                type="button"
                className="text-[#00694B] hover:underline"
              >
                Forgot password?
              </button>
            </div>
          </form>
        </div>
      );
    }

    switch (modalState.type) {
      case 'pin':
        return verified && (
          <div className="text-center p-8">
            <p className="text-2xl font-bold mb-2">1234</p>
            <p className="text-gray-500">This is your card PIN</p>
          </div>
        );
      case 'details':
        return verified && (
          <div className="space-y-4 p-8">
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Card Number</p>
              <p className="font-medium">4111 1111 1111 1111</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Expiry Date</p>
              <p className="font-medium">12/25</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">CVV</p>
              <p className="font-medium">123</p>
            </div>
          </div>
        );
      case 'lock':
        return verified && (
          <div className="p-8">
            <DialogHeader className="p-0">
              <DialogTitle className="text-[24px] font-semibold mb-6">
                Lock Card
              </DialogTitle>
            </DialogHeader>
            <p className="text-gray-600 mb-6">
              Are you sure you want to lock this card? All transactions will be
              declined until you unlock it.
            </p>
            <div className="flex gap-3">
              <Button
                variant="outline"
                onClick={() =>
                  setModalState({ type: null, isOpen: false })
                }
                className="flex-1"
              >
                Cancel
              </Button>
              <Button
                className="flex-1 bg-[#24EC7E] hover:bg-[#20d471] text-black"
                onClick={() =>
                  setModalState({ type: null, isOpen: false })
                }
              >
                Lock Card
              </Button>
            </div>
          </div>
        );
      case 'spending':
        return (
          <div className="p-8">
            <DialogHeader className="p-0">
              <DialogTitle className="text-[24px] font-semibold mb-6">
                Spending limits
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              {[
                {
                  title: 'Primary cards',
                  description:
                    'The maximum amount allowed on your primary cards'
                },
                {
                  title: 'Shared cards',
                  description:
                    'The maximum amount your team can spend on shared cards'
                }
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 p-4 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <CardIcon className="w-5 h-5 text-gray-600" />
                  </div>
                  <div className="flex-1">
                    <div className="font-medium text-base">
                      {item.title}
                    </div>
                    <div className="text-sm text-gray-500">
                      {item.description}
                    </div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </div>
              ))}
            </div>
          </div>
        );
      case 'replace':
        return (
          <div className="p-8">
            <DialogHeader className="p-0">
              <DialogTitle className="text-[24px] font-semibold mb-6">
                Reason for requesting a new card
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-2">
              {['Stolen card', 'Lost', 'Other'].map((reason, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 p-4 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <CardIcon className="w-5 h-5 text-gray-600" />
                  </div>
                  <div className="flex-1">
                    <span className="font-medium">{reason}</span>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </div>
              ))}
            </div>
          </div>
        );
      case 'usage':
        return (
          <div className="p-8">
            <DialogHeader className="p-0">
              <DialogTitle className="text-[24px] font-semibold mb-6">
                Card usage
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
              {[
                { icon: Globe, label: 'Online payments' },
                { icon: CardIcon, label: 'ATM withdrawals' },
                { icon: Smartphone, label: 'Mobile wallets' },
                { icon: Grid, label: 'Chip and PIN transactions' }
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 p-4 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <item.icon className="w-5 h-5 text-gray-600" />
                  </div>
                  <div className="flex-1">
                    <div className="font-medium text-base">
                      {item.label}
                    </div>
                  </div>
                  <Switch className="data-[state=checked]:bg-[#24EC7E]" />
                </div>
              ))}
            </div>
          </div>
        );
      case 'freeze':
        return (
          <div className="p-8">
            <DialogHeader className="p-0">
              <DialogTitle className="text-[24px] font-semibold mb-6">
                Freeze your card
              </DialogTitle>
            </DialogHeader>
            <p className="text-gray-600 mb-6">
              You can lock your card for added security or unlock it when
              needed.
            </p>
            <Button
              className="w-full bg-[#24EC7E] hover:bg-[#20d471] text-black font-medium rounded-full"
              onClick={() =>
                setModalState({ type: null, isOpen: false })
              }
            >
              Freeze card
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white p-6">
      <div className="max-w-[1200px] mx-auto">
        <Link
          to="/cards"
          className="inline-flex items-center gap-2 text-[15px] text-gray-600 hover:text-gray-800 transition-colors mb-6"
        >
          <ChevronLeft className="w-5 h-5" />
          Back to Cards
        </Link>

        <h1 className="text-2xl font-bold mb-8">Digital Card</h1>

        <div className="mb-16 flex items-start gap-8">
          <div className="relative w-[420px] aspect-[1.6/1] rounded-xl overflow-hidden bg-[#00694B]">
            <Image
              src={paayco}
              alt="Card placeholder"
              width={420}
              height={264}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="flex-1 space-y-6">
            {[
              {
                title: 'View Pin',
                icon: Eye,
                description:
                  'Securely access your pin whenever needed',
                type: 'pin'
              },
              {
                title: 'Details',
                icon: FileText,
                description: 'View details about your card',
                type: 'details'
              },
              {
                title: 'Lock',
                icon: Lock,
                description: 'Lock card temporarily',
                type: 'lock'
              }
            ].map((action, index) => (
              <button
                key={index}
                className="w-full flex items-center justify-between gap-6"
                onClick={() => {
                  setModalState({
                    type: action.type as ModalState['type'],
                    isOpen: true
                  });
                  setVerified(false);
                  setPassword('');
                  setError('');
                }}
              >
                <div className="flex items-center gap-6">
                  <div className="w-12 h-12 bg-[#E5F7F0] rounded-full flex items-center justify-center">
                    <action.icon className="w-5 h-5 text-[#00694B]" />
                  </div>
                  <div className="text-left">
                    <div className="text-[15px] font-medium mb-1">
                      {action.title}
                    </div>
                    <div className="text-[13px] text-gray-500">
                      {action.description}
                    </div>
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </button>
            ))}
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-[15px] text-gray-500 mb-6">
            Card preferences
          </h2>

          <div className="divide-y divide-gray-100 bg-white rounded-lg border border-gray-100">
            {[
              {
                icon: History,
                title: 'Check recent card activity',
                description:
                  'Easily review your recent purchases and payments',
                onClick: () =>
                  setModalState({ type: 'usage', isOpen: true })
              },
              {
                icon: AlertCircle,
                title: 'Freeze or Unfreeze card',
                description:
                  'Quickly lock your card for added security or unlock it when needed',
                onClick: () =>
                  setModalState({ type: 'freeze', isOpen: true })
              },
              {
                icon: BarChart3,
                title: 'Track card usage',
                description:
                  'Monitor where and how your card is used',
                onClick: () =>
                  setModalState({ type: 'usage', isOpen: true })
              },
              {
                icon: Search,
                title: 'Replace lost or stolen card',
                description:
                  'Request for a new card if your current card is lost or stolen',
                onClick: () =>
                  setModalState({ type: 'replace', isOpen: true })
              },
              {
                icon: MapPin,
                title: 'Locate an ATM',
                description: 'Find a withdrawal machine near you',
                href: '/cards/atm'
              },
              {
                icon: TrendingUp,
                title: 'Set spending limits',
                description:
                  'Define limits to manage your spending effectively',
                onClick: () =>
                  setModalState({ type: 'spending', isOpen: true })
              }
            ].map((item, index) =>
              item.href ? (
                <Link
                  key={index}
                  to={item.href}
                  className="flex items-center justify-between p-4 hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-[#F5F5F5] rounded-full flex items-center justify-center flex-shrink-0">
                      <item.icon className="w-5 h-5 text-gray-600" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="text-[15px] font-medium">
                        {item.title}
                      </div>
                      <div className="text-[13px] text-gray-500">
                        {item.description}
                      </div>
                    </div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </Link>
              ) : (
                <button
                  key={index}
                  onClick={item.onClick}
                  className="w-full flex items-center justify-between p-4 hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-[#F5F5F5] rounded-full flex items-center justify-center flex-shrink-0">
                      <item.icon className="w-5 h-5 text-gray-600" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="text-[15px] font-medium">
                        {item.title}
                      </div>
                      <div className="text-[13px] text-gray-500">
                        {item.description}
                      </div>
                    </div>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </button>
              )
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={modalState.isOpen}
        onOpenChange={(isOpen) => {
          setModalState({ type: null, isOpen });
          setVerified(false);
          setPassword('');
          setError('');
        }}
      >
        <DialogContent 
          style={{borderRadius: '20px'}}
          className="w-[50vw] max-w-[600px] p-0 overflow-hidden"
        >
          {renderModalContent()}
        </DialogContent>
      </Dialog>
    </div>
  );
}
