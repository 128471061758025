import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvoiceManagementComponent } from '../components/invoice-management';
import { InvoiceCreationComponent } from '../components/invoice-creation';

const Invoices: React.FC = () => {
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  if (isCreating) {
    return <InvoiceCreationComponent onCancel={() => setIsCreating(false)} />;
  }

  return (
    <div>
      <InvoiceManagementComponent onNewInvoice={() => setIsCreating(true)} />
    </div>
  );
};

export default Invoices;
