import React from 'react';
import CreatePaymentLink from '../components/payment-links';

const CreatePaymentLinkPage: React.FC = () => {
  return (
    <div className="flex flex-col h-screen">
      <CreatePaymentLink />
    </div>
  );
};

export default CreatePaymentLinkPage;