// src/components/ConvertCurrencyModalComponent.tsx

import React, { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { CircleFlag } from 'react-circle-flags';
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ArrowLeft } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api'; // Import the API module
import axios from 'axios';

type Currency = {
  id: string;
  code: string;
  flag: string;
};

type Wallet = {
  id: string;
  name: string;
  currency: string;
  net: number;
};

const currencyToFlagMap: { [key: string]: string } = {
  USD: 'us',
  GBP: 'gb',
  EUR: 'eu',
  AUD: 'au',
  // Add more as needed
};

const generateDummyChartData = (days: number) => {
  const data = [];
  const today = new Date();
  for (let i = days; i >= 0; i--) {
    const date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
    data.push({
      date: date.toISOString().split('T')[0],
      rate: 1.85 + Math.random() * 0.1 - 0.05,
    });
  }
  return data;
};

export function ConvertCurrencyModalComponent() {
  const { wallets, fetchWallets } = useAuth(); // Include fetchWallets
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [balances, setBalances] = useState<{ [key: string]: number }>({});
  const [fromCurrency, setFromCurrency] = useState('gbp');
  const [toCurrency, setToCurrency] = useState('aud');
  const [amount, setAmount] = useState('500');
  const [receivedAmount, setReceivedAmount] = useState('0');
  const [fee, setFee] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('balance');
  const [showChart, setShowChart] = useState(false);
  const [chartTimeframe, setChartTimeframe] = useState('30');
  const [chartData, setChartData] = useState(generateDummyChartData(30));
  const [transactionId, setTransactionId] = useState('');
  const [description, setDescription] = useState('');
  const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>({});

  // Fetch currencies and balances from wallets
  useEffect(() => {
    if (wallets && wallets.length > 0) {
      const currencyList: Currency[] = wallets.map((wallet: Wallet) => ({
        id: wallet.currency.toLowerCase(),
        code: wallet.currency.toUpperCase(),
        flag: currencyToFlagMap[wallet.currency.toUpperCase()] || 'us',
      }));
      setCurrencies(currencyList);

      const balancesMap: { [key: string]: number } = {};
      wallets.forEach((wallet: Wallet) => {
        balancesMap[wallet.currency.toUpperCase()] = wallet.net;
      });
      setBalances(balancesMap);
    } else {
      setCurrencies([]);
      setBalances({});
    }
  }, [wallets]);

  // Fetch exchange rates when fromCurrency changes
  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.exchangerate-api.com/v4/latest/GBP`
        );
        setExchangeRates(response.data.rates);
        setError('');
      } catch (error: any) {
        console.error('Error fetching exchange rates:', error);
        setExchangeRates({});
        setError('Failed to fetch exchange rates.');
      } finally {
        setLoading(false);
      }
    };
    fetchExchangeRates();
  }, [fromCurrency]);

  // Calculate conversion when amount or exchangeRates change
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!amount || isNaN(parsedAmount) || parsedAmount <= 0) {
      setReceivedAmount('0');
      setExchangeRate(0);
      return;
    }
    if (exchangeRates && exchangeRates[toCurrency.toUpperCase()]) {
      const rate = exchangeRates[toCurrency.toUpperCase()];
      setExchangeRate(rate);
      const convertedAmount = parsedAmount * rate;
      setReceivedAmount(convertedAmount.toFixed(2));
      // Calculate fee if needed
      const calculatedFee = calculateFee(parsedAmount);
      setFee(calculatedFee);
    } else {
      setReceivedAmount('0');
      setExchangeRate(0);
    }
  }, [amount, exchangeRates, toCurrency]);

  // Update chart data when chartTimeframe changes
  useEffect(() => {
    setChartData(generateDummyChartData(parseInt(chartTimeframe)));
  }, [chartTimeframe]);

  const handleFromCurrencyChange = (newCurrency: string) => {
    setFromCurrency(newCurrency);
    if (newCurrency === toCurrency) {
      // Select a different toCurrency
      const otherCurrencies = currencies.filter((c) => c.id !== newCurrency);
      if (otherCurrencies.length > 0) {
        setToCurrency(otherCurrencies[0].id);
      }
    }
  };

  const handleToCurrencyChange = (newCurrency: string) => {
    setToCurrency(newCurrency);
    if (newCurrency === fromCurrency) {
      // Select a different fromCurrency
      const otherCurrencies = currencies.filter((c) => c.id !== newCurrency);
      if (otherCurrencies.length > 0) {
        setFromCurrency(otherCurrencies[0].id);
      }
    }
  };

  const handleContinue = () => {
    setPage(2);
  };

  const handleBack = () => {
    setPage(1);
  };

  const handleConfirmTransaction = async () => {
    try {
      setLoading(true);
      const parsedAmount = parseFloat(amount);

      // Prepare the exchange data
      const exchangeData = {
        from_amount: parsedAmount,
        currency: fromCurrency.toUpperCase(),
        to_currency: toCurrency.toUpperCase(),
      };

      // Perform the API call using the correct method and endpoint
      // Use the api.doExchange function from api.ts
      const response = await api.doExchange(exchangeData);

      if (response && response.data && response.data.object) {
        console.log('Exchange successful:', response);
        setTransactionId(response.data.object.id);

        // Fetch updated wallets data
        await fetchWallets(); // Fetch wallets after exchange

        setPage(3);
      } else {
        setError('Failed to perform exchange.');
      }
    } catch (error: any) {
      console.error('Error performing exchange:', error);
      setError(error.message || 'Failed to perform exchange.');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    console.log('ConvertCurrencyModalComponent: Resetting to initial state');
    setPage(1);
    setTransactionId('');
    setAmount('500');
    setFromCurrency('gbp');
    setToCurrency('aud');
    setPaymentMethod('balance');
    setDescription('');
    setFee(0);
    setExchangeRate(1);
    setReceivedAmount('0');
    setShowChart(false);
    setError('');
  };

  const calculateFee = (amount: number): number => {
    // Implement your fee calculation logic here
    // For example, a 1% fee:
    return amount * 0.01;
  };

  const defaultCurrency = { id: 'usd', code: 'USD', flag: 'us' };

  const selectedFromCurrency =
    currencies.find((c) => c.id === fromCurrency) ||
    currencies[0] ||
    defaultCurrency;

  const selectedToCurrency =
    currencies.find((c) => c.id === toCurrency) ||
    currencies[0] ||
    defaultCurrency;

  if (currencies.length === 0) {
    return <div>No currencies available.</div>;
  }

  const renderPage1 = () => (
    <>
      <h2 className="text-2xl font-semibold text-center mb-6">
        How much are you sending?
      </h2>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-500 mb-2">
            You send exactly
          </label>
          <div className="flex space-x-2">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <CircleFlag
                  countryCode={selectedFromCurrency.flag}
                  height="24"
                  width="24"
                />
              </div>
              <Input
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full pl-12 pr-3 py-2 border border-gray-200 rounded-md text-lg h-[52px]"
              />
            </div>
            <Select
              value={fromCurrency}
              onValueChange={handleFromCurrencyChange}
            >
              <SelectTrigger className="w-1/3 border border-gray-200 rounded-md h-[52px]">
                <SelectValue placeholder="Select currency">
                  <div className="flex items-center">
                    <CircleFlag
                      countryCode={selectedFromCurrency.flag}
                      height="24"
                      width="24"
                    />
                    <span className="ml-2 text-lg">
                      {selectedFromCurrency.code}
                    </span>
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {currencies.map((c) => (
                  <SelectItem
                    key={c.id}
                    value={c.id}
                    disabled={c.id === toCurrency}
                  >
                    <div className="flex items-center">
                      <CircleFlag
                        countryCode={c.flag}
                        height="24"
                        width="24"
                      />
                      <span className="ml-2 text-lg">{c.code}</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-500 mb-2">
            You will receive
          </label>
          <div className="w-full px-4 py-3 border border-green-500 rounded-md flex justify-between items-center h-[52px]">
            <span className="text-2xl font-semibold">
              {loading ? 'Calculating...' : receivedAmount}
            </span>
            <Popover>
              <PopoverTrigger asChild>
                <div className="flex items-center cursor-pointer">
                  <CircleFlag
                    countryCode={selectedToCurrency.flag}
                    height="24"
                    width="24"
                  />
                  <span className="ml-2 text-lg font-medium">
                    {selectedToCurrency.code}
                  </span>
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0" align="end">
                <div className="grid">
                  {currencies.map((c) => (
                    <div
                      key={c.id}
                      className={`flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                        c.id === toCurrency ? 'bg-gray-100' : ''
                      }`}
                      onClick={() => handleToCurrencyChange(c.id)}
                    >
                      <CircleFlag
                        countryCode={c.flag}
                        height="24"
                        width="24"
                      />
                      <span className="ml-2 text-lg">{c.code}</span>
                    </div>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-500 mb-2">
            Pay with
          </label>
          <Select value={paymentMethod} onValueChange={setPaymentMethod}>
            <SelectTrigger className="w-full border border-gray-200 rounded-md h-[52px] px-4 py-3 text-left">
              <SelectValue placeholder="Select payment method">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <CircleFlag
                      countryCode={selectedFromCurrency.flag}
                      height="24"
                      width="24"
                    />
                    <span className="ml-2 text-lg">
                      Your {selectedFromCurrency.code} balance
                    </span>
                  </div>
                  <span className="text-sm text-gray-500">
                    {balances[selectedFromCurrency.code] || 0}{' '}
                    {selectedFromCurrency.code} available
                  </span>
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="balance">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <CircleFlag
                      countryCode={selectedFromCurrency.flag}
                      height="24"
                      width="24"
                    />
                    <span className="ml-2 text-lg">
                      Your {selectedFromCurrency.code} balance
                    </span>
                  </div>
                  <span className="text-sm text-gray-500">
                    {balances[selectedFromCurrency.code] || 0}{' '}
                    {selectedFromCurrency.code} available
                  </span>
                </div>
              </SelectItem>
              {/* Add more payment methods if available */}
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-500 mb-2">
            Fee
          </label>
          <div className="w-full px-4 py-3 bg-gray-100 rounded-md h-[52px] flex items-center">
            <span className="text-lg font-medium">
              {loading
                ? 'Calculating...'
                : `${fee.toFixed(2)} ${selectedFromCurrency.code}`}
            </span>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-500 mb-2">
            Exchange Rate
          </label>
          <div className="w-full px-4 py-3 bg-gray-100 rounded-md h-[52px] flex items-center">
            <span className="text-lg font-medium">
              {loading
                ? 'Calculating...'
                : `1 ${selectedFromCurrency.code} = ${exchangeRate.toFixed(
                    6
                  )} ${selectedToCurrency.code}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );

  const renderPage2 = () => (
    <>
      <div className="flex items-center mb-6">
        <Button onClick={handleBack} variant="ghost" className="p-0 mr-2">
          <ArrowLeft className="h-6 w-6" />
        </Button>
        <h2 className="text-2xl font-semibold">Review your transaction</h2>
      </div>

      <div className="space-y-6">
        <div className="space-y-3 bg-gray-50 p-4 rounded-md">
          <div className="flex justify-between items-center">
            <span className="text-gray-600">You send</span>
            <span className="font-semibold">
              {amount} {selectedFromCurrency.code}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Fee</span>
            <span className="font-semibold">
              -{fee.toFixed(2)} {selectedFromCurrency.code}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Exchange rate</span>
            <span className="font-semibold">
              1 {selectedFromCurrency.code} = {exchangeRate.toFixed(6)}{' '}
              {selectedToCurrency.code}
            </span>
          </div>
          <div className="h-px bg-gray-200 my-2"></div>
          <div className="flex justify-between items-center text-lg font-semibold">
            <span>You will receive</span>
            <span>
              {receivedAmount} {selectedToCurrency.code}
            </span>
          </div>
        </div>

        <Button
          onClick={() => setShowChart(!showChart)}
          variant="outline"
          className="w-full py-2 text-base"
        >
          {showChart ? 'Hide chart' : 'View chart'}
        </Button>

        {showChart && (
          <>
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">Time frame:</span>
              <Select value={chartTimeframe} onValueChange={setChartTimeframe}>
                <SelectTrigger className="w-32">
                  <SelectValue placeholder="Select timeframe" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="7">7 days</SelectItem>
                  <SelectItem value="30">30 days</SelectItem>
                  <SelectItem value="90">90 days</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="h-64 mt-4">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={['auto', 'auto']} />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="rate"
                    stroke="#22c55e"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    </>
  );

  const renderSuccess = () => (
    <>
      <h2 className="text-2xl font-bold mb-6 text-gray-900">
        Exchange successful
      </h2>
      <div className="p-4 bg-green-50 border border-green-200 rounded-md">
        <p className="text-green-700">
          Your exchange of {amount} {selectedFromCurrency.code} to{' '}
          {receivedAmount} {selectedToCurrency.code} has been processed successfully.
        </p>
      </div>
      <p className="text-sm text-gray-600">Transaction ID: {transactionId}</p>
      <p className="text-sm text-gray-600">
        Date: {new Date().toLocaleDateString()}
      </p>
    </>
  );

  const renderStepContent = () => {
    switch (page) {
      case 1:
        return renderPage1();
      case 2:
        return renderPage2();
      case 3:
        return renderSuccess();
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      {error && (
        <div className="text-red-500 mb-4">
          <strong>Error:</strong> {error}
        </div>
      )}
      <div className="flex-grow overflow-y-auto pr-2">
        {renderStepContent()}
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200">
        {page !== 3 && (
          <Button
            onClick={page === 2 ? handleConfirmTransaction : handleContinue}
            className="w-full bg-green-500 hover:bg-green-600 text-white py-3 rounded-md text-lg font-medium"
            disabled={
              (page === 1 && !selectedFromCurrency) || loading || amount === ''
            }
          >
            {loading
              ? 'Processing...'
              : page === 2
              ? 'Confirm Transaction'
              : 'Continue'}
          </Button>
        )}
        {page === 3 && (
          <Button
            onClick={handleReset}
            className="w-full bg-green-500 hover:bg-green-600 text-white py-3 rounded-md text-lg font-medium"
          >
            New Exchange
          </Button>
        )}
      </div>
    </div>
  );
}

export default ConvertCurrencyModalComponent;
