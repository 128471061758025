// TransferCalculatorComponent.tsx

import { useState, useEffect, useRef } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { ChevronDown, RefreshCcw, HelpCircle, Search, X } from "lucide-react"
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"
import { CurrencySelect } from '@/components/ui/CurrencySelect'

// Define the Currency type
type Currency = {
  code: string
  name: string
  unitsPerGBP: number
  flag: string
  popular?: boolean
}

// Button styles using Tailwind CSS classes
const buttonStyles = {
  primary: "bg-[#24EC7E] text-white border-none py-3 px-4 rounded-full inline-flex items-center justify-center gap-2 text-sm font-medium cursor-pointer w-full h-12",
  action: "bg-[#F5F5F5] text-[#111827] border border-gray-200 py-2 px-4 rounded-lg inline-flex items-center gap-2 text-sm font-medium cursor-pointer h-9",
  dropdownItem: "w-full flex items-center gap-4 px-4 py-3 hover:bg-gray-50 rounded-lg"
}

// Currency data with 'unitsPerGBP' and 'popular' flag
const currencies: Currency[] = [
  { code: 'USD', name: 'United States dollar', flag: 'us', unitsPerGBP: 1.2930869377817782, popular: true },
  { code: 'EUR', name: 'Euro', flag: 'eu', unitsPerGBP: 1.2062720245945004, popular: true },
  { code: 'GBP', name: 'British pound', flag: 'gb', unitsPerGBP: 1, popular: true },
  { code: 'JPY', name: 'Japanese yen', flag: 'jp', unitsPerGBP: 150.0, popular: true },
  { code: 'CHF', name: 'Swiss franc', flag: 'ch', unitsPerGBP: 1.08, popular: true },
  { code: 'CAD', name: 'Canadian dollar', flag: 'ca', unitsPerGBP: 1.70, popular: true },
  { code: 'AUD', name: 'Australian dollar', flag: 'au', unitsPerGBP: 1.963448076542104, popular: true },
  { code: 'CNY', name: 'Chinese yuan', flag: 'cn', unitsPerGBP: 8.80, popular: true },
  { code: 'ILS', name: 'Israeli new shekel', flag: 'il', unitsPerGBP: 4.50, popular: true },
  { code: 'INR', name: 'Indian rupee', flag: 'in', unitsPerGBP: 100.0, popular: true },
  { code: 'MXN', name: 'Mexican peso', flag: 'mx', unitsPerGBP: 25.0 },
  { code: 'SGD', name: 'Singapore dollar', flag: 'sg', unitsPerGBP: 1.75 },
  { code: 'HKD', name: 'Hong Kong dollar', flag: 'hk', unitsPerGBP: 10.5 },
  { code: 'NZD', name: 'New Zealand dollar', flag: 'nz', unitsPerGBP: 2.0 },
  { code: 'SEK', name: 'Swedish krona', flag: 'se', unitsPerGBP: 11.5 },
  { code: 'KRW', name: 'South Korean won', flag: 'kr', unitsPerGBP: 1500.0 },
  { code: 'AED', name: 'United Arab Emirates dirham', flag: 'ae', unitsPerGBP: 4.8 },
  { code: 'BRL', name: 'Brazilian real', flag: 'br', unitsPerGBP: 6.5 },
  { code: 'ZAR', name: 'South African rand', flag: 'za', unitsPerGBP: 20.0 },
  { code: 'RUB', name: 'Russian ruble', flag: 'ru', unitsPerGBP: 100.0 }
]

// Dummy historical data for currency pairs
const historicalRates: { [key: string]: { date: string, rate: number }[] } = {
  'GBPUSD': [
    { date: 'Jun', rate: 1.290 },
    { date: 'Jul', rate: 1.295 },
    { date: 'Aug', rate: 1.300 },
    { date: 'Sep', rate: 1.310 },
    { date: 'Oct', rate: 1.305 },
    { date: 'Nov', rate: 1.315 },
    { date: 'Dec', rate: 1.320 }
  ],
  'GBPEUR': [
    { date: 'Jun', rate: 1.200 },
    { date: 'Jul', rate: 1.205 },
    { date: 'Aug', rate: 1.210 },
    { date: 'Sep', rate: 1.215 },
    { date: 'Oct', rate: 1.220 },
    { date: 'Nov', rate: 1.225 },
    { date: 'Dec', rate: 1.230 }
  ],
  'GBPAUD': [
    { date: 'Jun', rate: 1.960 },
    { date: 'Jul', rate: 1.970 },
    { date: 'Aug', rate: 1.980 },
    { date: 'Sep', rate: 1.990 },
    { date: 'Oct', rate: 2.000 },
    { date: 'Nov', rate: 2.010 },
    { date: 'Dec', rate: 2.020 }
  ],
  'USDGBP': [
    { date: 'Jun', rate: 0.773 },
    { date: 'Jul', rate: 0.775 },
    { date: 'Aug', rate: 0.778 },
    { date: 'Sep', rate: 0.780 },
    { date: 'Oct', rate: 0.782 },
    { date: 'Nov', rate: 0.785 },
    { date: 'Dec', rate: 0.787 }
  ],
  'USDEUR': [
    { date: 'Jun', rate: 0.930 },
    { date: 'Jul', rate: 0.935 },
    { date: 'Aug', rate: 0.940 },
    { date: 'Sep', rate: 0.945 },
    { date: 'Oct', rate: 0.950 },
    { date: 'Nov', rate: 0.955 },
    { date: 'Dec', rate: 0.960 }
  ],
  'EURGBP': [
    { date: 'Jun', rate: 0.829 },
    { date: 'Jul', rate: 0.830 },
    { date: 'Aug', rate: 0.831 },
    { date: 'Sep', rate: 0.832 },
    { date: 'Oct', rate: 0.833 },
    { date: 'Nov', rate: 0.834 },
    { date: 'Dec', rate: 0.835 }
  ],
  // Add more pairs as needed
}

// Generate dummy graph data if not available
function generateDummyGraphData() {
  const months = ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months.map(month => ({
    date: month,
    rate: parseFloat((Math.random() * (2 - 0.5) + 0.5).toFixed(3))
  }))
}

export function TransferCalculatorComponent() {
  const [amount, setAmount] = useState('1,000.00')
  const [convertedAmount, setConvertedAmount] = useState('1,293.09') // Initial GBP to USD
  const [period, setPeriod] = useState('Monthly')
  const [sourceCurrency, setSourceCurrency] = useState(currencies.find(c => c.code === 'GBP')!)
  const [targetCurrency, setTargetCurrency] = useState(currencies.find(c => c.code === 'USD')!)
  const [graphData, setGraphData] = useState<{ date: string, rate: number }[]>(historicalRates['GBPUSD'] || generateDummyGraphData())

  // Function to get historical data based on selected currencies
  const getHistoricalData = (source: Currency, target: Currency) => {
    const pairKey = `${source.code}${target.code}`
    return historicalRates[pairKey] || generateDummyGraphData()
  }

  // Handle conversion
  const handleConversion = (amt: string, source: Currency, target: Currency): string => {
    const numericAmt = parseFloat(amt.replace(/,/g, ''))
    if (isNaN(numericAmt)) return '0.00'
    const exchangeRate = target.unitsPerGBP / source.unitsPerGBP
    const converted = numericAmt * exchangeRate
    return converted.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  // Update graph data when currencies change
  useEffect(() => {
    setGraphData(getHistoricalData(sourceCurrency, targetCurrency))
  }, [sourceCurrency, targetCurrency])

  // Update converted amount when amount or currencies change
  useEffect(() => {
    setConvertedAmount(handleConversion(amount, sourceCurrency, targetCurrency))
  }, [amount, sourceCurrency, targetCurrency])

  // Toggle period
  const togglePeriod = () => {
    setPeriod(prev => prev === 'Monthly' ? 'Weekly' : 'Monthly')
    // Optionally, update graphData based on period
  }

  // Swap currencies
  const swapCurrencies = () => {
    setSourceCurrency(targetCurrency)
    setTargetCurrency(sourceCurrency)
  }

  // Reset to default values
  const resetValues = () => {
    setAmount('1,000.00')
    setConvertedAmount(handleConversion('1,000.00', sourceCurrency, targetCurrency))
  }

  return (
    <div className="bg-[#F5F5F5] rounded-2xl p-6">
      <h2 className="text-xl font-semibold text-[#111827] mb-6">
        Transfer Calculator
      </h2>

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left section with graph */}
        <div className="flex-1 bg-white rounded-lg p-6 shadow-md">
          <div className="flex justify-between items-start mb-6">
            <div>
              <div className="text-sm text-gray-500 mb-1">
                CURRENT RATE
              </div>
              <div className="text-2xl font-medium text-[#111827]">
                1 {sourceCurrency.code} = {(targetCurrency.unitsPerGBP / sourceCurrency.unitsPerGBP).toFixed(3)} {targetCurrency.code}
              </div>
            </div>
            <button 
              className={`flex items-center gap-1 py-2 px-3 rounded-lg border border-gray-200 bg-white text-[#111827] text-sm font-medium cursor-pointer ${buttonStyles.action}`}
              onClick={togglePeriod}
            >
              {period}
              <ChevronDown size={16} />
            </button>
          </div>

          <div className="h-52">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={graphData} margin={{ top: 5, right: 20, bottom: 20, left: 0 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis 
                  dataKey="date" 
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fill: '#6B7280' }}
                />
                <YAxis 
                  domain={['auto', 'auto']}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fill: '#6B7280' }}
                  tickFormatter={(value) => typeof value === 'number' ? value.toFixed(3) : ''}
                />
                <Tooltip 
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="bg-white p-2 rounded shadow">
                          <p className="text-sm">{`${label}: ${payload[0].value?.toFixed(3)}`}</p>
                        </div>
                      )
                    }
                    return null
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="rate" 
                  stroke="#24EC7E" 
                  strokeWidth={2}
                  dot={{ fill: '#24EC7E', r: 4 }}
                  activeDot={{ r: 6, fill: '#24EC7E' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Right section with currency converter */}
        <div className="flex-1 flex flex-col gap-6">
          {/* Currency Converter */}
          <div className="bg-white rounded-lg p-6 shadow-md flex flex-col gap-6">
            {/* Source Currency */}
            <div className="flex items-center gap-0 border border-gray-200 rounded-xl bg-white relative">
              <input
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="flex-1 px-4 py-3 text-xl font-medium focus:outline-none border-none"
                placeholder="Amount"
              />
              <CurrencySelect selectedCurrency={sourceCurrency} onSelect={setSourceCurrency} />
            </div>

            {/* Swap Button */}
            <div className="flex justify-center">
              <button
                onClick={swapCurrencies}
                className="bg-white border border-gray-200 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
              >
                <RefreshCcw size={16} color="#6B7280" />
              </button>
            </div>

            {/* Target Currency */}
            <div className="flex items-center gap-0 border border-gray-200 rounded-xl bg-white relative">
              <input
                type="text"
                value={convertedAmount}
                readOnly
                className="flex-1 px-4 py-3 text-xl font-medium bg-gray-50 focus:outline-none border-none"
                placeholder="Converted Amount"
              />
              <CurrencySelect selectedCurrency={targetCurrency} onSelect={setTargetCurrency} />
            </div>


            {/* Includes Fees */}
            <div className="bg-white rounded-lg p-4 shadow-sm flex items-center justify-between">
              <div className="text-sm text-gray-500">
                Includes fees
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm font-medium text-[#111827]">0 {targetCurrency.code}</span>
                <HelpCircle size={16} color="#6B7280" />
              </div>
            </div>

            {/* Send Button */}
            <button className={buttonStyles.primary}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
