// src/components/TopUpModal.tsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CreditCard, Building2, Wallet as WalletIcon } from 'lucide-react';
import { CircleFlag } from 'react-circle-flags';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Separator } from "./ui/separator";
import { Checkbox } from "./ui/checkbox";
import { useModal } from '../../../contexts/ModalContext';
import { useAuth } from '../../../contexts/AuthContext';
import { Wallet } from '../../../types/types'; // Adjust the import path as necessary
import api from '../../../services/api'; // Adjust the import path as necessary
import { TopUpPayload } from '../../../types/api'; // Adjust the import path as necessary

// Utility mapping from currency to country code
const currencyToCountryCode: { [key: string]: string } = {
  USD: 'us',
  GBP: 'gb',
  EUR: 'eu', // You can choose a specific country like 'de' or 'fr' if preferred
  // Add more currencies and their corresponding country codes as needed
};

// Add these type definitions at the top
interface Wallet {
  id: string;
  currency: string;
  balance: number;
  net: number;
}

// Update the extractNumericValue function to handle all cases safely
const extractNumericValue = (value: string | number | null | undefined): number => {
  // Return 0 for null/undefined values
  if (value === null || value === undefined) {
    return 0;
  }

  // If it's already a number, return it
  if (typeof value === 'number') {
    return value;
  }

  // Convert string to number, removing non-numeric characters except decimal point
  const numericString = value.toString().replace(/[^0-9.]/g, '');
  const parsedValue = parseFloat(numericString);

  // Return 0 if parsing failed
  return isNaN(parsedValue) ? 0 : parsedValue;
};

// Luhn Algorithm for Card Number Validation
const validateCardNumber = (cardNumber: string): boolean => {
  const sanitized = cardNumber.replace(/\D/g, '');
  let sum = 0;
  let shouldDouble = false;

  for (let i = sanitized.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitized.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

// Visa and Mastercard SVG Icons
const VisaIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 1000 324.68">
    {/* Replace with actual Visa SVG path data */}
    <path d="M..." fill="#1A1F71" />
  </svg>
);

const MastercardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 1000 618">
    {/* Replace with actual Mastercard SVG path data */}
    <path d="M..." fill="#EB001B" />
    <path d="M..." fill="#F79E1B" />
  </svg>
);

export function TopUpModal() {
  const { updateSteps, closeModal } = useModal(); // Assuming you have a closeModal function
  const { wallets: userWallets } = useAuth();
  const [step, setStep] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [paymentMethod, setPaymentMethod] = useState<'card' | 'bank' | 'wallet'>('card');
  const [cardNumber, setCardNumber] = useState('');
  const [formattedCardNumber, setFormattedCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [cardType, setCardType] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    updateSteps([
      { label: 'Select Account', status: step === 0 ? 'current' : step > 0 ? 'completed' : 'upcoming' },
      { label: 'Amount', status: step === 1 ? 'current' : step > 1 ? 'completed' : 'upcoming' },
      { label: 'Payment Method', status: step === 2 ? 'current' : step > 2 ? 'completed' : 'upcoming' },
      { label: 'Confirm', status: step === 3 ? 'current' : 'upcoming' },
    ]);
  }, [step, updateSteps]);

  const handleNextStep = () => {
    if (validateStep()) {
      setStep(prevStep => Math.min(prevStep + 1, 3));
    }
  };

  const handlePrevStep = () => {
    setStep(prevStep => Math.max(prevStep - 1, 0));
  };

  const detectCardType = (number: string) => {
    const visa = /^4/;
    const mastercard = /^5[1-5]/;
    if (visa.test(number)) return 'visa';
    if (mastercard.test(number)) return 'mastercard';
    return null;
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    // Remove all non-digit characters
    const sanitized = input.replace(/\D/g, '');
    setCardNumber(sanitized);

    // Format with spaces every 4 digits
    const formatted = sanitized.replace(/(.{4})/g, '$1 ').trim();
    setFormattedCardNumber(formatted);

    // Detect card type
    setCardType(detectCardType(sanitized));
  };

  const handleExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // Remove all non-digit characters
    input = input.replace(/\D/g, '');

    // Add slash after 2 digits
    if (input.length > 2) {
      input = input.slice(0, 2) + '/' + input.slice(2, 4);
    }
    setExpiryDate(input);
  };

  const handleCvcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // Remove all non-digit characters
    input = input.replace(/\D/g, '');
    setCvc(input);
  };

  const validateStep = (): boolean => {
    const errors: { [key: string]: string } = {};

    if (step === 0 && !selectedAccount) {
      errors.selectedAccount = 'Please select an account to top up.';
    }

    if (step === 1) {
      const amt = parseFloat(amount);
      if (!amount) {
        errors.amount = 'Please enter an amount.';
      } else if (isNaN(amt) || amt < 10) {
        errors.amount = 'Minimum top-up amount is 10.';
      }

      if (!currency) {
        errors.currency = 'Please select a currency.';
      }
    }

    if (step === 2 && paymentMethod === 'card') {
      if (!cardNumber) {
        errors.cardNumber = 'Please enter your card number.';
      } else if (cardNumber.length < 13 || cardNumber.length > 19) {
        errors.cardNumber = 'Card number must be between 13 and 19 digits.';
      } else if (!validateCardNumber(cardNumber)) {
        errors.cardNumber = 'Invalid card number.';
      }

      if (!expiryDate) {
        errors.expiryDate = 'Please enter the expiry date.';
      } else {
        const [month, year] = expiryDate.split('/');
        if (!month || !year) {
          errors.expiryDate = 'Expiry date must be in MM/YY format.';
        } else {
          const expMonth = parseInt(month, 10);
          const expYear = parseInt(`20${year}`, 10);
          const now = new Date();
          const currentMonth = now.getMonth() + 1;
          const currentYear = now.getFullYear();

          if (isNaN(expMonth) || expMonth < 1 || expMonth > 12) {
            errors.expiryDate = 'Invalid expiry month.';
          }

          if (isNaN(expYear) || expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
            errors.expiryDate = 'Card has expired.';
          }
        }
      }

      if (!cvc) {
        errors.cvc = 'Please enter the CVC.';
      } else if (cvc.length < 3 || cvc.length > 4) {
        errors.cvc = 'CVC must be 3 or 4 digits.';
      }

      if (!cardholderName) {
        errors.cardholderName = 'Please enter the name on the card.';
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleConfirmTopUp = async () => {
    if (!validateStep()) {
      return;
    }

    // Parse expiryDate (assumed format "MM/YY")
    const [exp_month_str, exp_year_short] = expiryDate.split('/').map(part => part.trim());
    const exp_month = parseInt(exp_month_str, 10);
    const exp_year = parseInt(`20${exp_year_short}`, 10); // Convert "25" to 2025

    // Construct the payload with number and cvc as strings
    const payload: TopUpPayload = {
      amount: parseFloat(amount),
      currency: currency.toLowerCase(),
      source: {
        card: {
          number: cardNumber, // Already sanitized and without spaces
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: cvc,
        },
      },
    };

    try {
      setLoading(true);
      setError(null);
      const response = await api.topUp(payload);
      console.log('Top-up successful:', response);
      // Handle success (e.g., show a success message, close modal, refresh data)
      closeModal();
      // Optionally, refresh wallets or perform other actions
    } catch (err: any) {
      console.error('Top-up failed:', err);
      if (err.response && err.response.data) {
        const apiErrors = err.response.data.errors;
        const apiErrorMessage = err.response.data.message;
        if (apiErrors) {
          // Flatten the errors
          const flattenedErrors = Object.keys(apiErrors).reduce((acc: { [key: string]: string }, key) => {
            acc[key] = apiErrors[key].join(' ');
            return acc;
          }, {});
          setFormErrors(flattenedErrors);
        } else if (apiErrorMessage) {
          setError(apiErrorMessage);
        } else {
          setError('Top-up failed. Please try again.');
        }
      } else {
        setError('Top-up failed. Please check your network connection and try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <motion.div
            key="step0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Select Account to Top Up</h2>
            <div className={`space-y-2 ${userWallets.length > 4 ? 'max-h-[300px] overflow-y-auto pr-2' : ''}`}>
              {userWallets.map((wallet) => {
                const countryCode = currencyToCountryCode[wallet.currency] || 'us';
                // Handle balance whether it's a Balance object or a number
                const walletBalance = typeof wallet.balance === 'object' 
                  ? wallet.balance.amount 
                  : typeof wallet.balance === 'number'
                    ? wallet.balance
                    : 0;

                return (
                  <div
                    key={wallet.id}
                    className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-colors ${
                      selectedAccount === wallet.id ? 'bg-green-100 border border-green-500' : 'hover:bg-gray-100'
                    }`}
                    onClick={() => setSelectedAccount(wallet.id)}
                  >
                    <CircleFlag
                      countryCode={countryCode}
                      height="48"
                      width="48"
                    />
                    <div className="flex-grow">
                      <p className="font-medium text-lg">{wallet.currency.toUpperCase()} Account</p>
                      <p className="text-sm text-gray-500">
                        Balance: {walletBalance.toFixed(2)} {wallet.currency}
                      </p>
                    </div>
                    {selectedAccount === wallet.id && (
                      <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                    )}
                  </div>
                );
              })}
            </div>
            {formErrors.selectedAccount && (
              <p className="text-red-500 text-sm">{formErrors.selectedAccount}</p>
            )}
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            key="step1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Enter Top-Up Details</h2>
            <div className="space-y-2">
              <Label htmlFor="amount">Amount</Label>
              <Input
                id="amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="0.00"
                className="text-2xl font-bold"
                min="10"
              />
              {formErrors.amount && (
                <p className="text-red-500 text-sm">{formErrors.amount}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="currency">Currency</Label>
              <Select value={currency} onValueChange={setCurrency}>
                <SelectTrigger id="currency" className="h-12">
                  <SelectValue placeholder="Select currency">
                    {currency && (
                      <div className="flex items-center">
                        <CircleFlag
                          countryCode={currencyToCountryCode[currency] || 'us'}
                          height="32"
                          width="32"
                        />
                        <span className="ml-2 text-lg uppercase">{currency}</span>
                      </div>
                    )}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {['USD', 'GBP', 'EUR'].map((c) => (
                    <SelectItem key={c} value={c}>
                      <div className="flex items-center">
                        <CircleFlag
                          countryCode={currencyToCountryCode[c] || 'us'}
                          height="32"
                          width="32"
                        />
                        <span className="ml-2 text-lg uppercase">{c}</span>
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {formErrors.currency && (
                <p className="text-red-500 text-sm">{formErrors.currency}</p>
              )}
            </div>
            <div className="p-3 bg-green-50 border border-green-200 rounded-md">
              <p className="text-green-700">Minimum top-up amount: 10 {currency.toUpperCase()}</p>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Select Payment Method</h2>
            <div className="space-y-2">
              <Button
                variant="outline"
                className={`w-full justify-start h-auto py-3 ${paymentMethod === 'card' ? 'border-green-500' : ''}`}
                onClick={() => setPaymentMethod('card')}
              >
                <CreditCard className="mr-2 h-5 w-5" />
                <span>Credit/Debit Card</span>
              </Button>
              <Button
                variant="outline"
                className={`w-full justify-start h-auto py-3 ${paymentMethod === 'bank' ? 'border-green-500' : ''}`}
                onClick={() => setPaymentMethod('bank')}
              >
                <Building2 className="mr-2 h-5 w-5" />
                <span>Bank Transfer</span>
              </Button>
              <Button
                variant="outline"
                className={`w-full justify-start h-auto py-3 ${paymentMethod === 'wallet' ? 'border-green-500' : ''}`}
                onClick={() => setPaymentMethod('wallet')}
              >
                <WalletIcon className="mr-2 h-5 w-5" />
                <span>E-Wallet</span>
              </Button>
            </div>
            {paymentMethod === 'card' && (
              <div className="space-y-4 mt-4">
                <div className="space-y-2">
                  <Label htmlFor="cardNumber">Card Number</Label>
                  <div className="relative">
                    <Input
                      id="cardNumber"
                      type="text"
                      value={formattedCardNumber}
                      onChange={handleCardNumberChange}
                      placeholder="1234 5678 9012 3456"
                      maxLength={19} // 16 digits + 3 spaces
                    />
                    {cardType && (
                      <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                        {cardType === 'visa' ? <VisaIcon /> : <MastercardIcon />}
                      </div>
                    )}
                  </div>
                  {formErrors.cardNumber && (
                    <p className="text-red-500 text-sm">{formErrors.cardNumber}</p>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="expiryDate">Expiry Date</Label>
                    <Input
                      id="expiryDate"
                      type="text"
                      value={expiryDate}
                      onChange={handleExpiryDateChange}
                      placeholder="MM/YY"
                      maxLength={5} // e.g., "12/25"
                    />
                    {formErrors.expiryDate && (
                      <p className="text-red-500 text-sm">{formErrors.expiryDate}</p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="cvc">CVC</Label>
                    <Input
                      id="cvc"
                      type="text"
                      value={cvc}
                      onChange={handleCvcChange}
                      placeholder="123"
                      maxLength={4} // Some cards have 4 digits CVC
                    />
                    {formErrors.cvc && (
                      <p className="text-red-500 text-sm">{formErrors.cvc}</p>
                    )}
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="cardholderName">Name on Card</Label>
                  <Input
                    id="cardholderName"
                    type="text"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                    placeholder="John Doe"
                  />
                  {formErrors.cardholderName && (
                    <p className="text-red-500 text-sm">{formErrors.cardholderName}</p>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox id="saveCard" checked={saveCard} onCheckedChange={(checked) => setSaveCard(checked as boolean)} />
                  <Label htmlFor="saveCard">Save card for future payments</Label>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-2">
                    <VisaIcon />
                    <MastercardIcon />
                  </div>
                </div>
              </div>
            )}
            {formErrors.paymentMethod && (
              <p className="text-red-500 text-sm">{formErrors.paymentMethod}</p>
            )}
          </motion.div>
        );
      case 3:
        const selectedAccountData = userWallets.find(a => a.id === selectedAccount);
        const selectedBalance = selectedAccountData?.balance;
        const displayBalance = typeof selectedBalance === 'object' 
          ? selectedBalance.amount 
          : typeof selectedBalance === 'number'
            ? selectedBalance
            : 0;

        return (
          <motion.div
            key="step3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Card>
              <CardHeader>
                <CardTitle className="text-2xl font-bold text-center">Confirm Your Top-Up</CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">Account to Top Up</h3>
                  <div className="flex items-center space-x-4">
                    <CircleFlag
                      countryCode={currencyToCountryCode[selectedAccountData?.currency] || 'us'}
                      height="48"
                      width="48"
                    />
                    <div>
                      <p className="text-lg font-semibold">{selectedAccountData?.currency.toUpperCase()} Account</p>
                      <p className="text-sm text-gray-600">
                        Balance: {displayBalance.toFixed(2)} {selectedAccountData?.currency}
                      </p>
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between items-center">
                  <h3 className="text-sm font-medium text-gray-500">Amount to Top Up</h3>
                  <p className="text-lg font-semibold">{amount} {currency.toUpperCase()}</p>
                </div>
                <Separator />
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">Payment Method</h3>
                  <p className="text-lg font-semibold">
                    {paymentMethod === 'card' && 'Credit/Debit Card'}
                    {paymentMethod === 'bank' && 'Bank Transfer'}
                    {paymentMethod === 'wallet' && 'E-Wallet'}
                  </p>
                  {paymentMethod === 'card' && (
                    <p className="text-sm text-gray-600">Card ending in {cardNumber.slice(-4)}</p>
                  )}
                </div>
              </CardContent>
            </Card>
            {error && (
              <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
                <p className="text-red-700">{error}</p>
              </div>
            )}
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full flex flex-col bg-white rounded-xl overflow-hidden shadow-md">
      <div className="flex-grow overflow-y-auto p-6">
        <AnimatePresence mode="wait">
          {renderStep()}
        </AnimatePresence>
        {/* Display general errors */}
        {error && step !== 3 && (
          <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
            <p className="text-red-700">{error}</p>
          </div>
        )}
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between p-6">
        {step > 0 && (
          <Button onClick={handlePrevStep} variant="outline" className="h-11" disabled={loading}>
            Previous
          </Button>
        )}
        {step < 3 && (
          <Button
            onClick={handleNextStep}
            className="h-11 bg-green-500 hover:bg-green-600 text-white ml-auto"
            disabled={
              loading ||
              (step === 0 && !selectedAccount) ||
              (step === 1 && (!amount || parseFloat(amount) < 10)) ||
              (step === 2 && !paymentMethod) ||
              (step === 2 && paymentMethod === 'card' && (!cardNumber || !expiryDate || !cvc || !cardholderName)) ||
              Object.keys(formErrors).length > 0
            }
          >
            {step === 2 ? 'Review Top Up' : 'Continue'}
          </Button>
        )}
        {step === 3 && (
          <Button
            onClick={handleConfirmTopUp}
            className="h-11 bg-green-500 hover:bg-green-600 text-white ml-auto"
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Confirm Top Up'}
          </Button>
        )}
      </div>
    </div>
  );
}

export default TopUpModal;
