import { ChevronRight, CreditCard, Plus } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Link, useNavigate } from "react-router-dom"
import PhysicalCard from './images/paayco.png';
export function CardsListComponent() {
  const navigate = useNavigate();
  return (
    <div className="p-6 bg-white min-h-screen container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <h1 className="text-2xl font-bold mb-4">Cards</h1>

      <Button 
        variant="default"
        className="mb-6 bg-[#24EC7E] hover:bg-[#20d471] text-white font-medium rounded-full"
      >
        <Plus className="w-4 h-4 mr-2" />
        
        <Link to="/card-order-flow">
          Request new card
     
        </Link>
      </Button>
      
      <div className="flex mb-6 bg-gray-100 rounded-full p-1">
        <button 
          className="flex-1 py-3 px-6 text-sm font-medium rounded-full bg-white text-black shadow"
        >
          Primary Cards
        </button>
        <button 
          className="flex-1 py-3 px-6 text-sm font-medium rounded-full text-gray-600"
        >
          Shared cards
        </button>
      </div>
      
      <div className="space-y-px">
        <Link
          to="/cards-management"
          className="flex items-center justify-between p-4 hover:bg-gray-50 border-b border-gray-100"
        >
          <div className="flex items-center gap-4">
          <img src={PhysicalCard} alt="Physical Card" className=" h-10" />
            <div>
              <span className="font-medium">Digital card</span>
              <p className="text-sm text-gray-500">****3167</p>
            </div>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </Link>

        <Link
          to="/cards-management"
          className="flex items-center justify-between p-4 hover:bg-gray-50 border-b border-gray-100"
        >
          <div className="flex items-center gap-4">
          <img src={PhysicalCard} alt="Physical Card" className=" h-10" />

            <div>
              <span className="font-medium">Physical card</span>
              <p className="text-sm text-gray-500">****4747</p>
            </div>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </Link>
      </div>
    </div>
  )
}