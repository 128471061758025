import React, { useState, useEffect } from 'react'
import { ArrowRight } from 'lucide-react'

export default function ModernBankingLoadingScreen() {
  const [progress, setProgress] = useState(0)
  const [tip, setTip] = useState(0)

  const tips = [
    "Manage your finances with ease",
    "Track expenses in real-time",
    "Set and achieve financial goals",
    "Secure and fast transactions",
    "24/7 customer support",
    "Customize your dashboard for quick overviews",
    "Earn rewards on your everyday spending"
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1
        return newProgress > 100 ? 100 : newProgress
      })
    }, 50)

    return () => clearInterval(interval)
  }, [])

  const nextTip = () => {
    setTip((prevTip) => (prevTip + 1) % tips.length)
  }

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white z-50">
      <div className="relative w-64 h-64">
        <svg className="w-full h-full" viewBox="0 0 100 100">
          <circle
            className="text-[#AFF8C8] stroke-current"
            strokeWidth="4"
            cx="50"
            cy="50"
            r="48"
            fill="transparent"
          />
          <circle
            className="text-[#24EC7E] progress-ring__circle stroke-current"
            strokeWidth="4"
            strokeLinecap="round"
            cx="50"
            cy="50"
            r="48"
            fill="transparent"
            strokeDasharray="302"
            strokeDashoffset={302 - (progress / 100) * 302}
            style={{ transition: "stroke-dashoffset 0.35s" }}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-4xl font-bold text-[#097152]">{progress}%</div>
        </div>
        <div className="absolute inset-0">
          <div className="w-full h-full animate-spin-slow">
            <div className="w-2 h-2 bg-[#24EC7E] rounded-full absolute top-0 left-1/2 transform -translate-x-1/2"></div>
            <div className="w-2 h-2 bg-[#24EC7E] rounded-full absolute top-1/2 right-0 transform translate-y-1/2"></div>
            <div className="w-2 h-2 bg-[#24EC7E] rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2"></div>
            <div className="w-2 h-2 bg-[#24EC7E] rounded-full absolute top-1/2 left-0 transform -translate-y-1/2"></div>
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p className="text-2xl font-semibold text-[#00423C] mb-4">
          {progress === 100 ? "Ready to manage your finances!" : "Loading your financial dashboard..."}
        </p>
        <div className="flex items-center justify-center space-x-2">
          {[0, 1, 2].map((i) => (
            <div
              key={i}
              className="w-3 h-3 rounded-full bg-[#097152] animate-pulse"
              style={{ animationDelay: `${i * 0.2}s` }}
            />
          ))}
        </div>
      </div>
      <div className="mt-8 max-w-md text-center">
        <p className="text-lg text-[#00423C] mb-4 h-16 flex items-center justify-center">
          {tips[tip]}
        </p>
        <button
          onClick={nextTip}
          className="flex items-center justify-center px-6 py-3 bg-[#097152] text-white rounded-md hover:bg-[#24EC7E] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:ring-opacity-50"
        >
          Next Tip <ArrowRight className="ml-2 w-5 h-5" />
        </button>
      </div>
    </div>
  )
} 