import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Transactions } from '../components/transactions/components/transactions';

const TransactionsPage: React.FC = () => {
  return (
    <div>
      <Transactions />
    </div>
  );
};

export default TransactionsPage;
