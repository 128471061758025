// src/components/RequestMoneyModalComponent.tsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { useAuth } from '../../../contexts/AuthContext';
import { useModal } from '../../../contexts/ModalContext';
import { CircleFlag } from 'react-circle-flags';
import { Plus, Search, Heart } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "./ui/context-menu";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Separator } from "./ui/separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Wallet } from '../../../types/types'; // Adjust the import path as necessary

type Beneficiary = {
  id: string;
  name: string;
  email: string;
  country: string;
  tag: string;
  avatar?: string;
  isFavorite: boolean;
};

// Add this utility function at the top of the file
const extractNumericValue = (value: string | number | null | undefined): number => {
  if (value === null || value === undefined) return 0;
  if (typeof value === 'number') return value;
  const numericString = value.toString().replace(/[^0-9.]/g, '');
  return parseFloat(numericString) || 0;
};

export function RequestMoneyModalComponent() {
  const { updateSteps } = useModal();
  const { wallets: userWallets } = useAuth();
  const [step, setStep] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<string | null>(null);
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([
    { id: '1', name: 'John Doe', email: 'john@example.com', country: 'United Kingdom', tag: 'Friend', avatar: '/placeholder.svg', isFavorite: false },
    { id: '2', name: 'Jane Smith', email: 'jane@example.com', country: 'United States', tag: 'Family', avatar: '/placeholder.svg', isFavorite: false },
  ]);
  const [newBeneficiary, setNewBeneficiary] = useState<Omit<Beneficiary, 'id' | 'isFavorite'>>({ name: '', email: '', country: '', tag: '' });
  const [isAddingBeneficiary, setIsAddingBeneficiary] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [amount, setAmount] = useState('');

  // Utility mapping from currency to country code
  const currencyToCountryCode: { [key: string]: string } = {
    USD: 'us',
    GBP: 'gb',
    EUR: 'eu', // You can choose a specific country like 'de' or 'fr' if preferred
    // Add more currencies and their corresponding country codes as needed
  };

  const filteredBeneficiaries = beneficiaries.filter(beneficiary =>
    beneficiary.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    beneficiary.tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    updateSteps([
      { label: 'Account', status: step === 1 ? 'current' : step > 1 ? 'completed' : 'upcoming' },
      { label: 'Recipient', status: step === 2 ? 'current' : step > 2 ? 'completed' : 'upcoming' },
      { label: 'Amount', status: step === 3 ? 'current' : step > 3 ? 'completed' : 'upcoming' },
      { label: 'Review', status: step === 4 ? 'current' : 'upcoming' },
    ]);
  }, [step, updateSteps]);

  const handleNextStep = () => {
    setStep(prev => Math.min(prev + 1, 4));
  };

  const handlePrevStep = () => {
    setStep(prev => Math.max(prev - 1, 1));
  };

  const handleAddBeneficiary = () => {
    if (newBeneficiary.name && newBeneficiary.email && newBeneficiary.country) {
      const newId = (beneficiaries.length + 1).toString();
      setBeneficiaries([...beneficiaries, { ...newBeneficiary, id: newId, avatar: '/placeholder.svg', isFavorite: false }]);
      setSelectedBeneficiary(newId);
      setNewBeneficiary({ name: '', email: '', country: '', tag: '' });
      setIsAddingBeneficiary(false);
    }
  };

  const toggleFavorite = (id: string) => {
    setBeneficiaries(beneficiaries.map(beneficiary =>
      beneficiary.id === id ? { ...beneficiary, isFavorite: !beneficiary.isFavorite } : beneficiary
    ));
  };

  // Update the beneficiaries mapping
  const mappedBeneficiaries = beneficiaries.map((beneficiary) => ({
    id: beneficiary.id,
    name: `${beneficiary.beneficiary_first_name} ${beneficiary.beneficiary_last_name}`, // Updated property names
    email: '', // You might want to add this field to your beneficiary data
    country: beneficiary.beneficiary_country, // Updated property name
    tag: beneficiary.beneficiary_details_type, // Updated property name
    avatar: '/placeholder.svg',
    isFavorite: false,
  }));

  // Use mappedBeneficiaries instead of the static data
  const [localBeneficiaries, setLocalBeneficiaries] = useState<Beneficiary[]>(mappedBeneficiaries);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            key="step1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Select account to request from</h2>
            <div className={`space-y-2 ${userWallets.length > 4 ? 'max-h-[300px] overflow-y-auto pr-2' : ''}`}>
              {userWallets.map((wallet) => {
                const countryCode = currencyToCountryCode[wallet.currency] || wallet.countryCode?.toLowerCase() || 'us';
                return (
                  <div
                    key={wallet.id}
                    className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-colors ${
                      selectedAccount === wallet.id ? 'bg-green-100 border border-green-500' : 'hover:bg-gray-100'
                    }`}
                    onClick={() => setSelectedAccount(wallet.id)}
                  >
                    <CircleFlag
                      countryCode={countryCode}
                      height="48"
                      width="48"
                    />
                    <div className="flex-grow">
                      <p className="font-medium text-lg">{wallet.currency.toUpperCase()} Account</p>
                      <p className="text-sm text-gray-500">
                        Balance: {typeof wallet.balance === 'object' ? wallet.balance.amount.toFixed(2) : wallet.balance.toFixed(2)} {wallet.currency}
                      </p>
                    </div>
                    {selectedAccount === wallet.id && (
                      <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Select beneficiary</h2>
            {!isAddingBeneficiary ? (
              <>
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <Input
                    type="text"
                    placeholder="Search beneficiaries..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 w-full"
                  />
                </div>
                <div className={`space-y-2 ${filteredBeneficiaries.length > 4 ? 'max-h-[300px] overflow-y-auto pr-2' : ''}`}>
                  {filteredBeneficiaries.map((beneficiary) => (
                    <ContextMenu key={beneficiary.id}>
                      <ContextMenuTrigger>
                        <div
                          className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-colors ${
                            selectedBeneficiary === beneficiary.id ? 'bg-green-100 border border-green-500' : 'hover:bg-gray-100'
                          }`}
                          onClick={() => setSelectedBeneficiary(beneficiary.id)}
                        >
                          <Avatar>
                            <AvatarImage src={beneficiary.avatar} alt={beneficiary.name} />
                            <AvatarFallback>{beneficiary.name.charAt(0)}</AvatarFallback>
                          </Avatar>
                          <div className="flex-grow">
                            <p className="font-medium text-lg">{beneficiary.name}</p>
                            <p className="text-sm text-gray-500">{beneficiary.tag}</p>
                          </div>
                          {beneficiary.isFavorite && (
                            <Heart className="text-red-500" size={16} />
                          )}
                          {selectedBeneficiary === beneficiary.id && (
                            <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                          )}
                        </div>
                      </ContextMenuTrigger>
                      <ContextMenuContent>
                        <ContextMenuItem onClick={() => toggleFavorite(beneficiary.id)}>
                          {beneficiary.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                        </ContextMenuItem>
                      </ContextMenuContent>
                    </ContextMenu>
                  ))}
                </div>
                <Button
                  className="w-full h-10 flex items-center justify-center space-x-2 bg-green-500 hover:bg-green-600 text-white"
                  onClick={() => setIsAddingBeneficiary(true)}
                >
                  <Plus size={16} />
                  <span>Add new beneficiary</span>
                </Button>
              </>
            ) : (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Add new beneficiary</h3>
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={newBeneficiary.name}
                    onChange={(e) => setNewBeneficiary({ ...newBeneficiary, name: e.target.value })}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={newBeneficiary.email}
                    onChange={(e) => setNewBeneficiary({ ...newBeneficiary, email: e.target.value })}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="country">Country</Label>
                  <Input
                    id="country"
                    value={newBeneficiary.country}
                    onChange={(e) => setNewBeneficiary({ ...newBeneficiary, country: e.target.value })}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="tag">Tag</Label>
                  <Input
                    id="tag"
                    value={newBeneficiary.tag}
                    onChange={(e) => setNewBeneficiary({ ...newBeneficiary, tag: e.target.value })}
                    placeholder="e.g. Friend, Family"
                  />
                </div>
                <div className="flex space-x-2">
                  <Button onClick={handleAddBeneficiary} className="flex-1 bg-green-500 hover:bg-green-600 text-white">Add Beneficiary</Button>
                  <Button onClick={() => setIsAddingBeneficiary(false)} variant="outline" className="flex-1">Cancel</Button>
                </div>
              </div>
            )}
          </motion.div>
        );
      case 3:
        // Define selectedAccountData here
        const selectedAccountData = userWallets.find(a => a.id === selectedAccount);

        return (
          <motion.div
            key="step3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Enter amount</h2>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="amount">Amount</Label>
                <Input
                  id="amount"
                  placeholder="0.00"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="text-2xl font-bold"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="account">Select Account</Label>
                <Select value={selectedAccount} onValueChange={setSelectedAccount}>
                  <SelectTrigger id="account" className="w-full h-12">
                    <SelectValue placeholder="Select account">
                      {selectedAccount && selectedAccountData && (
                        <div className="flex items-center space-x-2">
                          <CircleFlag
                            countryCode={
                              currencyToCountryCode[selectedAccountData.currency] ||
                              selectedAccountData.currency.toLowerCase() ||
                              'us'
                            }
                            height="20"
                            width="20"
                          />
                          <span>{selectedAccountData.currency.toUpperCase()} Account - {extractNumericValue(selectedAccountData.net).toFixed(2)}</span>
                        </div>
                      )}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {userWallets.map((wallet) => {
                      const countryCode = currencyToCountryCode[wallet.currency] || wallet.currency.toLowerCase() || 'us';
                      return (
                        <SelectItem key={wallet.id} value={wallet.id}>
                          <div className="flex items-center space-x-2">
                            <CircleFlag countryCode={countryCode} height="20" width="20" />
                            <span>{wallet.currency.toUpperCase()} Account - {extractNumericValue(wallet.net).toFixed(2)}</span>
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </motion.div>
        );
      case 4:
        const selectedBeneficiaryData = beneficiaries.find(b => b.id === selectedBeneficiary);
        const selectedAccountDataReview = userWallets.find(a => a.id === selectedAccount);
        return (
          <motion.div
            key="step4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Card>
              <CardHeader>
                <CardTitle className="text-xl font-bold text-center">Confirm request</CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">Beneficiary</h3>
                  <div className="flex items-center space-x-4">
                    <Avatar>
                      <AvatarImage src={selectedBeneficiaryData?.avatar} alt={selectedBeneficiaryData?.name} />
                      <AvatarFallback>{selectedBeneficiaryData?.name.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <div>
                      <p className="text-base font-semibold">{selectedBeneficiaryData?.name}</p>
                      <p className="text-xs text-gray-600">{selectedBeneficiaryData?.email}</p>
                      <p className="text-xs text-gray-600">{selectedBeneficiaryData?.country}</p>
                      <p className="text-xs font-medium text-green-500">{selectedBeneficiaryData?.tag}</p>
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">Account</h3>
                  <div className="flex items-center space-x-3">
                    <CircleFlag
                      countryCode={
                        currencyToCountryCode[selectedAccountDataReview?.currency] ||
                        selectedAccountDataReview?.currency.toLowerCase() ||
                        'us'
                      }
                      height="32"
                      width="32"
                    />
                    <div>
                      <p className="text-sm font-semibold">{selectedAccountDataReview?.currency.toUpperCase()} Account</p>
                      <p className="text-sm text-gray-500">
                        Net: {extractNumericValue(selectedAccountDataReview?.net || '0').toFixed(2)} {selectedAccountDataReview?.balance.currency}
                      </p>
                    </div>
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between items-center">
                  <h3 className="text-sm font-medium text-gray-500">Amount</h3>
                  <p className="text-base font-semibold">{amount} {selectedAccountDataReview?.currency.toUpperCase()}</p>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full flex flex-col bg-white rounded-xl overflow-hidden shadow-md">
      <div className="flex-grow overflow-y-auto p-6">
        <AnimatePresence mode="wait">
          {renderStep()}
        </AnimatePresence>
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between p-6">
        {step > 1 && (
          <Button onClick={handlePrevStep} variant="outline" className="h-10">
            Previous
          </Button>
        )}
        {step < 4 && (
          <Button
            onClick={handleNextStep}
            className="ml-auto h-10 bg-green-500 hover:bg-green-600 text-white"
            disabled={
              (step === 1 && !selectedAccount) ||
              (step === 2 && !selectedBeneficiary) ||
              (step === 3 && (!selectedAccount || !amount))
            }
          >
            {step === 3 ? 'Review' : 'Continue'}
          </Button>
        )}
        {step === 4 && (
          <Button
            onClick={() => {
              console.log('Request submitted', { selectedAccount, selectedBeneficiary, amount });
              // Implement your submission logic here
            }}
            className="ml-auto h-10 bg-green-500 hover:bg-green-600 text-white"
          >
            Submit Request
          </Button>
        )}
      </div>
    </div>
  );
}

export default RequestMoneyModalComponent;
