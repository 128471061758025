import React from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronRight,
  Plus,
  Link as LinkIcon,
  FileText,
  QrCode,
  Send,
  Files,
  Calendar,
  CreditCard,
  Globe,
  FileCheck,
  MapPin
} from 'lucide-react';
import { CircleFlag } from 'react-circle-flags';

const buttonStyles = {
  primary: {
    backgroundColor: '#24EC7E',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
    minWidth: '120px',
    height: '40px'
  },
  secondary: {
    backgroundColor: '#F5F5F5',
    color: '#666666',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
    minWidth: '120px',
    height: '40px'
  }
};

const listItemStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'background-color 0.2s',
    marginBottom: '1px',
    backgroundColor: '#FFFFFF',
  },
  containerHover: {
    backgroundColor: '#F9F9F9'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  icon: {
    color: '#666666',
    width: '20px',
    height: '20px',
    flexShrink: 0
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000'
  },
  description: {
    fontSize: '14px',
    color: '#666666'
  },
  chevron: {
    color: '#666666',
    width: '20px',
    height: '20px',
    flexShrink: 0
  }
};

function Payments() {
  return (
    <div style={{ padding: '24px', backgroundColor: 'white', minHeight: '100vh' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <h1 style={{ fontSize: '24px', fontWeight: 600, marginBottom: '32px' }}>Payments</h1>

        {/* Incoming Payments Section */}
        <section style={{ marginBottom: '48px' }}>
          <h2 style={{ fontSize: '16px', fontWeight: 500, marginBottom: '24px' }}>Incoming payments</h2>
          <div style={{ display: 'flex', gap: '12px', marginBottom: '24px', flexWrap: 'wrap' }}>
            <button style={buttonStyles.primary}>
              <Plus size={16} />
              Request
            </button>
            <Link to="/create-payment-link" style={buttonStyles.secondary}>
              <LinkIcon size={16} />
              Create Payment Link
            </Link>
            <Link to="/create-invoice" style={buttonStyles.secondary}>
              <FileText size={16} />
              Create Invoice
            </Link>
            <Link to="/generate-qr-code" style={buttonStyles.secondary}>
              <QrCode size={16} />
              Generate QR Code
            </Link>
          </div>

          <div>
            <Link to="/view-payment-links" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <LinkIcon style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Payment links</span>
                    <span style={listItemStyles.description}>Generate or view payment link to get paid</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/create-invoice" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <FileText style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Invoices</span>
                    <span style={listItemStyles.description}>Create and view your invoices</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/instant-pay" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <QrCode style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Instant Pay</span>
                    <span style={listItemStyles.description}>Create QR code for instant payments</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>
          </div>
        </section>

        {/* Outgoing Payments Section */}
        <section style={{ marginBottom: '48px' }}>
          <h2 style={{ fontSize: '16px', fontWeight: 500, marginBottom: '24px' }}>Outgoing payments</h2>
          <div style={{ display: 'flex', gap: '12px', marginBottom: '24px', flexWrap: 'wrap' }}>
            <button style={buttonStyles.primary}>
              <Send size={16} />
              Send
            </button>
            <Link to="/bulk-payments" style={buttonStyles.secondary}>
              <Files size={16} />
              Bulk payments
            </Link>
            <Link to="/direct-debits" style={buttonStyles.secondary}>
              <CreditCard size={16} />
              Direct Debits
            </Link>
            <Link to="/scheduled-payments" style={buttonStyles.secondary}>
              <Calendar size={16} />
              Scheduled payments
            </Link>
          </div>

          <div>
            <Link to="/bulk-payments" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <Files style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Bulk payments</span>
                    <span style={listItemStyles.description}>
                      Complete multiple transfers with a single, unified payment file
                    </span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/direct-debits" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <CreditCard style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Direct Debits</span>
                    <span style={listItemStyles.description}>Manage your payment authorizations</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/scheduled-payments" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <Calendar style={listItemStyles.icon} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>Scheduled payments</span>
                    <span style={listItemStyles.description}>Schedule and view upcoming payments</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>
          </div>
        </section>

        {/* Account Details Section */}
        <section>
          <h2 style={{ fontSize: '16px', fontWeight: 500, marginBottom: '24px' }}>Account details</h2>
          <div style={{ display: 'flex', gap: '12px', marginBottom: '24px', flexWrap: 'wrap' }}>
            <Link to="/local-account" style={buttonStyles.primary}>
              <MapPin size={16} />
              Local
            </Link>
            <Link to="/global-account" style={buttonStyles.secondary}>
              <Globe size={16} />
              Global
            </Link>
            <Link to="/financial-audit" style={buttonStyles.secondary}>
              <FileCheck size={16} />
              Proof
            </Link>
            <Link to="/get-more" style={buttonStyles.secondary}>
              <Plus size={16} />
              Get more
            </Link>
          </div>

          <div>
            <Link to="/british-pound-account" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <CircleFlag countryCode="gb" style={{ width: '32px', height: '32px', flexShrink: 0 }} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>British Pound</span>
                    <span style={listItemStyles.description}>Account number, IBAN</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/us-dollar-account" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <CircleFlag countryCode="us" style={{ width: '32px', height: '32px', flexShrink: 0 }} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>US Dollar</span>
                    <span style={listItemStyles.description}>Account number, IBAN</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>

            <Link to="/nis-account" style={{ textDecoration: 'none' }}>
              <div
                style={listItemStyles.container}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.containerHover.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = listItemStyles.container.backgroundColor)}
              >
                <div style={listItemStyles.content}>
                  <CircleFlag countryCode="il" style={{ width: '32px', height: '32px', flexShrink: 0 }} />
                  <div style={listItemStyles.textContainer}>
                    <span style={listItemStyles.title}>NIS</span>
                    <span style={listItemStyles.description}>Account number</span>
                  </div>
                </div>
                <ChevronRight style={listItemStyles.chevron} />
              </div>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Payments;
