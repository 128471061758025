import React, { useState } from 'react';
import Select from 'react-select';
import { CircleFlag } from 'react-circle-flags';

interface Country {
  id: number;
  name: string;
  short_name: string;
  iso2: string;
}

interface CountryOption {
  value: number;
  label: JSX.Element;
}

interface CountrySelectProps {
  countries: { data: Country[] };
  onCountryChange?: (selectedCountry: CountryOption | null) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({ countries, onCountryChange }) => {
  // Step 1: Find the default country (GB) in the list of countries
  const defaultCountry = countries.data.find((c) => c.iso2 === 'GB');

  // Step 2: Create an initial value for the selected country state
  let initialSelectedCountry: CountryOption | null = null;

  // Step 3: If a country with iso2 'GB' is found, set it as the initial value
  if (defaultCountry) {
    initialSelectedCountry = {
      value: defaultCountry.id,
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircleFlag countryCode={defaultCountry.iso2.toLowerCase()} style={{ height: '30px' }} />
          {defaultCountry.short_name}
        </div>
      ),
    };
  }

  

  // Step 4: Set up the state using the initial value created earlier
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(initialSelectedCountry);

  // Step 5: Handle changes when the user selects a different country
  const handleChange = (selectedOption: CountryOption | null) => {
    setSelectedCountry(selectedOption);
    if (onCountryChange) {
      onCountryChange(selectedOption);
    }
  };

  // Step 6: Create the options list for the dropdown
  const options: CountryOption[] = countries.data.map((country) => ({
    value: country.id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CircleFlag countryCode={country.iso2.toLowerCase()} style={{ height: '30px' }} />
        {country.short_name}
      </div>
    ),
  }));

  // Step 7: Render the select dropdown
  return (
    <Select
      options={options}
      value={selectedCountry}
      onChange={handleChange}
      placeholder="Select a country"
      isSearchable
    />
  );
};

export default CountrySelect;
