import { useState, useEffect } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { ChevronDown, Download } from 'lucide-react'
import QRCode from 'react-qr-code'

interface CurrencyOption {
  value: string
  label: string
  flag: string
  symbol: string
}

export default function InstantPay() {
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState<CurrencyOption>({
    value: 'GBP',
    label: 'British Pound',
    flag: 'gb',
    symbol: '£'
  })
  const [personalLink, setPersonalLink] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [showCurrencies, setShowCurrencies] = useState(false)

  const currencies: CurrencyOption[] = [
    { value: 'GBP', label: 'British Pound', flag: 'gb', symbol: '£' },
    { value: 'USD', label: 'US Dollar', flag: 'us', symbol: '$' },
    { value: 'EUR', label: 'Euro', flag: 'eu', symbol: '€' },
  ]

  useEffect(() => {
    setIsValid(amount !== '' && parseFloat(amount) > 0)
  }, [amount])

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setAmount(value)
    }
  }

  const generateLink = () => {
    if (isValid) {
      setPersonalLink(`paayco.com/pay/business/${Math.random().toString(36).substring(7)}`)
    }
  }

  const downloadQR = () => {
    // Implementation for QR download
    console.log('Downloading QR code...')
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-4">Quick Pay</h1>
      <p className="text-gray-500 text-center mb-12 max-w-2xl mx-auto">
        Receive payments instantly in your preferred currency with PaayCo. 
        We offer an easy, swift and secure way of receiving payments from customers.
      </p>

      <div className="grid md:grid-cols-[1fr,auto] gap-8">
        <div className="space-y-6">
          <div>
            <label className="block text-sm text-gray-600 mb-2">
              How much are you expecting?
            </label>
            <div className="relative">
              <input
                type="text"
                value={amount}
                onChange={handleAmountChange}
                className="w-full rounded-lg border border-gray-200 p-3 pr-32 focus:border-[#24EC7E] focus:outline-none"
                placeholder="0"
              />
              <button 
                onClick={() => setShowCurrencies(!showCurrencies)}
                className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2"
              >
                <CircleFlag countryCode={currency.flag} style={{ width: '30px', height: '30px' }} />
                <span className="font-medium">{currency.value}</span>
                <ChevronDown className="h-4 w-4 text-gray-400" />
              </button>
              {showCurrencies && (
                <div className="absolute right-0 top-full mt-1 w-48 rounded-lg border border-gray-100 bg-white shadow-lg z-10">
                  {currencies.map((curr) => (
                    <button
                      key={curr.value}
                      onClick={() => {
                        setCurrency(curr)
                        setShowCurrencies(false)
                      }}
                      className="flex items-center gap-2 w-full p-2 hover:bg-gray-50"
                    >
                      <CircleFlag countryCode={curr.flag} style={{ width: '30px', height: '30px' }} />
                      <span>{curr.label}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-600 mb-2">
              Your personal link
            </label>
            <input
              type="text"
              value={personalLink}
              readOnly
              className="w-full rounded-lg border border-gray-200 p-3 bg-gray-50"
              placeholder="Your payment link will appear here"
            />
          </div>

          <button
            onClick={generateLink}
            disabled={!isValid}
            className={`w-full rounded-full py-3 text-center font-medium transition-all
              ${isValid 
                ? 'bg-[#24EC7E] text-white hover:bg-opacity-90' 
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
          >
            Generate payment link
          </button>
        </div>

        <div className="flex flex-col items-center">
          <div className="bg-[#F8FAF8] p-6 rounded-lg">
            <QRCode
              value={personalLink || 'https://paayco.com'}
              size={200}
              bgColor="#FFFFFF"
              fgColor="#00423C"
              level="L"
              includeMargin={false}
            />
          </div>
          <button
            onClick={downloadQR}
            className="mt-4 flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
          >
            <Download className="h-4 w-4" />
            Download QR
          </button>
        </div>
      </div>

      <div className="mt-16">
        <h2 className="text-xl font-semibold mb-8">
          Additional ways of getting paid with PaayCo
        </h2>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-[#F8FAF8] p-6 rounded-lg">
            <h3 className="font-semibold mb-2">Invoices</h3>
            <p className="text-sm text-gray-600">
              Integrate PaayCo with Xero, Quixkbooks and other accounting tools.
            </p>
          </div>
          <div className="bg-[#F8FAF8] p-6 rounded-lg">
            <h3 className="font-semibold mb-2">Emails, Social media and Website</h3>
            <p className="text-sm text-gray-600">
              Add a "Pay with PaayCo" button to your online platform for smoother payments.
            </p>
          </div>
          <div className="bg-[#F8FAF8] p-6 rounded-lg">
            <h3 className="font-semibold mb-2">Shops</h3>
            <p className="text-sm text-gray-600">
              Display your PaayCo QR code so customers can scan and pay instantly.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}