import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Download, FileText } from 'lucide-react'

export function TaxGeneration() {
  const [progress, setProgress] = useState(0)
  const [isGenerating, setIsGenerating] = useState(false)

  useEffect(() => {
    if (isGenerating && progress < 100) {
      const timer = setTimeout(() => setProgress(progress + 1), 50)
      return () => clearTimeout(timer)
    } else if (progress === 100) {
      setIsGenerating(false)
    }
  }, [progress, isGenerating])

  const startGeneration = () => {
    setIsGenerating(true)
    setProgress(0)
  }

  const downloadPDF = () => {
    // Implement actual PDF download logic here
    console.log('Downloading PDF...')
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <h1 className="text-3xl font-bold text-[#00423C] mb-8 text-center">Tax Generation</h1>
        <div className="bg-[#AFF8C8] rounded-lg p-6 shadow-lg mb-8">
          <div className="flex items-center justify-between mb-4">
            <span className="text-[#00423C] font-semibold">Progress</span>
            <span className="text-[#097152] font-bold">{progress}%</span>
          </div>
          <div className="w-full bg-white rounded-full h-4 overflow-hidden">
            <motion.div
              className="h-full bg-[#24EC7E]"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
          {progress === 100 && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-[#097152] font-semibold mt-4 text-center"
            >
              Tax generation complete!
            </motion.p>
          )}
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={startGeneration}
            disabled={isGenerating}
            className="bg-[#097152] text-white px-6 py-3 rounded-lg font-semibold transition-colors duration-300 hover:bg-[#00423C] disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            <FileText className="mr-2" />
            Generate Taxes
          </button>
          <button
            onClick={downloadPDF}
            disabled={progress !== 100}
            className="bg-[#24EC7E] text-[#00423C] px-6 py-3 rounded-lg font-semibold transition-colors duration-300 hover:bg-[#AFF8C8] disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            <Download className="mr-2" />
            Download PDF
          </button>
        </div>
      </motion.div>
    </div>
  )
}

export default TaxGeneration;