import React from 'react';
import { CardOrderFlow } from '../components/cardPages/card-order-flow';
import { useNavigate } from 'react-router-dom'

const CardCustomizationPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 bg-white p-8 relative"> {/* Updated to match card-list.tsx and card-customizer.tsx */}
      <CardOrderFlow onClose={() => navigate('/cards')} />
    </div>
  );
};

export default CardCustomizationPage;
