// src/components/login/ui/GlobeFallback.tsx
import React from 'react';
import loginImage from '../images/loginImage.png'; // Adjust the path as necessary

interface GlobeFallbackProps {
  className?: string;
}

const GlobeFallback: React.FC<GlobeFallbackProps> = ({ className }) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        width: '100%',
        height: '100%',
      }}
    >
      <img src={loginImage} alt="Fallback Login" style={{ maxWidth: '100%', height: 'auto' }} />
      <p style={{ position: 'absolute', bottom: '10px', color: '#333' }}>
        Your device does not support WebGL. Please update your browser or use a different device for the best experience.
      </p>
    </div>
  );
};

export default GlobeFallback;
