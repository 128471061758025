// src/services/api.ts

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ApiError,
  ApiResponse,
  Contact,
  ContactPayload,

  ConversionResponse,
  PayTransferResponse,
  PayTransferPayload,
  TopUpPayload,
  TopUpResponse,
  Beneficiary,
  BeneficiaryResponse,
  BeneficiariesResponse,
  Contract,
  AddContractPayload,
  AddContractResponse,
  CalendarResponse,
  CalendarEventData,
  Wallet,
  GetWalletsResponse,
  Ticket,
  AddTicketPayload,
  UpdateTicketPayload,
  User,
  Transaction,
  EventData,
  EventsResponse,
  Project,
  AddProjectPayload,
  UpdateProjectPayload,
  Item,
  ItemPayload,
  CreditNote,
  CreditNoteItem,
  Expense,
  ExpensePayload,
  Task,
  UpdateTaskPayload,
} from './types';

// -----------------------------
// Axios Instance Configuration
// -----------------------------

// List of public endpoints that do not require authentication
const PUBLIC_ENDPOINTS = [
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/verify-email',
  '/resend-verification',
  '/countries',
  '/check-email',
  '/check-username',
];

// Create axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: '/api', // Adjust this to your backend's base URL
  withCredentials: true, // Enable sending cookies
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Function to check if endpoint is public
const isPublicEndpoint = (url: string): boolean => {
  return PUBLIC_ENDPOINTS.some(endpoint => url.includes(endpoint));
};

let isSessionExpired = false;

// Function to check if user is authenticated
const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('_api_token');
  const user = localStorage.getItem('user');
  return !!(token && user);
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // Add auth token if it exists
    const token = localStorage.getItem('_api_token');
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    console.error('API: Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      // Only clear auth if it's not a login attempt
      if (!error.config.url.includes('/login')) {
        localStorage.removeItem('_api_token');
        localStorage.removeItem('user');
        window.dispatchEvent(new Event('auth:logout'));
      }
    }
    return Promise.reject(error);
  }
);

// Export the resetSession function
export const resetSession = () => {
  isSessionExpired = false;
  console.log('API: Session has been reset. API calls are now allowed.');
};

// -----------------------------
// API Functions
// -----------------------------

/**
 * Fetches contacts from the API.
 * @returns The contacts data.
 */
export const getContacts = async (): Promise<Contact[]> => {
  try {
    console.log('API: Fetching contacts...');
    const response = await axiosInstance.get<ApiResponse<Contact[]>>('/contacts/128');
    if (response.data.success && response.data.data) { // Added check for response.data.data
      console.log('API: Contacts fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch contacts.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching contacts:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new contact via the API.
 * @param contact - The contact data to add.
 * @returns The added contact data.
 */
export const addContact = async (contact: ContactPayload): Promise<Contact> => {
  try {
    console.log('API: Adding a new contact...', contact);
    const response = await axiosInstance.post<{ success: boolean; contact?: Contact; error?: any }>(
      '/contacts',
      contact
    );
    console.log('API: Contact added successfully:', response.data.contact);
    if (response.data.success && response.data.contact) {
      return response.data.contact;
    } else if (response.data.error) {
      console.error('API: Error adding contact:', response.data.error);
      throw new Error('Failed to add contact.');
    } else {
      throw new Error('Failed to add contact.');
    }
  } catch (error) {
    const err = error as ApiError;
    if (err.response && err.response.status === 422) {
      console.error('API: Validation errors adding contact:', err.response.data.errors);
    } else {
      console.error('API: Error adding contact:', err.data?.error || err.message);
    }
    throw err;
  }
};

/**
 * Updates an existing contact via the API.
 * @param contactId - The ID of the contact to update.
 * @param contact - The updated contact data.
 * @returns The updated contact data.
 */
export const updateContact = async (
  contactId: string,
  contact: ContactPayload
): Promise<Contact> => {
  try {
    console.log(`API: Updating contact with ID: ${contactId}...`, contact);
    const response = await axiosInstance.put<{ success: boolean; contact?: Contact; error?: any }>(
      `/contacts/${contactId}`,
      contact
    );
    if (response.data.success && response.data.contact) {
      console.log('API: Contact updated successfully:', response.data.contact);
      return response.data.contact;
    } else if (response.data.error) {
      console.error('API: Error updating contact:', response.data.error);
      throw new Error('Failed to update contact.');
    } else {
      throw new Error('Failed to update contact.');
    }
  } catch (error) {
    const err = error as ApiError;
    if (err.response && err.response.status === 422) {
      console.error('API: Validation errors updating contact:', err.response.data.errors);
    } else {
      console.error('API: Error updating contact:', err.data?.error || err.message);
    }
    throw err;
  }
};

/**
 * Deletes a contact via the API.
 * @param contactId - The ID of the contact to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteContact = async (contactId: string): Promise<string> => {
  try {
    console.log(`API: Deleting contact with ID: ${contactId}...`);
    const response = await axiosInstance.delete<{ success: boolean; message?: string; error?: any }>(
      `/contacts/${contactId}`
    );
    if (response.data.success) {
      console.log(`API: Contact with ID: ${contactId} deleted successfully.`);
      return response.data.message || 'Contact deleted successfully.';
    } else if (response.data.error) {
      console.error('API: Error deleting contact:', response.data.error);
      throw new Error('Failed to delete contact.');
    } else {
      throw new Error('Failed to delete contact.');
    }
  } catch (error) {
    const err = error as ApiError;
    if (err.response && err.response.status === 422) {
      console.error('API: Validation errors deleting contact:', err.response.data.errors);
    } else {
      console.error('API: Error deleting contact:', err.data?.error || err.message);
    }
    throw err;
  }
};

/**
 * Fetches all tickets from the API.
 * @returns An array of tickets.
 */
export const getTickets = async (): Promise<Ticket[]> => {
  try {
    console.log('API: Fetching all tickets...');
    const response = await axiosInstance.get<ApiResponse<Ticket[]>>('/tickets');
    if (response.data.success && response.data.data) { // Ensure data is not undefined
      console.log('API: Tickets fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch tickets.');
    }
  } catch (error: any) {
    console.error('API: Error fetching tickets:', {
      status: error.status,
      message: error.message,
      data: error.data
    });
    throw error;
  }
};

/**
 * Adds a new ticket via the API.
 * @param payload - The ticket data to add.
 * @returns The added ticket data.
 */
export const addTicket = async (payload: AddTicketPayload): Promise<Ticket> => {
  try {
    console.log('API: Adding a new ticket...', payload);
    const response = await axiosInstance.post<{ success: boolean; data: Ticket; message?: string }>(
      '/tickets',
      payload
    );
    if (response.data.success) {
      console.log('API: Ticket added successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to add ticket.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding ticket:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates a specific ticket via the API.
 * @param ticketId - The ID of the ticket to update.
 * @param payload - The ticket data to update.
 * @returns The updated ticket data.
 */
export const updateTicket = async (
  ticketId: string,
  payload: UpdateTicketPayload
): Promise<Ticket> => {
  try {
    console.log(`API: Updating ticket with ID: ${ticketId}...`, payload);
    const response = await axiosInstance.put<{ success: boolean; data: Ticket; message?: string }>(
      `/tickets/${ticketId}`,
      payload
    );
    if (response.data.success) {
      console.log('API: Ticket updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to update ticket.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating ticket:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes a specific ticket via the API.
 * @param ticketId - The ID of the ticket to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteTicket = async (ticketId: string): Promise<string> => {
  try {
    console.log(`API: Deleting ticket with ID: ${ticketId}...`);
    const response = await axiosInstance.delete<{ success: boolean; message?: string }>(
      `/tickets/${ticketId}`
    );
    if (response.data.success) {
      console.log(`API: Ticket with ID: ${ticketId} deleted successfully.`);
      return response.data.message || 'Ticket deleted successfully.';
    } else {
      throw new Error(response.data.message || 'Failed to delete ticket.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error deleting ticket:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches wallets from the API.
 * Implements a retry mechanism in case of errors.
 * @returns The wallets data.
 */
export const getWallets = async (): Promise<GetWalletsResponse> => {
  if (!isAuthenticated()) {
    throw new Error('Not authenticated');
  }
  
  try {
    const response = await axiosInstance.get<GetWalletsResponse>('/wallets');
    // Ensure transactions array exists for each wallet
    if (response.data.wallets) {
      response.data.wallets = response.data.wallets.map(wallet => ({
        ...wallet,
        transactions: wallet.transactions || []
      }));
    }
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching wallets:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Logs in the user.
 * @param credentials - An object containing the user's email and password.
 * @returns The login response data.
 */
export const login = async (credentials: { email: string; password: string }) => {
  try {
    console.log(`API: Attempting to log in user with email: ${credentials.email}`);
    const response = await axiosInstance.post('/login', credentials);
    console.log('API: Login response:', response.data);

    const userData = response.data;

    // Check if essential fields are present to determine successful login
    if (userData && userData.token && userData.userid) {
      console.log('API: User data received and cookies set by the backend.');

      return {
        success: true,
        data: {
          // Extract relevant data from the response
          crm_token: userData.crm_token || userData.token, // Adjust based on actual field
          customer_id: userData.customer_id,
          key: userData.key,
          token: userData.token,
          userid: userData.userid,
          is_verified: userData._is_verified,
          name: userData.name || '', // Adjust based on actual data
          avatar: userData.avatar || null, // Adjust based on actual data
          // Add other fields as necessary
        },
      };
    } else {
      const errorMessage = response.data?.message || 'Login failed';
      console.error(`API: Login failed with message: ${errorMessage}`);
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const err = error as ApiError;

    return { 
      success: false, 
      message: err.data?.error || err.message || 'Login failed',
      status: err.response?.status,
    };
    
  }
};

/**
 * Logs out the user.
 * @returns A success message upon successful logout.
 */
export const logout = async (): Promise<string> => {
  try {
    console.log('API: Logging out user...');
    const response = await axiosInstance.post('/logout');
    console.log('API: Logout successful:', response.data);
    return response.data.message || 'Logout successful.';
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error during logout:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches the authenticated user's data.
 * @returns The user data.
 */
export const getUser = async (): Promise<User> => {
  try {
    console.log('API: Fetching user data...');
    const response = await axiosInstance.get('/user');
    console.log('API: User data fetched successfully:', response.data);

    const userData = response.data;

    if (userData && userData.id) {
      return {
        id: userData.id,
        name: userData.name || '',
        email: userData.email || '',
        // Add other fields as necessary
      };
    } else {
      throw new Error('Failed to fetch user data.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching user:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Checks the current session status.
 * @returns The session data.
 */
export const checkSession = async () => {
  try {
    console.log('API: Checking session status...');
    const response = await axiosInstance.get('/check-session');
    console.log('API: Session status:', response.data);
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error checking session:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches beneficiaries from the API.
 * @returns The beneficiaries array.
 */
export const getBeneficiaries = async (): Promise<Beneficiary[]> => {
  if (!isAuthenticated()) {
    throw new Error('Not authenticated');
  }

  try {
    const response = await axiosInstance.get<{ 
      success: boolean; 
      data: { 
        data: BeneficiaryResponse[] 
      } 
    }>('/beneficiary/get_beneficiaries');

    console.log('Raw API response:', response.data);

    if (!response.data?.data?.data) {
      throw new Error('Invalid response format');
    }

    // Return the data directly since it already matches our structure
    return response.data.data.data.map(b => ({
      id: b.id.toString(),
      firstname: b.beneficiary_first_name,
      lastname: b.beneficiary_last_name,
      country: b.beneficiary_country,
      currency: b.beneficiary_currency,
      detailsType: b.beneficiary_details_type,
      iban: b.beneficiary_iban,
      bic: b.beneficiary_bic
    }));
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching beneficiaries:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new beneficiary via the API.
 * @param beneficiary - The beneficiary data (excluding 'id').
 * @returns The response data.
 */
export const addBeneficiary = async (beneficiary: Omit<Beneficiary, 'id'>) => {
  try {
    console.log('API: Adding a new beneficiary...', beneficiary);
    const response = await axiosInstance.post('/beneficiary/add', beneficiary);
    console.log('API: Beneficiary added successfully:', response.data);
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding beneficiary:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches a specific transaction by ID.
 * @param transactionId - The ID of the transaction.
 * @returns The transaction data.
 */
export const getTransaction = async (transactionId: string) => {
  try {
    console.log(`API: Fetching transaction with ID: ${transactionId}`);
    const response = await axiosInstance.get(`/transactions/${transactionId}`);
    console.log('API: Transaction fetched successfully:', response.data);
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching transaction:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches events from the API.
 * @returns An array of events.
 */
export const getEvents = async (): Promise<Event[]> => {
  if (!isAuthenticated()) {
    throw new Error('Not authenticated');
  }

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 5000; // 5 seconds
  let attempt = 0;

  while (attempt < MAX_RETRIES) {
    try {
      console.log('API: Fetching events via /settings/get_events');
      const response = await axiosInstance.get<EventsResponse>('/settings/get_events');
      console.log('API: /settings/get_events response:', response.data);
      
      // Access the nested data array
      const eventData = response.data.data;
      
      if (Array.isArray(eventData)) {
        // Map the response data to match the Event interface
        const events: Event[] = eventData.map((event: EventData) => ({
          id: event.event_id,
          message: event.event,
          time: event.created
        }));
        
        return events;
      } else {
        throw new Error('Events data is not an array');
      }
    } catch (error) {
      const err = error as ApiError;
      if (err.status === 500) {
        attempt += 1;
        console.warn(
          `API: Received 500 error. Retrying attempt ${attempt}/${MAX_RETRIES} in ${
            RETRY_DELAY / 1000
          } seconds...`
        );
        await new Promise((res) => setTimeout(res, RETRY_DELAY));
      } else {
        console.error('API: Error fetching events:', err.data?.error || err.message);
        throw err;
      }
    }
  }

  console.error('API: Failed to fetch events after maximum retry attempts.');
  throw new Error('Failed to fetch events after multiple attempts.');
};

/**
 * Initiates a payment transfer.
 * @param amount - The amount to transfer.
 * @param currency - The currency of the transfer.
 * @param description - A description for the transfer.
 * @returns The payment transfer response data.
 */
export const payTransfer = async (
  amount: number,
  currency: string,
  description: string
): Promise<PayTransferResponse> => {
  const payload: PayTransferPayload = {
    amount: amount,
    currency: currency.toLowerCase(),
    type: 'sepa',
    source: {
      account: {
        acct_number: 'acct_81390f3935f6fd07457897d7',
      },
    },
    destination: {
      account: {
        acct_number: 'acct_898923b371191899b5946e27',
      },
    },
    description: description,
  };

  try {
    console.log('API: Initiating payment transfer with payload:', payload);
    const response = await axiosInstance.post('/accounts/pay', payload);
    console.log('API: Payment transfer successful:', response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error making payment:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Initiates a top-up process.
 * @param payload - The top-up payload data.
 * @returns The top-up response data.
 */
export const topUp = async (payload: TopUpPayload): Promise<TopUpResponse> => {
  try {
    console.log('API: Initiating top-up with payload:', payload);
    const response: AxiosResponse<TopUpResponse> = await axiosInstance.post(
      '/payments/do_top_up',
      payload
    );
    console.log('API: Top-up response:', response.data);
    if (response.data.success) {
      return response.data;
    } else {
      console.error('API: Top-up failed with message:', response.data.message);
      throw new Error(response.data.message || 'Top-up failed');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error during top-up:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches calendar events.
 * @returns The calendar events data.
 */
export const getCalendarEvents = async (): Promise<CalendarEventData[]> => {
  try {
    console.log('API: Fetching calendar events...');
    const response = await axiosInstance.get<CalendarResponse>('/calendar');
    console.log('API: Calendar events fetched successfully:', response.data.data);
    return response.data.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching calendar:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new calendar event via the API.
 * @param eventData - The event data to be added.
 * @returns The response data.
 */
export const addCalendarEvent = async (eventData: CalendarEventData) => {
  try {
    console.log('API: Adding a new calendar event...', eventData);
    const postData = [
      {
        title: eventData.title,
        description: eventData.description,
        userid: eventData.userid,
        start: eventData.start,
        end: eventData.end,
        public: eventData.public,
        color: eventData.color,
        isstartnotified: eventData.isstartnotified,
        reminder_before: eventData.reminder_before,
        reminder_before_type: eventData.reminder_before_type,
      },
    ];

    const response = await axiosInstance.post('/calendar/add', postData);
    console.log('API: Calendar event added successfully:', response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding calendar event:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Edits an existing calendar event via the API.
 * @param eventId - The ID of the event to edit.
 * @param eventData - The event data to be updated.
 * @returns The response data.
 */
export const editCalendarEvent = async (eventId: string, eventData: CalendarEventData) => {
  try {
    console.log('API: Editing calendar event:', eventId, eventData);
    const response = await axiosInstance.put(`/calendar/${eventId}`, [eventData]);
    console.log(`API: Calendar event ${eventId} edited successfully:`, response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error(`API: Error editing calendar event ${eventId}:`, err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes a calendar event via the API.
 * @param eventId - The ID of the event to delete.
 * @returns The response data.
 */
export const deleteCalendarEvent = async (eventId: string) => {
  try {
    console.log(`API: Deleting calendar event ${eventId}...`);
    const response = await axiosInstance.delete(`/calendar/${eventId}`);
    console.log(`API: Calendar event ${eventId} deleted successfully:`, response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error(`API: Error deleting calendar event ${eventId}:`, err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches contracts from the API.
 * @returns An array of contracts.
 */
export const getContracts = async (): Promise<Contract[]> => {
  try {
    console.log('API: Fetching contracts...');
    const token = localStorage.getItem('_api_token');
    
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axiosInstance.get<ApiResponse<Contract[]>>('/contracts', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.data) {
      throw new Error('No data received from contracts endpoint');
    }

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    console.log('API: Contracts fetched successfully:', response.data);
    return response.data.data || [];
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch contracts';
    const errorData = error.response?.data || error.data;
    
    console.error('API: Error fetching contracts:', {
      message: errorMessage,
      data: errorData,
      status: error.response?.status,
      originalError: error
    });

    throw {
      message: errorMessage,
      data: errorData,
      status: error.response?.status,
      originalError: error
    };
  }
};

/**
 * Adds a new contract via the API.
 * @param payload - The contract data to add.
 * @returns The added contract data.
 */
export const addContract = async (payload: AddContractPayload): Promise<Contract> => {
  try {
    console.log('API: Adding a new contract...', payload);
    const response = await axiosInstance.post<AddContractResponse>('/contracts', payload);
    if (response.data.success && response.data.contract) {
      console.log('API: Contract added successfully:', response.data.contract);
      return response.data.contract;
    } else {
      console.error('API: Failed to add contract:', response.data.message);
      throw new Error(response.data.message || 'Failed to add contract');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding contract:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Edits an existing contract via the API.
 * @param contractId - The ID of the contract to edit.
 * @param payload - The contract data to update.
 * @returns The updated contract data.
 */
export const editContract = async (
  contractId: string,
  payload: Partial<Contract>
): Promise<Contract> => {
  try {
    console.log(`API: Editing contract ${contractId} with payload:`, payload);
    const response = await axiosInstance.put<Contract>(`/contracts/${contractId}`, payload);
    console.log(`API: Contract ${contractId} edited successfully:`, response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error(`API: Error editing contract ${contractId}:`, err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes a contract via the API.
 * @param contractId - The ID of the contract to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteContract = async (contractId: string): Promise<string> => {
  try {
    console.log(`API: Deleting contract ${contractId}...`);
    const response = await axiosInstance.delete(`/contracts/${contractId}`);
    console.log(`API: Contract ${contractId} deleted successfully:`, response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error(`API: Error deleting contract ${contractId}:`, err.data?.error || err.message);
    throw err;
  }
};

/**
 * Initiates an exchange between two currencies.
 * @param data - The exchange data containing amount and currencies.
 * @returns The exchange response data.
 */
export const doExchange = async (data: {
  from_amount: number;
  currency: string;
  to_currency: string;
}): Promise<ConversionResponse> => {
  try {
    console.log('API: Initiating currency exchange with data:', data);
    const response = await axiosInstance.post<ConversionResponse>('/accounts/exchange', data);
    console.log('API: Currency exchange completed successfully:', response.data);
    return response.data;
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error doing exchange:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Fetches conversion rates.
 * @param fromCurrency - The source currency.
 * @param toCurrency - The target currency.
 * @param amount - The amount to convert.
 * @returns The conversion response.
 */
export const getConversion = async (
  fromCurrency: string,
  toCurrency: string,
  amount: number
): Promise<ConversionResponse> => {
  try {
    console.log(`API: Fetching conversion from ${fromCurrency} to ${toCurrency} for amount ${amount}`);
    const response = await axiosInstance.post(`/accounts/getConversion`, {
      from_currency: fromCurrency,
      to_currency: toCurrency,
      amount,
    });

    const data = response.data;

    // Check if the response contains the expected fields
    if (data && typeof data === 'object' && 'converted_amount' in data) {
      console.log('API: Conversion fetched successfully:', data);
      return data as ConversionResponse;
    } else {
      throw new Error(data.error || 'Failed to fetch conversion.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching conversion:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Projects API Functions
// -----------------------------

/**
 * Fetches all projects from the API.
 * @returns An array of projects.
 */
export const getProjects = async (): Promise<Project[]> => {
  try {
    console.log('API: Fetching projects...');
    const response = await axiosInstance.get<{ success: boolean; data: Project[]; message?: string }>('/projects');
    
    if (response.data.success) {
      console.log('API: Projects fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch projects.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching projects:', err.data?.message || err.message);
    throw err;
  }
};

/**
 * Adds a new project via the API.
 * @param project - The project data to add.
 * @returns The added project data.
 */
export const addProject = async (project: AddProjectPayload): Promise<Project> => {
  try {
    console.log('API: Adding a new project...', project);
    const response = await axiosInstance.post<{ success: boolean; data: Project; error?: any }>(
      '/projects',
      project
    );
    if (response.data.success) {
      console.log('API: Project added successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.error || 'Failed to add project.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding project:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates an existing project via the API.
 * @param projectId - The ID of the project to update.
 * @param updatedProject - The updated project data.
 * @returns The updated project data.
 */
export const updateProject = async (
  projectId: string,
  updatedProject: UpdateProjectPayload
): Promise<Project> => {
  try {
    console.log(`API: Updating project with ID: ${projectId}...`, updatedProject);
    const response = await axiosInstance.put<{ success: boolean; data: Project; error?: any }>(
      `/projects/${projectId}`,
      updatedProject
    );
    if (response.data.success) {
      console.log('API: Project updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.error || 'Failed to update project.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating project:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes a project via the API.
 * @param projectId - The ID of the project to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteProject = async (projectId: string): Promise<string> => {
  try {
    console.log(`API: Deleting project with ID: ${projectId}...`);
    const response = await axiosInstance.delete<{ success: boolean; message?: string; error?: any }>(
      `/projects/${projectId}`
    );
    if (response.data.success) {
      console.log(`API: Project ${projectId} deleted successfully.`);
      return response.data.message || 'Project deleted successfully.';
    } else {
      throw new Error(response.data.error || 'Failed to delete project.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error deleting project:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Items API Functions
// -----------------------------

/**
 * Fetches all items from the API.
 * @returns An array of items.
 */
export const getItems = async (): Promise<Item[]> => {
  try {
    console.log('API: Fetching items...');
    const response = await axiosInstance.get<ApiResponse<Item[]>>('/getItems');
    if (response.data.success && response.data.data) {
      console.log('API: Items fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch items.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching items:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new item via the API.
 * @param item - The item data to add.
 * @returns The added item data.
 */
export const postItem = async (item: ItemPayload): Promise<Item> => {
  try {
    console.log('API: Adding a new item...', item);
    const response = await axiosInstance.post<ApiResponse<Item>>('/postItem', item);
    if (response.data.success && response.data.data) {
      console.log('API: Item added successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to add item.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding item:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates an existing item via the API.
 * @param itemId - The ID of the item to update.
 * @param item - The updated item data.
 * @returns The updated item data.
 */
export const putItem = async (itemId: string, item: ItemPayload): Promise<Item> => {
  try {
    console.log(`API: Updating item with ID: ${itemId}...`, item);
    const response = await axiosInstance.put<ApiResponse<Item>>(`/putItem/${itemId}`, item);
    if (response.data.success && response.data.data) {
      console.log('API: Item updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to update item.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating item:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes an item via the API.
 * @param itemId - The ID of the item to delete.
 * @returns The deleted item data.
 */
export const deleteItem = async (itemId: string): Promise<Item> => {
  try {
    console.log(`API: Deleting item with ID: ${itemId}...`);
    const response = await axiosInstance.delete<ApiResponse<Item>>(`/deleteItem/${itemId}`);
    if (response.data.success && response.data.data) {
      console.log('API: Item deleted successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to delete item.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error deleting item:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Credit Notes API Functions
// -----------------------------

/**
 * Fetches all credit notes from the API.
 * @returns An array of credit notes.
 */
export const getCreditNotes = async (): Promise<CreditNote[]> => {
  try {
    console.log('API: Fetching credit notes...');
    const response = await axiosInstance.get<ApiResponse<CreditNote[]>>('/crm/credit-notes');
    if (response.data.success && response.data.data) {
      console.log('API: Credit notes fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch credit notes.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching credit notes:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new credit note via the API.
 * @param creditNote - The credit note data to add.
 * @returns The added credit note data.
 */
export const postCreditNote = async (creditNote: Omit<CreditNote, 'id'>): Promise<CreditNote> => {
  try {
    console.log('API: Creating new credit note...', creditNote);
    const response = await axiosInstance.post<ApiResponse<CreditNote>>('/credit-notes', creditNote);
    if (response.data.success && response.data.data) {
      console.log('API: Credit note created successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to create credit note.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error creating credit note:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates an existing credit note via the API.
 * @param id - The ID of the credit note to update.
 * @param creditNote - The updated credit note data.
 * @returns The updated credit note data.
 */
export const updateCreditNote = async (id: string, creditNote: Partial<CreditNote>): Promise<CreditNote> => {
  try {
    console.log(`API: Updating credit note ${id}...`, creditNote);
    const response = await axiosInstance.put<ApiResponse<CreditNote>>(`/credit-notes/${id}`, creditNote);
    if (response.data.success && response.data.data) {
      console.log('API: Credit note updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to update credit note.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating credit note:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes a credit note via the API.
 * @param id - The ID of the credit note to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteCreditNote = async (id: string): Promise<void> => {
  try {
    console.log(`API: Deleting credit note ${id}...`);
    const response = await axiosInstance.delete<ApiResponse<void>>(`/api/credit-notes/${id}`);
    if (response.data.success) {
      console.log('API: Credit note deleted successfully');
    } else {
      throw new Error(response.data.message || 'Failed to delete credit note.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error deleting credit note:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Expenses API Functions
// -----------------------------

/**
 * Fetches all expenses from the API.
 * @returns An array of expenses.
 */
export const getExpenses = async (): Promise<Expense[]> => {
  try {
    console.log('API: Fetching expenses...');
    const response = await axiosInstance.get<ApiResponse<Expense[]>>('/expenses');
    if (response.data.success && response.data.data) {
      console.log('API: Expenses fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch expenses.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching expenses:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Adds a new expense via the API.
 * @param expense - The expense data to add.
 * @returns The added expense data.
 */
export const postExpense = async (expense: ExpensePayload): Promise<Expense> => {
  try {
    console.log('API: Adding a new expense...', expense);
    const response = await axiosInstance.post<ApiResponse<Expense>>('/expenses', expense);
    if (response.data.success && response.data.data) {
      console.log('API: Expense added successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to add expense.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error adding expense:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates an existing expense via the API.
 * @param expenseId - The ID of the expense to update.
 * @param expense - The updated expense data.
 * @returns The updated expense data.
 */
export const updateExpense = async (
  expenseId: string,
  expense: Partial<ExpensePayload>
): Promise<Expense> => {
  try {
    console.log(`API: Updating expense with ID: ${expenseId}...`, expense);
    const response = await axiosInstance.put<ApiResponse<Expense>>(`/expenses/${expenseId}`, expense);
    if (response.data.success && response.data.data) {
      console.log('API: Expense updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to update expense.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating expense:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Deletes an expense via the API.
 * @param expenseId - The ID of the expense to delete.
 * @returns A success message upon successful deletion.
 */
export const deleteExpense = async (expenseId: string): Promise<void> => {
  try {
    console.log(`API: Deleting expense with ID: ${expenseId}...`);
    const response = await axiosInstance.delete<ApiResponse<void>>(`/expenses/${expenseId}`);
    if (response.data.success) {
      console.log('API: Expense deleted successfully');
    } else {
      throw new Error(response.data.message || 'Failed to delete expense.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error deleting expense:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Tasks API Functions
// -----------------------------

/**
 * Fetches tasks associated with a specific ID.
 * @param id - The ID to fetch tasks for.
 * @returns An array of tasks.
 */
export const getTasks = async (id: string): Promise<Task[]> => {
  try {
    console.log('API: Fetching tasks...');
    const response = await axiosInstance.get<ApiResponse<Task[]>>(`/tasks/${id}`);
    if (response.data.success && response.data.data) {
      console.log('API: Tasks fetched successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch tasks.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error fetching tasks:', err.data?.error || err.message);
    throw err;
  }
};

/**
 * Updates a specific task via the API.
 * @param id - The ID of the task to update.
 * @param taskData - The updated task data.
 * @returns The updated task data.
 */
export const updateTask = async (id: string, taskData: UpdateTaskPayload): Promise<Task> => {
  try {
    console.log('API: Updating task...', taskData);
    const response = await axiosInstance.put<ApiResponse<Task>>(`/tasks/${id}`, taskData);
    if (response.data.success && response.data.data) {
      console.log('API: Task updated successfully:', response.data.data);
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to update task.');
    }
  } catch (error) {
    const err = error as ApiError;
    console.error('API: Error updating task:', err.data?.error || err.message);
    throw err;
  }
};

// -----------------------------
// Exporting API Functions
// -----------------------------

const apiFunctions = {
  // Contacts
  getContacts,
  addContact,
  updateContact,
  deleteContact,

  // Tickets
  getTickets,
  addTicket,
  updateTicket,
  deleteTicket,

  // Wallets
  getWallets,

  // Auth
  login,
  logout,
  getUser,
  checkSession,

  // Beneficiaries
  getBeneficiaries,
  addBeneficiary,

  // Transactions
  getTransaction,

  // Events
  getEvents,

  // Payments
  payTransfer,
  topUp,

  // Calendar
  getCalendarEvents,
  addCalendarEvent,
  editCalendarEvent,
  deleteCalendarEvent,

  // Contracts
  getContracts,
  addContract,
  editContract,
  deleteContract,

  // Exchange
  doExchange,
  getConversion,

  // Projects
  getProjects,
  addProject,
  updateProject,
  deleteProject,

  // Items
  getItems,
  postItem,
  putItem,
  deleteItem,

  // Credit Notes
  getCreditNotes,
  postCreditNote,
  updateCreditNote,
  deleteCreditNote,

  // Expenses
  getExpenses,
  postExpense,
  updateExpense,
  deleteExpense,

  // Tasks
  getTasks,
  updateTask,

  // Utilities
  resetSession,
  isAuthenticated,
};

export default apiFunctions;
