// src/pages/CRM.tsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import Main from '../components/dashboard/components/crm/main';
import { useAuth } from '../contexts/AuthContext';

const CRM: React.FC = () => {
  const { user, loading, error } = useAuth();

  if (loading) return <div>Loading...</div>;

  if (!user) {
    return <div>Please log in</div>;
  }

  return (
    <div>
      {/* Common CRM Layout or Header */}
      {/* Place where child routes will render */}
      <Outlet />

      {error && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          <strong>Note:</strong> {error} (Displaying cached data)
        </div>
      )}
    </div>
  );
};

export default CRM;
