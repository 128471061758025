// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import App from './App';
import '../css/app.css';
import ErrorBoundary from './ErrorBoundary';

const root = document.getElementById('app');

if (root) {
  ReactDOM.createRoot(root).render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
} else {
  console.error("Root element with id 'app' not found");
}
