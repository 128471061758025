import React, { useState, useEffect, useRef } from 'react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Card, CardContent } from "@/components/ui/card"
import { Pencil, Plus, ChevronDown, X, Calendar as CalendarIcon } from 'lucide-react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Calendar } from "@/components/ui/calendar"
import { format, addDays } from "date-fns"
import { cn } from "@/lib/utils"

const InvoicePreview = ({ invoiceData }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const scale = 2 // For better resolution on high-DPI displays
    canvas.width = 595 * scale // A4 width in pixels at 72 DPI
    canvas.height = 842 * scale // A4 height in pixels at 72 DPI
    ctx.scale(scale, scale)

    // Set background
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Load and draw logo placeholder
    const logo = new Image()
    logo.src = '/placeholder.svg?height=50&width=100'
    logo.onload = () => {
      ctx.drawImage(logo, 40, 40, 100, 50)

      // Set font and color
      ctx.fillStyle = 'black'
      ctx.font = '16px Arial'

      // Draw invoice header
      ctx.font = 'bold 24px Arial'
      ctx.fillText('Invoice', 40, 120)

      ctx.font = '12px Arial'
      ctx.fillText(`Invoice number: ${invoiceData.invoiceNumber}`, 40, 150)
      ctx.fillText(`Issue date: ${invoiceData.invoiceDate}`, 40, 170)

      // Draw company info
      ctx.textAlign = 'right'
      ctx.fillText('Ovii Limited', 555, 70)
      ctx.fillText('Unit C 105 Eade Road', 555, 85)
      ctx.fillText('London, N4 1TJ', 555, 100)
      ctx.fillText('United Kingdom', 555, 115)

      // Draw customer info
      ctx.textAlign = 'left'
      ctx.fillText(`To: ${invoiceData.customerName}`, 40, 200)
      if (invoiceData.customerEmail) {
        ctx.fillText(invoiceData.customerEmail, 40, 215)
      }

      // Draw invoice details
      ctx.font = 'bold 16px Arial'
      ctx.fillText(`${invoiceData.total} ${invoiceData.currency} due by ${format(invoiceData.dueDate, 'dd MMM yyyy')}`, 40, 250)

      // Draw table header
      ctx.font = '12px Arial'
      const tableTop = 280
      ctx.fillText('Product or service', 40, tableTop)
      ctx.fillText('Quantity', 300, tableTop)
      ctx.fillText('Unit price', 380, tableTop)
      ctx.fillText('Tax', 460, tableTop)
      ctx.fillText('Total', 520, tableTop)

      // Draw horizontal line
      ctx.beginPath()
      ctx.moveTo(40, tableTop + 10)
      ctx.lineTo(555, tableTop + 10)
      ctx.stroke()

      // Draw table content
      ctx.fillText(invoiceData.productName, 40, tableTop + 30)
      ctx.textAlign = 'right'
      ctx.fillText(invoiceData.quantity.toString(), 340, tableTop + 30)
      ctx.fillText(`${invoiceData.price} ${invoiceData.currency}`, 440, tableTop + 30)
      ctx.fillText(`0.00 ${invoiceData.currency}`, 500, tableTop + 30)
      ctx.fillText(`${invoiceData.total} ${invoiceData.currency}`, 555, tableTop + 30)

      // Draw "Ways to pay" section
      ctx.textAlign = 'left'
      ctx.font = 'bold 14px Arial'
      ctx.fillText('Ways to pay', 40, tableTop + 80)
      ctx.font = '12px Arial'
      ctx.fillText('Pay online', 40, tableTop + 100)
      ctx.fillText('or scan the QR code with your phone', 40, tableTop + 115)

      // Draw QR code placeholder
      ctx.strokeRect(455, tableTop + 80, 100, 100)
      ctx.font = '10px Arial'
      ctx.textAlign = 'center'
      ctx.fillText('QR Code', 505, tableTop + 130)
      ctx.fillText('generated after', 505, tableTop + 145)
      ctx.fillText('invoice is issued', 505, tableTop + 160)

      // Draw watermark
      ctx.font = 'bold 48px Arial'
      ctx.fillStyle = 'rgba(200, 200, 200, 0.5)'
      ctx.textAlign = 'center'
      ctx.translate(297.5, 421)
      ctx.rotate(-Math.PI / 4)
      ctx.fillText(`DRAFT`, 0, 0)
      ctx.rotate(Math.PI / 4)
      ctx.translate(-297.5, -421)
    }

    logo.onerror = () => {
      console.error('Error loading logo placeholder')
    }

  }, [invoiceData])

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
    </div>
  )
}

export function InvoiceCreationComponent() {
  const [invoiceNumber, setInvoiceNumber] = useState('INV-001')
  const [invoiceDate, setInvoiceDate] = useState('23 Oct 2024')
  const [currency, setCurrency] = useState('USD')
  const [customerName, setCustomerName] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [productName, setProductName] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [price, setPrice] = useState(0)
  const [dueDate, setDueDate] = useState(addDays(new Date(), 30))
  const [showAddress, setShowAddress] = useState(false)
  const [country, setCountry] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [stateOrRegion, setStateOrRegion] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const total = quantity * price

  const contacts = [
    { name: 'John Doe', email: 'johndoe@email.com' },
    { name: 'Jane Smith', email: 'janesmith@email.com' },
  ]

  const handleContactSelect = (value: string) => {
    if (value === 'new') {
      setCustomerName('')
      setCustomerEmail('')
    } else {
      const contact = contacts.find(c => c.name === value)
      if (contact) {
        setCustomerName(contact.name)
        setCustomerEmail(contact.email)
      }
    }
  }

  const invoiceData = {
    invoiceNumber,
    invoiceDate,
    currency,
    customerName,
    customerEmail,
    productName,
    quantity,
    price,
    total,
    dueDate
  }

  const renderAddressFields = () => {
    switch (country) {
      case 'United Kingdom':
        return (
          <>
            <Label htmlFor="region">Country</Label>
            <Select onValueChange={setStateOrRegion}>
              <SelectTrigger id="region">
                <SelectValue placeholder="Select country" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="England">England</SelectItem>
                <SelectItem value="Scotland">Scotland</SelectItem>
                <SelectItem value="Wales">Wales</SelectItem>
                <SelectItem value="Northern Ireland">Northern Ireland</SelectItem>
              </SelectContent>
            </Select>
          </>
        )
      case 'United States':
        return (
          <>
            <Label htmlFor="state">State</Label>
            <Select onValueChange={setStateOrRegion}>
              <SelectTrigger id="state">
                <SelectValue placeholder="Select state" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="CA">California</SelectItem>
                <SelectItem value="NY">New York</SelectItem>
                <SelectItem value="TX">Texas</SelectItem>
                {/* Add more states as needed */}
              </SelectContent>
            </Select>
          </>
        )
      default:
        return (
          <>
            <Label htmlFor="stateOrRegion">State/Region</Label>
            <Input
              id="stateOrRegion"
              value={stateOrRegion}
              onChange={(e) => setStateOrRegion(e.target.value)}
            />
          </>
        )
    }
  }

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex justify-between items-start">
          <div className="w-2/3 pr-8">
            <h1 className="text-3xl font-bold mb-6">New invoice</h1>
            
            <div className="flex justify-between mb-6">
              <div className="flex items-center space-x-2 bg-[#F3F4F6] px-3 py-1 rounded-md">
                <span>{invoiceNumber}</span>
                <Pencil className="w-4 h-4 text-gray-500 cursor-pointer" />
              </div>
              <div className="flex items-center space-x-2 bg-[#F3F4F6] px-3 py-1 rounded-md">
                <span>{invoiceDate}</span>
                <Pencil className="w-4 h-4 text-gray-500 cursor-pointer" />
              </div>
            </div>

            <Card className="mb-6 border-0 shadow-none">
              <CardContent className="p-0">
                <h2 className="text-lg font-semibold mb-4">Who it's for</h2>
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="customerSelect">Select from contacts or enter new</Label>
                    <Select onValueChange={handleContactSelect}>
                      <SelectTrigger id="customerSelect" className="w-full mt-1">
                        <SelectValue placeholder="Select a contact or enter new" />
                      </SelectTrigger>
                      <SelectContent>
                        {contacts.map((contact, index) => (
                          <SelectItem key={index} value={contact.name}>
                            {contact.name}
                          </SelectItem>
                        ))}
                        <SelectItem value="new">Enter new contact</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="customerName">Name</Label>
                    <Input
                      id="customerName"
                      placeholder="Enter customer name"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <Label htmlFor="customerEmail">Email (Optional)</Label>
                    <Input
                      id="customerEmail"
                      placeholder="Enter email"
                      value={customerEmail}
                      onChange={(e) => setCustomerEmail(e.target.value)}
                      className="mt-1"
                    />
                    <p className="text-sm text-gray-500 mt-1">We'll send your invoice to this email.</p>
                  </div>
                  {!showAddress && (
                    <Button
                      variant="outline"
                      className="w-full justify-start bg-[#F3F4F6] text-gray-700 hover:bg-gray-200 border-0 rounded-full"
                      onClick={() => setShowAddress(true)}
                    >
                      <Plus className="w-4 h-4 mr-2" />
                      Add postal address
                    </Button>
                  )}
                  {showAddress && (
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor="country">Country</Label>
                        <Select onValueChange={setCountry}>
                          <SelectTrigger id="country">
                            <SelectValue placeholder="Select country" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="United Kingdom">United Kingdom</SelectItem>
                            <SelectItem value="United States">United States</SelectItem>
                            <SelectItem value="Other">Other</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div>
                        <Label htmlFor="addressLine1">Address Line 1</Label>
                        <Input
                          id="addressLine1"
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.target.value)}
                        />
                      </div>
                      <div>
                        <Label htmlFor="addressLine2">Address Line 2 (Optional)</Label>
                        <Input
                          id="addressLine2"
                          value={addressLine2}
                          onChange={(e) => setAddressLine2(e.target.value)}
                        />
                      </div>
                      <div>
                        <Label htmlFor="city">City</Label>
                        <Input
                          id="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      {renderAddressFields()}
                      <div>
                        <Label htmlFor="postalCode">Postal Code</Label>
                        <Input
                          id="postalCode"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card className="mb-6 border-0 shadow-none">
              <CardContent className="p-0">
                <h2 className="text-lg font-semibold mb-4">What you sold</h2>
                <div className="space-y-4">
                  <div>
                    
                    <Label htmlFor="productName">Product or service</Label>
                    <Input
                      id="productName"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/3">
                      <Label htmlFor="quantity">Quantity</Label>
                      <Input
                        id="quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        className="mt-1"
                      />
                    </div>
                    <div className="w-1/3">
                      <Label htmlFor="price">Price</Label>
                      <div className="relative mt-1">
                        <Input
                          id="price"
                          type="number"
                          value={price}
                          onChange={(e) => setPrice(Number(e.target.value))}
                          className="pl-8"
                        />
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                          {currency}
                        </span>
                      </div>
                    </div>
                    <div className="w-1/3">
                      <Label>Total</Label>
                      <div className="h-10 px-3 py-2 rounded-md bg-gray-100 flex items-center mt-1">
                        {total.toFixed(2)} {currency}
                      </div>
                    </div>
                  </div>
                  <Button variant="outline" className="w-full justify-start bg-[#F3F4F6] text-gray-700 hover:bg-gray-200 border-0 rounded-full">
                    <Plus className="w-4 h-4 mr-2" />
                    Add tax
                  </Button>
                </div>
              </CardContent>
            </Card>

            <Card className="mb-6 border-0 shadow-none">
              <CardContent className="p-0">
                <h2 className="text-lg font-semibold mb-4">How you'll be paid</h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-4 p-4 bg-[#F3F4F6] rounded-md">
                    <div className="w-8 h-8 bg-[#1E4E3C] rounded-full flex items-center justify-center text-white font-bold">P</div>
                    <div>
                      <h3 className="font-semibold">Pay with Paayco</h3>
                      <p className="text-sm text-gray-500">Instant and free.</p>
                    </div>
                    <Button variant="outline" className="ml-auto bg-[#F3F4F6] text-[#1E4E3C] hover:bg-[#E6F0EC] border-[#1E4E3C] rounded-full">Learn more</Button>
                  </div>
                  <div>
                    <Label htmlFor="dueDate">Due</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal mt-1 rounded-full",
                            !dueDate && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {dueDate ? format(dueDate, "PPP") : <span>Pick a date</span>}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={dueDate}
                          onSelect={setDueDate}
                          initialFocus
                          className="rounded-md border border-[#1E4E3C]"
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="border-0 shadow-none">
              <CardContent className="p-0">
                <h2 className="text-lg font-semibold mb-4">Additional details (Optional)</h2>
                <Textarea 
                  placeholder="Add a note or extra info, like your company registration or tax number."
                  className="min-h-[100px]"
                />
              </CardContent>
            </Card>

            <p className="text-sm text-gray-500 mt-6">
              It's your responsibility to invoice your customer in line with their local laws and regulations.
            </p>
          </div>

          <div className="w-1/3">
            <div className="flex justify-end mb-4">
              <Select value={currency} onValueChange={setCurrency}>
                <SelectTrigger className="w-[180px] rounded-full">
                  <SelectValue placeholder="Select currency" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="USD">🇺🇸 USD</SelectItem>
                  <SelectItem value="EUR">🇪🇺 EUR</SelectItem>
                  <SelectItem value="GBP">🇬🇧 GBP</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <Card className="mb-4">
              <CardContent className="p-4">
                <h3 className="font-semibold mb-2">Invoice PDF</h3>
                <div className="bg-[#F3F4F6] h-40 mb-4 rounded-md flex items-center justify-center overflow-hidden">
                  <InvoicePreview invoiceData={invoiceData} />
                </div>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button className="w-full bg-[#E6F0EC] text-[#1E4E3C] hover:bg-[#D1E5DC] rounded-full">Preview</Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-4xl">
                    <DialogHeader>
                      <DialogTitle>Invoice Preview</DialogTitle>
                    </DialogHeader>
                    <InvoicePreview invoiceData={invoiceData} />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>

            <Card className="mb-4">
              <CardContent className="p-4">
                <h3 className="font-semibold mb-2">Payment summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Total excluding tax</span>
                    <span>{total.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Taxes</span>
                    <span>0.00</span>
                  </div>
                  <div className="flex justify-between font-semibold">
                    <span>Amount due</span>
                    <span>{total.toFixed(2)} {currency}</span>
                  </div>
                  <div className="text-sm text-gray-500">
                    by {format(dueDate, "d MMM yyyy")}
                  </div>
                </div>
              </CardContent>
            </Card>

            <Button className="w-full bg-[#1E4E3C] text-white hover:bg-[#2C745A] rounded-full">Create invoice</Button>
          </div>
        </div>
      </div>
    </div>
  )
}