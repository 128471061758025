// ModalPageContext.tsx

import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

interface ModalPageState {
  isOpen: boolean;
  content: ReactNode | null;
  title: string;
}

interface ModalPageContextType {
  isOpen: boolean;
  content: ReactNode | null;
  title: string;
  openModalPage: (config: {
    title?: string;
    content: ReactNode;
  }) => void;
  closeModalPage: () => void;
}

const initialState: ModalPageState = {
  isOpen: false,
  content: null,
  title: '',
};

const ModalPageContext = createContext<ModalPageContextType | undefined>(undefined);

export const ModalPageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<ModalPageState>(initialState);

  const openModalPage = useCallback((config: {
    title?: string;
    content: ReactNode;
  }) => {
    setState({
      isOpen: true,
      content: config.content,
      title: config.title || '',
    });
  }, []);

  const closeModalPage = useCallback(() => {
    setState(initialState);
  }, []);

  const value = useMemo(() => ({
    ...state,
    openModalPage,
    closeModalPage,
  }), [state, openModalPage, closeModalPage]);

  return (
    <ModalPageContext.Provider value={value}>
      {children}
    </ModalPageContext.Provider>
  );
};

export const useModalPage = () => {
  const context = useContext(ModalPageContext);
  if (!context) {
    throw new Error('useModalPage must be used within a ModalPageProvider');
  }
  return context;
};

export default ModalPageContext;
