// src/pages/Login.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/login/components/login-form';
import { useAuth } from '../contexts/AuthContext';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { user, requiresTwoFactor } = useAuth();

  const handleLoginSuccess = (userData: any) => {
    console.log('Login successful', userData);
    navigate('/home');
  };

  useEffect(() => {
    console.log('user', user);
    if (user) {
      // User is fully authenticated, navigate to home
      navigate('/home');
    }
  }, [user, requiresTwoFactor, navigate]);

  return (
    <LoginForm onLoginSuccess={handleLoginSuccess} />
  );
};

export default Login;
