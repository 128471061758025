// src/components/dashboardNew/components/CurrencyCard.tsx

import React from 'react';
import styled from 'styled-components';
import { CircleFlag } from 'react-circle-flags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../../../contexts/ModalContext';
import { useAuth } from '../../../contexts/AuthContext';
import { CurrencyDetailsModal } from '../modals/CurrencyDetailsModal';
import { Wallet } from '../../../types';

interface CurrencySymbols {
  [key: string]: string;
}

interface CurrencyToCountryCode {
  [key: string]: string;
}

const currencyToCountryCode: CurrencyToCountryCode = {
  GBP: 'gb',
  USD: 'us',
  EUR: 'eu',
  AUD: 'au',
  // Add more mappings as needed
};

// Styled Components
const Card = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#E0F7FA' : '#F5F5F5')};
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ isActive }) => (isActive ? '2px solid #10B981' : 'none')};
  box-shadow: ${({ isActive }) => (isActive ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none')};
  /* Add the following flex properties */
  flex: 0 0 33.3333%; /* 33.3333% of ExistingCardsContainer (75% of row) ≈ 25% of row */
  max-width: 33.3333%;
  
  height: 180px; /* Fixed height for consistency */
  cursor: pointer;
  transition: box-shadow 0.3s, border 0.3s;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CurrencyLabel = styled.span`
  color: #111827;
  font-size: 14px;
`;

const Balance = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #111827;
`;

// Added Styled Components
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto; /* Pushes the bottom section to the bottom */
`;

const BankInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BankCode = styled.span`
  font-size: 12px;
  color: #6B7280;
`;

interface CurrencyCardProps {
  wallet: Wallet;
  onSetAsMainWallet: (wallet: Wallet) => void;
}

export default function CurrencyCard({
  wallet,
  onSetAsMainWallet,
}: CurrencyCardProps) {
  const { openModal } = useModal();
  const { user } = useAuth(); // Get user from context to check active wallet

  const currencySymbols: CurrencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€',
    JPY: '¥',
    CAD: 'C$',
    AUD: 'A$',
    // Add more symbols as needed
  };

  const formatAmount = (value: number | string) => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue);
  };

  const countryCode = currencyToCountryCode[wallet.currency.toUpperCase()] || 'us';

  const handleCardClick = () => {
    openModal({
      title: `${wallet.currency.toUpperCase()} Details`,
      content: <CurrencyDetailsModal wallet={wallet} />,
    });
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    // Implement context menu logic if needed
    // For example, you can open a custom context menu here
    console.log(`Context menu for ${wallet.currency}`);
    // Optionally, you can invoke onSetAsMainWallet or other handlers here
  };

  const isActiveWallet = user?.activeWallet === wallet.currency;

  return (
    <Card isActive={isActiveWallet} onClick={handleCardClick} onContextMenu={handleContextMenu}>
      <TopSection>
        <CircleFlag countryCode={countryCode} height="20" width="20" />
        <CurrencyLabel>{wallet.currency.toUpperCase()}</CurrencyLabel>
      </TopSection>
      
      <BottomSection>
        <Balance>
          {currencySymbols[wallet.currency.toUpperCase()] || ''}{formatAmount(wallet.balance)}
        </Balance>
        <BankInfo>
          <FontAwesomeIcon icon={faUniversity} />
          <BankCode>{wallet.id.slice(-4)}</BankCode>
        </BankInfo>
      </BottomSection>
    </Card>
  );
}
