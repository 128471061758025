// TransferPage.tsx

import React from 'react';
import { TransferModal } from '../components/dashboard/components/sendModal/transfer-modal';

const TransferPage = () => {
  return (
    <div className="w-full h-full bg-white">
      <div className="border-b border-gray-200">
        <h1 className="text-2xl font-bold p-6">Transfer Money</h1>
      </div>
      <div className="p-6">
        <div className="w-full max-w-4xl mx-auto">
          <TransferModal isPageView={true} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransferPage);
