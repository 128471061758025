// src/components/dashboardNew/app-page.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ActionButtonsComponent } from './components/Action-buttons';
import { TransactionsTableComponent } from './components/TransactionsTable';
import { TransferCalculatorComponent } from './components/TransferCalculator';
import { useAuth } from '../../contexts/AuthContext';  
import { useAuthWallets } from '../../contexts/AuthContextData/useAuthWallets';
import * as Sentry from '@sentry/react';
import { Transaction } from './components/types';
import CurrencyCard from './components/CurrencyCard';
import NewCurrencyCard from './components/NewCurrencyCard';
import styled from 'styled-components';

// Define Wallet type
interface Wallet {
  id: string;
  currency: string;
  balance: number;
  transactions: Transaction[];
}

// Define props for useAuthWallets hook if necessary
interface UseAuthWalletsProps {
  walletId?: string;
}

// Helper functions
const aggregateAllTransactions = (wallets: Wallet[]): Transaction[] => {
  if (!wallets || wallets.length === 0) return [];
  
  return wallets.flatMap(wallet => wallet.transactions || []);
};

// Styled Components
const Container = styled.div`
  padding: 24px;
  background-color: white;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const HeaderSection = styled.div`
  color: #6B7280;
  font-size: 14px;
`;

const HeaderValue = styled.div<{ isError?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  color: ${({ isError }) => (isError ? '#EF4444' : '#111827')};
`;

const CardsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 12px;
  }
`;

const ExistingCardsContainer = styled.div`
  flex: 0 0 75%;
  max-width: 75%;
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 8px; /* Space for scrollbar */

  /* Stylish scrollbar */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    overflow-x: hidden;
    gap: 12px;
  }
`;

const AddCardContainer = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  display: flex;
  align-items: flex-start; /* Align to top */

  @media (max-width: 768px) {
    display: none;
  }
`;

const ViewMoreButton = styled.button`
  background-color: #e5e7eb; /* Gray background */
  border: none;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
  min-width: 180px;

  @media (max-width: 768px) {
    width: calc(50% - 6px);
    margin-bottom: 12px;
  }
`;

export function BlockPage() {
  const { user } = useAuth();
  const { wallets } = useAuthWallets({ walletId: user?.activeWallet } as UseAuthWalletsProps);

  const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>({});
  const [isLoadingRates, setIsLoadingRates] = useState<boolean>(true);
  const [errorRates, setErrorRates] = useState<Error | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showAllCards, setShowAllCards] = useState<boolean>(false);

  useEffect(() => {
    // Determine if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log('User:', user);
    console.log('Wallets:', wallets);
  }, [user, wallets]);

  useEffect(() => {
    async function fetchExchangeRates() {
      if (!user?.activeWallet) {
        setExchangeRates({});
        setIsLoadingRates(false);
        return;
      }

      try {
        setIsLoadingRates(true);
        const baseCurrency = user.activeWallet.toUpperCase();
        const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${baseCurrency}`);
        console.log('Exchange Rates:', response.data.rates);
        setExchangeRates(response.data.rates);
        setIsLoadingRates(false);
      } catch (error) {
        console.error('Dashboard: Error fetching exchange rates:', error);
        Sentry.captureException(error, {
          extra: { context: 'Fetching exchange rates' },
        });
        setErrorRates(error as Error);
        setIsLoadingRates(false);
      }
    }

    fetchExchangeRates();
  }, [user?.activeWallet]);

  // Helper function to calculate total balance
  const calculateTotalBalance = (): number => {
    if (!wallets || !exchangeRates) return 0;

    return wallets.reduce((total, wallet) => {
      const walletCurrency = wallet.currency.toUpperCase();
      const activeCurrency = user?.activeWallet.toUpperCase();

      if (walletCurrency === activeCurrency) {
        return total + wallet.balance;
      }

      const rate = exchangeRates[walletCurrency];
      if (!rate) {
        console.warn(`Exchange rate for ${walletCurrency} not found. Skipping.`);
        return total;
      }

      return total + wallet.balance / rate;
    }, 0);
  };

  // Helper function to get active wallet balance
  const getActiveWalletBalance = (): number => {
    if (!wallets) return 0;
    const activeCurrency = user?.activeWallet.toUpperCase();
    const activeWallet = wallets.find(wallet => wallet.currency.toUpperCase() === activeCurrency);
    return activeWallet ? activeWallet.balance : 0;
  };

  const totalBalance = calculateTotalBalance();
  const activeWalletBalance = getActiveWalletBalance();

  // Aggregate all transactions
  const allTransactions = aggregateAllTransactions(wallets);

  // Handlers
  const onSetAsMainWallet = (wallet: Wallet) => {
    // Implement your set as main wallet logic here
    console.log(`Setting ${wallet.currency} as main wallet`);
    // Example: Update user context or make an API call
  };

  const onAddCurrency = () => {
    // Implement your add currency logic here
    console.log('Add Currency clicked');
    // Example: Open a modal to add a new currency
    // openAddCurrencyModal();
  };

  // Determine wallets to display on mobile
  let displayedWallets = wallets;
  if (isMobile && !showAllCards) {
    displayedWallets = wallets.slice(0, 3);
  }

  return (
    <Container>
      <ContentWrapper>
        {/* Header */}
        <Header>
          <div>
            <HeaderSection>Main Account balance</HeaderSection>
            <HeaderValue>
              {isLoadingRates ? 'Loading...' : `${activeWalletBalance.toFixed(2)} ${user?.activeWallet.toUpperCase()}`}
            </HeaderValue>
          </div>
          <div style={{ textAlign: 'right' }}>
            <HeaderSection>Total account balance</HeaderSection>
            <HeaderValue isError={!!errorRates}>
              {isLoadingRates 
                ? 'Loading...' 
                : errorRates 
                  ? 'Error fetching rates' 
                  : `${totalBalance.toFixed(2)} ${user?.activeWallet.toUpperCase()}`}
            </HeaderValue>
          </div>
        </Header>

        {/* Action Buttons */}
        <ActionButtonsComponent />

        {/* Currency Cards and Add Currency Card */}
        <CardsSection>
          {/* Existing Currency Cards Container */}
          <ExistingCardsContainer>
            {displayedWallets.map((wallet) => (
              <CurrencyCard
                key={wallet.id}
                wallet={wallet}
                onSetAsMainWallet={onSetAsMainWallet}
              />
            ))}
            {/* On mobile, if there are more cards, and 'showAllCards' is false, show 'View More' button */}
            {isMobile && !showAllCards && wallets.length > 3 && (
              <ViewMoreButton onClick={() => setShowAllCards(true)}>View More</ViewMoreButton>
            )}
            {/* On mobile, show 'Add new currency' card at the bottom */}
            {isMobile && (
              <div style={{ width: 'calc(50% - 6px)', marginBottom: '12px' }}>
                <NewCurrencyCard onAddCurrency={onAddCurrency} />
              </div>
            )}
          </ExistingCardsContainer>

          {/* Add Currency Card Container for desktop and tablet */}
          {!isMobile && (
            <AddCardContainer>
              <NewCurrencyCard onAddCurrency={onAddCurrency} />
            </AddCardContainer>
          )}
        </CardsSection>

        {/* Transactions Table */}
        <TransactionsTableComponent 
          transactions={allTransactions} 
          isLoading={isLoadingRates} // Assuming exchange rates loading state affects transactions
          error={errorRates} // Assuming exchange rate errors affect transactions
        />

        {/* Transfer Calculator */}
        <TransferCalculatorComponent />
      </ContentWrapper>
    </Container>
  );
}

export default BlockPage;
