// src/components/dashboard/dashboard-components/CurrencyCard.tsx

import React from 'react';
import { Card, CardContent } from '../ui/card';
import { CircleFlag } from 'react-circle-flags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../../../../contexts/ModalContext';
import { useAuth } from '../../../../contexts/AuthContext';
import { CurrencyDetailsModal } from '../modals/CurrencyDetailsModal';
import { Wallet } from '../../../../types';
import { CurrencyCardContextMenu } from './CurrencyCardContextMenu';

interface CurrencySymbols {
  [key: string]: string;
}

interface CurrencyToCountryCode {
  [key: string]: string;
}

const currencyToCountryCode: CurrencyToCountryCode = {
  GBP: 'gb',
  USD: 'us',
  EUR: 'eu',
  AUD: 'au',
  // Add more mappings as needed
};

interface CurrencyCardProps {
  wallet: Wallet;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClick?: (wallet: Wallet) => void;
  onSetAsMainWallet: (wallet: Wallet) => void;
}

export function CurrencyCard({
  wallet,
  isSelectable = false,
  isSelected = false,
  onClick,
  onSetAsMainWallet,
}: CurrencyCardProps) {
  const { openModal } = useModal();
  const { user } = useAuth(); // Get user from context to check active wallet

  const currencySymbols: CurrencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€',
    JPY: '¥',
    CAD: 'C$',
    AUD: 'A$',
    // Add more symbols as needed
  };

  const formatAmount = (value: number | string) => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue);
  };

  const countryCode = currencyToCountryCode[wallet.currency.toUpperCase()] || 'us';

  const handleCardClick = (e: React.MouseEvent) => {
    if (e.button === 0) {
      if (onClick) {
        onClick(wallet);
      } else {
        openModal({
          title: `${wallet.currency} Details`,
          content: <CurrencyDetailsModal wallet={wallet} />,
        });
      }
    }
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    openModal({
      title: `${wallet.currency} Details`,
      content: <CurrencyDetailsModal wallet={wallet} />,
    });
  };

  const isActiveWallet = user?.activeWallet === wallet.currency;

  const cardContent = (
    <Card
      className={`
        w-full bg-gray-100 cursor-pointer flex flex-col h-[180px] rounded-xl
        transition-all duration-200
        ${isSelectable && isSelected ? 'ring-2 ring-primary' : ''}
        ${isActiveWallet ? 'border-2 border-primary' : ''}
        hover:shadow-md
      `}
      onClick={handleCardClick}
      onContextMenu={handleContextMenu}
    >
      <CardContent className="p-4 flex flex-col justify-between h-full">
        <div className="flex items-center space-x-3">
          <CircleFlag countryCode={countryCode} height="48" width="48" />
          <div className="flex flex-col">
            <span className="font-medium text-[18px] text-gray-500">
              {wallet.currency.toUpperCase()}
            </span>
            {isActiveWallet && (
              <span className="text-xs text-primary">Active Wallet</span>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[20px] font-semibold text-gray-800">
            <span>{currencySymbols[wallet.currency.toUpperCase()] || ''}</span>
            {formatAmount(wallet.balance)}
          </div>
          <div className="flex items-center text-gray-400 text-[12px]">
            <FontAwesomeIcon icon={faBuildingColumns} className="w-4 h-4 mr-1" />
            <span>{wallet.id.slice(-4)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <CurrencyCardContextMenu
      wallet={wallet}
      onSetAsMainWallet={onSetAsMainWallet}
    >
      {cardContent}
    </CurrencyCardContextMenu>
  );
}

export default CurrencyCard;
