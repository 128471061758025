import React from 'react';


const Payees: React.FC = () => {
    return (
    <div className="flex flex-col h-screen">
    
    </div>
  );
};

export default Payees;