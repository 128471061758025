// src/components/sidebar/ModernSidebarMenu.tsx

import React from 'react';
import {
  CreditCard,
  FileText,
  HelpCircle,
  LayoutDashboard,
  MoreHorizontal,
  Receipt,
  Users,
  Wallet,
  ChevronDown,
  ArrowLeftRight,
  Banknote,
  BadgeDollarSign,
  Terminal,
  BarChart3,
  FileSpreadsheet,
  ClipboardList,
  X,
} from 'lucide-react';
import { useSidebar } from '../../contexts/SidebarContext';
import PaaycoLogoComponent from '../headers/PaaycoLogo.svg';
import { NavLink } from 'react-router-dom';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from './ui/collapsible';

import Icon from './icons/Icon';

export function ModernSidebarMenu() {
  const { isSidebarOpen, toggleSidebar, isMobile } = useSidebar();

  return (
    <>
      {/* Overlay for Mobile when Sidebar is Open */}
      {isSidebarOpen && isMobile && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`${
          isMobile
            ? `fixed top-0 left-0 h-full w-64 bg-white transform ${
                isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
              } transition-transform duration-300 ease-in-out z-50`
            : `relative w-64 bg-white`
        }`}
      >
        {/* Logo Container - Only show on mobile */}
        {isMobile && (
          <div className="h-[72px] flex items-center px-4 border-b">
            <PaaycoLogoComponent className="h-8 w-auto" />
            <button
              onClick={toggleSidebar}
              className="ml-auto p-2 hover:bg-gray-100 rounded-lg"
              aria-label="Close sidebar"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>
        )}

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto p-4">
          <div className="space-y-8">
            {/* Business Account Section */}
            <div>
              <h2 className="mb-4 text-sm font-semibold text-[#097152] uppercase">
                Business Account
              </h2>
              <div className="space-y-1">
                <NavItem to="/home" iconName="account" label="Account" />
                <NavItem to="/payments" iconName="payments" label="Payments" />
                <NavItem to="/cards" iconName="card" label="Cards" />
                <NavItem to="/statements" iconName="statements" label="Statements" />
                <CollapsibleNavItem iconName="more" label="More">
                  <NavItem to="/more/sub-item1" label="Sub Item 1" />
                  <NavItem to="/more/sub-item2" label="Sub Item 2" />
                </CollapsibleNavItem>
              </div>
            </div>

            {/* Payment Processing Section */}
            <div>
              <h2 className="mb-4 text-sm font-semibold text-[#097152] uppercase">
                Payment Processing
              </h2>
              <div className="space-y-1">
                <NavItem to="/transactions" iconName="transactions" label="Transactions" />
                <NavItem to="/balances" iconName="balances" label="Balances" />
                <NavItem to="/accept-payment" iconName="AcceptPayment" label="Accept Payment" />
                <NavItem to="/card-machines" iconName="cardMachines" label="Card Machines" />
                <CollapsibleNavItem iconName="more" label="More">
                  <NavItem to="/more/sub-item3" label="Sub Item 3" />
                  <NavItem to="/more/sub-item4" label="Sub Item 4" />
                </CollapsibleNavItem>
              </div>
            </div>

            {/* Plus CRM Section */}
            <div>
              <h2 className="mb-4 text-sm font-semibold text-[#097152] uppercase">
                Plus CRM
              </h2>
              <div className="space-y-1">
                <NavItem to="/sales" iconName="sales" label="Sales" />
                <NavItem to="/expenses" iconName="expenses" label="Expenses" />
                <NavItem to="/teams" iconName="teams" label="Teams" />
                <NavItem to="/tasks" iconName="tasks" label="Tasks" />
                <CollapsibleNavItem iconName="more" label="More">
                  <NavItem to="/more/sub-item5" label="Sub Item 5" />
                  <NavItem to="/more/sub-item6" label="Sub Item 6" />
                </CollapsibleNavItem>
              </div>
            </div>

            {/* Support Section */}
            <div>
              <NavItem to="/support" iconName="support" label="Support" isHeader />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

interface NavItemProps {
  to: string;
  iconName?: string;
  label: string;
  isHeader?: boolean;
}

function NavItem({ to, iconName, label, isHeader = false }: NavItemProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
          isActive
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
        }`
      }
    >
      {iconName && <Icon name={iconName} className="h-5 w-5" />}
      <span>{label}</span>
    </NavLink>
  );
}

interface CollapsibleNavItemProps {
  iconName?: string;
  label: string;
  children: React.ReactNode;
}

function CollapsibleNavItem({ iconName, label, children }: CollapsibleNavItemProps) {
  return (
    <Collapsible>
      <CollapsibleTrigger className="flex items-center gap-3 rounded-lg px-3 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-900">
        {iconName && <Icon name={iconName} className="h-5 w-5" />}
        <span className="flex-1 text-left">{label}</span>
        <ChevronDown className="h-4 w-4" />
      </CollapsibleTrigger>
      <CollapsibleContent className="pl-10 pt-1 space-y-1">
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
}

export default ModernSidebarMenu;
