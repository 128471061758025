// /home/thomas/projects/funddpayNew/funddpay-dashboard/resources/js/contexts/AuthContextData/useAuthExchangeRates.tsx

import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { logAuthEvent } from './utils.ts';
import { User } from './types';

interface UseAuthExchangeRatesProps {
  user: User | null;
}

export const useAuthExchangeRates = ({ user }: UseAuthExchangeRatesProps) => {
  const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>({});
  const [exchangeRatesError, setExchangeRatesError] = useState<string | null>(null);
  const [loadingExchangeRates, setLoadingExchangeRates] = useState<boolean>(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000; // 3 seconds

  const fetchExchangeRates = useCallback(async () => {
    if (!user?.activeWallet) {
      logAuthEvent(
        'No active wallet set. Skipping exchange rates fetch.',
        null,
        'warning'
      );
      setExchangeRates({});
      return;
    }

    let attempts = 0;
    let success = false;

    while (attempts < MAX_RETRIES && !success) {
      try {
        logAuthEvent('Fetching Exchange Rates', {
          baseCurrency: user.activeWallet,
        });
        setLoadingExchangeRates(true);
        const response = await axios.get(
          `https://api.exchangerate-api.com/v4/latest/${user.activeWallet}`
        );
        setExchangeRates(response.data.rates);
        setExchangeRatesError(null);
        logAuthEvent(
          'Exchange Rates Fetched Successfully',
          { rates: response.data.rates },
          'success'
        );
        success = true;
      } catch (error: any) {
        attempts++;
        logAuthEvent(
          `Error Fetching Exchange Rates - Attempt ${attempts}`,
          error,
          'error'
        );

        if (attempts >= MAX_RETRIES) {
          setExchangeRatesError(
            'Failed to fetch exchange rates after multiple attempts.'
          );
          logAuthEvent(
            'Failed to fetch exchange rates after maximum retries.',
            null,
            'error'
          );
        } else {
          logAuthEvent(
            `Retrying to fetch exchange rates in ${
              RETRY_DELAY / 1000
            } seconds...`,
            null,
            'warning'
          );
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      } finally {
        setLoadingExchangeRates(false);
      }
    }
  }, [user?.activeWallet]);

  // Sync exchange rates to localStorage if needed
  useEffect(() => {
    if (exchangeRates && Object.keys(exchangeRates).length > 0) {
      localStorage.setItem('exchangeRates', JSON.stringify(exchangeRates));
      logAuthEvent(
        'Exchange rates stored in localStorage',
        exchangeRates,
        'info'
      );
    }
  }, [exchangeRates]);

  return {
    exchangeRates,
    setExchangeRates,
    fetchExchangeRates,
    exchangeRatesError,
    loadingExchangeRates,
  };
};
