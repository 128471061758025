// src/contexts/RightSideMenuContext.tsx
import React, { useMemo, useState } from 'react';

interface RightSideMenuContextProps {
  isOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
}

const RightSideMenuContext = React.createContext<RightSideMenuContextProps>({
  isOpen: false,
  openMenu: () => {},
  closeMenu: () => {},
  toggleMenu: () => {},
});

interface RightSideMenuProviderProps {
  children: React.ReactNode;
}

export const RightSideMenuProvider: React.FC<RightSideMenuProviderProps> = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);

    const openMenu = () => setIsOpen(true);
    const closeMenu = () => setIsOpen(false);
    const toggleMenu = () => setIsOpen(!isOpen);


    const contextValue = useMemo(
        () => ({
          isOpen,
          openMenu,
          closeMenu,
          toggleMenu,
        }),
        [isOpen, openMenu, closeMenu, toggleMenu]
      );
    

    return (
        <RightSideMenuContext.Provider value={contextValue}>
          {children}
        </RightSideMenuContext.Provider>
      );
    
    
    };

export default RightSideMenuContext;
