import React from 'react';
import { CardOrderFlow } from '../components/cardPages/card-order-flow';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "../components/cardManagement/uicustomizer/button";

export function CardOrderFlowPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <CardOrderFlow />
    </div>
  );
}

export default CardOrderFlowPage;
  