import React from 'react';
import { DirectDebits } from '../components/direct-debits';
import { useParams, useNavigate } from 'react-router-dom';
export function DirectDebitsPage() {




  return (
    <div className="flex flex-col flex-grow">
      <div className="p-4">
        {/* You can add additional content or navigation here if needed */}
      </div>
      <DirectDebits />
    </div>
  );
}

export default DirectDebitsPage;
    