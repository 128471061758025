// /home/thomas/projects/funddpayNew/funddpay-dashboard/resources/js/contexts/AuthContextData/useAuthWallets.tsx

import { useState, useEffect, useCallback } from 'react';
import { Wallet, User } from './types';
import api from '../../services/api';
import { logAuthEvent } from './utils.ts';

interface UseAuthWalletsProps {
  user: User | null;
  logout: (reason?: string) => Promise<void>;
}

export const useAuthWallets = ({ user, logout }: UseAuthWalletsProps) => {
  const [wallets, setWallets] = useState<Wallet[]>(() => {
    try {
      const storedWallets = localStorage.getItem('wallets');
      const parsedWallets = storedWallets ? JSON.parse(storedWallets) : [];
      return Array.isArray(parsedWallets) ? parsedWallets : [];
    } catch (e) {
      console.error('AuthContext: Error parsing wallets from localStorage:', e);
      return [];
    }
  });

  const [error, setError] = useState<string | null>(null);
  const [loadingWallets, setLoadingWallets] = useState<boolean>(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000; // 3 seconds

  const fetchWallets = useCallback(async () => {
    const token = localStorage.getItem('_api_token');
    if (!token || !user) {
      logAuthEvent('Skipping wallet fetch - no token or user', null, 'info');
      return;
    }

    logAuthEvent('Fetching Wallets');
    let attempts = 0;

    while (attempts < MAX_RETRIES) {
      try {
        setError(null);
        setLoadingWallets(true);
        const response = await api.getWallets();

        if (response?.wallets) {
          // Normalize the wallets data
          const normalizedWallets = response.wallets.map((wallet: any) => ({
            ...wallet,
            transactions: Array.isArray(wallet.transactions)
              ? wallet.transactions
              : [],
            balance: Number(wallet.balance) || 0,
            gross: Number(wallet.gross) || null,
            net: Number(wallet.net) || null,
          }));

          setWallets(normalizedWallets);
          localStorage.setItem('wallets', JSON.stringify(normalizedWallets));
          logAuthEvent(
            'Wallets Fetched Successfully',
            {
              count: normalizedWallets.length,
              currencies: normalizedWallets.map(
                (w: Wallet) => w.currency
              ),
            },
            'success'
          );
          return; // Exit after successful fetch
        }

        throw new Error('No wallets received from API');
      } catch (error: any) {
        attempts++;
        logAuthEvent(
          'Wallet Fetch Error',
          {
            attempt: attempts,
            error: error.message,
          },
          'error'
        );

        if (error?.response?.status === 401) {
          await logout('Unauthorized during wallet fetch');
          return;
        }

        if (attempts >= MAX_RETRIES) {
          setError('Failed to fetch wallets after multiple attempts');
          logAuthEvent(
            'Failed to fetch wallets after maximum retries.',
            null,
            'error'
          );
          return;
        }

        logAuthEvent(
          `Retrying to fetch wallets in ${
            RETRY_DELAY / 1000
          } seconds...`,
          null,
          'warning'
        );
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      } finally {
        setLoadingWallets(false);
      }
    }
  }, [user, logout]); // Add user to dependencies

  // Sync wallets state to localStorage whenever wallets change
  useEffect(() => {
    if (wallets.length > 0) {
      localStorage.setItem('wallets', JSON.stringify(wallets));
      logAuthEvent(
        'Wallets data stored in localStorage',
        wallets,
        'info'
      );
    }
  }, [wallets]);

  return { wallets, setWallets, fetchWallets, error, loadingWallets };
};
