// src/components/login/ui/GlobeWithoutRings.tsx

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import countryDotsPositions from './countryDotsPositions.json'; // Adjust the path accordingly

const MAX_WEBGL_CONTEXTS = 16; // Browser limit is usually 16
let activeRenderer: THREE.WebGLRenderer | null = null;

interface GlobeProps {
  className?: string;
  onError?: () => void; // Callback to notify parent of errors
}

const GlobeWithoutRings: React.FC<GlobeProps> = ({ className, onError }) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const animationFrameId = useRef<number>();
  const isDisposed = useRef(false);

  useEffect(() => {
    let scene: THREE.Scene,
      camera: THREE.PerspectiveCamera,
      renderer: THREE.WebGLRenderer,
      globeMesh: THREE.Mesh,
      countryDots: THREE.Points,
      currentFlightPath: THREE.Line,
      earthGroup: THREE.Group;

    const originalSphereRadius = 1;
    const sphereRadius = 3; // Increased from 2.5
    const totalCycleTime = 120000; // Increased to 120 seconds for slower rotation
    const pathDuration = 20000; // 20 seconds per path segment
    let currentPathIndex = 0;
    let currentPointIndex = 0;

    let cycleStartTime: number;
    let pathStartTime: number;
    let isDataLoaded = false;

    const travelPathCoords = [
      { name: 'London', lat: 51.5074, lon: -0.1278 },
      { name: 'New York', lat: 40.7128, lon: -74.006 },
      { name: 'Sydney', lat: -33.8688, lon: 151.2093 },
      { name: 'Tokyo', lat: 35.6762, lon: 139.6503 },
      { name: 'Israel', lat: 31.0461, lon: 34.8516 },
      { name: 'London', lat: 51.5074, lon: -0.1278 }, // Return to London
    ];

    let destinationMarkers: THREE.Group[] = [];
    const markerColors = [0xaff8c8, 0x24ec7e, 0x008664, 0x00423c];

    function init() {
      try {
        // Cleanup previous renderer properly
        if (rendererRef.current) {
          rendererRef.current.dispose();
          rendererRef.current.forceContextLoss();
          const gl = rendererRef.current.getContext();
          if (gl) {
            const ext = gl.getExtension('WEBGL_lose_context');
            if (ext) ext.loseContext();
          }
          rendererRef.current = null;
        }

        scene = new THREE.Scene();
        const width = mountRef.current?.clientWidth || window.innerWidth;
        const height = mountRef.current?.clientHeight || window.innerHeight;
        const aspectRatio = width / height;

        camera = new THREE.PerspectiveCamera(45, aspectRatio, 0.1, 1000);
        
        // Update initial camera positioning
        const isMobile = width < 768;
        camera.position.set(0, 0, isMobile ? 10 : 8); // Remove Y offset, keep camera centered
        camera.lookAt(0, 0, 0); // Look at center of scene

        // Create new renderer with context preservation
        const renderer = new THREE.WebGLRenderer({ 
          antialias: true, 
          alpha: true,
          powerPreference: 'high-performance',
          preserveDrawingBuffer: true // Add this
        });
        
        renderer.setSize(width, height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        mountRef.current?.appendChild(renderer.domElement);
        rendererRef.current = renderer;

        window.addEventListener('resize', onWindowResize, false);

        earthGroup = new THREE.Group();
        scene.add(earthGroup);

        setInitialRotation(travelPathCoords[0].lat, travelPathCoords[0].lon);

        // Initialize cycleStartTime and pathStartTime
        cycleStartTime = performance.now();
        pathStartTime = cycleStartTime;

        // Add resize observer for smoother resizing
        const resizeObserver = new ResizeObserver(() => {
          requestAnimationFrame(() => onWindowResize());
        });
        
        if (mountRef.current) {
          resizeObserver.observe(mountRef.current);
        }

        // Clean up resize observer in the cleanup function
        return () => {
          if (mountRef.current) {
            resizeObserver.unobserve(mountRef.current);
          }
          resizeObserver.disconnect();
        };
      } catch (error) {
        console.error('Error initializing WebGL:', error);
        if (onError) onError();
      }
    }

    function setInitialRotation(lat: number, lon: number) {
      const phi = THREE.MathUtils.degToRad(90 - lat);
      const theta = THREE.MathUtils.degToRad(lon);
      earthGroup.rotation.y = -theta;
    }

    function onWindowResize() {
      if (!mountRef.current) return;

      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      const aspectRatio = width / height;

      camera.aspect = aspectRatio;
      
      // Update camera position based on screen size while maintaining center
      const isMobile = width < 768;
      const distance = isMobile ? 10 : 8;
      
      // Keep camera centered but adjust distance
      camera.position.set(0, 0, distance);
      camera.lookAt(0, 0, 0);
      
      camera.updateProjectionMatrix();

      if (rendererRef.current) {
        rendererRef.current.setSize(width, height);
        rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      }
    }

    function addLights() {
      // Using ambient light for uniform illumination
      const ambientLight = new THREE.AmbientLight(0xffffff, 1.0); // Increased intensity
      scene.add(ambientLight);
    }

    function createGlobe() {
      const geometry = new THREE.SphereGeometry(sphereRadius, 64, 64);
      const material = new THREE.MeshBasicMaterial({
        color: 0xffffff, // White base color
        transparent: true, // Enable transparency
        opacity: 0.9, // Slightly transparent
        // Add a very subtle green tint
        emissive: 0xE6FFF2,
        emissiveIntensity: 0.1, // Very subtle intensity
      });
      globeMesh = new THREE.Mesh(geometry, material);
      earthGroup.add(globeMesh);
    }

    function createDestinationMarkers() {
      travelPathCoords.forEach((coord, index) => {
        if (index < travelPathCoords.length - 1) {
          const markerGroup = new THREE.Group();

          // Main dot
          const markerSize = 0.02 * (sphereRadius / originalSphereRadius); // Adjusted marker size for visibility
          const dotGeometry = new THREE.SphereGeometry(markerSize, 32, 32);
          const dotMaterial = new THREE.MeshBasicMaterial({ color: markerColors[0] });
          const dot = new THREE.Mesh(dotGeometry, dotMaterial);

          markerGroup.add(dot);

          const position = latLongToVector3(coord.lat, coord.lon, sphereRadius);
          markerGroup.position.copy(position);

          // Align marker to be tangent to the globe surface
          markerGroup.lookAt(new THREE.Vector3(0, 0, 0));

          earthGroup.add(markerGroup);
          destinationMarkers.push(markerGroup);
        }
      });
    }

    function createCountryDots() {
      try {
        // Scale positions based on the new sphereRadius
        const scaleFactor = sphereRadius / originalSphereRadius;
        const positions = countryDotsPositions.map((pos: number) => pos * scaleFactor);

        const dotsGeometry = new THREE.BufferGeometry();
        dotsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

        const dotSize = 0.01 * scaleFactor; // Adjust dot size based on globe size
        const dotsMaterial = new THREE.PointsMaterial({
          color: 0xaff8c8,
          size: dotSize,
        });

        countryDots = new THREE.Points(dotsGeometry, dotsMaterial);
        earthGroup.add(countryDots);

        isDataLoaded = true;
      } catch (error) {
        console.error('Error creating country dots:', error);
        if (onError) {
          onError(); // Notify parent of the error
        }
      }
    }

    function createFlightPath() {
      if (currentFlightPath) {
        earthGroup.remove(currentFlightPath);
        currentFlightPath.geometry.dispose();
        (currentFlightPath.material as THREE.Material).dispose();
      }

      const lineMaterial = new THREE.LineBasicMaterial({
        color: 0x24ec7e,
        linewidth: 2,
      });

      const from = latLongToVector3(travelPathCoords[currentPathIndex].lat, travelPathCoords[currentPathIndex].lon);
      const to = latLongToVector3(
        travelPathCoords[(currentPathIndex + 1) % travelPathCoords.length].lat,
        travelPathCoords[(currentPathIndex + 1) % travelPathCoords.length].lon
      );

      const points = generateGreatCirclePoints(from, to, 100, sphereRadius + 0.01);
      const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
      currentFlightPath = new THREE.Line(lineGeometry, lineMaterial);
      earthGroup.add(currentFlightPath);

   

      return { geometry: lineGeometry, points: points };
    }

    function generateGreatCirclePoints(
      start: THREE.Vector3,
      end: THREE.Vector3,
      numPoints: number,
      radius: number
    ) {
      const points: THREE.Vector3[] = [];
      const startNormal = start.clone().normalize();
      const endNormal = end.clone().normalize();

      const axis = new THREE.Vector3().crossVectors(startNormal, endNormal).normalize();
      const angle = Math.acos(startNormal.dot(endNormal));

      if (axis.length() < 1e-6) {
        axis.set(0, 1, 0);
      }

      for (let i = 0; i <= numPoints; i++) {
        const t = i / numPoints;
        const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, angle * t);
        const intermediatePoint = startNormal.clone().applyQuaternion(quaternion).multiplyScalar(radius);
        points.push(intermediatePoint);
      }
      return points;
    }

    function latLongToVector3(lat: number, lon: number, radiusOffset: number = 0) {
      const phi = ((90 - lat) * Math.PI) / 180;
      const theta = ((lon + 180) * Math.PI) / 180;

      const radius = sphereRadius + radiusOffset;

      const x = -radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.cos(phi);
      const z = radius * Math.sin(phi) * Math.sin(theta);

      return new THREE.Vector3(x, y, z);
    }

    let currentPath: { geometry: THREE.BufferGeometry; points: THREE.Vector3[] } | null = null;

    function animate(time: number) {
      if (isDisposed.current) return;
      
      animationFrameId.current = requestAnimationFrame(animate);
      
      try {
        if (rendererRef.current && scene && camera) {
          const elapsedTime = time - cycleStartTime;
          const elapsedPathTime = time - pathStartTime;
          
          // Slow down the rotation significantly
          earthGroup.rotation.y = (elapsedTime * 0.00005) + (Math.PI * 2 * (elapsedTime / totalCycleTime));

          if (isDataLoaded) {
            if (!currentPath || elapsedPathTime >= pathDuration) {
              if (currentPath) {
                // Reset previous destination marker color
                if (currentPathIndex > 0) {
                  (
                    (destinationMarkers[currentPathIndex - 1].children[0] as THREE.Mesh).material as THREE.MeshBasicMaterial
                  ).color.setHex(markerColors[0]);
                }
              }
              currentPathIndex = (currentPathIndex + 1) % (travelPathCoords.length - 1);
              currentPath = createFlightPath();
              currentPointIndex = 0;
              pathStartTime = time;

              if (currentPathIndex === 0 && elapsedTime > totalCycleTime) {
                cycleStartTime = time;
            
              }

              // Add console log for debugging
              console.log(`Moving to path ${currentPathIndex}: ${travelPathCoords[currentPathIndex].name} -> ${travelPathCoords[currentPathIndex + 1].name}`);
            }

            const pathProgress = elapsedPathTime / pathDuration;
            currentPointIndex = Math.floor(pathProgress * (currentPath.points.length - 1));

            const positions = currentPath.points
              .slice(0, currentPointIndex + 1)
              .flatMap((p) => [p.x, p.y, p.z]);
            currentPath.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            currentPath.geometry.attributes.position.needsUpdate = true;

            // Update current destination marker color
            if (destinationMarkers[currentPathIndex]) {
              ((destinationMarkers[currentPathIndex].children[0] as THREE.Mesh)
                .material as THREE.MeshBasicMaterial).color.setHex(markerColors[1]);
            }
          }

          rendererRef.current.render(scene, camera);
        }
      } catch (error) {
        console.error('Animation error:', error);
        if (onError) onError();
      }
    }

    // Initialize everything inside try-catch
    try {
      init();
      addLights();
      createGlobe();
      createDestinationMarkers();
      animate(performance.now());
      createCountryDots();
    } catch (error) {
      console.error('Error initializing WebGL content:', error);
      if (onError) onError();
    }

    // Cleanup function
    return () => {
      isDisposed.current = true;

      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      window.removeEventListener('resize', onWindowResize);

      // Cleanup Three.js objects
      if (scene) {
        scene.traverse((object) => {
          if (object instanceof THREE.Mesh || object instanceof THREE.Points) {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
              if (Array.isArray(object.material)) {
                object.material.forEach(material => material.dispose());
              } else {
                object.material.dispose();
              }
            }
          }
        });
      }

      // Properly dispose of the renderer
      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current.forceContextLoss();
        const gl = rendererRef.current.getContext();
        if (gl) {
          const ext = gl.getExtension('WEBGL_lose_context');
          if (ext) ext.loseContext();
        }
        if (rendererRef.current.domElement && rendererRef.current.domElement.parentNode) {
          rendererRef.current.domElement.parentNode.removeChild(rendererRef.current.domElement);
        }
        rendererRef.current = null;
      }
    };
  }, [onError]);

  return (
    <div className={`${className} relative`}>
      <div 
        ref={mountRef} 
        style={{ 
          width: '100%', 
          height: '100%',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          overflow: 'hidden'
        }} 
      />
    </div>
  );
};

export default GlobeWithoutRings;
