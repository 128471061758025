// src/components/headers/MainHeader.tsx

import React, { useContext, useState, useEffect } from 'react';
import {
  Search,
  Plus,
  Share,
  History,
  Bell,
  Menu,
} from 'lucide-react';
import { useSidebar } from '../../contexts/SidebarContext';
import logo from './logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../contexts/ModalContext';
import BankSettingsModalComponent from './settings/bank-settings-modal';
import RightSideMenuContext from '@/contexts/RightSideMenuContext';
import { Avatar, AvatarFallback, AvatarImage } from './crmui/avatar';
import { Button } from './crmui/button';
import { Input } from './crmui/input';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from './ui/dropdown-menu';

interface Notification {
  id: string;
  message: string;
  transactionId?: string;
  timestamp: Date; // Changed from string to Date
  read: boolean;
}

const PlaceholderAvatar = React.memo(() => (
  <svg
    className="w-10 h-10 text-gray-300 bg-gray-100 rounded-full"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
));

export function MainHeader() {
  const [searchQuery, setSearchQuery] = useState('');
  const { toggleSidebar, isSidebarOpen } = useSidebar();
  const { events = [], fetchEvents, logout, user, loading, error } = useAuth();
  const navigate = useNavigate();
  const { setModal } = useModal();
  const { isOpen, openMenu, closeMenu, toggleMenu } = useContext(RightSideMenuContext);

  // Log events to verify data
  useEffect(() => {
    console.log('MainHeader: Current Events:', events);
  }, [events]);

  // Fetch notifications on component mount
  useEffect(() => {
    if (events.length === 0 && !error) {
      fetchEvents();
    }
  }, [events, fetchEvents, error]);

  // Handler to open the right side menu
  const handleOpenRightSideMenu = () => {
    openMenu();
    console.log('Right Side Menu Opened');
  };

  // Handler for search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Handler for user logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, display an error message to the user
    }
  };

  // Handler to toggle the main sidebar
  const handleMenuToggle = () => {
    toggleSidebar();
    if (isOpen) closeMenu();
  };

  // Handler to open settings modal
  const openSettingsModal = () => {
    setModal({
      isOpen: true,
      title: 'Settings',
      content: <BankSettingsModalComponent />,
      steps: [
        { label: 'Profile', status: 'current' },
        { label: 'Security', status: 'upcoming' },
        { label: 'Activity', status: 'upcoming' },
      ],
    });
  };

  // Handler for notification clicks
  const handleNotificationClick = (notification: Notification) => {
    if (!notification.timestamp) {
      console.warn(`Notification ${notification.id} is missing a timestamp.`);
      return;
    }

    console.log('Notification clicked:', notification.id);

    // Mark notification as read
    // TODO: Implement backend update if necessary
    console.log(`Marking notification ${notification.id} as read.`);

    // Navigate to transaction details if transactionId exists
    if (notification.transactionId) {
      console.log('Navigating to transactions with ID:', notification.transactionId);
      navigate('/transactions', {
        state: {
          highlightTransactionId: notification.transactionId,
          timestamp: Date.now(),
        },
      });
    }
  };

  // Function to determine if a menu item's URL is active
  const isActive = (url?: string): boolean => {
    if (!url) return false;
    if (url === '/home' && navigate.location.pathname === '/') return true;
    return navigate.location.pathname === url;
  };

  // Update headerClasses by removing fixed positioning and z-index
  const headerClasses = `bg-white shadow-none w-full h-18 flex justify-between items-center px-4 sm:px-6 lg:px-8 transition-all duration-300`;

  return (
    <>
      <header className={headerClasses} style={{ height: '72px' }}>
        {/* Left Section */}
        <div className="flex items-center space-x-4 md:space-x-6 flex-grow ">
          {/* Sidebar Toggle Button */}
          <button
            onClick={handleMenuToggle}
            className="lg:hidden text-gray-600 focus:outline-none"
            aria-label="Toggle Sidebar"
          >
            <Menu className="w-6 h-6" />
          </button>

          {/* Logo Container */}
          <div className="flex items-center justify-start h-full py-4">
            <img src={logo} alt="BAT Client Dashboard" className="h-10 max-h-full object-contain" />
          </div>

          {/* Search Bar (Hidden When Right Side Menu is Open) */}
          {!isOpen && (
            <div className="hidden md:flex relative flex-grow max-w-2xl ml-4 md:ml-8">
              <Input 
                id="search"
                type="search" 
                placeholder="Search..." 
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-full leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent sm:text-sm"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button
                style={{ height: '100%' }}
                className="absolute right-0 top-0 px-4 py-2 text-gray-600 bg-gray-100 border border-l-0 border-gray-300 rounded-r-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                aria-label="Search"
              >
                <Search className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>

        {/* Right Section */}
        <div className="flex items-center space-x-4 md:space-x-6 $'isopen'mr-80">
          {/* Quick Create Button (Hidden When Right Side Menu is Open) */}
          {!isOpen && (
            <button className="hidden md:flex items-center px-4 py-2 text-base font-medium text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
              <Plus className="inline-block w-5 h-5 mr-2" />
              <span>Quick Create</span>
            </button>
          )}

          {/* Icons */}
          <div className="flex items-center space-x-3 md:space-x-4">
            <button
              className="text-gray-600 hover:text-gray-800 focus:outline-none"
              aria-label="Share"
            >
              <Share className="w-6 h-6" />
            </button>
            <button
              className="text-gray-600 hover:text-gray-800 focus:outline-none"
              aria-label="History"
            >
              <History className="w-6 h-6" />
            </button>

            {/* Notification Dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" aria-label="Notifications" className="relative">
                  <Bell className="h-5 w-5" />
                  {/* Only show notification count if events exist and are not the placeholder */}
                  {Array.isArray(events) && events.length > 0 && events[0]?.id !== 'no_events' && (
                    <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center">
                      {events.length}
                    </span>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-80">
                <DropdownMenuLabel>Notifications</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <div className="max-h-60 overflow-y-auto">
                  {events.length === 0 ? (
                    <div className="p-4 text-sm text-gray-500">No notifications</div>
                  ) : (
                    events.map((event) => (
                      <DropdownMenuItem
                        key={event.id}
                        onClick={() => handleNotificationClick(event)}
                        className={`p-3 ${event.read ? 'opacity-60' : ''}`}
                      >
                        <div className="flex justify-between items-center">
                          <span>{event.message}</span>
                          <span className="text-xs text-gray-500 ml-2">
                            {event.timestamp
                              ? event.timestamp.toLocaleTimeString()
                              : 'N/A'}
                          </span>
                        </div>
                      </DropdownMenuItem>
                    ))
                  )}
                </div>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => navigate('/notifications')} className="text-center text-blue-600">
                  View All Notifications
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* User Button to Open Right Side Menu */}
          <Button
            variant="ghost"
            size="sm"
            className="flex items-center space-x-2"
            onClick={handleOpenRightSideMenu}
            aria-label="Open Right Sidebar"
          >
            <Avatar className="h-8 w-8">
              <AvatarImage src={user?.avatar || "/placeholder.svg"} alt={user?.name || "User"} />
              <AvatarFallback>{user?.name ? user.name.charAt(0).toUpperCase() : 'U'}</AvatarFallback>
            </Avatar>
            <span className="hidden sm:inline-block font-medium">{user?.name || 'User'}</span>
          </Button>
        </div>
      </header>

      {/* Error Message Display */}
      {error && (
        <div className="mt-20 p-4 bg-red-100 text-red-700 rounded-md">
          <p>Failed to load notifications. Please try again later.</p>
        </div>
      )}
    </>
  );
}

export default MainHeader;
