import { Plus } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom';


export default function Component() {
    const navigate = useNavigate();
  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="space-y-4">
        <h1 className="text-xl font-semibold">Scheduled transfers</h1>
        
        <div className="flex items-center justify-between p-4 rounded-full border bg-white shadow-sm">
          <button 
            onClick={() => navigate('/send-payment')}
            style={{
              backgroundColor: 'white',
              color: '#666666',
              border: 'none',
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer'
            }}
          >
            <Plus className="w-4 h-4" />
            Schedule a new transfer
          </button>
          
          <button
            style={{
              backgroundColor: '#24EC7E',
              color: 'white',
              border: 'none',
              padding: '8px 24px',
              borderRadius: '100px',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer'
            }}
          >
            Schedule
          </button>
        </div>

        <div className="bg-[#AFF8C8]/10 rounded-lg p-4 space-y-1">
          <h2 className="font-medium text-sm">Never miss any important transfer</h2>
          <p className="text-sm text-gray-600">
            Schedule, view and manage any recurring or upcoming payments
          </p>
        </div>

        {/* Empty state or list of scheduled transfers would go here */}
        <div className="py-8 text-center text-gray-500 text-sm">
          No scheduled transfers yet
        </div>
      </div>
    </div>
  )
}