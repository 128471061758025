import React from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '../ui/context-menu';
import { Wallet } from '../../../../types';
import { useAuth } from '../../../../contexts/AuthContext';

interface CurrencyCardContextMenuProps {
  children: React.ReactNode;
  wallet: Wallet;
  onSetAsMainWallet: (wallet: Wallet) => void;
}

export function CurrencyCardContextMenu({
  children,
  wallet,
  onSetAsMainWallet,
}: CurrencyCardContextMenuProps) {
  const { user } = useAuth();
  const isActiveWallet = user?.activeWallet === wallet.currency;

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>
        {children}
      </ContextMenuTrigger>
      <ContextMenuContent className="w-48">
        <ContextMenuItem
          onClick={() => onSetAsMainWallet(wallet)}
          disabled={isActiveWallet}
          className={isActiveWallet ? 'text-gray-400 cursor-not-allowed' : 'cursor-pointer'}
        >
          {isActiveWallet ? 'Current Active Wallet' : 'Set as Active Wallet'}
        </ContextMenuItem>
        {/* Add more context menu items here as needed */}
      </ContextMenuContent>
    </ContextMenu>
  );
} 