// StatementsPages.tsx

import { useState } from 'react'
import { ChevronLeft, Download, ArrowDown } from 'lucide-react'
import { CurrencySelect, Currency, currencies } from '@/components/ui/CurrencySelect'
import { useAuth } from '@/contexts/AuthContext'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Reusable components
const DatePicker = ({ label }: { label: string }) => (
  <div className="flex flex-col gap-2">
    <label className="text-sm text-gray-600">{label}</label>
    <div className="relative">
      <input
        type="date"
        className="p-2 border rounded-lg w-full"
        defaultValue="2024-05-14"
      />
    </div>
  </div>
)


interface DownloadButtonProps {
  text?: string;
  onClick: () => void;
}



// Main component
export function StatementsPages() {
  const auth = useAuth()
  const [currentView, setCurrentView] = useState<'overview' | 'statement' | 'fees' | 'confirmation' | 'audit'>('overview')

  const PageWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className="min-h-screen bg-white">
      {currentView !== 'overview' && (
        <div className="border-b mb-6">
          <div className="max-w-2xl mx-auto px-6 h-[72px] flex items-center">
            <button 
              onClick={() => setCurrentView('overview')}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors"
            >
              <ChevronLeft className="w-5 h-5" />
              Back to statements
            </button>
          </div>
        </div>
      )}
      <div className="max-w-2xl mx-auto px-6 py-6"> {/* Updated padding */}
        {children}
      </div>
    </div>
  )

  const StatementOverview = () => (
    <div className="space-y-8">
      <h1 className="text-2xl font-semibold mb-4">Statements</h1>
      
      <div>
        <h2 className="text-lg font-medium mb-4">Transactions and reports</h2>
        <div className="space-y-4">
          {[
            {
              title: 'Transaction history',
              description: 'Access an official record or transactions for any of your balances or accounts.',
              onClick: () => setCurrentView('statement')
            },
            {
              title: 'Fee overview',
              description: 'View a detailed summary on fees paid on your account',
              onClick: () => setCurrentView('fees')
            }
          ].map((item, i) => (
            <button
              key={i}
              onClick={item.onClick}
              className="w-full flex items-center justify-between p-4 bg-white hover:bg-gray-50 rounded-lg border border-gray-100 transition-colors"
            >
              <div className="flex items-center gap-4">
                <div className="w-10 h-10 bg-gray-50 rounded-full flex items-center justify-center">
                  <ArrowDown className="w-5 h-5 text-gray-600" />
                </div>
                <div className="text-left">
                  <h3 className="font-medium text-gray-900">{item.title}</h3>
                  <p className="text-sm text-gray-600">{item.description}</p>
                </div>
              </div>
              <ChevronLeft className="w-5 h-5 text-gray-400 rotate-180" />
            </button>
          ))}
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-4">Report and Account confirmation</h2>
        <div className="space-y-4">
          {[
            {
              title: 'Financial audit report',
              description: 'Receive an official report with specific financial details for auditing purposes',
              onClick: () => setCurrentView('audit')
            },
            {
              title: 'Account confirmation',
              description: 'Download a document confirming your ownership of this PaayCo account.',
              onClick: () => setCurrentView('confirmation')
            }
          ].map((item, i) => (
            <button
              key={i}
              onClick={item.onClick}
              className="w-full flex items-center justify-between p-4 bg-white hover:bg-gray-50 rounded-lg border border-gray-100 transition-colors"
            >
              <div className="flex items-center gap-4">
                <div className="w-10 h-10 bg-gray-50 rounded-full flex items-center justify-center">
                  <ArrowDown className="w-5 h-5 text-gray-600" />
                </div>
                <div className="text-left">
                  <h3 className="font-medium text-gray-900">{item.title}</h3>
                  <p className="text-sm text-gray-600">{item.description}</p>
                </div>
              </div>
              <ChevronLeft className="w-5 h-5 text-gray-400 rotate-180" />
            </button>
          ))}
        </div>
      </div>
    </div>
  )

  const handleDownload = async () => {
    try {
      await auth.downloadStatement()
      alert('Statement downloaded successfully.')
    } catch (error) {
      console.error('Download failed:', error)
      alert('Failed to download statement.')
    }
  }

  const handleDownloadFees = async () => {
    try {
      await auth.downloadFeesReport()
      alert('Fees report downloaded successfully.')
    } catch (error) {
      console.error('Fees download failed:', error)
      alert('Failed to download fees report.')
    }
  }

  const handleDownloadConfirmation = async () => {
    try {
      await auth.downloadAccountConfirmation()
      alert('Account confirmation downloaded successfully.')
    } catch (error) {
      console.error('Account confirmation download failed:', error)
      alert('Failed to download account confirmation.')
    }
  }

  const handleDownloadAuditReport = async () => {
    try {
      await auth.downloadAuditReport()
      alert('Financial audit report downloaded successfully.')
    } catch (error) {
      console.error('Audit report download failed:', error)
      alert('Failed to download financial audit report.')
    }
  }

  const StatementDetails = () => {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencies.find(c => c.code === 'GBP')!)

    const generatePDF = () => {
      const doc = new jsPDF();

      // Document Title
      doc.setFontSize(18);
      doc.text('Transaction Report', 14, 22);

      // Table Headers
      const tableColumn = ["Type", "Date", "Description", "Paid In", "Paid Out", "Balance"];
      
      // Table Rows
      const tableRows: Array<Array<string | number>> = auth.statements.flatMap(statement =>
        statement.transactions.map(tx => [
          tx.type,
          new Date(tx.date).toLocaleDateString(),
          tx.description,
          tx.paidIn || '-',
          tx.paidOut || '-',
          `${tx.balance.amount} ${tx.balance.currency}`
        ])
      );

      // Add AutoTable
      (doc as any).autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: { fontSize: 10 },
        headStyles: { fillColor: [36, 236, 126] }, // Green header
        alternateRowStyles: { fillColor: [240, 248, 255] }, // Light blue
      });

      // Save the PDF
      doc.save('transactions_report.pdf');
    };

    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-semibold mb-4">Statement details</h1>
        <p className="text-gray-600">
          PaayCo allows you to generate statements up to one year. Kindly download multiple statements if you want an extended timeframe.
        </p>
        
        <div className="grid grid-cols-2 gap-4">
          <DatePicker label="Date (from)" />
          <DatePicker label="Date (to)" />
        </div>

        

        <div className="space-y-2">
          <label className="text-sm text-gray-600">Select Account</label>
          <CurrencySelect selectedCurrency={selectedCurrency} onSelect={setSelectedCurrency} />
        </div>
        
        <div className="space-y-2">
          <label className="text-sm text-gray-600">File format</label>
          <select className="p-2 border rounded-lg w-full">
            <option>PDF</option>
          </select>
        </div>
        
        <div className="space-y-2">
          <label className="text-sm text-gray-600">Language preference</label>
          <select className="p-2 border rounded-lg w-full">
            <option>English (UK)</option>
          </select>
        </div>
        
        <div className="space-y-3">
          <label className="flex items-center gap-2">
            <input type="checkbox" className="rounded border-gray-300" />
            <span className="text-sm text-gray-600">View transactions with fee breakdown</span>
          </label>
          <label className="flex items-center gap-2">
            <input type="checkbox" className="rounded border-gray-300" />
            <span className="text-sm text-gray-600">Add official stamp and signature</span>
          </label>
        </div>
        
       
        <button
          onClick={generatePDF}
          style={{
            backgroundColor: '#24EC7E',
            color: 'white',
            border: 'none',
            padding: '8px 16px',
            borderRadius: '100px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: 500,
            cursor: 'pointer',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Download size={20} />
          Download Statements PDF
        </button>
      </div>
    )
  }

  const FeesOverview = () => {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencies.find(c => c.code === 'GBP')!)

    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-semibold mb-4">Fees overview</h1>
        <p className="text-gray-600">
          PaayCo allows you to generate statements up to one year. Kindly download multiple statements if you want an extended timeframe.
        </p>
        
        <div className="grid grid-cols-2 gap-4">
          <DatePicker label="Date (from)" />
          <DatePicker label="Date (to)" />
        </div>
        <div className="space-y-2">
          <label className="text-sm text-gray-600">Select Account</label>
          <CurrencySelect selectedCurrency={selectedCurrency} onSelect={setSelectedCurrency} />
        </div>
    

        <div className="space-y-2">
          <label className="text-sm text-gray-600">Select Account</label>
          <CurrencySelect selectedCurrency={selectedCurrency} onSelect={setSelectedCurrency} />
        </div>

        <DownloadButton onClick={handleDownloadFees} />
      </div>
    )
  }

  const AccountConfirmation = () => {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencies.find(c => c.code === 'GBP')!)

    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-semibold mb-4">Account confirmation</h1>
        <p className="text-gray-600">
          A document that verifies your ownership of this PaayCo account, detailing your account holder name and account information.
        </p>
        
        <div className="grid grid-cols-2 gap-4">
          <DatePicker label="Date (from)" />
          <DatePicker label="Date (to)" />
        </div>
        
   

        <div className="space-y-2">
          <label className="text-sm text-gray-600">Select Account</label>
          <CurrencySelect selectedCurrency={selectedCurrency} onSelect={setSelectedCurrency} />
        </div>
        
        <label className="flex items-center gap-2">
          <input type="checkbox" className="rounded border-gray-300" />
          <span className="text-sm text-gray-600">Add official stamp and signature</span>
        </label>
        
        <DownloadButton onClick={handleDownloadConfirmation} />
      </div>
    )
  }

  const FinancialAuditReport = () => {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencies.find(c => c.code === 'GBP')!)

    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-semibold mb-4">Financial audit report</h1>
        <p className="text-gray-600">
          Receive an official report with specific financial details for auditing purposes.
        </p>

        <div className="grid grid-cols-2 gap-4">
          <DatePicker label="Date (from)" />
          <DatePicker label="Date (to)" />
        </div>

        <AccountSelect />

        <div className="space-y-2">
          <label className="text-sm text-gray-600">Select Account</label>
          <CurrencySelect selectedCurrency={selectedCurrency} onSelect={setSelectedCurrency} />
        </div>

        <DownloadButton onClick={handleDownloadAuditReport} />
      </div>
    )
  }

  const views = {
    overview: <StatementOverview />,
    statement: <StatementDetails />,
    fees: <FeesOverview />,
    confirmation: <AccountConfirmation />,
    audit: <FinancialAuditReport />
  }

  return (
    <PageWrapper>
      {views[currentView]}
    </PageWrapper>
  )
}

export default StatementsPages;
