// src/components/CardOrderFlow.tsx

import React, { useState } from 'react';
import { Check, ChevronLeft, Upload, Copy } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Card3DViewer from "./Card3DViewer";
import GoogleLogo from './images/Google.svg';
import AppleLogo from './images/AppleLogo.svg';

interface CardOrderData {
  cardType: 'physical' | 'virtual' | null;
  cardHolder: string;
  cardLabel: string;
  logoImage: string | null; // Changed from File | null to string | null
  color: 'green' | 'neon' | 'white' | 'black';
  shippingDetails: {
    fullName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    country: string;
  };
}

export function CardOrderFlow() {
  const [step, setStep] = useState<'type' | 'customize' | 'shipping' | 'confirm'>('type');
  const [orderData, setOrderData] = useState<CardOrderData>({
    cardType: null,
    cardHolder: '',
    cardLabel: '',
    logoImage: null, // Initialize as null
    color: 'green',
    shippingDetails: {
      fullName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      country: ''
    }
  });

  // Helper function to convert File to Base64
  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') resolve(reader.result);
        else reject('Failed to convert file to Base64.');
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleLogoUpload = async (file: File) => {
    try {
      const base64 = await convertFileToBase64(file);
      setOrderData(prev => ({ ...prev, logoImage: base64 }));
      console.log('Logo uploaded and converted to Base64.');
    } catch (error) {
      console.error('Error converting file to Base64:', error);
      alert('Failed to upload logo. Please try again.');
    }
  };

  const renderCardTypeSelection = () => (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-12">Select your preference</h1>
      
      <div className="grid md:grid-cols-2 gap-8">
        {/* Physical Card Option */}
        <div 
          className={`p-8 rounded-xl border-2 cursor-pointer transition-all ${
            orderData.cardType === 'physical' 
              ? 'border-[#24EC7E] bg-[#24EC7E]/5' 
              : 'border-gray-200 hover:border-gray-300'
          }`}
          onClick={() => setOrderData(prev => ({ ...prev, cardType: 'physical' }))}
        >
          <h2 className="text-2xl font-semibold mb-4">Physical Card</h2>
          <p className="text-gray-600 mb-8">
            Get a tangible card mailed to your address. Perfect for in-person transactions and ATM withdrawals.
          </p>
          <ul className="space-y-4">
            {[
              'Use at physical stores',
              'ATM withdrawals',
              'Online purchases',
              'Contactless payments'
            ].map((feature, index) => (
              <li key={index} className="flex items-center gap-3">
                <Check className="w-5 h-5 text-[#24EC7E]" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Virtual Card Option */}
        <div 
          className={`p-8 rounded-xl border-2 cursor-pointer transition-all ${
            orderData.cardType === 'virtual' 
              ? 'border-[#24EC7E] bg-[#24EC7E]/5' 
              : 'border-gray-200 hover:border-gray-300'
          }`}
          onClick={() => setOrderData(prev => ({ ...prev, cardType: 'virtual' }))}
        >
          <h2 className="text-2xl font-semibold mb-4">Virtual Card</h2>
          <p className="text-gray-600 mb-8">
            Instantly available for online purchases. Can be added to your digital wallet for contactless payments.
          </p>
          <ul className="space-y-4">
            {[
              'Instant activation',
              'Online purchases',
              'Digital wallet integration',
              'Supports Google Pay',
              'Supports Apple Pay'
            ].map((feature, index) => (
              <li key={index} className="flex items-center gap-3">
                <Check className="w-5 h-5 text-[#24EC7E]" />
                <span className="flex items-center gap-2">
                  {feature}
                  {feature.includes('Google') && (
                    <GoogleLogo className="h-5 w-5" />
                  )}
                  {feature.includes('Apple') && (
                    <AppleLogo className="h-5 w-5" />
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex justify-between mt-12">
        <Button
          variant="ghost"
          className="bg-[#F5F5F5] text-[#666666] hover:bg-gray-100 px-8 py-3 rounded-full font-medium text-sm"
          onClick={() => setStep('type')}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setStep('customize')}
          disabled={!orderData.cardType}
          className="bg-[#24EC7E] hover:bg-[#20d471] text-black px-8 py-3 rounded-full font-medium text-sm"
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const renderCustomizeCard = () => (
    <div className="min-h-screen bg-white p-6">
      <button 
        onClick={() => setStep('type')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors mb-6"
      >
        <ChevronLeft className="w-5 h-5" />
        Back
      </button>

      <h1 className="text-3xl font-bold mb-8">Customize your card</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
        <div className="space-y-8">
          <div>
            <h2 className="text-lg font-semibold mb-2">Card Holder</h2>
            <Input
              type="text"
              placeholder="Enter name of card holder"
              value={orderData.cardHolder}
              onChange={(e) => setOrderData(prev => ({ ...prev, cardHolder: e.target.value }))}
              className="w-full"
            />
          </div>

          <div>
            <h2 className="text-lg font-semibold mb-2">Card Label <span className="text-gray-500 font-normal">(Optional)</span></h2>
            <Input
              type="text"
              placeholder="Enter label for your card"
              value={orderData.cardLabel}
              onChange={(e) => setOrderData(prev => ({ ...prev, cardLabel: e.target.value }))}
              className="w-full"
            />
          </div>

          <div>
            <h2 className="text-lg font-semibold mb-2">Add a logo</h2>
            <div
              className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files?.[0];
                if (file && file.type.startsWith('image/')) {
                  handleLogoUpload(file);
                } else {
                  alert('Please upload a valid image file.');
                }
              }}
            >
              <input
                type="file"
                id="logo-upload"
                className="hidden"
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file && file.type.startsWith('image/')) {
                    await handleLogoUpload(file);
                  } else {
                    alert('Please upload a valid image file.');
                  }
                }}
                accept="image/*"
              />
              <label
                htmlFor="logo-upload"
                className="flex flex-col items-center gap-2 cursor-pointer"
              >
                <p className="text-gray-500">
                  Drag file here or{' '}
                  <span className="text-emerald-600 hover:text-emerald-700">click to upload</span>
                </p>
                <Upload className="w-5 h-5 text-gray-400" />
              </label>
              {orderData.logoImage && (
                <p className="mt-4 text-green-600">Logo uploaded successfully!</p>
              )}
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold mb-4">Select your preferred colour</h2>
            <div className="flex gap-4">
              {[
                { id: 'green', bg: 'bg-emerald-800' },
                { id: 'neon', bg: 'bg-[#24EC7E]' },
                { id: 'white', bg: 'bg-white border-2' },
                { id: 'black', bg: 'bg-black' },
              ].map((color) => (
                <button
                  key={color.id}
                  onClick={() => setOrderData(prev => ({ ...prev, color: color.id as CardOrderData['color'] }))}
                  className={`w-20 h-12 rounded-lg ${color.bg} ${
                    orderData.color === color.id ? 'ring-2 ring-offset-2 ring-emerald-500' : ''
                  }`}
                >
                  <div className={`w-full h-full flex items-center justify-center text-xs ${color.id === 'white' ? 'text-black' : 'text-white'}`}>
                    PAAYCO
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4">Card Previdfdsew</h2>
          <Card3DViewer
            selectedTexture={getTextureName(orderData.color)}
            logoImage={orderData.logoImage}
            cardName={orderData.cardHolder}
            cardTitle={orderData.cardLabel}
            cardCategory="Premium"
            logoProperties={{
              scale: 1,
              maintainAspectRatio: true,
              isFlipped: false
            }}
          />
        </div>
      </div>

      <div className="flex justify-between mt-12 max-w-6xl mx-auto">
        <Button
          variant="ghost"
          className="bg-[#F5F5F5] text-[#666666] hover:bg-gray-100 px-8 py-3 rounded-full font-medium text-sm"
          onClick={() => setStep('type')}
        >
          Back
        </Button>
        <Button
          onClick={() => setStep(orderData.cardType === 'physical' ? 'shipping' : 'confirm')}
          className="bg-[#24EC7E] hover:bg-[#20d471] text-black px-8 py-3 rounded-full font-medium text-sm"
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const renderShippingForm = () => (
    <div className="max-w-2xl mx-auto p-6">
      <button 
        onClick={() => setStep('customize')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors mb-6"
      >
        <ChevronLeft className="w-5 h-5" />
        Back
      </button>

      <h1 className="text-3xl font-bold mb-8">Provide your shipping details</h1>

      <form className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Full Name
          </label>
          <Input
            placeholder="Enter your full name"
            value={orderData.shippingDetails?.fullName || ''}
            onChange={(e) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                fullName: e.target.value
              }
            }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Address Line 1
          </label>
          <Input
            placeholder="Enter your address"
            value={orderData.shippingDetails?.addressLine1 || ''}
            onChange={(e) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                addressLine1: e.target.value
              }
            }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Address Line 2 <span className="text-gray-500">(Optional)</span>
          </label>
          <Input
            placeholder="Enter apartment number, building number, etc"
            value={orderData.shippingDetails?.addressLine2 || ''}
            onChange={(e) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                addressLine2: e.target.value
              }
            }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            City/Town
          </label>
          <Input
            placeholder="Enter name of your city"
            value={orderData.shippingDetails?.city || ''}
            onChange={(e) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                city: e.target.value
              }
            }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Postal Code
          </label>
          <Input
            placeholder="Enter your postal code"
            value={orderData.shippingDetails?.postalCode || ''}
            onChange={(e) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                postalCode: e.target.value
              }
            }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Country
          </label>
          <Select
            value={orderData.shippingDetails?.country || ''}
            onValueChange={(value) => setOrderData(prev => ({
              ...prev,
              shippingDetails: {
                ...prev.shippingDetails,
                country: value
              }
            }))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select your country" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="uk">United Kingdom</SelectItem>
              <SelectItem value="us">United States</SelectItem>
              <SelectItem value="ca">Canada</SelectItem>
              {/* Add more countries as needed */}
            </SelectContent>
          </Select>
        </div>
      </form>

      <div className="flex justify-between mt-12">
        <Button
          variant="ghost"
          className="bg-[#F5F5F5] text-[#666666] hover:bg-gray-100 px-8 py-3 rounded-full font-medium text-sm"
          onClick={() => setStep('customize')}
        >
          Back
        </Button>
        <Button
          onClick={() => setStep('confirm')}
          className="bg-[#24EC7E] hover:bg-[#20d471] text-black px-8 py-3 rounded-full font-medium text-sm"
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const renderConfirmation = () => (
    <div className="max-w-6xl mx-auto p-6">
      <button 
        onClick={() => setStep(orderData.cardType === 'physical' ? 'shipping' : 'customize')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors mb-6"
      >
        <ChevronLeft className="w-5 h-5" />
        Back
      </button>

      <h1 className="text-3xl font-bold mb-8">Confirm details</h1>

      <div className="grid md:grid-cols-2 gap-12">
        <div className="space-y-6">
          <div className="flex items-center justify-between group">
            <div>
              <h3 className="text-sm text-gray-500">Card holder</h3>
              <p className="font-medium">{orderData.cardHolder || 'Not specified'}</p>
            </div>
            <button className="opacity-0 group-hover:opacity-100 transition-opacity">
              <Copy className="w-4 h-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>

          <div className="flex items-center justify-between group">
            <div>
              <h3 className="text-sm text-gray-500">Card label</h3>
              <p className="font-medium">{orderData.cardLabel || 'None'}</p>
            </div>
            <button className="opacity-0 group-hover:opacity-100 transition-opacity">
              <Copy className="w-4 h-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>

          <div className="flex items-center justify-between group">
            <div>
              <h3 className="text-sm text-gray-500">Logo</h3>
              <p className="font-medium">{orderData.logoImage ? 'Uploaded' : 'None'}</p>
            </div>
            <button className="opacity-0 group-hover:opacity-100 transition-opacity">
              <Copy className="w-4 h-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>

          <div className="flex items-center justify-between group">
            <div>
              <h3 className="text-sm text-gray-500">Your preferred colour</h3>
              <div className={`w-20 h-12 rounded-lg mt-2 flex items-center justify-center text-white text-xs ${
                orderData.color === 'green' ? 'bg-emerald-800' :
                orderData.color === 'neon' ? 'bg-[#24EC7E]' :
                orderData.color === 'white' ? 'bg-white border text-black' :
                'bg-black'
              }`}>
                PAAYCO
              </div>
            </div>
            <button className="opacity-0 group-hover:opacity-100 transition-opacity">
              <Copy className="w-4 h-4 text-gray-400 hover:text-gray-600" />
            </button>
          </div>

          {orderData.cardType === 'physical' && orderData.shippingDetails && (
            <div className="flex items-start justify-between group">
              <div>
                <h3 className="text-sm text-gray-500">Shipping Address</h3>
                <p className="font-medium">{orderData.shippingDetails.fullName}</p>
                <p className="text-sm text-gray-600">{orderData.shippingDetails.addressLine1}</p>
                {orderData.shippingDetails.addressLine2 && (
                  <p className="text-sm text-gray-600">{orderData.shippingDetails.addressLine2}</p>
                )}
                <p className="text-sm text-gray-600">
                  {orderData.shippingDetails.city}, {orderData.shippingDetails.postalCode}
                </p>
                <p className="text-sm text-gray-600">{orderData.shippingDetails.country}</p>
              </div>
              <button className="opacity-0 group-hover:opacity-100 transition-opacity">
                <Copy className="w-4 h-4 text-gray-400 hover:text-gray-600" />
              </button>
            </div>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4">Card Prevdsfsdiew</h2>
          <Card3DViewer
            selectedTexture={getTextureName(orderData.color)}
            logoImage={orderData.logoImage}
            cardName={orderData.cardHolder}
            cardTitle={orderData.cardLabel}
            cardCategory="Premium"
            logoProperties={{
              scale: 1,
              maintainAspectRatio: true,
              isFlipped: false
            }}
          />
        </div>
      </div>

      <div className="mt-12 text-center text-gray-600">
        By clicking on "Order now", you agree to Paayco's{' '}
        <a href="#" className="underline hover:text-gray-900">
          terms and conditions
        </a>
        .
      </div>

      <div className="flex justify-between mt-6">
        <Button
          variant="ghost"
          className="bg-[#F5F5F5] text-[#666666] hover:bg-gray-100 px-8 py-3 rounded-full font-medium text-sm"
          onClick={() => setStep(orderData.cardType === 'physical' ? 'shipping' : 'customize')}
        >
          Back
        </Button>
        <Button
          onClick={() => handleOrderNow()} // Implement handleOrderNow as needed
          className="bg-[#24EC7E] hover:bg-[#20d471] text-black px-8 py-3 rounded-full font-medium text-sm"
        >
          Order now
        </Button>
      </div>
    </div>
  );

  const getTextureName = (color: CardOrderData['color']): string => {
    switch(color) {
      case 'green':
        return 'paaycogreen.png';
      case 'neon':
        return 'paaycoglneon.png';
      case 'white':
        return 'paaycowhite.png';
      case 'black':
        return 'paaycoblack.png';
      default:
        return 'paaycowhite.png';
    }
  };

  const handleOrderNow = () => {
    // Implement your order submission logic here
    alert('Order placed successfully!');
  };

  return (
    <div className="min-h-screen bg-white">
      {step === 'type' && renderCardTypeSelection()}
      {step === 'customize' && renderCustomizeCard()}
      {step === 'shipping' && renderShippingForm()}
      {step === 'confirm' && renderConfirmation()}
    </div>
  );
}
