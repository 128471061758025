import { useState, useCallback } from 'react';
import { Beneficiary } from './types';
import api from '@/services/api';

interface UseAuthBeneficiariesProps {
  logout: () => void;
  onFetch?: () => Promise<void>;
}

export const useAuthBeneficiaries = ({ logout, onFetch }: UseAuthBeneficiariesProps) => {
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBeneficiaries = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      if (onFetch) {
        await onFetch();
      }
    } catch (err: any) {
      console.error('Error fetching beneficiaries:', err);
      setError(err.message || 'Failed to fetch beneficiaries');
      if (err.response?.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [logout, onFetch]);

  const addBeneficiary = useCallback(async (data: Omit<Beneficiary, 'id'>) => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.addBeneficiary(data);
      
      // Map the response to match our Beneficiary interface
      const newBeneficiary: Beneficiary = {
        id: response.id.toString(),
        beneficiary_first_name: response.beneficiary_first_name,
        beneficiary_last_name: response.beneficiary_last_name,
        beneficiary_country: response.beneficiary_country,
        beneficiary_currency: response.beneficiary_currency,
        beneficiary_details_type: response.beneficiary_details_type,
        beneficiary_iban: response.beneficiary_iban,
        beneficiary_bic: response.beneficiary_bic,
        isFavorite: false
      };

      setBeneficiaries(prev => [...prev, newBeneficiary]);
      return newBeneficiary;
    } catch (err: any) {
      console.error('Error adding beneficiary:', err);
      setError(err.message || 'Failed to add beneficiary');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleFavorite = useCallback((id: string) => {
    setBeneficiaries(prev => 
      prev.map(b => 
        b.id === id ? { ...b, isFavorite: !b.isFavorite } : b
      )
    );
  }, []);

  return {
    beneficiaries,
    setBeneficiaries,
    fetchBeneficiaries,
    addBeneficiary,
    toggleFavorite,
    loading,
    error,
  };
}; 