import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Copy, QrCode, LinkIcon, ChevronRight } from 'lucide-react'

const buttonStyles = {
  primary: {
    backgroundColor: '#24EC7E',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer'
  }
}

const listItemStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    cursor: 'pointer',
    borderRadius: '12px',
    border: '1px solid #eee',
    transition: 'all 0.2s',
    marginBottom: '1px',
    backgroundColor: 'white'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  icon: {
    color: '#666666',
    width: '20px',
    height: '20px'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000'
  },
  description: {
    fontSize: '14px',
    color: '#666666'
  },
  chevron: {
    color: '#666666',
    width: '20px',
    height: '20px'
  }
}

export const CurrencyDetailsModal: React.FC = () => {
  const [activeTab, setActiveTab] = useState('local')

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const renderBankDetails = () => {
    if (activeTab === 'local') {
      return (
        <>
          <div className="flex items-center gap-2 mb-8">
            <span className="inline-block w-6 h-6">🇬🇧</span>
            <span className="text-gray-600">This bank details can be used for any businesses in UK</span>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <div className="text-gray-600 text-sm mb-2">Account holder</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">Ovil Limited</div>
                <button
                  onClick={() => handleCopy("Ovil Limited")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div>
              <div className="text-gray-600 text-sm mb-2">Short code</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">45-15-68</div>
                <button
                  onClick={() => handleCopy("45-15-68")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div>
              <div className="text-gray-600 text-sm mb-2">Account number</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">56000000</div>
                <button
                  onClick={() => handleCopy("56000000")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div>
              <div className="text-gray-600 text-sm mb-2">IBAN</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">GB03 BARC 2003 8469 3214 91</div>
                <button
                  onClick={() => handleCopy("GB03 BARC 2003 8469 3214 91")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div className="md:col-span-2">
              <div className="text-gray-600 text-sm mb-2">Bank name and address</div>
              <div className="flex justify-between items-start">
                <div className="font-medium">
                  PaayCo Limited
                  <br />
                  Unit C 105 Eade Road
                  <br />
                  London
                  <br />
                  N4 1TJ
                  <br />
                  United Kingdom
                </div>
                <button
                  onClick={() => handleCopy("PaayCo Limited\nUnit C 105 Eade Road\nLondon\nN4 1TJ\nUnited Kingdom")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mb-8">
            <span className="text-gray-600">Use these details for international transfers</span>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <div className="text-gray-600 text-sm mb-2">SWIFT/BIC code</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">BARCGB22</div>
                <button
                  onClick={() => handleCopy("BARCGB22")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div>
              <div className="text-gray-600 text-sm mb-2">Account number</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">56000000</div>
                <button
                  onClick={() => handleCopy("56000000")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div>
              <div className="text-gray-600 text-sm mb-2">IBAN</div>
              <div className="flex justify-between items-center">
                <div className="font-medium">GB03 BARC 2003 8469 3214 91</div>
                <button
                  onClick={() => handleCopy("GB03 BARC 2003 8469 3214 91")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>

            <div className="md:col-span-2">
              <div className="text-gray-600 text-sm mb-2">Bank name and address</div>
              <div className="flex justify-between items-start">
                <div className="font-medium">
                  Barclays Bank PLC
                  <br />
                  1 Churchill Place
                  <br />
                  London
                  <br />
                  E14 5HP
                  <br />
                  United Kingdom
                </div>
                <button
                  onClick={() => handleCopy("Barclays Bank PLC\n1 Churchill Place\nLondon\nE14 5HP\nUnited Kingdom")}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 bg-white rounded-full hover:bg-gray-50"
                >
                  <Copy className="w-4 h-4" />
                  Copy
                </button>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div className="p-6 max-w-[1200px] mx-auto bg-white">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold">GBP account information</h1>
        <button style={buttonStyles.primary}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" y1="15" x2="12" y2="3" />
          </svg>
          Download pdf
        </button>
      </div>

      <div className="mb-8">
        <div className="border-b border-gray-200">
          <nav className="flex gap-8">
            <button
              className={`px-1 py-4 font-medium ${
                activeTab === 'local' ? 'border-b-2 border-[#24EC7E] text-[#097152]' : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('local')}
            >
              Local
            </button>
            <button
              className={`px-1 py-4 font-medium ${
                activeTab === 'swift' ? 'border-b-2 border-[#24EC7E] text-[#097152]' : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('swift')}
            >
              Global Swift transfer
            </button>
          </nav>
        </div>
      </div>

      <div className="bg-[#F4FAF6] p-8 rounded-2xl mb-8">
        {renderBankDetails()}
      </div>

      <h2 className="text-xl font-semibold mb-6">Get the most from your account</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Link to="/instant-pay" style={listItemStyles.container} className="hover:bg-gray-50">
          <div style={listItemStyles.content}>
            <QrCode style={listItemStyles.icon} />
            <div style={listItemStyles.textContainer}>
              <span style={listItemStyles.title}>Instant Pay</span>
              <span style={listItemStyles.description}>Create QR code for instant payments.</span>
            </div>
          </div>
          <ChevronRight style={listItemStyles.chevron} />
        </Link>

        <Link to="/payment-links" style={listItemStyles.container} className="hover:bg-gray-50">
          <div style={listItemStyles.content}>
            <LinkIcon style={listItemStyles.icon} />
            <div style={listItemStyles.textContainer}>
              <span style={listItemStyles.title}>Create payment links</span>
              <span style={listItemStyles.description}>Generate payment link to get paid with ease</span>
            </div>
          </div>
          <ChevronRight style={listItemStyles.chevron} />
        </Link>
      </div>
    </div>
  )
}