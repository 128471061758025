import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardOrderFlow } from '../components/cardPages/card-order-flow';

const OrderCardPage: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex-1 bg-white p-8 relative">
      <CardOrderFlow onClose={() => navigate('/cards')} />
    </div>
  );
};

export default OrderCardPage;
