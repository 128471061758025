import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import api, {
  Contract,
  AddContractPayload,
  Beneficiary,
  Contact,
  CalendarEventData,
  ContactPayload,
  Ticket,
  UpdateTicketPayload,
  AddTicketPayload,
  Project, // Ensure these are correctly exported in api.ts
  AddProjectPayload,
  UpdateProjectPayload,
  Task,
  UpdateTaskPayload,
} from '../services/api';
import { toast } from 'react-toastify';
import { useAuth } from './AuthContext';

// -----------------------------
// Type Definitions
// -----------------------------

interface Project {
  id: string;
  name: string;
  status: string;
  clientid: string;
  company: string;
  start_date: string;
  deadline: string;
  project_rate_per_hour: string;
  description: string;
}

interface Task {
  id: string;
  name: string;
  description: string;
  status: string;
  priority: string;
  startdate: string;
  duedate: string | null;
  assignees: Array<{
    id: string;
    full_name: string;
  }>;
  checklist_items: Array<{
    id: string;
    description: string;
    finished: string;
  }>;
}

interface ProjectTasks {
  [projectId: string]: {
    tasks: Task[];
    loading: boolean;
    error: string | null;
    lastFetched: number;
  }
}

interface CRMContextData {
  // Contracts
  contracts: Contract[];
  fetchContracts: () => Promise<void>;
  addContract: (payload: AddContractPayload) => Promise<void>;
  editContract: (contractId: string, payload: Partial<Contract>) => Promise<void>;
  deleteContract: (contractId: string) => Promise<void>;

  // Calendar Events
  calendarEvents: CalendarEventData[];
  fetchCalendarEvents: () => Promise<void>;
  addCalendarEvent: (eventData: CalendarEventData) => Promise<void>;
  editCalendarEvent: (eventId: string, eventData: CalendarEventData) => Promise<void>;
  deleteCalendarEvent: (eventId: string) => Promise<void>;
  recoverDeletedEvent: (eventId: string) => CalendarEventData | undefined;

  // Contacts
  contacts: Contact[];
  fetchContacts: () => Promise<void>;
  addContact: (contactData: ContactPayload) => Promise<void>;
  updateContact: (contactId: string, contactData: ContactPayload) => Promise<void>;
  deleteContact: (contactId: string) => Promise<void>;

  // Beneficiaries
  beneficiaries: Beneficiary[];
  fetchBeneficiaries: () => Promise<void>;
  addBeneficiary: (beneficiary: Omit<Beneficiary, 'id'>) => Promise<void>;

  // Tickets
  tickets: Ticket[];
  fetchTickets: () => Promise<void>;
  addTicket: (payload: AddTicketPayload) => Promise<void>;
  updateTicket: (ticketId: string, payload: UpdateTicketPayload) => Promise<void>;
  deleteTicket: (ticketId: string) => Promise<void>;

  // Projects
  projects: Project[];
  fetchProjects: () => Promise<void>;
  addProject: (payload: AddProjectPayload) => Promise<void>;
  updateProject: (projectId: string, payload: UpdateProjectPayload) => Promise<void>;
  deleteProject: (projectId: string) => Promise<void>;

  // Recently Deleted Events
  recentlyDeleted: CalendarEventData[];

  // Loading and Error States
  contractsLoading: boolean;
  contractsError: string | null;

  calendarLoading: boolean;
  calendarError: string | null;

  contactsLoading: boolean;
  contactsError: string | null;

  beneficiariesLoading: boolean;
  beneficiariesError: string | null;

  ticketsLoading: boolean;
  ticketsError: string | null;

  projectsLoading: boolean;
  projectsError: string | null;

  projectTasks: ProjectTasks;
  fetchProjectTasks: (projectId: string) => Promise<void>;
  updateProjectTask: (projectId: string, taskId: string, taskData: UpdateTaskPayload) => Promise<void>;
}

const CRMContext = createContext<CRMContextData | undefined>(undefined);

// -----------------------------
// Custom Hook for Context
// -----------------------------

export const useCRM = (): CRMContextData => {
  const context = useContext(CRMContext);
  if (!context) {
    throw new Error('useCRM must be used within a CRMProvider');
  }
  return context;
};

// -----------------------------
// CRMProvider Component
// -----------------------------

export const CRMProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  // ---------------------------
  // State Management
  // ---------------------------

  // Contracts State
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contractsLoading, setContractsLoading] = useState<boolean>(false);
  const [contractsError, setContractsError] = useState<string | null>(null);

  // Calendar Events State
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventData[]>([]);
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);
  const [calendarError, setCalendarError] = useState<string | null>(null);

  // Recently Deleted Events State
  const [recentlyDeleted, setRecentlyDeleted] = useState<CalendarEventData[]>([]);

  // Contacts State
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsLoading, setContactsLoading] = useState<boolean>(false);
  const [contactsError, setContactsError] = useState<string | null>(null);

  // Beneficiaries State
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
  const [beneficiariesLoading, setBeneficiariesLoading] = useState<boolean>(false);
  const [beneficiariesError, setBeneficiariesError] = useState<string | null>(null);

  // Tickets State
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const [ticketsError, setTicketsError] = useState<string | null>(null);

  // Projects State
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [projectsError, setProjectsError] = useState<string | null>(null);

  // Project Tasks State
  const [projectTasks, setProjectTasks] = useState<ProjectTasks>({});

  // ---------------------------
  // Data Fetching Methods
  // ---------------------------

  /**
   * Fetches contracts from the API.
   */
  const fetchContracts = useCallback(async () => {
    setContractsLoading(true);
    setContractsError(null);
    try {
      const token = localStorage.getItem('_api_token');
      if (!token) {
        setContractsError('Not authenticated');
        return;
      }

      const fetchedContracts = await api.getContracts();
      setContracts(fetchedContracts);
      console.log('CRMContext: Contracts fetched successfully.');
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to fetch contracts';
      const errorDetails = error.data ? `: ${JSON.stringify(error.data)}` : '';
      const fullError = `${errorMessage}${errorDetails}`;
      
      setContractsError(fullError);
      console.error('CRMContext: Error fetching contracts:', {
        message: error.message,
        data: error.data,
        status: error.status
      });
      toast.error(errorMessage);
    } finally {
      setContractsLoading(false);
    }
  }, []);

  /**
   * Adds a new contract via the API.
   * @param payload - The contract data to add.
   */
  const addContract = useCallback(async (payload: AddContractPayload) => {
    try {
      const addedContract: Contract = await api.addContract(payload);
      setContracts(prevContracts => [...prevContracts, addedContract]);
      console.log('CRMContext: Contract added successfully.');
      toast.success('Contract added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding contract:', error);
      toast.error('Failed to add contract.');
      throw error; // Let the component handle the error
    }
  }, []);

  /**
   * Edits an existing contract via the API.
   * @param contractId - The ID of the contract to edit.
   * @param payload - The contract data to update.
   */
  const editContract = useCallback(async (contractId: string, payload: Partial<Contract>) => {
    try {
      const updatedContract = await api.editContract(contractId, payload);
      setContracts(prevContracts =>
        prevContracts.map(contract =>
          contract.id === contractId ? { ...contract, ...updatedContract } : contract
        )
      );
      console.log('CRMContext: Contract edited successfully.');
      toast.success('Contract updated successfully!');
    } catch (error) {
      console.error('CRMContext: Error editing contract:', error);
      toast.error('Failed to update contract.');
      throw error;
    }
  }, []);

  /**
   * Deletes a contract via the API.
   * @param contractId - The ID of the contract to delete.
   */
  const deleteContract = useCallback(async (contractId: string) => {
    try {
      await api.deleteContract(contractId);
      setContracts(prevContracts => prevContracts.filter(contract => contract.id !== contractId));
      console.log('CRMContext: Contract deleted successfully.');
      toast.success('Contract deleted successfully!');
    } catch (error) {
      console.error('CRMContext: Error deleting contract:', error);
      toast.error('Failed to delete contract.');
      throw error;
    }
  }, []);

  // ---------------------------
  // Calendar Events Methods
  // ---------------------------

  /**
   * Fetches calendar events from the API.
   */
  const fetchCalendarEvents = useCallback(async () => {
    setCalendarLoading(true);
    setCalendarError(null);
    try {
      // Check for token first
      const token = localStorage.getItem('_api_token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const fetchedEvents = await api.getCalendarEvents();
      if (Array.isArray(fetchedEvents)) {
        setCalendarEvents(fetchedEvents);
        console.log('CRMContext: Calendar events fetched successfully.');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to fetch calendar events';
      setCalendarError(errorMessage);
      console.error('CRMContext: Error fetching calendar events:', error);
      toast.error(errorMessage);
    } finally {
      setCalendarLoading(false);
    }
  }, []);

  /**
   * Adds a new calendar event via the API.
   * @param eventData - The event data to be added.
   */
  const addCalendarEvent = useCallback(async (eventData: CalendarEventData) => {
    try {
      const addedEvent: CalendarEventData = await api.addCalendarEvent(eventData);
      setCalendarEvents(prevEvents => [...prevEvents, addedEvent]);
      console.log('CRMContext: Calendar event added successfully.');
      toast.success('Calendar event added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding calendar event:', error);
      toast.error('Failed to add calendar event.');
      throw error;
    }
  }, []);

  /**
   * Edits an existing calendar event via the API.
   * @param eventId - The ID of the event to edit.
   * @param eventData - The event data to be updated.
   */
  const editCalendarEvent = useCallback(async (eventId: string, eventData: CalendarEventData) => {
    try {
      const updatedEvent: CalendarEventData = await api.editCalendarEvent(eventId, eventData);
      setCalendarEvents(prevEvents =>
        prevEvents.map(event => (event.eventid === eventId ? updatedEvent : event))
      );
      console.log('CRMContext: Calendar event edited successfully.');
      toast.success('Calendar event updated successfully!');
    } catch (error) {
      console.error('CRMContext: Error editing calendar event:', error);
      toast.error('Failed to update calendar event.');
      throw error;
    }
  }, []);

  /**
   * Deletes a calendar event via the API.
   * @param eventId - The ID of the event to delete.
   */
  const deleteCalendarEvent = useCallback(async (eventId: string) => {
    try {
      await api.deleteCalendarEvent(eventId);

      // Find the event to move to recentlyDeleted
      const eventToDelete = calendarEvents.find(event => event.eventid === eventId);
      if (eventToDelete) {
        setRecentlyDeleted(prev => [...prev, eventToDelete]);
      }

      // Remove from calendarEvents
      setCalendarEvents(prevEvents => prevEvents.filter(event => event.eventid !== eventId));

      console.log('CRMContext: Calendar event deleted successfully.');
      toast.success('Calendar event deleted successfully!');
    } catch (error) {
      console.error('CRMContext: Error deleting calendar event:', error);
      toast.error('Failed to delete calendar event.');
      throw error;
    }
  }, [calendarEvents]);

  /**
   * Recovers a recently deleted calendar event.
   * @param eventId - The ID of the event to recover.
   * @returns The recovered event data or undefined if not found.
   */
  const recoverDeletedEvent = useCallback((eventId: string): CalendarEventData | undefined => {
    // Find the event in recentlyDeleted
    const eventToRecover = recentlyDeleted.find(event => event.eventid === eventId);

    if (eventToRecover) {
      // Remove from recentlyDeleted
      setRecentlyDeleted(prev => prev.filter(event => event.eventid !== eventId));

      // Re-add it to calendarEvents
      setCalendarEvents(prev => [...prev, eventToRecover]);

      console.log('CRMContext: Calendar event recovered successfully.');
      toast.success('Calendar event recovered successfully!');
      return eventToRecover;
    } else {
      console.warn('CRMContext: Event not found in recentlyDeleted.');
      toast.warn('Event not found or already recovered.');
      return undefined;
    }
  }, [recentlyDeleted]);

  // ---------------------------
  // Contacts Methods
  // ---------------------------

  /**
   * Fetches contacts from the API.
   */
  const fetchContacts = useCallback(async () => {
    setContactsLoading(true);
    setContactsError(null);
    try {
      const fetchedContacts = await api.getContacts();
      setContacts(fetchedContacts);
      console.log('CRMContext: Contacts fetched successfully.');
    } catch (error) {
      setContactsError('Failed to fetch contacts.');
      console.error('CRMContext: Error fetching contacts:', error);
      toast.error('Failed to fetch contacts.');
    } finally {
      setContactsLoading(false);
    }
  }, []);

  /**
   * Adds a new contact via the API.
   * @param contactData - The contact data to add.
   */
  const addContact = useCallback(async (contactData: ContactPayload) => {
    try {
      const addedContact = await api.addContact(contactData);
      setContacts(prev => [...prev, addedContact]);
      console.log('CRMContext: Contact added successfully.');
      toast.success('Contact added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding contact:', error);
      toast.error('Failed to add contact.');
      throw error;
    }
  }, []);

  /**
   * Updates an existing contact via the API.
   * @param contactId - The ID of the contact to update.
   * @param contactData - The contact data to update.
   */
  const updateContact = useCallback(async (contactId: string, contactData: ContactPayload) => {
    try {
      const updatedContact = await api.updateContact(contactId, contactData);
      setContacts(prev =>
        prev.map(contact =>
          contact.id === contactId ? { ...contact, ...updatedContact } : contact
        )
      );
      console.log('CRMContext: Contact updated successfully.');
      toast.success('Contact updated successfully!');
    } catch (error) {
      console.error('CRMContext: Error updating contact:', error);
      toast.error('Failed to update contact.');
      throw error;
    }
  }, []);

  /**
   * Deletes a contact via the API.
   * @param contactId - The ID of the contact to delete.
   */
  const deleteContact = useCallback(async (contactId: string) => {
    try {
      await api.deleteContact(contactId);
      setContacts(prev => prev.filter(contact => contact.id !== contactId));
      console.log('CRMContext: Contact deleted successfully.');
      toast.success('Contact deleted successfully!');
    } catch (error) {
      console.error('CRMContext: Error deleting contact:', error);
      toast.error('Failed to delete contact.');
      throw error;
    }
  }, []);

  // ---------------------------
  // Beneficiaries Methods
  // ---------------------------

  /**
   * Fetches beneficiaries from the API.
   */
  const fetchBeneficiaries = useCallback(async () => {
    setBeneficiariesLoading(true);
    setBeneficiariesError(null);
    try {
      const fetchedBeneficiaries = await api.getBeneficiaries();
      setBeneficiaries(fetchedBeneficiaries);
      console.log('CRMContext: Beneficiaries fetched successfully.');
    } catch (error) {
      setBeneficiariesError('Failed to fetch beneficiaries.');
      console.error('CRMContext: Error fetching beneficiaries:', error);
      toast.error('Failed to fetch beneficiaries.');
    } finally {
      setBeneficiariesLoading(false);
    }
  }, []);

  /**
   * Adds a new beneficiary via the API.
   * @param beneficiary - The beneficiary data to add.
   */
  const addBeneficiary = useCallback(async (beneficiary: Omit<Beneficiary, 'id'>) => {
    try {
      const addedBeneficiary = await api.addBeneficiary(beneficiary);
      setBeneficiaries(prev => [...prev, addedBeneficiary]);
      console.log('CRMContext: Beneficiary added successfully.');
      toast.success('Beneficiary added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding beneficiary:', error);
      toast.error('Failed to add beneficiary.');
      throw error;
    }
  }, []);

  // ---------------------------
  // Tickets Methods
  // ---------------------------

  /**
   * Fetches all tickets from the API.
   */
  const fetchTickets = useCallback(async () => {
    setTicketsLoading(true);
    setTicketsError(null);
    try {
      const fetchedTickets = await api.getTickets();
      setTickets(Array.isArray(fetchedTickets) ? fetchedTickets : []);
      console.log('CRMContext: Tickets fetched successfully.');
    } catch (error) {
      setTicketsError('Failed to fetch tickets.');
      console.error('CRMContext: Error fetching tickets:', error);
      toast.error('Failed to fetch tickets.');
    } finally {
      setTicketsLoading(false);
    }
  }, []);

  /**
   * Adds a new ticket via the API.
   * @param payload - The ticket data to add.
   */
  const addTicket = useCallback(async (payload: AddTicketPayload) => {
    try {
      const newTicket: Ticket = await api.addTicket(payload);
      setTickets(prevTickets => [...prevTickets, newTicket]);
      console.log('CRMContext: Ticket added successfully.');
      toast.success('Ticket added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding ticket:', error);
      toast.error('Failed to add ticket.');
      throw error;
    }
  }, []);

  /**
   * Updates a specific ticket via the API.
   * @param ticketId - The ID of the ticket to update.
   * @param payload - The ticket data to update.
   */
  const updateTicket = useCallback(async (ticketId: string, payload: UpdateTicketPayload) => {
    try {
      const updatedTicket: Ticket = await api.updateTicket(ticketId, payload);
      setTickets(prevTickets =>
        prevTickets.map(ticket => (ticket.id === ticketId ? updatedTicket : ticket))
      );
      console.log('CRMContext: Ticket updated successfully.');
      toast.success('Ticket updated successfully!');
    } catch (error) {
      console.error('CRMContext: Error updating ticket:', error);
      toast.error('Failed to update ticket.');
      throw error;
    }
  }, []);

  /**
   * Deletes a specific ticket via the API.
   * @param ticketId - The ID of the ticket to delete.
   */
  const deleteTicket = useCallback(async (ticketId: string) => {
    try {
      await api.deleteTicket(ticketId);
      setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== ticketId));
      console.log('CRMContext: Ticket deleted successfully.');
      toast.success('Ticket deleted successfully!');
    } catch (error) {
      console.error('CRMContext: Error deleting ticket:', error);
      toast.error('Failed to delete ticket.');
      throw error;
    }
  }, []);

  // ---------------------------
  // Projects Methods
  // ---------------------------

  /**
   * Fetches projects from the API.
   */
  const fetchProjects = useCallback(async () => {
    setProjectsLoading(true);
    setProjectsError(null);
    try {
      const fetchedProjects = await api.getProjects();
      // Map status codes to meaningful strings
      const statusMapping: { [key: string]: string } = {
        '1': 'In Progress',
        '2': 'Completed',
        '3': 'On Hold',
        // Add more mappings as necessary
      };
      const mappedProjects = fetchedProjects.map(project => ({
        ...project,
        status: statusMapping[project.status] || 'Unknown',
        // Assuming you have a method to get client name from clientid
        // For simplicity, we'll map clientid to a placeholder string
        client: `Client ${project.clientid}`,
        // You can add more mappings or transformations here
      }));
      setProjects(mappedProjects);
      console.log('CRMContext: Projects fetched successfully.');
    } catch (error) {
      setProjectsError('Failed to fetch projects.');
      console.error('CRMContext: Error fetching projects:', error);
      toast.error('Failed to fetch projects.');
    } finally {
      setProjectsLoading(false);
    }
  }, []);

  /**
   * Adds a new project via the API.
   * @param payload - The project data to add.
   */
  const addProject = useCallback(async (payload: AddProjectPayload) => {
    try {
      const addedProject: Project = await api.addProject(payload);
      // Map status code to string
      const statusMapping: { [key: string]: string } = {
        '1': 'In Progress',
        '2': 'Completed',
        '3': 'On Hold',
        // Add more mappings as necessary
      };
      const mappedProject = {
        ...addedProject,
        status: statusMapping[addedProject.status] || 'Unknown',
        client: `Client ${addedProject.clientid}`,
      };
      setProjects(prev => [...prev, mappedProject]);
      console.log('CRMContext: Project added successfully.');
      toast.success('Project added successfully!');
    } catch (error) {
      console.error('CRMContext: Error adding project:', error);
      toast.error('Failed to add project.');
      throw error;
    }
  }, []);

  /**
   * Updates an existing project via the API.
   * @param projectId - The ID of the project to update.
   * @param payload - The project data to update.
   */
  const updateProject = useCallback(async (projectId: string, payload: UpdateProjectPayload) => {
    try {
      const updatedProject = await api.updateProject(projectId, payload);
      setProjects(prev => prev.map(p => p.id === projectId ? { ...p, ...updatedProject } : p));
      console.log(`CRMContext: Project ID ${projectId} updated.`); // ⬅️ Debug log
      toast.success('Project updated successfully!');
    } catch (error) {
      console.error(`CRMContext: Error updating Project ID ${projectId}:`, error); // ⬅️ Debug log
      toast.error('Failed to update project.');
      throw error;
    }
  }, [api, setProjects]);

  /**
   * Deletes a project via the API.
   * @param projectId - The ID of the project to delete.
   */
  const deleteProject = useCallback(async (projectId: string) => {
    try {
      await api.deleteProject(projectId);
      setProjects(prev => prev.filter(p => p.id !== projectId));
      console.log(`CRMContext: Project ID ${projectId} deleted.`); // ⬅️ Debug log
      toast.success('Project deleted successfully!');
    } catch (error) {
      console.error(`CRMContext: Error deleting Project ID ${projectId}:`, error); // ⬅️ Debug log
      toast.error('Failed to delete project.');
      throw error;
    }
  }, [api, setProjects]);

  // ---------------------------
  // Project Tasks Methods
  // ---------------------------

  const fetchProjectTasks = useCallback(async (projectId: string) => {
    // Check if we already have recent data (less than 5 minutes old)
    const existingData = projectTasks[projectId];
    const now = Date.now();
    const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

    if (existingData && (now - existingData.lastFetched) < CACHE_DURATION) {
      console.log('CRMContext: Using cached tasks for project', projectId);
      return;
    }

    // Update loading state
    setProjectTasks(prev => ({
      ...prev,
      [projectId]: {
        ...(prev[projectId] || { tasks: [] }),
        loading: true,
        error: null,
        lastFetched: now
      }
    }));

    try {
      const tasks = await api.getTasks(projectId);
      setProjectTasks(prev => ({
        ...prev,
        [projectId]: {
          tasks,
          loading: false,
          error: null,
          lastFetched: now
        }
      }));
      console.log('CRMContext: Tasks fetched successfully for project', projectId);
    } catch (error) {
      console.error('CRMContext: Error fetching tasks for project', projectId, error);
      setProjectTasks(prev => ({
        ...prev,
        [projectId]: {
          ...(prev[projectId] || { tasks: [] }),
          loading: false,
          error: 'Failed to fetch tasks',
          lastFetched: now
        }
      }));
    }
  }, [projectTasks]);

  const updateProjectTask = useCallback(async (projectId: string, taskId: string, taskData: UpdateTaskPayload) => {
    try {
      const updatedTask = await api.updateTask(taskId, taskData);
      setProjectTasks(prev => ({
        ...prev,
        [projectId]: {
          ...(prev[projectId] || { tasks: [], loading: false, error: null, lastFetched: Date.now() }),
          tasks: prev[projectId]?.tasks.map(task => 
            task.id === taskId ? updatedTask : task
          ) || []
        }
      }));
      console.log('CRMContext: Task updated successfully', taskId);
    } catch (error) {
      console.error('CRMContext: Error updating task', taskId, error);
      throw error;
    }
  }, []);

  // ---------------------------
  // Initialization Effect
  // ---------------------------

  const initializeData = useCallback(async () => {
    try {
      setLoading(true);
      
      // Execute each fetch independently to prevent one failure from affecting others
      try {
        await fetchContracts();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch contracts:', error);
      }

      try {
        await fetchCalendarEvents();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch calendar events:', error);
      }

      try {
        await fetchContacts();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch contacts:', error);
      }

      try {
        await fetchBeneficiaries();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch beneficiaries:', error);
      }

      try {
        await fetchTickets();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch tickets:', error);
      }

      try {
        await fetchProjects();
      } catch (error) {
        console.warn('CRMContext: Failed to fetch projects:', error);
      }

    } catch (error) {
      console.error('CRMContext: Error during initialization:', error);
    } finally {
      setLoading(false);
    }
  }, [fetchContracts, fetchCalendarEvents, fetchContacts, fetchBeneficiaries, fetchTickets, fetchProjects]);

  useEffect(() => {
    const token = localStorage.getItem('_api_token');
    if (!token || !user) {
      console.log('CRMContext: No token or user found, skipping initialization');
      return;
    }

    // Initialize data with error handling
    initializeData().catch(error => {
      console.error('CRMContext: Failed to initialize:', error);
      setLoading(false);
    });
  }, [user, initializeData]);

  // ---------------------------
  // Context Value Memoization
  // ---------------------------

  const contextValue = useMemo(() => ({
    // Contracts
    contracts,
    fetchContracts,
    addContract,
    editContract,
    deleteContract,

    // Calendar Events
    calendarEvents,
    fetchCalendarEvents,
    addCalendarEvent,
    editCalendarEvent,
    deleteCalendarEvent,
    recoverDeletedEvent,

    // Contacts
    contacts,
    fetchContacts,
    addContact,
    updateContact,
    deleteContact,

    // Beneficiaries
    beneficiaries,
    fetchBeneficiaries,
    addBeneficiary,

    // Tickets
    tickets,
    fetchTickets,
    addTicket,
    updateTicket,
    deleteTicket,

    // Projects
    projects,
    fetchProjects,
    addProject,
    updateProject,
    deleteProject,

    // Recently Deleted Events
    recentlyDeleted,

    // Loading and Error States
    contractsLoading,
    contractsError,
    calendarLoading,
    calendarError,
    contactsLoading,
    contactsError,
    beneficiariesLoading,
    beneficiariesError,
    ticketsLoading,
    ticketsError,
    projectsLoading,
    projectsError,

    // Project Tasks
    projectTasks,
    fetchProjectTasks,
    updateProjectTask,
  }), [
    // Contracts
    contracts,
    fetchContracts,
    addContract,
    editContract,
    deleteContract,

    // Calendar Events
    calendarEvents,
    fetchCalendarEvents,
    addCalendarEvent,
    editCalendarEvent,
    deleteCalendarEvent,
    recoverDeletedEvent,

    // Contacts
    contacts,
    fetchContacts,
    addContact,
    updateContact,
    deleteContact,

    // Beneficiaries
    beneficiaries,
    fetchBeneficiaries,
    addBeneficiary,

    // Tickets
    tickets,
    fetchTickets,
    addTicket,
    updateTicket,
    deleteTicket,

    // Projects
    projects,
    fetchProjects,
    addProject,
    updateProject,
    deleteProject,

    // Recently Deleted Events
    recentlyDeleted,

    // Loading and Error States
    contractsLoading,
    contractsError,
    calendarLoading,
    calendarError,
    contactsLoading,
    contactsError,
    beneficiariesLoading,
    beneficiariesError,
    ticketsLoading,
    ticketsError,
    projectsLoading,
    projectsError,

    // Project Tasks
    projectTasks,
    fetchProjectTasks,
    updateProjectTask,
  ]);

  // ---------------------------
  // Provide Context
  // ---------------------------

  return (
    <CRMContext.Provider value={contextValue}>
      {children}
    </CRMContext.Provider>
  );
};

export default CRMContext;
