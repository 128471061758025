import { FileText } from 'lucide-react'

export function FinancialAuditReports() {
  return (
    <div className="w-full p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-semibold mb-6">Financial audit reports</h1>
        
        <div className="flex gap-4 mb-12">
          <button
            style={{
              backgroundColor: '#24EC7E',
              color: 'white',
              border: 'none',
              padding: '8px 16px',
              borderRadius: '100px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer'
            }}
          >
            Download report
          </button>
          <button
            style={{
              backgroundColor: '#24EC7E',
              color: 'white',
              border: 'none',
              padding: '8px 16px',
              borderRadius: '100px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '14px',
              fontWeight: 500,
              cursor: 'pointer'
            }}
          >
            Send report
          </button>
        </div>

        <div className="text-center max-w-2xl mx-auto py-12">
          <div className="mb-6 flex justify-center">
            <FileText className="w-16 h-16 text-[#24EC7E]" />
          </div>
          <h2 className="text-2xl font-medium text-gray-900 mb-4">
            You currently have no audit requests.
          </h2>
          <p className="text-gray-500">
            When you auditor sends audit requests, you will be able to review and download them here.
          </p>
        </div>
      </div>
    </div>
  )
}