// /home/thomas/projects/funddpayNew/funddpay-dashboard/resources/js/contexts/AuthContextData/useAuthEvents.tsx

import { useState, useEffect, useCallback } from 'react';
import { Event } from './types';
import api from '../../services/api';
import { logAuthEvent } from './utils.ts';

interface UseAuthEventsProps {
  logout: (reason?: string) => Promise<void>;
}

export const useAuthEvents = ({ logout }: UseAuthEventsProps) => {
  const [events, setEvents] = useState<Event[]>(() => {
    try {
      const storedEvents = localStorage.getItem('events');
      const parsedEvents = storedEvents ? JSON.parse(storedEvents) : [];
      // Avoid initializing with the 'no_events' placeholder from localStorage
      if (
        parsedEvents.length === 1 &&
        parsedEvents[0].id === 'no_events'
      ) {
        localStorage.removeItem('events'); // Remove the placeholder from localStorage
        return [];
      }
      return Array.isArray(parsedEvents) ? parsedEvents : [];
    } catch (e) {
      console.error(
        'AuthContext: Error parsing events from localStorage:',
        e
      );
      return [];
    }
  });

  const [error, setError] = useState<string | null>(null);
  const [eventsFetched, setEventsFetched] = useState<boolean>(false);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000; // 3 seconds

  const fetchEvents = useCallback(async () => {
    if (eventsFetched) {
      logAuthEvent(
        'Events already fetched. Skipping fetchEvents.',
        null,
        'info'
      );
      return;
    }

    logAuthEvent('Fetching Events');
    let attempts = 0;

    while (attempts < MAX_RETRIES) {
      try {
        setError(null);
        setLoadingEvents(true);
        const eventsData = await api.getEvents();
        logAuthEvent('Events API Response', eventsData);

        if (Array.isArray(eventsData) && eventsData.length > 0) {
          setEvents(eventsData);
          localStorage.setItem('events', JSON.stringify(eventsData));
          logAuthEvent(
            'Events Fetched',
            { count: eventsData.length, latest: eventsData[0] },
            'success'
          );
          setEventsFetched(true);
          return;
        } else {
          const noEvents: Event[] = [
            { id: 'no_events', message: 'No events available', time: '' },
          ];
          setEvents(noEvents);
          localStorage.setItem('events', JSON.stringify(noEvents));
          logAuthEvent(
            'No events available. Set default message.',
            null,
            'info'
          );
          setEventsFetched(true);
          return;
        }
      } catch (error: any) {
        attempts++;
        logAuthEvent(
          'Error fetching events',
          { attempt: attempts, error: error.message },
          'error'
        );

        if (error?.response?.status === 401) {
          await logout('Unauthorized during events fetch');
          return;
        }

        if (attempts >= MAX_RETRIES) {
          setError('Failed to fetch events after multiple attempts');
          logAuthEvent(
            'Failed to fetch events after maximum retries.',
            null,
            'error'
          );
          return;
        }

        logAuthEvent(
          `Retrying to fetch events in ${
            RETRY_DELAY / 1000
          } seconds...`,
          null,
          'warning'
        );
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      } finally {
        setLoadingEvents(false);
      }
    }
  }, [logout, eventsFetched]);

  // Sync events state to localStorage whenever events change
  useEffect(() => {
    if (events.length > 0) {
      localStorage.setItem('events', JSON.stringify(events));
      logAuthEvent(
        'Events data stored in localStorage',
        events,
        'info'
      );
    }
  }, [events]);

  return { events, setEvents, fetchEvents, error, loadingEvents };
};
