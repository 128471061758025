import { useState, useEffect, useRef } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { ChevronDown, Search, X } from "lucide-react"

// **Export the Currency type**
export type Currency = {
  code: string
  name: string
  unitsPerGBP: number
  flag: string
  popular?: boolean
}

// Button styles using Tailwind CSS classes
const buttonStyles = {
  dropdownItem: "w-full flex items-center gap-4 px-4 py-3 hover:bg-gray-50 rounded-lg"
}

// **Export the currencies array**
export const currencies: Currency[] = [
  { code: 'USD', name: 'United States dollar', flag: 'us', unitsPerGBP: 1.2930869377817782, popular: true },
  { code: 'EUR', name: 'Euro', flag: 'eu', unitsPerGBP: 1.2062720245945004, popular: true },
  { code: 'GBP', name: 'British pound', flag: 'gb', unitsPerGBP: 1, popular: true },
  { code: 'JPY', name: 'Japanese yen', flag: 'jp', unitsPerGBP: 150.0, popular: true },
  { code: 'CHF', name: 'Swiss franc', flag: 'ch', unitsPerGBP: 1.08, popular: true },
  { code: 'CAD', name: 'Canadian dollar', flag: 'ca', unitsPerGBP: 1.70, popular: true },
  { code: 'AUD', name: 'Australian dollar', flag: 'au', unitsPerGBP: 1.963448076542104, popular: true },
  { code: 'CNY', name: 'Chinese yuan', flag: 'cn', unitsPerGBP: 8.80, popular: true },
  { code: 'ILS', name: 'Israeli new shekel', flag: 'il', unitsPerGBP: 4.50, popular: true },
  { code: 'INR', name: 'Indian rupee', flag: 'in', unitsPerGBP: 100.0, popular: true },
  { code: 'MXN', name: 'Mexican peso', flag: 'mx', unitsPerGBP: 25.0 },
  { code: 'SGD', name: 'Singapore dollar', flag: 'sg', unitsPerGBP: 1.75 },
  { code: 'HKD', name: 'Hong Kong dollar', flag: 'hk', unitsPerGBP: 10.5 },
  { code: 'NZD', name: 'New Zealand dollar', flag: 'nz', unitsPerGBP: 2.0 },
  { code: 'SEK', name: 'Swedish krona', flag: 'se', unitsPerGBP: 11.5 },
  { code: 'KRW', name: 'South Korean won', flag: 'kr', unitsPerGBP: 1500.0 },
  { code: 'AED', name: 'United Arab Emirates dirham', flag: 'ae', unitsPerGBP: 4.8 },
  { code: 'BRL', name: 'Brazilian real', flag: 'br', unitsPerGBP: 6.5 },
  { code: 'ZAR', name: 'South African rand', flag: 'za', unitsPerGBP: 20.0 },
  { code: 'RUB', name: 'Russian ruble', flag: 'ru', unitsPerGBP: 100.0 }
]

interface CurrencySelectProps {
  selectedCurrency: Currency
  onSelect: (currency: Currency) => void
}

export function CurrencySelect({ selectedCurrency, onSelect }: CurrencySelectProps) {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const filteredCurrencies = currencies.filter(currency =>
    currency.code.toLowerCase().includes(search.toLowerCase()) ||
    currency.name.toLowerCase().includes(search.toLowerCase())
  )

  const popularCurrencies = filteredCurrencies.filter(c => c.popular)
  const otherCurrencies = filteredCurrencies.filter(c => !c.popular)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between bg-transparent py-2 px-3"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <div className="flex items-center gap-2">
          <CircleFlag countryCode={selectedCurrency.flag} style={{ width: '24px', height: '24px' }} />
          <span className="text-base font-medium">{selectedCurrency.code}</span>
        </div>
        <ChevronDown size={20} color="#6B7280" />
      </button>

      {isOpen && (
        <div 
          className="absolute top-full left-0 bg-white rounded-2xl shadow-lg border border-gray-200 z-50 w-full mt-1 max-h-[200px] overflow-y-auto"
        >
          <div className="p-4">
            <div className="relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search currency"
                className="w-full pl-12 pr-12 py-4 border border-gray-200 rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-[#24EC7E]"
              />
              {search && (
                <button
                  onClick={() => setSearch('')}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X size={20} />
                </button>
              )}
            </div>
          </div>

          <div className="px-2">
            {popularCurrencies.length > 0 && (
              <>
                <div className="px-4 py-3 text-base text-gray-500 font-medium">Popular currencies</div>
                {popularCurrencies.map((currency) => (
                  <button
                    key={currency.code}
                    onClick={() => {
                      onSelect(currency)
                      setIsOpen(false)
                    }}
                    className={buttonStyles.dropdownItem}
                  >
                    <CircleFlag countryCode={currency.flag} style={{ width: '30px', height: '30px' }} />
                    <span className="font-medium text-base min-w-[56px]">{currency.code}</span>
                    <span className="text-gray-500 text-base">{currency.name}</span>
                  </button>
                ))}
              </>
            )}

            {otherCurrencies.length > 0 && (
              <>
                <div className="px-4 py-3 text-base text-gray-500 font-medium mt-2">All currencies</div>
                {otherCurrencies.map((currency) => (
                  <button
                    key={currency.code}
                    onClick={() => {
                      onSelect(currency)
                      setIsOpen(false)
                    }}
                    className={buttonStyles.dropdownItem}
                  >
                    <CircleFlag countryCode={currency.flag} style={{ width: '30px', height: '30px' }} />
                    <span className="font-medium text-base min-w-[56px]">{currency.code}</span>
                    <span className="text-gray-500 text-base">{currency.name}</span>
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
} 
