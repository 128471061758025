import { useState } from 'react'
import { Upload, X } from 'lucide-react'

export function BulkPayments() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      // Handle file upload logic here
      console.log('File uploaded:', file.name)
    }
  }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <div className="min-h-screen bg-white p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-semibold text-gray-900 mb-4">
          Bulk payments
        </h1>
        <p className="text-gray-600 text-lg mb-8">
          Complete multiple transfers with a single payment file. PaayCo allows you to make 1000 transfer with just a single file.
        </p>

        {/* Upload Area */}
        <div className="bg-gray-50 rounded-lg p-8 text-center border-2 border-dashed border-gray-200">
          <div className="mb-6">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <Upload className="w-8 h-8 text-gray-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              Upload your payment file
            </h2>
            <p className="text-gray-600">
              Drag or click the upload button to add file. Accepted files are .xlsx and .csv
            </p>
          </div>

          <div className="flex flex-col items-center gap-3">
            <label 
              htmlFor="file-upload"
              style={{
                backgroundColor: '#24EC7E',
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '100px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer'
              }}
            >
              Upload file
              <input
                id="file-upload"
                type="file"
                className="hidden"
                accept=".xlsx,.csv"
                onChange={handleFileUpload}
              />
            </label>
            <button 
              className="text-gray-600 text-sm hover:underline"
              onClick={openModal}
            >
              Learn more
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Bulk Payments Information</h3>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="text-gray-600">
              <p className="mb-4">
                Bulk payments allow you to process multiple payments in a single transaction. This feature is ideal for businesses that need to make regular payments to multiple recipients, such as payroll or vendor payments.
              </p>
              <p className="mb-4">
                To use bulk payments:
              </p>
              <ol className="list-decimal list-inside mb-4">
                <li>Prepare your payment file in .xlsx or .csv format</li>
                <li>Upload the file using the provided button</li>
                <li>Review and confirm the payments</li>
                <li>Authorize the bulk transaction</li>
              </ol>
              <p>
                For more detailed instructions or support, please contact our customer service team.
              </p>
            </div>
            <button
              onClick={closeModal}
              style={{
                backgroundColor: '#24EC7E',
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '100px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px',
                fontWeight: 500,
                cursor: 'pointer',
                marginTop: '16px'
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}