import React from 'react';

// Import SVGs directly
import AccountIcon from './Account.svg';
import BalancesIcon from './Balances.svg';
import CardIcon from './Card.svg';
import CardMachinesIcon from './CardMachines.svg';
import ExpensesIcon from './Expenses.svg';
import MoreIcon from './More.svg';
import SalesIcon from './Sales.svg';
import StatementsIcon from './Statements.svg';
import SupportIcon from './Support.svg';
import TasksIcon from './Tasks.svg';
import TeamsIcon from './Teams.svg';
import TransactionsIcon from './Transactions.svg';
import AcceptPaymentIcon from './AcceptPayment.svg';
import PaymentsIcon from './Payments.svg';

interface IconProps {
  name: string;
  className?: string;
}

const iconComponents: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  account: AccountIcon,
  balances: BalancesIcon,
  card: CardIcon,
  cardMachines: CardMachinesIcon,
  expenses: ExpensesIcon,
  more: MoreIcon,
  sales: SalesIcon,
  statements: StatementsIcon,
  support: SupportIcon,
  tasks: TasksIcon,
  teams: TeamsIcon,
  transactions: TransactionsIcon,
  payments: PaymentsIcon,
  AcceptPayment: AcceptPaymentIcon,
};

const Icon: React.FC<IconProps> = ({ name, className = '' }) => {
  const SvgIcon = iconComponents[name];

  if (!SvgIcon) {
    console.warn(`Icon "${name}" does not exist.`);
    return null;
  }

  return <SvgIcon className={className} />;
};

export default Icon; 