import { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

export function DirectDebits() {
  const [showSetupForm, setShowSetupForm] = useState(false)
  const [activeTab, setActiveTab] = useState('active')

  const currencies = [
    { value: 'gbp', label: 'British Pound (GBP)' },
    { value: 'eur', label: 'Euro (EUR)' },
    { value: 'usd', label: 'US Dollar (USD)' },
  ]

  if (showSetupForm) {
    return (
      <div className="w-full p-6">
        <div className="mb-8">
          <h1 className="text-2xl font-semibold mb-6">Direct Debits</h1>
          
          <div className="max-w-3xl">
            <div className="mb-6">
              <Select>
                <SelectTrigger className="w-full bg-white border rounded-lg p-4">
                  <SelectValue placeholder="Choose currency" />
                </SelectTrigger>
                <SelectContent>
                  {currencies.map((currency) => (
                    <SelectItem key={currency.value} value={currency.value}>
                      {currency.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-4">
              <div>
                <Label htmlFor="account" className="text-sm text-gray-600 mb-1 block">
                  Account Number
                </Label>
                <Input
                  id="account"
                  placeholder="Enter account number"
                  className="w-full bg-white border rounded-lg p-4"
                />
              </div>

              <div>
                <Label htmlFor="sort-code" className="text-sm text-gray-600 mb-1 block">
                  Sort Code
                </Label>
                <Input
                  id="sort-code"
                  placeholder="XX-XX-XX"
                  className="w-full bg-white border rounded-lg p-4"
                />
              </div>

              <div>
                <Label htmlFor="amount" className="text-sm text-gray-600 mb-1 block">
                  Payment Amount
                </Label>
                <div className="relative">
                  <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">£</span>
                  <Input
                    id="amount"
                    type="number"
                    placeholder="0.00"
                    className="w-full bg-white border rounded-lg p-4 pl-8"
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="date" className="text-sm text-gray-600 mb-1 block">
                  First Payment Date
                </Label>
                <Input
                  id="date"
                  type="date"
                  className="w-full bg-white border rounded-lg p-4"
                />
              </div>

              <div className="flex gap-4 pt-6">
                <button
                  type="button"
                  onClick={() => setShowSetupForm(false)}
                  style={{
                    backgroundColor: '#F5F5F5',
                    color: '#666666',
                    border: 'none',
                    padding: '8px 16px',
                    borderRadius: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '14px',
                    fontWeight: 500,
                    cursor: 'pointer',
                    flex: 1
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{
                    backgroundColor: '#24EC7E',
                    color: 'white',
                    border: 'none',
                    padding: '8px 16px',
                    borderRadius: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '14px',
                    fontWeight: 500,
                    cursor: 'pointer',
                    flex: 1,
                    justifyContent: 'center'
                  }}
                >
                  Set up Direct Debit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-semibold mb-2">Direct Debits</h1>
        <div className="bg-gray-100 rounded-full p-1 flex mb-8">
          <button 
            className={`flex-1 px-6 py-2 rounded-full text-sm font-medium transition-colors ${
              activeTab === 'active' ? 'bg-white' : 'text-gray-600'
            }`}
            onClick={() => setActiveTab('active')}
          >
            Active
          </button>
          <button 
            className={`flex-1 px-6 py-2 rounded-full text-sm font-medium transition-colors ${
              activeTab === 'inactive' ? 'bg-white' : 'text-gray-600'
            }`}
            onClick={() => setActiveTab('inactive')}
          >
            Inactive
          </button>
        </div>
      </div>

      <div className="text-center py-12">
        <h2 className="text-4xl font-medium text-gray-600 mb-4">Oops!</h2>
        <p className="text-gray-500 mb-8">You don't have an active direct debit yet.</p>
        
        <button
          onClick={() => setShowSetupForm(true)}
          style={{
            backgroundColor: '#24EC7E',
            color: 'white',
            border: 'none',
            padding: '8px 16px',
            borderRadius: '100px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: 500,
            cursor: 'pointer',
            width: '100%',
            maxWidth: '300px',
            margin: '0 auto',
            justifyContent: 'center',
            height: '48px'
          }}
        >
          Set up a Direct Debit
        </button>
      </div>
    </div>
  )
}