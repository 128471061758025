import React from 'react';
import { X } from 'lucide-react';
import { useModal } from '../../contexts/ModalContext';
import { useAuth } from '../../contexts/AuthContext';
import logo from './logo.png';

interface ModalHeaderProps {
  title: string;
  steps: Array<{ label: string; status: 'completed' | 'current' | 'upcoming' }>;
}

const PlaceholderAvatar = () => (
  <svg className="w-10 h-10 text-gray-300 bg-gray-100 rounded-full" fill="currentColor" viewBox="0 0 24 24">
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, steps }) => {
  const { closeModal } = useModal();
  const { user } = useAuth();

  return (
    <header
      className="sticky top-0 z-50 bg-white"
      style={{ height: '72px' }}
    >
      <div className="flex items-center justify-between px-4 sm:px-6 h-full">
        <div className="flex items-center space-x-4 sm:space-x-6 mr-80">
          <img src={logo} alt="EBANNC Logo" className="h-8 sm:h-12" />
          <h2 className="text-lg sm:text-xl font-bold">{title}</h2>
        </div>
        <div className="hidden md:flex items-center space-x-2 flex-grow justify-center">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-col items-center">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    step.status === 'completed'
                      ? 'bg-green-500 text-white'
                      : step.status === 'current'
                      ? 'border-4 border-green-500 bg-white'
                      : 'bg-gray-200 text-gray-500'
                  }`}
                >
                  {step.status === 'completed' ? '✓' : ''}
                </div>
                <span
                  className={`text-xs mt-1 ${
                    step.status === 'completed'
                      ? 'text-gray-500'
                      : step.status === 'current'
                      ? 'text-green-600 font-semibold'
                      : 'text-gray-400'
                  }`}
                >
                  {step.label}
                </span>
              </div>
              {index < steps.length - 1 && (
                <div className={`h-0.5 w-12 mt-4 ${
                  step.status === 'completed' ? 'bg-green-500' : 'bg-gray-200'
                }`} />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex items-center space-x-2 sm:space-x-4">
          <div className="flex items-center space-x-2">
            <PlaceholderAvatar />
            <span className="text-base font-medium text-gray-700">{user?.name}</span>
          </div>
          <button onClick={closeModal} className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <X size={24} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default ModalHeader;