// src/contexts/WalletContext.tsx

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';
import api, { Wallet } from '../services/api';
import { useAuth } from './AuthContext';

interface WalletContextData {
  wallets: Wallet[];
  setWallets: React.Dispatch<React.SetStateAction<Wallet[]>>;
  loading: boolean;
  error: string | null;
  refreshWallets: () => Promise<void>; // Changed to return a Promise
}

const WalletContext = createContext<WalletContextData>({} as WalletContextData);

// Custom Hook
export const useWallet = () => useContext(WalletContext);

// WalletProvider Component
export const WalletProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, logout } = useAuth();
  const [wallets, setWallets] = useState<Wallet[]>(() => {
    const storedWallets = localStorage.getItem('wallets');
    try {
      const parsedWallets = storedWallets ? JSON.parse(storedWallets) : [];
      return Array.isArray(parsedWallets) ? parsedWallets : [];
    } catch (e) {
      console.error('WalletContext: Failed to parse wallets from localStorage:', e);
      return [];
    }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch wallets
  const fetchWallets = useCallback(async () => {
    if (!user) {
      setError('User not authenticated');
      return;
    }

    console.log('WalletContext: Fetching wallets');
    try {
      setLoading(true);
      setError(null);
      const response = await api.getWallets();

      // Check for error in response
      if (response.error) {
        console.warn('WalletContext: Error fetching wallets:', response.error);
        setError(response.error);
        return; // Exit early without modifying wallets
      }

      // Proceed only if user exists and wallets is a non-empty array
      if (response.user && Array.isArray(response.wallets) && response.wallets.length > 0) {
        console.log('WalletContext: Wallets fetched successfully');

        // Update wallets
        setWallets(response.wallets);
        localStorage.setItem('wallets', JSON.stringify(response.wallets));
        console.log('WalletContext: Wallets data stored in localStorage.');
      } else {
        // If wallets array is empty but no error, decide on behavior
        if (response.user) {
          console.warn('WalletContext: Wallets data is empty.');
          setError('No wallets available.');
        } else {
          console.warn('WalletContext: Unexpected response structure from wallets API.');
          setError('Unexpected response from server.');
        }
      }
    } catch (error: any) {
      console.error('WalletContext: Error fetching wallets:', error);
      const status = error.status || error.response?.status || null;
      const message = error.data?.message || error.response?.data?.message || '';

      if (status === 401 || message === 'Unauthenticated.') {
        await logout('Unauthorized (401) error when fetching wallets');
      } else if (status === 500) {
        // **Handle 500 Internal Server Error by Ignoring It**
        console.warn(
          'WalletContext: Server error (500) encountered. Ignoring the error and using existing wallets data.'
        );
      } else {
        setError('Failed to fetch wallets');
        console.log('WalletContext: Keeping existing wallets data despite the error.');
      }
    } finally {
      setLoading(false);
      console.log('WalletContext: Fetch wallets operation completed');
    }
  }, [user, logout]);

  // Function to refresh wallets manually
  const refreshWallets = useCallback(async () => {
    await fetchWallets();
  }, [fetchWallets]);

  // Sync wallets state to localStorage whenever wallets change
  useEffect(() => {
    if (wallets.length > 0) {
      localStorage.setItem('wallets', JSON.stringify(wallets));
      console.log('WalletContext: Wallets data updated in localStorage');
    }
  }, [wallets]);

  const contextValue = React.useMemo(
    () => ({
      wallets,
      setWallets,
      loading,
      error,
      refreshWallets,
    }),
    [wallets, setWallets, loading, error, refreshWallets]
  );

  return <WalletContext.Provider value={contextValue}>{children}</WalletContext.Provider>;
};

export default WalletContext;
