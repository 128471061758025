// src/contexts/AuthContextData/useAuthStatements.tsx

import { useState, useEffect, useCallback, useRef } from 'react';
import { Statement, Transaction, Wallet } from './types';
import { logAuthEvent } from './utils';
import { 
  startOfWeek, 
  addWeeks, 
  format, 
  isBefore, 
  parse 
} from 'date-fns';

interface UseAuthStatementsProps {
  wallets: Wallet[];
  user: {
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    activeWallet: string;
  } | null;
  fetchExchangeRates: () => Promise<void>;
}

export const useAuthStatements = ({
  wallets,
  user,
  fetchExchangeRates,
}: UseAuthStatementsProps) => {
  const [statements, setStatements] = useState<Statement[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loadingStatements, setLoadingStatements] = useState<boolean>(false);
  const isInitialMount = useRef(true);
  const hasLoggedWalletsMessage = useRef(false);

  const fetchAllTransactions = useCallback(async () => {
    if (!wallets.length || !user) return;

    try {
      setLoadingStatements(true);
      setError(null);

      const token = localStorage.getItem('_api_token');
      if (!token) {
        throw new Error('Not authenticated');
      }

      // Aggregate all transactions from all wallets
      const allTransactions: Transaction[] = wallets.flatMap(wallet => 
        wallet.transactions.map(tx => ({
          ...tx,
          walletId: wallet.id,
          walletCurrency: wallet.currency
        }))
      );

      console.log('Fetched Transactions:', allTransactions);

      if (allTransactions.length === 0) {
        setStatements([]);
        return;
      }

      // Sort transactions by date ascending
      allTransactions.sort((a, b) => {
        const dateA = parseTransactionDate(a.created);
        const dateB = parseTransactionDate(b.created);
        return dateA.getTime() - dateB.getTime();
      });

      // Initialize variables for grouping
      const groupedStatements: Statement[] = [];
      let currentStart = startOfWeek(parseTransactionDate(allTransactions[0].created), { weekStartsOn: 1 });
      let currentEnd = addWeeks(currentStart, 2);
      let currentTransactions: Transaction[] = [];
      let openingBalance: number | null = null;

      allTransactions.forEach(transaction => {
        const txDate = parseTransactionDate(transaction.created);

        if (isBefore(txDate, currentEnd)) {
          if (currentTransactions.length === 0) {
            openingBalance = parseFloat(transaction.balance.amount.toString());
          }
          currentTransactions.push(transaction);
        } else {
          const closingBalance = currentTransactions.length > 0 
            ? parseFloat(currentTransactions[currentTransactions.length - 1].balance.amount.toString()) 
            : openingBalance;

          groupedStatements.push({
            id: `STMT-${format(currentStart, 'yyyyMMdd')}-${format(currentEnd, 'yyyyMMdd')}`,
            currency: currentTransactions[0].walletCurrency || 'USD',
            startDate: currentStart,
            endDate: currentEnd,
            openingBalance: openingBalance || 0,
            closingBalance: closingBalance || 0,
            transactions: [...currentTransactions]
          });

          currentStart = startOfWeek(txDate, { weekStartsOn: 1 });
          currentEnd = addWeeks(currentStart, 2);
          currentTransactions = [transaction];
          openingBalance = parseFloat(transaction.balance.amount.toString());
        }
      });

      if (currentTransactions.length > 0) {
        const closingBalance = currentTransactions.length > 0 
          ? parseFloat(currentTransactions[currentTransactions.length - 1].balance.amount.toString()) 
          : openingBalance;

        groupedStatements.push({
          id: `STMT-${format(currentStart, 'yyyyMMdd')}-${format(currentEnd, 'yyyyMMdd')}`,
          currency: currentTransactions[0].walletCurrency || 'USD',
          startDate: currentStart,
          endDate: currentEnd,
          openingBalance: openingBalance || 0,
          closingBalance: closingBalance || 0,
          transactions: [...currentTransactions]
        });
      }

      console.log('Grouped Statements:', groupedStatements);

      setStatements(groupedStatements);
      await fetchExchangeRates();

    } catch (error: any) {
      const errorMessage = error?.message || 'Failed to fetch transactions';
      setError(errorMessage);
    } finally {
      setLoadingStatements(false);
    }
  }, [wallets, user, fetchExchangeRates]);

  // Helper function to parse transaction dates
  const parseTransactionDate = (dateString: string): Date => {
    // Expected format: "Oct 02, 11:37 AM"
    // Assume the current year
    const currentYear = new Date().getFullYear();
    const fullDateString = `${dateString} ${currentYear}`;
    const parsedDate = parse(fullDateString, "MMM dd, hh:mm a yyyy", new Date());

    if (isNaN(parsedDate.getTime())) {
      throw new Error(`Invalid date format: ${dateString}`);
    }

    return parsedDate;
  };

  // Modify the useEffect to be more conservative with logging
  useEffect(() => {
    if (!user) return; // Don't do anything if there's no user

    if (isInitialMount.current) {
      isInitialMount.current = false;
      // Only log once during the entire session
      if (!wallets.length && !hasLoggedWalletsMessage.current) {
        hasLoggedWalletsMessage.current = true;
        console.debug('useAuthStatements: Waiting for wallets to be loaded...');
      }
      return;
    }

    // Only fetch if we have wallets and no statements yet
    if (wallets.length > 0 && statements.length === 0 && !loadingStatements) {
      fetchAllTransactions();
    }
  }, [user, wallets.length, statements.length, fetchAllTransactions, loadingStatements]);

  return {
    statements,
    setStatements,
    fetchAllTransactions,
    error,
    loadingStatements,
  };
};
