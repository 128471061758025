import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, CheckCircle, AlertCircle, Info } from 'lucide-react'

interface ToastProps {
  id: string
  message: string
  type: 'success' | 'error' | 'info'
  onClose: (id: string) => void
}

const toastVariants = {
  initial: { opacity: 0, x: 300, y: 0 },
  animate: { opacity: 1, x: 0, y: 0, transition: { type: "spring", stiffness: 200, damping: 20 } },
  exit: { opacity: 0, x: 300, transition: { duration: 0.2 } }
}

const Toast: React.FC<ToastProps> = ({ id, message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(id)
    }, 5000) // Toast will disappear after 5 seconds

    return () => clearTimeout(timer)
  }, [id, onClose])

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle className="w-6 h-6" />
      case 'error':
        return <AlertCircle className="w-6 h-6" />
      case 'info':
        return <Info className="w-6 h-6" />
    }
  }

  const getStyles = () => {
    switch (type) {
      case 'success':
        return 'bg-[#008664] text-[#AFF8C8] border-l-4 border-[#24EC7E]'
      case 'error':
        return 'bg-[#00423C] text-[#AFF8C8] border-l-4 border-red-500'
      case 'info':
        return 'bg-[#24EC7E] text-[#00423C] border-l-4 border-[#008664]'
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        className={`flex items-center p-4 rounded-r-lg shadow-lg ${getStyles()} max-w-md`}
        variants={toastVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        role="alert"
      >
        <div className="flex items-center">
          <div className="flex-shrink-0">{getIcon()}</div>
          <p className="ml-3 mr-8 text-sm font-medium">{message}</p>
        </div>
        <button
          onClick={() => onClose(id)}
          className={`ml-auto rounded-full p-1 transition-colors duration-200 ${
            type === 'info' ? 'hover:bg-[#008664] text-[#00423C]' : 'hover:bg-[#24EC7E] text-[#AFF8C8]'
          }`}
          aria-label="Close notification"
        >
          <X className="w-4 h-4" />
        </button>
      </motion.div>
    </AnimatePresence>
  )
}

export default Toast