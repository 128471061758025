// modalContext.tsx

import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

// Define the possible status values
export type StepStatus = 'current' | 'upcoming' | 'completed' | 'complete';

export interface ModalStep {
  label: string;
  status: StepStatus;
}

interface ModalState {
  isOpen: boolean;
  content: ReactNode | null;
  title: string;
  steps: ModalStep[];
  error: string | null;
}

interface ModalContextType {
  isOpen: boolean;
  content: ReactNode | null;
  title: string;
  steps: ModalStep[];
  error: string | null;
  openModal: (config: {
    title?: string;
    content: ReactNode;
    steps?: ModalStep[];
  }) => void;
  closeModal: () => void;
  updateSteps: (newSteps: ModalStep[]) => void;
  setError: (error: string | null) => void;
}

const initialState: ModalState = {
  isOpen: false,
  content: null,
  title: '',
  steps: [],
  error: null,
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<ModalState>(initialState);

  const openModal = useCallback((config: {
    title?: string;
    content: ReactNode;
    steps?: ModalStep[];
  }) => {
    setState({
      isOpen: true,
      content: config.content,
      title: config.title || '',
      steps: config.steps || [],
      error: null, // Reset error when opening modal
    });
  }, []);

  const closeModal = useCallback(() => {
    setState(initialState);
  }, []);

  const updateSteps = useCallback((newSteps: ModalStep[]) => {
    setState(prev => ({
      ...prev,
      steps: newSteps,
      error: null, // Reset error when updating steps
    }));
  }, []);

  const setError = useCallback((error: string | null) => {
    setState(prev => ({
      ...prev,
      error,
    }));
  }, []);

  const value = useMemo(() => ({
    ...state,
    openModal,
    closeModal,
    updateSteps,
    setError,
  }), [state, openModal, closeModal, updateSteps, setError]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {state.error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg">
          <p className="font-bold">Error</p>
          <p>{state.error}</p>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export default ModalContext;
