// src/components/cardManagement/Card3DViewer.tsx

import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import gsap from 'gsap';

// Importing Standard Textures
import paaycowhite from './images/paaycowhite.png';
import paaycogreen from './images/paaycogreen.png';
import paaycoglenn from './images/paaycoglenn.png'; // Ensure this import exists
import paaycodarkgreen from './images/paaycodarkgreen.png';
import paaycoblue from './images/paaycoblue.png';

// Importing Custom Textures
import paaycowhiteCustom from './images/paaycowhiteCustom.png';
import paaycogreenCustom from './images/paaycogreenCustom.png';
import paaycodarkgreenCustom from './images/paaycodarkgreenCustom.png';
import paaycoblueCustom from './images/paaycoblueCustom.png';

interface Card3DViewerProps {
  selectedTexture: string; // e.g., 'paaycowhite.png', 'paaycogreen.png', etc.
  logoImage: string | null; // Base64 Data URL of the uploaded logo
  cardName: string;
  cardTitle: string;
  cardCategory: string;
  logoProperties: {
    scale: number;
    maintainAspectRatio: boolean; 
    isFlipped: boolean;
  };
}

const Card3DViewer: React.FC<Card3DViewerProps> = ({
  selectedTexture,
  logoImage,
  cardName,
  cardTitle,
  cardCategory,
  logoProperties,
}) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<THREE.Mesh | null>(null);
  const nameTextRef = useRef<THREE.Mesh | null>(null);
  const titleTextRef = useRef<THREE.Mesh | null>(null);
  const logoMeshRef = useRef<THREE.Mesh | null>(null);
  const frontMaterialRef = useRef<THREE.MeshStandardMaterial | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);

  // Map texture names to imported images
  const textureMap: { [key: string]: string } = {
    'paaycowhite.png': paaycowhite,
    'paaycogreen.png': paaycogreen,
    'paaycoglenn.png': paaycoglenn, // Ensure this is correctly imported
    'paaycodarkgreen.png': paaycodarkgreen,
    'paaycoblue.png': paaycoblue,
    'paaycowhiteCustom.png': paaycowhiteCustom,
    'paaycogreenCustom.png': paaycogreenCustom,
    'paaycodarkgreenCustom.png': paaycodarkgreenCustom,
    'paaycoblueCustom.png': paaycoblueCustom,
  };

  // Card dimensions constants
  const cardWidth = 3.4;
  const cardHeight = 2.1;
  const cardDepth = 0.1;

  useEffect(() => {
    if (!mountRef.current) return;

    // === THREE.JS SETUP ===
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    const camera = new THREE.PerspectiveCamera(
      45,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 5);
    cameraRef.current = camera; // Store camera reference

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setClearColor(0xffffff, 0);
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.physicallyCorrectLights = true;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // === LIGHTING SETUP ===
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const mainLight = new THREE.DirectionalLight(0xffffff, 1.2);
    mainLight.position.set(5, 5, 7);
    mainLight.castShadow = true;
    scene.add(mainLight);

    const fillLight = new THREE.DirectionalLight(0xffffff, 0.8);
    fillLight.position.set(-5, 3, 5);
    scene.add(fillLight);

    const topLight = new THREE.DirectionalLight(0xffffff, 0.6);
    topLight.position.set(0, 10, 0);
    scene.add(topLight);

    const frontLight = new THREE.DirectionalLight(0xffffff, 0.4);
    frontLight.position.set(0, 0, 5);
    scene.add(frontLight);

    // === CONTROLS ===
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enablePan = false;
    controls.minDistance = 3;
    controls.maxDistance = 7;
    controls.minAzimuthAngle = -Math.PI / 3; // -60 degrees
    controls.maxAzimuthAngle = Math.PI / 3;  // +60 degrees
    controls.minPolarAngle = Math.PI / 2 - Math.PI / 9; // 20 degrees up
    controls.maxPolarAngle = Math.PI / 2 + Math.PI / 9; // 20 degrees down

    // === CARD GEOMETRY AND MATERIALS ===
    const geometry = new THREE.BoxGeometry(cardWidth, cardHeight, cardDepth);

    // Define materials for each face of the box
    const materials = [
      new THREE.MeshStandardMaterial({ 
        color: 0x000000, 
        transparent: true, 
        opacity: 0,
        metalness: 0.1,
        roughness: 0.8,
      }),
      new THREE.MeshStandardMaterial({ 
        color: 0x000000, 
        transparent: true, 
        opacity: 0,
        metalness: 0.1,
        roughness: 0.8,
      }),
      new THREE.MeshStandardMaterial({ 
        color: 0x000000, 
        transparent: true, 
        opacity: 0,
        metalness: 0.1,
        roughness: 0.8,
      }),
      new THREE.MeshStandardMaterial({ 
        color: 0x000000, 
        transparent: true, 
        opacity: 0,
        metalness: 0.1,
        roughness: 0.8,
      }),
      new THREE.MeshStandardMaterial({ 
        map: null, 
        transparent: true,
        metalness: 0.1,
        roughness: 0.8,
      }),
      new THREE.MeshStandardMaterial({ 
        color: 0xffffff, 
        transparent: false,
        metalness: 0.1,
        roughness: 0.8,
      }),
    ];

    const card = new THREE.Mesh(geometry, materials);
    card.castShadow = true;
    card.receiveShadow = true;
    scene.add(card);
    cardRef.current = card;
    frontMaterialRef.current = materials[4];

    // === FONT LOADER AND TEXT ===
    const fontLoader = new FontLoader();
    fontLoader.load(
      '/fonts/helvetiker_regular.typeface.json', // Ensure the font file is correctly hosted
      (loadedFont) => {
        console.log('Font loaded successfully.');
        // Create Name Text
        const nameText = createTextMesh(
          formatName(cardName, cardTitle),
          loadedFont,
          0x000000 // Text color
        );
        nameTextRef.current = nameText;
        card.add(nameText);

        // Create Category Text
        const categoryText = createTextMesh(
          cardCategory || 'Category',
          loadedFont,
          0x000000,
          -0.1
        );
        titleTextRef.current = categoryText;
        card.add(categoryText);

        // Create Logo Mesh if logoImage exists
        if (logoImage) {
          addLogoToCard(logoImage);
        }
      },
      undefined,
      (err) => {
        console.error('An error happened while loading the font.', err);
      }
    );

    // === INITIAL TEXTURE LOADING ===
    loadTexture(selectedTexture);

    // === ANIMATION LOOP ===
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // === HANDLE RESIZE ===
    const handleResize = () => {
      if (!mountRef.current) return;
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', handleResize);

    // === CLEANUP ON UNMOUNT ===
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
      // Dispose Three.js objects
      geometry.dispose();
      materials.forEach((material) => (material as THREE.Material).dispose());

      if (nameTextRef.current) {
        nameTextRef.current.geometry.dispose();
        (nameTextRef.current.material as THREE.Material).dispose();
      }

      if (titleTextRef.current) {
        titleTextRef.current.geometry.dispose();
        (titleTextRef.current.material as THREE.Material).dispose();
      }

      if (logoMeshRef.current) {
        logoMeshRef.current.geometry.dispose();
        (logoMeshRef.current.material as THREE.Material).dispose();
      }
    };
  }, []); // Empty dependency array to run once on mount

  // === UPDATE TEXTURE ===
  useEffect(() => {
    loadTexture(selectedTexture);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTexture]);

  const loadTexture = useCallback((textureName: string) => {
    if (!cardRef.current || !rendererRef.current) return;

    console.log(`Attempting to load texture: ${textureName}`);

    let finalTextureName = textureName;

    // Determine if a custom texture should be used
    const baseTextureName = finalTextureName.slice(0, -4); // Remove '.png'
    const customTextureName = `${baseTextureName}Custom.png`;
    if (logoImage && textureMap[customTextureName]) {
      finalTextureName = customTextureName;
      console.log(`Using custom texture: ${finalTextureName}`);
    }

    const textureURL = textureMap[finalTextureName] || textureMap['paaycowhite.png'];
    console.log(`Loading texture from URL: ${textureURL}`);

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(
      textureURL,
      (texture) => {
        console.log(`Texture ${finalTextureName} loaded successfully.`);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.anisotropy = rendererRef.current!.capabilities.getMaxAnisotropy();
        texture.needsUpdate = true;

        if (frontMaterialRef.current) {
          frontMaterialRef.current.map = texture;
          frontMaterialRef.current.needsUpdate = true;
          console.log(`Applied texture to front face: ${finalTextureName}`);
        }

        // Update back face color based on selected texture
        if (Array.isArray(cardRef.current.material)) {
          switch(finalTextureName) {
            case 'paaycowhite.png':
            case 'paaycowhiteCustom.png':
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0xffffff); // Solid white
              break;
            case 'paaycogreen.png':
            case 'paaycogreenCustom.png':
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0x24EC7E); // Green
              break;
            case 'paaycoglenn.png':
            case 'paaycoglennCustom.png':
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0x24EC7E); // Adjust as per actual color
              break;
            case 'paaycodarkgreen.png':
            case 'paaycodarkgreenCustom.png':
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0x00433c); // Dark Green
              break;
            case 'paaycoblue.png':
            case 'paaycoblueCustom.png':
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0x0000ff); // Blue
              break;
            default:
              (cardRef.current.material[5] as THREE.MeshStandardMaterial).color.setHex(0xffffff); // Default to white
          }
        }

        // Update text meshes with new color
        const textColor = finalTextureName.includes('Custom') ? 0xffffff : (finalTextureName === 'paaycowhite.png' ? 0x000000 : 0xffffff);
        updateTextColor(nameTextRef.current, textColor);
        updateTextColor(titleTextRef.current, textColor);
      },
      undefined,
      (error) => {
        console.error(`Error loading texture ${finalTextureName}:`, error);
        // Fallback to white if there's an error
        if (!finalTextureName.includes('Custom')) {
          loadTexture('paaycowhite.png');
        }
      }
    );

  }, [logoImage, textureMap]);

  const updateTextColor = (textMesh: THREE.Mesh | null, color: number) => {
    if (textMesh) {
      (textMesh.material as THREE.MeshStandardMaterial).color.setHex(color);
      (textMesh.material as THREE.MeshStandardMaterial).needsUpdate = true;
      console.log(`Updated text color to: ${color.toString(16)}`);
    }
  };

  // === ADD LOGO TO CARD ===
  const addLogoToCard = (logoDataURL: string) => {
    if (!cardRef.current || !rendererRef.current) return;

    console.log('Adding logo to card:', logoDataURL);

    const textureLoader = new THREE.TextureLoader();
    const logoTexture = textureLoader.load(
      logoDataURL,
      () => {
        console.log('Logo texture loaded successfully.');
        logoTexture.colorSpace = THREE.SRGBColorSpace;
        logoTexture.anisotropy = rendererRef.current!.capabilities.getMaxAnisotropy();
        logoTexture.needsUpdate = true;

        const logoMaterial = new THREE.MeshStandardMaterial({
          map: logoTexture,
          transparent: true,
          depthTest: false,
          depthWrite: false,
          side: THREE.DoubleSide,
          alphaTest: 0.1,
        });

        const maxHeight = cardHeight / 7;
        const maxWidth = cardWidth / 3;
        const aspectRatio = logoTexture.image.width / logoTexture.image.height;
        
        let planeWidth, planeHeight;
        
        if (logoProperties.maintainAspectRatio) {
          planeHeight = maxHeight * logoProperties.scale;
          planeWidth = planeHeight * aspectRatio;
          
          // Ensure width doesn't exceed max width
          if (planeWidth > maxWidth) {
            planeWidth = maxWidth;
            planeHeight = planeWidth / aspectRatio;
          }
        } else {
          planeWidth = maxWidth * logoProperties.scale;
          planeHeight = maxHeight * logoProperties.scale;
        }

        const logoGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
        const logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);
        
        // Apply flip if needed
        if (logoProperties.isFlipped) {
          logoMesh.scale.x = -1; // Flip horizontally
          console.log('Logo flipped horizontally.');
        }
        
        // Position in top right with increased padding
        const EDGE_PADDING = 0.3;
        const offsetX = (cardWidth / 2) - (planeWidth / 2) - EDGE_PADDING;
        const offsetY = (cardHeight / 2) - (planeHeight / 2) - EDGE_PADDING;
        logoMesh.position.set(offsetX, offsetY, cardDepth / 2 + 0.03);
        
        // Ensure logo renders on top
        logoMesh.renderOrder = 999;

        // Remove existing logo if present
        if (logoMeshRef.current) {
          console.log('Removing existing logo mesh.');
          cardRef.current.remove(logoMeshRef.current);
          logoMeshRef.current.geometry.dispose();
          (logoMeshRef.current.material as THREE.Material).dispose();
        }

        // Add new logo mesh to card
        cardRef.current.add(logoMesh);
        logoMeshRef.current = logoMesh;
        console.log('New logo mesh added to card.');

      },
      (progress) => {
        console.log('Logo loading progress:', progress.loaded / progress.total * 100, '%');
      },
      (error) => {
        console.error('Error loading logo image:', error);
      }
    );
  };

  // === UPDATE LOGO ===
  useEffect(() => {
    if (logoImage) {
      addLogoToCard(logoImage);
    } else {
      // Remove logo if logoImage is null
      if (logoMeshRef.current && cardRef.current) {
        console.log('Removing logo as logoImage is null.');
        cardRef.current.remove(logoMeshRef.current);
        logoMeshRef.current.geometry.dispose();
        (logoMeshRef.current.material as THREE.Material).dispose();
        logoMeshRef.current = null;
      }
    }
  }, [logoImage, logoProperties]); // Update when logoImage or logoProperties change

  // === UPDATE TEXT MESHES ===
  useEffect(() => {
    if (nameTextRef.current && titleTextRef.current) {
      // Update Name Text
      const newName = formatName(cardName, cardTitle);
      console.log(`Updating name text to: ${newName}`);
      const nameGeometry = new TextGeometry(newName, {
        font: (nameTextRef.current.geometry as any).parameters.font,
        size: 0.05,
        height: 0.005,
        curveSegments: 12,
        bevelEnabled: false,
      });
      nameGeometry.computeBoundingBox();
      if (nameGeometry.boundingBox) {
        const centerOffset =
          -0.5 * (nameGeometry.boundingBox.max.x - nameGeometry.boundingBox.min.x);
        nameGeometry.translate(centerOffset, 0, 0.06);
      }
      nameTextRef.current.geometry.dispose();
      nameTextRef.current.geometry = nameGeometry;

      // Update Category Text
      const newCategory = cardCategory || 'Category';
      console.log(`Updating category text to: ${newCategory}`);
      const categoryGeometry = new TextGeometry(newCategory, {
        font: (titleTextRef.current.geometry as any).parameters.font,
        size: 0.03,
        height: 0.003,
        curveSegments: 12,
        bevelEnabled: false,
      });
      categoryGeometry.computeBoundingBox();
      if (categoryGeometry.boundingBox) {
        const centerOffset =
          -0.5 * (categoryGeometry.boundingBox.max.x - categoryGeometry.boundingBox.min.x);
        categoryGeometry.translate(centerOffset, -0.1, 0.06);
      }
      titleTextRef.current.geometry.dispose();
      titleTextRef.current.geometry = categoryGeometry;
    }
  }, [cardName, cardTitle, cardCategory]);

  return (
    <div>
      <div
        ref={mountRef}
        style={{ width: '100%', height: '250px', background: 'transparent' }}
      />
    </div>
  );
};

// === HELPER FUNCTIONS ===
const formatName = (name: string, title: string) => {
  if (!name.trim()) return '';
  const nameParts = name.trim().split(' ');
  if (nameParts.length === 1) {
    return `${title} ${nameParts[0]}`;
  } else {
    const firstInitial = nameParts[0].charAt(0).toUpperCase() + '.';
    const lastName = nameParts.slice(1).join(' ');
    return `${title} ${firstInitial}${lastName}`;
  }
};

const createTextMesh = (
  text: string,
  font: any, // Use any for now since THREE.Font is not exported
  color: number,
  yOffset: number = 0
) => {
  const geometry = new TextGeometry(text, {
    font: font,
    size: 0.05,
    height: 0.005,
    curveSegments: 12,
    bevelEnabled: false,
  });
  geometry.computeBoundingBox();
  if (geometry.boundingBox) {
    const centerOffset =
      -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
    geometry.translate(centerOffset, yOffset, 0.06);
  }
  const material = new THREE.MeshStandardMaterial({ color: color });
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export default Card3DViewer;
