// src/components/TransferModal.tsx

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Plus, Search, Heart, ArrowLeft } from 'lucide-react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import { Label } from './ui/label';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Separator } from './ui/separator';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from './ui/context-menu';
import { useModal } from '../../../../contexts/ModalContext';
import { CircleFlag } from 'react-circle-flags';
import { useAuth } from '../../../../contexts/AuthContext';
import api from '../../../../services/api';
import { useDebounce } from '../../../../hooks/useDebounce';

type Recipient = {
  id: string;
  name: string;
  bankDetails: string;
  country: string;
  tag: string;
  avatar?: string;
  isFavorite: boolean;
};

type Wallet = {
  id: string;
  name: string;
  currency: string;
  net: number;
};

type ConversionResponse = {
  converted_amount: number;
  fee: number;
  rate: number;
};

type PayTransferResponse = {
  transaction_id: string;
};

const currencyToCountryMap: { [key: string]: string } = {
  GBP: 'gb',
  USD: 'us',
  EUR: 'eu',
  AUD: 'au',
  // Add more currencies as needed
};

const ExchangeDetails = React.memo(({ fee, currency, exchangeRate }: { fee: number; currency: string; exchangeRate: number }) => (
  <div className="space-y-2">
    <div>
      <label className="block text-sm font-medium text-gray-500 mb-1">
        Fee
      </label>
      <div className="w-full px-4 py-3 bg-gray-100 rounded-md h-11 flex items-center">
        <span className="text-lg font-medium">
          {fee.toFixed(2)} {currency}
        </span>
      </div>
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-500 mb-1">
        Exchange Rate
      </label>
      <div className="w-full px-4 py-3 bg-gray-100 rounded-md h-11 flex items-center">
        <span className="text-lg font-medium">
          1 {currency} = {exchangeRate.toFixed(6)} {currency}
        </span>
      </div>
    </div>
  </div>
));

ExchangeDetails.displayName = 'ExchangeDetails';

interface TransferModalProps {
  isPageView?: boolean;
}

export function TransferModal({ isPageView = false }: TransferModalProps) {
  const { updateSteps, closeModal } = useModal();
  const [step, setStep] = useState(0);
  const [selectedRecipient, setSelectedRecipient] = useState<string | null>(null);
  const [newRecipient, setNewRecipient] = useState<Omit<Recipient, 'id' | 'isFavorite'>>({
    name: '',
    bankDetails: '',
    country: '',
    tag: '',
  });
  const [isAddingRecipient, setIsAddingRecipient] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fromAmount, setFromAmount] = useState('500'); // Renamed for consistency
  const [currency, setCurrency] = useState('GBP');
  const [paymentMethod, setPaymentMethod] = useState<string>(''); // Initialize as empty
  const [description, setDescription] = useState(''); // State for description
  const [fee, setFee] = useState<number>(0); // State for fee
  const [exchangeRate, setExchangeRate] = useState<number>(1); // State for exchange rate
  const [receivedAmount, setReceivedAmount] = useState<number>(0); // State for received amount
  const [transactionId, setTransactionId] = useState<string>(''); // State for transaction ID
  const [errorMessage, setErrorMessage] = useState<string>(''); // State for errors

  const {
    beneficiaries,
    loading: beneficiariesLoading,
    error: beneficiariesError,
    fetchBeneficiaries,
    addBeneficiary,
    toggleFavorite,
    wallets,
  } = useAuth();

  // Debounced amount to reduce API calls
  const debouncedFromAmount = useDebounce(fromAmount, 500);

  // Memoize recipients to prevent re-creation on every render
  const recipients: Recipient[] = useMemo(() => {
    console.log('beneficiaries: recipients', beneficiaries);
    return beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      name: `${beneficiary.firstname} ${beneficiary.lastname}`,
      bankDetails: beneficiary.iban,
      country: beneficiary.country,
      tag: beneficiary.detailsType,
      avatar: '/placeholder.svg?height=40&width=40',
      isFavorite: false,
    }));
  }, [beneficiaries]);

  // Memoize selectedRecipientData
  const selectedRecipientData = useMemo(() => {
    return recipients.find((r) => r.id === selectedRecipient);
  }, [recipients, selectedRecipient]);

  // Memoize filteredRecipients to optimize performance
  const filteredRecipients = useMemo(() => {
    return recipients.filter(
      (recipient) =>
        recipient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        recipient.tag.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [recipients, searchTerm]);

  // Define callbacks outside of JSX to prevent re-creation on every render
  const handleCurrencyChange = useCallback((val: string) => {
    console.log(`TransferModal: Currency changed to ${val}`);
    setCurrency(val.toUpperCase()); // Ensure uppercase
  }, []);

  const handlePaymentMethodChange = useCallback((val: string) => {
    console.log(`TransferModal: Payment method changed to ${val}`);
    setPaymentMethod(val);
  }, []);

  // **Define handleAddRecipient before its usage**
  const handleAddRecipient = useCallback(async () => {
    if (
      newRecipient.name &&
      newRecipient.bankDetails &&
      newRecipient.country
    ) {
      try {
        const [firstName, ...lastNameArr] = newRecipient.name.split(' ');
        const lastName = lastNameArr.join(' ') || '';
        await addBeneficiary({
          firstname: firstName,
          lastname: lastName,
          country: newRecipient.country.toUpperCase(),
          currency: currency.toLowerCase(),
          detailsType: 'bic.swift',
          iban: newRecipient.bankDetails,
          bic: '',
        });
        
        await fetchBeneficiaries();
        setIsAddingRecipient(false);
        setNewRecipient({
          name: '',
          bankDetails: '',
          country: '',
          tag: '',
        });
        console.log('TransferModal: Recipient added successfully.');
      } catch (err) {
        console.error('TransferModal: Error adding beneficiary:', err);
        setErrorMessage('Failed to add recipient. Please try again.');
      }
    } else {
      console.warn('TransferModal: Incomplete recipient details.');
      setErrorMessage('Please fill in all recipient details.');
    }
  }, [newRecipient, addBeneficiary, fetchBeneficiaries, currency]);

  const handleToggleFavorite = useCallback((id: string) => {
    toggleFavorite(id);
    console.log(`TransferModal: Toggled favorite for recipient ID: ${id}`);
  }, [toggleFavorite]);

  // Define the selected wallet to avoid multiple find operations
  const selectedWallet = useMemo(() => {
    return wallets.find((w) => w.id === paymentMethod);
  }, [wallets, paymentMethod]);

  const renderStepContent = useCallback(() => {
    switch (step) {
      case 0:
        return (
          <motion.div
            key="step-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">
              Select recipient
            </h2>
            {!isAddingRecipient ? (
              <>
                <div className="relative">
                  <Search
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                  <Input
                    type="text"
                    placeholder="Search recipients..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 w-full"
                  />
                </div>
                {beneficiariesLoading ? (
                  <p>Loading beneficiaries...</p>
                ) : beneficiariesError ? (
                  <p className="text-red-500">{beneficiariesError}</p>
                ) : (
                  <div className="space-y-2 max-h-[300px] overflow-y-auto">
                    {filteredRecipients.length > 0 ? (
                      filteredRecipients.map((recipient) => (
                        <ContextMenu key={recipient.id}>
                          <ContextMenuTrigger>
                            <div
                              className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-colors ${
                                selectedRecipient === recipient.id
                                  ? 'bg-green-100'
                                  : 'hover:bg-gray-100'
                              }`}
                              onClick={() =>
                                setSelectedRecipient(recipient.id)
                              }
                            >
                              <Avatar>
                                <AvatarImage
                                  src={recipient.avatar}
                                  alt={recipient.name}
                                />
                                <AvatarFallback>
                                  {recipient.name.charAt(0)}
                                </AvatarFallback>
                              </Avatar>
                              <div className="flex-grow">
                                <p className="font-medium">
                                  {recipient.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {recipient.tag}
                                </p>
                              </div>
                              {recipient.isFavorite && (
                                <Heart
                                  className="text-red-500"
                                  size={20}
                                />
                              )}
                              {selectedRecipient === recipient.id && (
                                <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                              )}
                            </div>
                          </ContextMenuTrigger>
                          <ContextMenuContent>
                            <ContextMenuItem
                              onClick={() =>
                                handleToggleFavorite(recipient.id)
                              }
                            >
                              {recipient.isFavorite
                                ? 'Remove from Favorites'
                                : 'Add to Favorites'}
                            </ContextMenuItem>
                          </ContextMenuContent>
                        </ContextMenu>
                      ))
                    ) : (
                      <p>No recipients found.</p>
                    )}
                  </div>
                )}
                <Button
                  className="w-full h-11 flex items-center justify-center space-x-2 text-white bg-[#00423C] hover:bg-[#008664] rounded-full"
                  onClick={() => setIsAddingRecipient(true)}
                >
                  <Plus size={16} />
                  <span>Add new recipient</span>
                </Button>
                {errorMessage && (
                  <p className="text-red-500 text-sm">{errorMessage}</p>
                )}
              </>
            ) : (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  Add new recipient
                </h3>
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={newRecipient.name}
                    onChange={(e) =>
                      setNewRecipient({
                        ...newRecipient,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="bankDetails">Bank Details</Label>
                  <Input
                    id="bankDetails"
                    value={newRecipient.bankDetails}
                    onChange={(e) =>
                      setNewRecipient({
                        ...newRecipient,
                        bankDetails: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="country">Country</Label>
                  <Input
                    id="country"
                    value={newRecipient.country}
                    onChange={(e) =>
                      setNewRecipient({
                        ...newRecipient,
                        country: e.target.value.toUpperCase(), // Ensure uppercase
                      })
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="tag">Tag</Label>
                  <Input
                    id="tag"
                    value={newRecipient.tag}
                    onChange={(e) =>
                      setNewRecipient({
                        ...newRecipient,
                        tag: e.target.value,
                      })
                    }
                    placeholder="e.g. Electrician, Plumber"
                  />
                </div>
                <div className="flex space-x-2">
                  <Button
                    onClick={handleAddRecipient}
                    className="flex-1 rounded-full bg-[#00423C] hover:bg-[#008664] text-white"
                  >
                    Add Recipient
                  </Button>
                  <Button
                    onClick={() => setIsAddingRecipient(false)}
                    variant="outline"
                    className="flex-1 rounded-full"
                  >
                    Cancel
                  </Button>
                </div>
                {errorMessage && (
                  <p className="text-red-500 text-sm">{errorMessage}</p>
                )}
              </div>
            )}
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            key="step-1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">
              How much are you sending?
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="fromAmount"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  You send exactly
                </label>
                <Input
                  id="fromAmount"
                  type="number"
                  value={fromAmount}
                  onChange={(e) => setFromAmount(e.target.value)}
                  className="w-full h-11"
                />
              </div>
              <div>
                <Label htmlFor="currency">Currency type</Label>
                <Select
                  value={currency}
                  onValueChange={handleCurrencyChange} // Use the defined callback
                  id="currency"
                >
                  <SelectTrigger
                    className="w-full h-11"
                    aria-labelledby="currency"
                  >
                    <SelectValue>
                      <div className="flex items-center">
                        <CircleFlag
                          countryCode={currencyToCountryMap[currency] || 'us'}
                          height={20}
                          width={30}
                        />
                        <span className="ml-2 text-lg">{currency}</span>
                      </div>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(currencyToCountryMap).map((curr) => (
                      <SelectItem key={curr} value={curr}>
                        <div className="flex items-center">
                          <CircleFlag
                            countryCode={currencyToCountryMap[curr] || 'us'}
                            height={20}
                            width={30}
                            className="mr-2"
                          />
                          {curr}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            {/* Description Input */}
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Input
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter a description for this transfer"
              />
            </div>
            {/* Received Amount */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Received after deduction
              </label>
              <div className="p-3 bg-green-50 border border-green-200 rounded-md text-lg font-semibold flex justify-between items-center h-11">
                <span>{receivedAmount.toFixed(2)}</span>
                <div className="flex items-center">
                  <CircleFlag
                    countryCode={currencyToCountryMap[currency] || 'us'}
                    height={20}
                    width={30}
                    className="mr-2"
                  />
                  <span>{currency}</span>
                </div>
              </div>
            </div>
            {/* Payment Method */}
            <div>
              <Label htmlFor="paymentMethod">Pay with</Label>
              {wallets && wallets.length > 0 ? (
                <Select
                  value={paymentMethod}
                  onValueChange={handlePaymentMethodChange} // Use the defined callback
                  id="paymentMethod"
                >
                  <SelectTrigger
                    className="w-full border border-gray-200 rounded-md h-11"
                    aria-labelledby="paymentMethod"
                  >
                    <SelectValue placeholder="Select payment method">
                      {selectedWallet ? (
                        <div className="flex items-center">
                          <CircleFlag
                            countryCode={
                              currencyToCountryMap[selectedWallet.currency.toUpperCase()] || 'us'
                            }
                            height={20}
                            width={30}
                            className="mr-2"
                          />
                          {selectedWallet.name} - Net: {selectedWallet.net} {selectedWallet.currency}
                        </div>
                      ) : (
                        'Select Payment Method'
                      )}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {wallets.map((wallet) => {
                      const flagCountryCode = currencyToCountryMap[wallet.currency.toUpperCase()] || 'us';
                      const balance = typeof wallet.balance === 'object' ? wallet.balance.amount : wallet.balance;
                      
                      return (
                        <SelectItem key={wallet.id} value={wallet.id}>
                          <div className="flex items-center">
                            <CircleFlag
                              countryCode={flagCountryCode}
                              height={20}
                              width={30}
                              className="mr-2"
                            />
                            {wallet.name} - Balance: {balance.toFixed(2)} {wallet.currency}
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              ) : (
                <p>Loading payment methods...</p>
              )}
            </div>
            {/* Fee and Exchange Rate */}
            <ExchangeDetails fee={fee} currency={currency} exchangeRate={exchangeRate} />
            {/* Error Message */}
            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <Card>
              <CardHeader>
                <CardTitle className="text-2xl font-bold text-center">
                  Review your transfer
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">
                    Recipient
                  </h3>
                  <div className="flex items-center space-x-4">
                    <Avatar>
                      <AvatarImage
                        src={selectedRecipientData?.avatar}
                        alt={selectedRecipientData?.name}
                      />
                      <AvatarFallback>
                        {selectedRecipientData?.name.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <p className="text-lg font-semibold">
                        {selectedRecipientData?.name}
                      </p>
                      <p className="text-sm text-gray-600">
                        {selectedRecipientData?.bankDetails}
                      </p>
                      <p className="text-sm text-gray-600">
                        {selectedRecipientData?.country}
                      </p>
                      <p className="text-sm font-medium text-green-500">
                        {selectedRecipientData?.tag}
                      </p>
                    </div>
                    {selectedRecipientData?.isFavorite && (
                      <Heart className="text-red-500" size={20} />
                    )}
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">
                      You send
                    </h3>
                    <p className="text-lg font-semibold">
                      {fromAmount} {currency}
                    </p>
                  </div>
                  <div className="text-right">
                    <h3 className="text-sm font-medium text-gray-500">
                      They receive
                    </h3>
                    <p className="text-lg font-semibold">
                      {receivedAmount.toFixed(2)} {currency}
                    </p>
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between items-center">
                  <h3 className="text-sm font-medium text-gray-500">Fee</h3>
                  <p className="text-lg font-semibold">
                    -{fee.toFixed(2)} {currency}
                  </p>
                </div>
                <Separator />
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">
                    Description
                  </h3>
                  <p className="text-lg font-semibold">
                    {description || 'No description provided'}
                  </p>
                </div>
                <Separator />
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-500">
                    Payment method
                  </h3>
                  <p className="text-lg font-semibold">
                    {paymentMethod
                      ? wallets.find((w) => w.id === paymentMethod)?.name
                      : 'No payment method selected'}
                  </p>
                </div>
              </CardContent>
            </Card>
            {errorMessage && (
              <p className="text-red-500 text-sm mt-4">{errorMessage}</p>
            )}
          </motion.div>
        );
      case 3:
        const finalRecipient = recipients.find(
          (r) => r.id === selectedRecipient
        );
        return (
          <motion.div
            key="step-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900">
              Payment successful
            </h2>
            <div className="p-4 bg-green-50 border border-green-200 rounded-md">
              <p className="text-green-700">
                Your transfer of {fromAmount} {currency} to{' '}
                {finalRecipient?.name} ({finalRecipient?.tag}) has been
                processed successfully.
              </p>
            </div>
            <p className="text-sm text-gray-600">
              Transaction ID: {transactionId}
            </p>
            <p className="text-sm text-gray-600">
              Date: {new Date().toLocaleDateString()}
            </p>
          </motion.div>
        );
      default:
        return null;
    }
  }, [
    step,
    isAddingRecipient,
    searchTerm,
    beneficiariesLoading,
    beneficiariesError,
    filteredRecipients,
    selectedRecipient,
    toggleFavorite,
    errorMessage,
    fromAmount,
    currency,
    receivedAmount,
    fee,
    description,
    paymentMethod,
    wallets,
    transactionId,
    selectedRecipientData,
    recipients,
    handleAddRecipient, // Ensure all dependencies are included
  ]);

  const handleReset = useCallback(() => {
    console.log('TransferModal: Resetting to initial state');
    setStep(0);
    setSelectedRecipient(null);
    setTransactionId('');
    setFromAmount('500');
    setCurrency('GBP');
    setPaymentMethod(wallets.length > 0 ? wallets[0].id : '');
    setDescription('');
    setFee(0);
    setExchangeRate(1);
    setReceivedAmount(0);
    updateSteps((prevSteps: any) => {
      console.log('TransferModal: Resetting steps', prevSteps);
      return prevSteps.map((s: any, i: number) => ({
        ...s,
        status: i === 0 ? 'current' : 'upcoming',
      }));
    });
    closeModal(); // Optionally close the modal after successful transfer
  }, [wallets, updateSteps, closeModal]);

  // Update the useEffect for fetching conversion rates
  useEffect(() => {

    console.log('beneficiaries: wallets', beneficiaries);
    const fetchConversion = async () => {
      // Skip API call if amount is 0 or empty
      if (parseFloat(debouncedFromAmount) === 0 || !debouncedFromAmount) {
        setReceivedAmount(0);
        setFee(0);
        setExchangeRate(1);
        return;
      }

      // Skip API call if no recipient is selected
      if (!selectedRecipientData) {
        setReceivedAmount(0);
        setFee(0);
        setExchangeRate(1);
        return;
      }

      try {
        setErrorMessage('');
        console.log('TransferModal: Calling api.getConversion with params:', {
          fromCurrency: currency.toUpperCase(),
          toCurrency: selectedRecipientData.country.toUpperCase(),
          amount: parseFloat(debouncedFromAmount)
        });

        const result = await api.getConversion(
          currency.toUpperCase(),
          selectedRecipientData.country.toUpperCase(),
          parseFloat(debouncedFromAmount)
        );

        // Use the values directly from the API response
        setExchangeRate(result.rate);
        setFee(result.fee);
        setReceivedAmount(result.converted_amount);

        console.log('TransferModal: Conversion successful:', result);
      } catch (error) {
        console.error('TransferModal: Error in conversion process:', error);
        // In case of error, set default values
        setReceivedAmount(parseFloat(debouncedFromAmount));
        setFee(0);
        setExchangeRate(1);
        setErrorMessage('Error fetching conversion rates. Using default values.');
      }
    };

    fetchConversion();
  }, [debouncedFromAmount, currency, selectedRecipientData]);

  // Update steps based on current step
  useEffect(() => {
    console.log('beneficiaries: wallets', beneficiaries);
    updateSteps([
      {
        label: 'Recipient',
        status:
          step === 0 ? 'current' : step > 0 ? 'completed' : 'upcoming',
      },
      {
        label: 'Amount',
        status:
          step === 1 ? 'current' : step > 1 ? 'completed' : 'upcoming',
      },
      {
        label: 'Review',
        status:
          step === 2 ? 'current' : step > 2 ? 'completed' : 'upcoming',
      },
      {
        label: 'Confirm',
        status: step === 3 ? 'current' : 'upcoming',
      },
    ]);
  }, [step, updateSteps]);

  // Initialize paymentMethod when wallets are loaded
  useEffect(() => {

    console.log('beneficiaries: wallets', beneficiaries);
    if (wallets.length > 0 && !paymentMethod) {
      setPaymentMethod(wallets[0].id);
    }
  }, [wallets, paymentMethod]);

  const handleNextStep = useCallback(async () => {
    if (step === 2) {
      // Confirm and Pay
      if (!selectedRecipientData) {
        setErrorMessage('No recipient selected.');
        return;
      }
      if (!paymentMethod) {
        setErrorMessage('No payment method selected.');
        return;
      }
      try {
        setErrorMessage('');
        const toCurrency = currencyToCountryMap[selectedRecipientData.country.toUpperCase()] || 'EUR';
        const result: PayTransferResponse = await api.payTransfer(
          parseFloat(fromAmount),
          currency.toUpperCase(),
          description
        );
        console.log('Payment successful:', result);
        setTransactionId(result.transaction_id);
        setStep(3); // Move to success step
      } catch (error: any) {
        console.error('Error making payment:', error);
        setErrorMessage('Failed to complete the transfer. Please try again.');
      }
    } else {
      const nextStep = step + 1;
      console.log(`TransferModal: Moving to step ${nextStep}`);
      setStep(nextStep);
    }
  }, [step, selectedRecipientData, paymentMethod, fromAmount, currency, description]);

  const handlePrevStep = useCallback(() => {
    const prevStep = Math.max(step - 1, 0);
    console.log(`TransferModal: Moving back to step ${prevStep}`);
    setStep(prevStep);
  }, [step]);

  // Add specific styles for page view
  const containerStyles = useMemo(() => ({
    width: '100%',
    maxWidth: isPageView ? '100%' : '640px',
    margin: '0 auto',
    padding: isPageView ? '24px' : '0',
    height: isPageView ? 'auto' : '100%',
  }), [isPageView]);

  return (
    <div className="w-full" style={containerStyles}>
      <div className={`space-y-4 ${isPageView ? 'max-w-4xl mx-auto' : ''}`}>
        <div className="p-6 h-full flex flex-col relative">
          <div className="flex-grow overflow-y-auto">
            <AnimatePresence mode="wait" initial={false}>
              {renderStepContent()}
            </AnimatePresence>
          </div>
          <div className="mt-6 flex justify-between">
            {step > 0 && step < 3 && (
              <Button
                onClick={handlePrevStep}
                variant="outline"
                className="h-11 rounded-full"
              >
                Previous
              </Button>
            )}
            {step < 3 && (
              <Button
                onClick={handleNextStep}
                className="h-11 bg-green-500 hover:bg-green-600 text-white ml-auto rounded-full"
                disabled={step === 0 && selectedRecipient === null}
              >
                {step === 2 ? 'Confirm and Pay' : 'Continue'}
              </Button>
            )}
            {step === 3 && (
              <Button
                onClick={handleReset}
                className="h-11 bg-green-500 hover:bg-green-600 text-white ml-auto rounded-full"
              >
                New Transfer
              </Button>
            )}
          </div>
          {/* Display loading indicator if any */}
          {(beneficiariesLoading || errorMessage) && (
            <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
              <div className="loader"></div> {/* Replace with your preferred loader */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransferModal;
