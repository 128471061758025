// resources/js/pages/sendPayment.tsx

import React, { useState } from 'react'
import { ChevronRight, Calendar, Edit2, ArrowLeft, Search, UserPlus, Check } from 'lucide-react'
import { CircleFlag } from 'react-circle-flags'

interface Contact {
  id: string
  initials: string
  name: string
  email: string
  flag: string
}

interface Currency {
  code: string
  name: string
  flag: string
}

export function PaymentFlow() {
  const [step, setStep] = useState(1)
  const [screen, setScreen] = useState<'payees' | 'newPayee' | 'currency' | 'amount' | 'review' | 'pay'>('payees')
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null)
  const [amount, setAmount] = useState('')
  const [receivedAmount, setReceivedAmount] = useState('')
  const [reference, setReference] = useState('')
  const [scheduleTransfer, setScheduleTransfer] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [frequency, setFrequency] = useState('Once')

  const steps = [
    { label: 'Payees', completed: step > 1 },
    { label: 'Currency', completed: step > 2 },
    { label: 'Amount', completed: step > 3 },
    { label: 'Review', completed: step > 4 },
    { label: 'Pay', completed: step > 5 }
  ]

  const currencies: Currency[] = [
    { code: 'GBP', name: 'British Pound', flag: 'gb' },
    { code: 'EUR', name: 'Euro', flag: 'eu' },
    { code: 'USD', name: 'US Dollar', flag: 'us' },
  ]

  const recentContacts: Contact[] = [
    { id: '1', initials: 'JD', name: 'John Doe', email: 'john.doe@example.com', flag: 'gb' },
    { id: '2', initials: 'JS', name: 'Jane Smith', email: 'jane.smith@example.com', flag: 'gb' },
    { id: '3', initials: 'MJ', name: 'Michael Johnson', email: 'michael.johnson@example.com', flag: 'ca' },
    { id: '4', initials: 'EA', name: 'Emily Adams', email: 'emily.adams@example.com', flag: 'au' },
    { id: '5', initials: 'DW', name: 'Daniel White', email: 'daniel.white@example.com', flag: 'nz' },
    { id: '6', initials: 'SS', name: 'Sarah Stein', email: 'sarah.stein@example.com', flag: 'il' },
  ]
  
  const allContacts: Contact[] = [
    { id: '7', initials: 'JD', name: 'John Doe', email: 'john.doe2@example.com', flag: 'us' },
    { id: '8', initials: 'LG', name: 'Linda Green', email: 'linda.green@example.com', flag: 'ca' },
    { id: '9', initials: 'MJ', name: 'Mark Johnson', email: 'mark.johnson@example.com', flag: 'gb' },
    { id: '10', initials: 'AP', name: 'Anna Parker', email: 'anna.parker@example.com', flag: 'au' },
    { id: '11', initials: 'TW', name: 'Tom Williams', email: 'tom.williams@example.com', flag: 'nz' },
    { id: '12', initials: 'CL', name: 'Cathy Lee', email: 'cathy.lee@example.com', flag: 'ie' },
    { id: '13', initials: 'JW', name: 'James White', email: 'james.white@example.com', flag: 'us' },
  ]
  

  const handlePayeeSelect = (payee: Contact) => {
    setSelectedContact(payee)
    setStep(2)
    setScreen('currency')
  }

  const handleAddNewPayee = () => {
    setScreen('newPayee')
  }

  const handleCurrencySelect = (currency: Currency) => {
    setSelectedCurrency(currency)
    setStep(3)
    setScreen('amount')
  }

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1)
      if (screen === 'currency') {
        setScreen('payees')
      } else if (screen === 'amount') {
        setScreen('currency')
      } else if (screen === 'review') {
        setScreen('amount')
      } else if (screen === 'pay') {
        setScreen('review')
      } else if (screen === 'newPayee') {
        setScreen('payees')
      }
    }
  }

  const handleContinue = () => {
    if (screen === 'amount') {
      setStep(4)
      setScreen('review')
    } else if (screen === 'review') {
      setStep(5)
      setScreen('pay')
    } else if (step < 5) {
      setStep(step + 1)
      setScreen(steps[step].label.toLowerCase() as 'payees' | 'currency' | 'amount' | 'review' | 'pay')
    }
  }

  const handleConfirmAndSend = () => {
    setStep(5)
    setScreen('pay')
  }

  return (
    <div className="min-h-screen flex flex-col  text-gray-900">
      {/* Progress Steps */}
      <div className="px-6 py-4 border-b border-gray-200 bg-white shadow-sm">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center">
            {steps.map((s, i) => (
              <React.Fragment key={i}>
                <div className="flex flex-col items-center">
                  <div className={`
                    relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 
                    ${s.completed 
                      ? 'border-[#24EC7E] bg-[#24EC7E]' 
                      : i + 1 === step 
                        ? 'border-[#24EC7E] bg-white' 
                        : 'border-gray-300 bg-white'
                    }
                  `}>
                    {s.completed && <Check className="h-4 w-4 text-white" />}
                    {!s.completed && (i + 1 === step) && <span className="font-semibold">{i + 1}</span>}
                    {!s.completed && (i + 1 !== step) && <span className="font-semibold">{i + 1}</span>}
                  </div>
                  <span className={`mt-2 text-sm font-medium ${
                    i + 1 === step ? 'text-[#24EC7E]' : 'text-gray-500'
                  }`}>
                    {s.label}
                  </span>
                </div>
                {i < steps.length - 1 && (
                  <div className="flex-1 h-0.5 bg-gray-200 relative">
                    <div 
                      className="h-full bg-[#24EC7E] transition-all duration-300"
                      style={{ width: s.completed ? '100%' : '0%' }}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        <div className="max-w-4xl w-full mx-auto px-6 py-8 flex-1">
          {/* Back Button */}
          {step > 1 && (
            <button
              onClick={handleBack}
              className="mb-6 flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors"
              aria-label="Go back"
            >
              <ArrowLeft className="h-5 w-5" />
            </button>
          )}

          {/* Header */}
          {screen !== 'pay' && (
            <h1 className="text-2xl font-semibold text-gray-900 mb-6">
              {screen === 'amount' ? 'Enter the Amount' :
                screen === 'payees' ? 'Select the Payee' : 
                screen === 'currency' ? 'Select Currency' :
                screen === 'review' ? 'Review Summary' :
                screen === 'newPayee' ? 'Add New Payee' :
                'Confirm Payment'}
            </h1>
          )}

          {/* Payees Screen */}
          {screen === 'payees' && (
            <div className="space-y-6">
              {/* Search Input */}
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search Name, @PaayCotag, email or phone"
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-base placeholder:text-gray-400"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              {/* Add New Payee */}
              <button
                onClick={handleAddNewPayee}
                className="w-full flex items-center justify-between p-4 bg-white shadow-sm rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center">
                  <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mr-4">
                    <UserPlus className="w-6 h-6 text-gray-600" />
                  </div>
                  <span className="font-medium text-gray-700">Add new payee</span>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </button>

              {/* Recent Payees */}
              {/* Recent Contacts */}
<div>
  <h2 className="text-sm font-medium text-gray-700 mb-4">Recent</h2>
  <div className="flex space-x-4 overflow-x-auto pb-4">
    {recentContacts.map((contact) => (
      <button
        key={contact.id}
        onClick={() => handlePayeeSelect(contact)}
        className="flex flex-col items-center space-y-1"
      >
        <div className="w-14 h-14 bg-gray-100 rounded-full flex items-center justify-center relative"> {/* Updated size */}
          <span className="text-base font-medium text-gray-700">{contact.initials}</span> {/* Updated font size */}
          <div className="absolute -bottom-1 -right-1">
            <CircleFlag countryCode={contact.flag} height="16" width="16" />
          </div>
        </div>
        <span className="text-xs text-center text-gray-600">{contact.name.split(' ')[0]}</span>
      </button>
    ))}
  </div>
</div>


              {/* All Payees */}
              <div>
                <h2 className="text-sm font-medium text-gray-700 mb-4">All payees</h2>
                <div className="grid grid-cols-1 gap-2">
                  {allContacts
                    .filter(contact => 
                      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      contact.email.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((contact) => (
                      <button
                        key={contact.id}
                        onClick={() => handlePayeeSelect(contact)}
                        className="w-full flex items-center justify-between p-4 bg-white shadow-sm rounded-lg hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex items-center">
                          <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mr-4 relative">
                            <span className="text-sm font-medium text-gray-700">{contact.initials}</span>
                            <div className="absolute -bottom-1 -right-1">
                              <CircleFlag countryCode={contact.flag} height="16" width="16" />
                            </div>
                          </div>
                          <div>
                            <div className="font-medium text-gray-700">{contact.name}</div>
                            <div className="text-sm text-gray-500">{contact.email}</div>
                          </div>
                        </div>
                        <ChevronRight className="w-5 h-5 text-gray-400" />
                      </button>
                    ))}
                </div>
              </div>
            </div>
          )}

          {/* New Payee Screen */}
          {screen === 'newPayee' && (
            <div className="space-y-6">
              {/* Search PaayCo */}
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900">Search PaayCo</h2>
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <input
                    type="text"
                    placeholder="Search Name, @PaayCotag, email or phone"
                    className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-base placeholder:text-gray-400"
                  />
                </div>
              </div>

              {/* Enter Bank Details */}
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900">Enter bank details</h2>
                <input
                  type="text"
                  placeholder="Add bank details of the payee"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-base placeholder:text-gray-400"
                />
              </div>

              {/* Continue Button */}
              <button
                onClick={() => setScreen('currency')}
                className="w-full py-3 px-6 bg-[#24EC7E] text-white font-medium rounded-lg hover:bg-[#1DCB6C] transition-colors"
              >
                Continue
              </button>
            </div>
          )}

          {/* Currency Selection Screen */}
          {screen === 'currency' && (
            <div className="space-y-6">
              {/* Search Currency */}
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search Currency"
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-base placeholder:text-gray-400"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              {/* Currency List */}
              <div className="space-y-2">
                {currencies
                  .filter(currency => 
                    currency.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    currency.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((currency) => (
                    <button
                      key={currency.code}
                      onClick={() => handleCurrencySelect(currency)}
                      className="w-full flex items-center justify-between p-4 bg-white shadow-sm rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      <div className="flex items-center">
                        <div className="w-10 h-10 mr-4">
                          <CircleFlag countryCode={currency.flag} height="40" width="40" />
                        </div>
                        <div className="flex flex-col">
                          <span className="font-medium text-gray-700">{currency.code}</span>
                          <span className="text-sm text-gray-500">{currency.name}</span>
                        </div>
                      </div>
                      <ChevronRight className="w-5 h-5 text-gray-400" />
                    </button>
                  ))}
              </div>
            </div>
          )}

          {/* Amount Input Screen */}
          {screen === 'amount' && (
            <div className="space-y-6">
              {/* Transfer Details */}
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4 bg-white shadow-sm rounded-lg p-4">
                  <CircleFlag countryCode={selectedCurrency?.flag || 'gb'} height="32" width="32" />
                  <div>
                    <p className="text-sm text-gray-500">From</p>
                    <p className="font-medium text-gray-700">Main Account</p>
                    <p className="text-sm text-gray-500">{selectedCurrency?.code} 200.00</p>
                  </div>
                </div>
                <div className="bg-gray-100 rounded-full p-2">
                  <ChevronRight className="w-6 h-6 text-gray-400" />
                </div>
                <div className="flex items-center space-x-4 bg-white shadow-sm rounded-lg p-4">
                  <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                    <span className="text-sm font-medium text-gray-700">{selectedContact?.initials}</span>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">To</p>
                    <p className="font-medium text-gray-700">{selectedContact?.name}</p>
                    <p className="text-sm text-gray-500">{selectedContact?.email}</p>
                  </div>
                  <CircleFlag countryCode={selectedContact?.flag || 'gb'} height="16" width="16" />
                </div>
              </div>

              {/* Amount Input */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">How much are you sending?</label>
                <div className="relative">
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg pr-20 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-lg placeholder:text-gray-500"
                    placeholder="0"
                    min="0"
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
                    <CircleFlag countryCode={selectedCurrency?.flag || 'gb'} height="24" width="24" />
                    <span className="font-medium text-gray-700">{selectedCurrency?.code}</span>
                  </div>
                </div>
                <p className="text-sm text-gray-500">Amount available for transfer: {selectedCurrency?.code} 200.00</p>
              </div>

              {/* Recipient Receives */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">{selectedContact?.name} receives</label>
                <div className="relative">
                  <input
                    type="number"
                    value={receivedAmount}
                    onChange={(e) => setReceivedAmount(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg pr-20 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-lg placeholder:text-gray-500"
                    placeholder="0"
                    min="0"
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
                    <CircleFlag countryCode="eu" height="24" width="24" /> {/* Assuming EUR is the received currency */}
                    <span className="font-medium text-gray-700">EUR</span>
                  </div>
                </div>
              </div>

              {/* Reference Input */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Add a reference number or message (optional)</label>
                <input
                  type="text"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900 text-base placeholder:text-gray-500"
                  placeholder="Reference"
                />
              </div>

              {/* Schedule Transfer */}
              <div className="flex items-center justify-between bg-white shadow-sm rounded-lg p-4">
                <span className="text-gray-700">Schedule transfer</span>
                <button
                  onClick={() => setScheduleTransfer(!scheduleTransfer)}
                  className={`w-12 h-6 rounded-full transition-colors ${
                    scheduleTransfer ? 'bg-[#24EC7E]' : 'bg-gray-200'
                  } flex items-center p-0.5`}
                  aria-label="Toggle Schedule Transfer"
                >
                  <div
                    className={`w-5 h-5 rounded-full bg-white transform transition-transform ${
                      scheduleTransfer ? 'translate-x-6' : 'translate-x-1'
                    }`}
                  />
                </button>
              </div>

              {/* Schedule Options */}
              {scheduleTransfer && (
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-1 text-gray-700">Start date</label>
                    <div className="relative">
                      <input
                        type="text"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg pr-10 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900"
                        placeholder="dd-mm-yyyy"
                      />
                      <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1 text-gray-700">End date (optional)</label>
                    <div className="relative">
                      <input
                        type="text"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg pr-10 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white text-gray-900"
                        placeholder="dd-mm-yyyy"
                      />
                      <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1 text-gray-700">Frequency</label>
                    <div className="relative">
                      <select
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg text-gray-900 appearance-none pr-10 focus:outline-none focus:ring-2 focus:ring-[#24EC7E] focus:border-transparent bg-white"
                      >
                        <option>Once</option>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </select>
                      <ChevronRight className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              )}

              {/* Continue Button */}
              <button
                onClick={handleContinue}
                className="w-full py-3 px-6 bg-[#24EC7E] text-white font-medium rounded-lg hover:bg-[#1DCB6C] transition-colors"
              >
                Continue
              </button>
            </div>
          )}

          {/* Review Screen */}
          {screen === 'review' && (
            <div className="space-y-6">
              {/* Transfer Summary */}
              <div className="bg-white shadow-sm rounded-lg">
                <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                  <h2 className="text-lg font-semibold">Transfer Summary</h2>
                  <button className="text-[#24EC7E]" onClick={() => setScreen('amount')}>
                    <Edit2 className="h-5 w-5" />
                  </button>
                </div>
                <div className="p-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Amount to be transferred</span>
                    <span className="font-medium">{amount} {selectedCurrency?.code}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Transfer charges</span>
                    <span className="font-medium">0 {selectedCurrency?.code}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Recipient gets</span>
                    <span className="font-medium">{receivedAmount} EUR</span>
                  </div>
                </div>
              </div>

              {/* Payee Details */}
              <div className="bg-white shadow-sm rounded-lg">
                <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                  <h2 className="text-lg font-semibold">Payee Details</h2>
                  <button className="text-[#24EC7E]" onClick={() => setScreen('payees')}>
                    Change
                  </button>
                </div>
                <div className="p-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Name</span>
                    <span className="font-medium">{selectedContact?.name}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Email address</span>
                    <span className="font-medium">{selectedContact?.email}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Reference</span>
                    <span className="font-medium">{reference || 'N/A'}</span>
                  </div>
                </div>
              </div>

              {/* Confirm and Send Button */}
              <button
                onClick={handleConfirmAndSend}
                className="w-full py-3 px-6 bg-[#24EC7E] text-white font-medium rounded-lg hover:bg-[#1DCB6C] transition-colors"
              >
                Confirm and Send
              </button>
            </div>
          )}

          {/* Pay Confirmation Screen */}
          {screen === 'pay' && (
            <div className="text-center space-y-6">
              <h2 className="text-3xl font-bold mb-8 text-gray-900">Transfer Sent</h2>
              <div className="w-24 h-24 bg-[#24EC7E] rounded-full flex items-center justify-center mx-auto mb-8">
                <Check className="h-12 w-12 text-white" />
              </div>
              <div className="space-y-4">
                <button
                  onClick={() => {
                    setStep(1)
                    setScreen('payees')
                    setSelectedContact(null)
                    setSelectedCurrency(null)
                    setAmount('')
                    setReceivedAmount('')
                    setReference('')
                    setScheduleTransfer(false)
                    setStartDate('')
                    setEndDate('')
                    setFrequency('Once')
                  }}
                  className="w-full py-3 px-6 bg-gray-100 text-gray-600 font-medium rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Schedule another transfer
                </button>
                <button
                  onClick={() => {
                    // Handle completion logic here
                    console.log('Transfer process completed')
                  }}
                  className="w-full py-3 px-6 bg-[#24EC7E] text-white font-medium rounded-lg hover:bg-[#1DCB6C] transition-colors"
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentFlow
