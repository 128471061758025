// src/ErrorBoundary.tsx

import React, { Component, ReactNode } from "react";
import { Button } from "./button";
import { AlertCircle, RefreshCw, BarChart2 } from "lucide-react";
import AuthContext from "./contexts/AuthContext"; // Adjust the import path accordingly

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  static contextType = AuthContext;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
    // You can also log the error to Sentry or another logging service here
  }

  handleRefresh = () => {
    // Clear AuthContext data
    if (this.context && this.context.clearContext) {
      this.context.clearContext();
    }

    // Clear local storage
    localStorage.clear();

    // Clear session storage
    sessionStorage.clear();

    // Clear cookies
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(
          /=.*/,
          "=;expires=" + new Date().toUTCString() + ";path=/"
        );
    });

    // Clear caches
    if ("caches" in window) {
      caches.keys().then(function (names) {
        names.forEach(function (name) {
          caches.delete(name);
        });
      });
    }

    // Reload the page
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      const { error } = this.state;
      return (
        <div className="min-h-screen bg-gradient-to-br from-green-50 to-green-100 flex items-center justify-center px-4 py-6 sm:py-12 sm:px-6 lg:px-8">
          <div className="max-w-md w-full bg-white p-8 sm:p-12 rounded-2xl shadow-xl flex flex-col items-center">
            {/* Error Icon */}
            <AlertCircle className="h-12 w-12 text-green-500 mb-4" />

            {/* Error Title */}
            <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">
              Something Went Wrong
            </h1>

            {/* Error Message */}
            <p className="text-gray-600 text-center mb-6">
              We encountered an unexpected error. Please try refreshing the page.
            </p>

            {/* Error Details */}
            {error && (
              <div
                className="w-full bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6"
                role="alert"
              >
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline"> {error.toString()}</span>
              </div>
            )}

            {/* Refresh Button */}
            <Button
              onClick={this.handleRefresh}
              className="w-full flex items-center justify-center py-3 px-4 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg shadow-md transition duration-300"
            >
              <RefreshCw className="h-5 w-5 mr-2" />
              Refresh Page
            </Button>

            {/* Support Information */}
            <p className="mt-4 text-sm text-gray-500 text-center">
              If the problem persists, please contact our support team at{" "}
              <a
                href="mailto:support@Ebannc.com"
                className="text-green-500 underline hover:text-green-600"
              >
                support@Ebannc.com
              </a>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
