// src/contexts/AuthContext.tsx

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import {
  AuthContextData,
  AuthProviderProps,
  User,
  Wallet,
  Event,
  Statement,
  Beneficiary,
} from './AuthContextData/types';
import { useAuthUser } from './AuthContextData/useAuthUser';
import { useAuthWallets } from './AuthContextData/useAuthWallets';
import { useAuthBeneficiaries } from './AuthContextData/useAuthBeneficiaries';
import { useAuthEvents } from './AuthContextData/useAuthEvents';
import { useAuthExchangeRates } from './AuthContextData/useAuthExchangeRates.tsx';
import { useAuthStatements } from './AuthContextData/useAuthStatements';
import Cookies from 'js-cookie';
import api, { resetSession } from '../services/api';
import * as Sentry from '@sentry/react';
import { logAuthEvent } from './AuthContextData/utils';
import { generateStatementsFromTransactions } from './AuthContextData/statementUtils';
import fileDownload from 'js-file-download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Near the top of the file, add this type definition
interface AuthUser {
  id: number;
  name: string;
  email: string;
  avatar: string | null;
  activeWallet: string;
  totalBalance: number;
}

// Define Event interface with timestamp as Date
interface Event {
  id: string;
  message: string;
  transactionId?: string;
  timestamp: Date; // Ensure this property exists and is of type Date
  read: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthContextType {
  statements: Statement[];
  wallets: Wallet[];
  loading: boolean;
  // ... other auth context properties
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // Add a ref to track if initial data has been loaded
  const initialDataLoaded = React.useRef(false);
  
  // Define user state before hooks that might use it
  const [user, setUser] = useState<AuthUser | null>(() => {
    try {
      const storedUser = localStorage.getItem('user');
      const token = localStorage.getItem('_api_token');

      if (!storedUser || !token) {
        return null;
      }

      const parsedUser = JSON.parse(storedUser);
      const storedActiveWallet = Cookies.get('activeWallet');

      // Ensure the parsed user matches our AuthUser interface
      const typedUser: AuthUser = {
        id: Number(parsedUser.id), // Convert id to number
        name: parsedUser.name,
        email: parsedUser.email,
        avatar: parsedUser.avatar,
        activeWallet: storedActiveWallet || 'GBP',
        totalBalance: 0
      };

      if (!storedActiveWallet) {
        Cookies.set('activeWallet', 'GBP', { expires: 365 });
      }

      return typedUser;
    } catch (e) {
      console.error('AuthContext: Error parsing user from localStorage:', e);
      return null;
    }
  });

  // Define clearContext before other hooks
  const clearContext = useCallback(() => {
    setUser(null);
    setWallets([]);
    setBeneficiaries([]);
    setStatements([]);
    setEvents([]);
    setExchangeRates({});
    setError(null);
    setIsInitialLoad(false);
    setRetryCount(0);
    initialDataLoaded.current = false;
    
    // Batch localStorage removals
    const keysToRemove = [
      'user',
      'wallets',
      'beneficiaries',
      'statements',
      'events',
      'exchangeRatesLastFetch',
      '_api_token'
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
    Cookies.remove('activeWallet');
  }, []);

  // Initialize User Hook
  const {
    login,
    logout,
    error: userError,
    requiresTwoFactor,
    authLoading,
  } = useAuthUser({ clearContext, setUser }); // Pass setUser here

  // Initialize Wallets Hook with required props
  const {
    wallets,
    setWallets,
    fetchWallets,
    error: walletsError,
    loadingWallets,
  } = useAuthWallets({ user, logout });

  // Initialize Events Hook with required props
  const {
    events,
    setEvents,
    fetchEvents,
    error: eventsError,
    loadingEvents,
  } = useAuthEvents({ logout });

  // Initialize Beneficiaries Hook with required props
  const {
    beneficiaries,
    setBeneficiaries,
    fetchBeneficiaries,
    addBeneficiary,
    toggleFavorite,
    error: beneficiariesError,
    loadingBeneficiaries,
  } = useAuthBeneficiaries({ 
    logout,
    onFetch: async () => {
      try {
        const response = await api.getBeneficiaries();
        console.log('Raw beneficiaries response:', response);
        
        // The response is already the array of beneficiaries
        setBeneficiaries(response);
        
        console.log('Beneficiaries set in context:', response);
      } catch (error) {
        console.error('Error fetching beneficiaries:', error);
        throw error;
      }
    }
  });

  // Initialize Exchange Rates Hook with required props
  const {
    exchangeRates,
    setExchangeRates,
    fetchExchangeRates,
    exchangeRatesError,
    loadingExchangeRates,
  } = useAuthExchangeRates({ user });

  // Initialize Statements Hook with required props
  const {
    statements,
    setStatements,
    fetchAllTransactions,
    error: statementsError,
    loadingStatements,
  } = useAuthStatements({ wallets, user, fetchExchangeRates });

  // Define error state
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState<number>(0);

  // Add exchange rates cache time tracking
  const [lastExchangeRatesFetch, setLastExchangeRatesFetch] = useState<number>(() => {
    const stored = localStorage.getItem('exchangeRatesLastFetch');
    return stored ? parseInt(stored, 10) : 0;
  });

  // Add state for totalBalance
  const [totalBalance, setTotalBalance] = useState<number>(0);

  // Add state for mainAccountBalance
  const [mainAccountBalance, setMainAccountBalance] = useState<{ amount: number; currency: string }>({ amount: 0, currency: 'GBP' });

  // Compute mainAccountBalance whenever user or wallets change
  useEffect(() => {
    if (user && wallets.length > 0) {
      const baseCurrency = user.activeWallet.toUpperCase();
      const mainWallet = wallets.find(wallet => wallet.currency.toLowerCase() === user.activeWallet.toLowerCase());
      const balance = mainWallet ? (typeof mainWallet.balance === 'number' ? mainWallet.balance : 0) : 0;
      const currency = mainWallet?.currency || user.activeWallet;
      setMainAccountBalance({ amount: balance, currency });
    }
  }, [user, wallets]);

  // Compute totalBalance whenever wallets or exchangeRates change
  useMemo(() => {
    console.log('AuthContext: Calculating total account balance');
    if (!wallets || wallets.length === 0 || !exchangeRates || Object.keys(exchangeRates).length === 0) {
      console.warn('AuthContext: Wallets or exchange rates not available yet.');
      setTotalBalance(0);
      return;
    }

    const baseCurrency = user?.activeWallet.toUpperCase() || 'GBP';

    const total = wallets.reduce((accumulator, wallet) => {
      const currency = wallet.currency.toUpperCase();
      const rate = exchangeRates[currency];
      
      if (wallet.balance == null || isNaN(wallet.balance)) {
        console.warn(`AuthContext: Invalid balance for wallet ${wallet.id}, currency ${wallet.currency}. Skipping.`);
        return accumulator;
      }

      if (!rate) {
        console.warn(`AuthContext: Exchange rate not found for currency ${currency}. Skipping.`);
        return accumulator;
      }

      const amountInBaseCurrency = currency === baseCurrency ? wallet.balance : wallet.balance / rate;
      return accumulator + amountInBaseCurrency;
    }, 0);

    console.log(`AuthContext: Total account balance calculated: ${baseCurrency} ${total.toFixed(2)}`);
    setTotalBalance(total);
  }, [wallets, exchangeRates, user?.activeWallet]);

  // Modify fetchAllData to reduce unnecessary operations
  const fetchAllData = useCallback(async () => {
    if (initialDataLoaded.current || !user) {
      return;
    }

    try {
      const token = localStorage.getItem('_api_token');
      if (!token) {
        throw new Error('No authentication token');
      }

      const fetchPromises = [];

      // Only fetch what's needed
      if (!wallets.length) fetchPromises.push(fetchWallets());
      if (!beneficiaries.length) fetchPromises.push(fetchBeneficiaries());
      
      // Check exchange rates cache
      const SIX_HOURS = 6 * 60 * 60 * 1000;
      const now = Date.now();
      if (!lastExchangeRatesFetch || (now - lastExchangeRatesFetch) > SIX_HOURS) {
        fetchPromises.push(fetchExchangeRates());
        setLastExchangeRatesFetch(now);
      }

      if (fetchPromises.length > 0) {
        await Promise.all(fetchPromises);
      }

      initialDataLoaded.current = true;
      setIsInitialLoad(false);
      setRetryCount(0);
    } catch (error) {
      console.error('Error in fetchAllData:', error);
      if (retryCount < 3) {
        setRetryCount(prev => prev + 1);
      } else {
        setError('Failed to load data after multiple attempts');
        setIsInitialLoad(false);
      }
    } finally {
      setIsAuthenticating(false);
    }
  }, [
    user,
    wallets.length,
    beneficiaries.length,
    lastExchangeRatesFetch,
    retryCount,
    fetchWallets,
    fetchBeneficiaries,
    fetchExchangeRates
  ]);

  // Modify the useEffect for initial authentication
  useEffect(() => {
    let mounted = true;
    const token = localStorage.getItem('_api_token');
    
    if (!token) {
      clearContext();
      return;
    }

    const initializeData = async () => {
      // Only fetch if we haven't loaded initial data and have a user
      if (mounted && user && !initialDataLoaded.current && !loading) {
        try {
          setLoading(true);
          resetSession();
          await fetchAllData();
        } catch (error) {
          console.error('Error initializing data:', error);
          if (mounted) {
            setError('Failed to initialize data');
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      }
    };

    initializeData();

    return () => {
      mounted = false;
    };
  }, [user, clearContext]); // Remove fetchAllData and loading from dependencies

  // Modify the localStorage sync effect
  useEffect(() => {
    if (loading || !user) return; // Add user check

    const batchStorageUpdates = () => {
      const updates: Record<string, string> = {};
      
      if (user) updates.user = JSON.stringify(user);
      if (wallets.length > 0) updates.wallets = JSON.stringify(wallets);
      if (beneficiaries.length > 0) updates.beneficiaries = JSON.stringify(beneficiaries);
      if (events.length > 0) updates.events = JSON.stringify(events);
      if (exchangeRates && Object.keys(exchangeRates).length > 0) {
        updates.exchangeRates = JSON.stringify(exchangeRates);
      }

      Object.entries(updates).forEach(([key, value]) => {
        try {
          localStorage.setItem(key, value);
        } catch (error) {
          console.error(`Error saving ${key} to localStorage:`, error);
        }
      });
    };

    const timeoutId = setTimeout(batchStorageUpdates, 1000);
    return () => clearTimeout(timeoutId);
  }, [user]); // Only depend on user changes

  // Modify the authentication loading state
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  // Update the loading combination
  const isLoading = loading || isAuthenticating;

  // Helper functions
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat(undefined, { style: 'currency', currency }).format(amount);
  };

  // Add the downloadStatement function
  const downloadStatement = useCallback(async () => {
    try {
      if (!statements || statements.length === 0) {
        alert('No statements available to download.');
        return;
      }

      // Select the desired statement. Here, we're choosing the latest one.
      const latestStatement = statements[statements.length - 1];

      const doc = new jsPDF();

      // Add Company Logo (Optional)
      // const imgData = 'data:image/jpeg;base64,...'; // Replace with your logo's base64
      // doc.addImage(imgData, 'JPEG', 14, 10, 50, 15);

      // Document Title
      doc.setFontSize(18);
      doc.text('Statement Report', 14, 22);

      // Statement Details
      doc.setFontSize(12);
      doc.text(`Statement Period: ${formatDate(latestStatement.startDate)} - ${formatDate(latestStatement.endDate)}`, 14, 32);
      doc.text(`Opening Balance: ${formatCurrency(latestStatement.openingBalance, latestStatement.currency)}`, 14, 40);
      doc.text(`Closing Balance: ${formatCurrency(latestStatement.closingBalance, latestStatement.currency)}`, 14, 48);

      // Add a table of transactions
      const tableColumn = ["Date", "Description", "Amount"];
      const tableRows: Array<Array<string | number>> = [];

      latestStatement.transactions.forEach(tx => {
        const txData = [
          formatDate(tx.date),
          tx.description,
          formatCurrency(tx.amount, tx.currency)
        ];
        tableRows.push(txData);
      });

      // AutoTable plugin for jsPDF
      (doc as any).autoTable({
        startY: 60,
        head: [tableColumn],
        body: tableRows,
        styles: { fontSize: 10 },
        headStyles: { fillColor: [36, 236, 126] }, // Green header
        alternateRowStyles: { fillColor: [240, 248, 255] }, // Light blue
      });

      // Save the PDF
      doc.save(`statement_${latestStatement.id}.pdf`);
      logAuthEvent('Statement downloaded successfully.', null, 'success');
    } catch (error) {
      console.error('Error generating PDF:', error);
      logAuthEvent('Failed to download statement.', { error }, 'error');
      alert('Failed to download statement.');
      throw error;
    }
  }, [statements]);

  // Add the downloadFeesReport function
  const downloadFeesReport = useCallback(async () => {
    try {
      const response = await api.get('/fees/download', { responseType: 'blob' }); // Adjust the endpoint as needed
      fileDownload(response.data, 'fees-report.pdf');
      logAuthEvent('Fees report downloaded successfully.', null, 'success');
    } catch (error) {
      logAuthEvent('Failed to download fees report.', { error }, 'error');
      throw error;
    }
  }, []);

  // Add the downloadAccountConfirmation function
  const downloadAccountConfirmation = useCallback(async () => {
    try {
      const response = await api.get('/account/confirmation/download', { responseType: 'blob' }); // Adjust the endpoint as needed
      fileDownload(response.data, 'account-confirmation.pdf');
      logAuthEvent('Account confirmation downloaded successfully.', null, 'success');
    } catch (error) {
      logAuthEvent('Failed to download account confirmation.', { error }, 'error');
      throw error;
    }
  }, []);

  // Add the downloadAuditReport function
  const downloadAuditReport = useCallback(async () => {
    try {
      const response = await api.get('/audit/report/download', { responseType: 'blob' }); // Adjust the endpoint as needed
      fileDownload(response.data, 'audit-report.pdf');
      logAuthEvent('Financial audit report downloaded successfully.', null, 'success');
    } catch (error) {
      logAuthEvent('Failed to download financial audit report.', { error }, 'error');
      throw error;
    }
  }, []);

  // Update the context value
  const contextValue: AuthContextData = {
    user,
    setUser,
    wallets,
    setWallets,
    beneficiaries,
    setBeneficiaries,
    statements,
    setStatements,
    events,
    setEvents,
    exchangeRates,
    setExchangeRates,
    totalBalance,
    setTotalBalance,
    mainAccountBalance,
    setMainAccountBalance,
    loading: isLoading,
    isInitialLoad,
    retryCount,
    error:
      error ||
      userError ||
      walletsError ||
      beneficiariesError ||
      eventsError ||
      statementsError ||
      exchangeRatesError,
    login,
    logout,
    requiresTwoFactor,
    clearContext,
    fetchBeneficiaries,
    addBeneficiary,
    toggleFavorite,
    fetchEvents,
    fetchWallets,
    fetchExchangeRates,
    fetchAllTransactions,
    downloadStatement,
    downloadFeesReport,
    downloadAccountConfirmation,
    downloadAuditReport,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
