// src/components/ProtectedRoute.tsx

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ModernBankingLoadingScreen from './loading/ModernBankingLoadingScreen';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  const token = localStorage.getItem('_api_token');
  const storedUser = localStorage.getItem('user');

  // Show loading screen during initial authentication
  if (loading) {
    return <ModernBankingLoadingScreen />;
  }

  // Check authentication
  if (!token || !storedUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
