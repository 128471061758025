// src/components/dashboardNew/components/TransactionsTableComponent.tsx

import React from 'react';
import { ArrowDown, ArrowUp, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Transaction } from './types'; // Ensure the path is correct

// Define button styles
const buttonStyles = {
  primary: {
    backgroundColor: '#24EC7E',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  secondary: {
    backgroundColor: '#F5F5F5',
    color: '#666666',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
  },
};

const listItemStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'background-color 0.2s',
    marginBottom: '1px',
    border: '1px solid #E5E7EB',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  icon: {
    color: '#666666',
    width: '20px',
    height: '20px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
  },
  description: {
    fontSize: '14px',
    color: '#666666',
  },
};

// Map your transaction data to the designer's format
function mapTransaction(transaction: Transaction) {
  const isReceived = ['exchanged', 'added', 'received'].includes(
    transaction.status.toLowerCase()
  );
  const isSent = ['sent', 'withdrawn'].includes(transaction.status.toLowerCase());

  return {
    id: transaction.id,
    name: transaction.description || 'No Description',
    type: isReceived ? 'received' : 'sent',
    details: transaction.status,
    date: new Date(transaction.created).toLocaleDateString(),
    amount: transaction.amount_from,
    currency: transaction.currency_from,
    equivalentAmount: transaction.order_amount,
    equivalentCurrency: transaction.order_currency,
  };
}

interface TransactionsTableProps {
  transactions: Transaction[];
  isLoading: boolean;
  error: Error | null;
}

export const TransactionsTableComponent: React.FC<TransactionsTableProps> = ({
  transactions,
  isLoading,
  error,
}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle "View All" button click
  const handleViewAll = () => {
    navigate('/transactions');
  };

  if (isLoading) {
    return (
      <div style={{ padding: '16px', textAlign: 'center', color: '#6B7280' }}>
        Loading transactions...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '16px', textAlign: 'center', color: '#DC2626' }}>
        Error loading transactions: {error.message}
      </div>
    );
  }

  if (!transactions || transactions.length === 0) {
    return (
      <div style={{ padding: '16px', textAlign: 'center', color: '#6B7280' }}>
        No transactions available.
      </div>
    );
  }

  // Map transactions to the designer's format
  const mappedTransactions = transactions.map(mapTransaction);

  return (
    <div className="w-full h-full bg-white p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Transactions</h2>
        <button
          style={buttonStyles.secondary}
          className="flex items-center gap-2"
          onClick={handleViewAll}
        >
          See All
          <ChevronRight className="w-4 h-4" />
        </button>
      </div>
      <div className="space-y-4">
        {mappedTransactions.slice(0, 3).map((transaction) => (
          <div
            key={transaction.id}
            style={listItemStyles.container}
            className="hover:bg-gray-50"
          >
            <div style={listItemStyles.content}>
              <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                {transaction.type === 'sent' ? (
                  <ArrowUp className="w-5 h-5 text-gray-600" />
                ) : (
                  <ArrowDown className="w-5 h-5 text-gray-600" />
                )}
              </div>
              <div style={listItemStyles.textContainer}>
                <span style={listItemStyles.title}>{transaction.name}</span>
                <span style={listItemStyles.description}>
                  {transaction.details} - {transaction.date}
                </span>
              </div>
            </div>
            <div className="text-right">
              <div className="text-base font-medium">
                {transaction.type === 'received' && '+'}
                {transaction.amount} {transaction.currency}
              </div>
              {transaction.equivalentAmount && (
                <div className="text-sm text-gray-500">
                  Equivalent {transaction.equivalentAmount}{' '}
                  {transaction.equivalentCurrency}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
