// src/components/transactions/components/Transactions.tsx

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { CircleFlag } from 'react-circle-flags';
import { Search, ArrowDown, ArrowUp, ChevronRight } from 'lucide-react';
import { useModal } from '../../../contexts/ModalContext';
import { useWallet } from '../../../contexts/WalletContext';
import { useAuth } from '../../../contexts/AuthContext';
import { format, subDays, startOfDay, endOfDay, parse, isWithinInterval } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { CurrencyCard } from '../../../components/dashboard/components/dashboard-components/CurrencyCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionButtons } from '../../../components/dashboard/components/dashboard-components/ActionButtons';
import TransferModal from '../../../components/dashboard/components/sendModal/transfer-modal';
import TopUpModal from '../../../components/dashboard/components/TopupModal/top-up-modal';
import ConvertCurrencyModalComponent from '../../../components/dashboard/components/convertModal/convert-currency-modal';
import RequestMoneyModal from '../../../components/dashboard/components/RequestMoney/request-money-modal';

// Import icons for the TransactionDetailModal
import { CheckCircle2, Share2, Download, AlertCircle } from 'lucide-react';

// Define necessary interfaces
interface Currency {
  name: string;
  code: string;
  balance: number;
  iban: string;
}

interface Transaction {
  id: string;
  payment_id?: string | null;
  description: string;
  amount_from: string | number;
  currency_from: string;
  order_amount: string;
  order_currency: string;
  status: string;
  created: string;
  walletId?: string;
  walletCurrency?: string;
  walletBalance?: number;
}

interface Wallet {
  id: string;
  currency: string;
  balance: string | number;
  transactions?: Transaction[];
}

type TransactionType = 'all' | 'incoming' | 'outgoing';

export function Transactions() {
  const { openModal } = useModal();
  const { wallets, loading: walletsLoading, error: walletsError } = useWallet();
  const { user } = useAuth();
  const location = useLocation();
  const highlightedRowRef = useRef<HTMLDivElement>(null);
  const [highlightedTransactionId, setHighlightedTransactionId] = useState<string | null>(null);

  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([]);
  const [transactionType, setTransactionType] = useState<TransactionType>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dateRange, setDateRange] = useState<string>('all');
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const tableRef = useRef<HTMLDivElement>(null);

  // Initialize selected currencies when wallets are loaded
  useEffect(() => {
    if (wallets.length > 0 && selectedCurrencies.length === 0) {
      setSelectedCurrencies(wallets.map(wallet => wallet.currency));
    }
  }, [wallets]);

  // Reset currentPage to 1 when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCurrencies, transactionType, searchTerm, dateRange]);

  const currencies: Currency[] = useMemo(() => {
    return wallets.map(wallet => {
      let balanceValue: number;

      if (typeof wallet.balance === 'string') {
        balanceValue = parseFloat(wallet.balance.replace(/[^\d.-]/g, ''));
      } else if (typeof wallet.balance === 'number') {
        balanceValue = wallet.balance;
      } else {
        console.warn(`Unexpected balance type for wallet ${wallet.id}:`, wallet.balance);
        balanceValue = 0;
      }

      return {
        name: wallet.currency,
        code: getCountryCodeFromCurrency(wallet.currency),
        balance: balanceValue,
        iban: wallet.id.slice(-4),
      };
    });
  }, [wallets]);

  const transactions: Transaction[] = useMemo(() => {
    return wallets.flatMap(wallet =>
      (wallet.transactions || []).map(transaction => ({
        ...transaction,
        walletCurrency: wallet.currency,
        walletId: wallet.id,
        walletBalance: wallet.balance,
      }))
    );
  }, [wallets]);

  const toggleCurrencySelection = (currencyName: string) => {
    setSelectedCurrencies(prevSelected => {
      if (prevSelected.includes(currencyName)) {
        return prevSelected.filter(c => c !== currencyName);
      } else {
        return [...prevSelected, currencyName];
      }
    });
  };

  const filteredTransactions: Transaction[] = useMemo(() => {
    let filtered = [...transactions];

    // Filter by selected currencies
    if (selectedCurrencies.length > 0) {
      filtered = filtered.filter(t => selectedCurrencies.includes(t.walletCurrency || ''));
    } else {
      filtered = [];
    }

    // Filter by transactionType
    if (transactionType !== 'all') {
      filtered = filtered.filter(t => {
        const description = t.description.toLowerCase();
        if (transactionType === 'incoming') {
          return description.includes('received') || description.includes('bought');
        } else if (transactionType === 'outgoing') {
          return description.includes('sent') || description.includes('sold');
        }
        return true;
      });
    }

    // Filter by searchTerm
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(t =>
        t.description.toLowerCase().includes(term) ||
        t.amount_from.toString().toLowerCase().includes(term) ||
        t.currency_from.toLowerCase().includes(term) ||
        (t.walletCurrency && t.walletCurrency.toLowerCase().includes(term))
      );
    }

    // Filter by dateRange
    if (dateRange !== 'all') {
      const now = new Date();
      let startDate: Date;
      let endDate: Date = endOfDay(now);

      switch (dateRange) {
        case 'today':
          startDate = startOfDay(now);
          break;
        case 'yesterday':
          startDate = startOfDay(subDays(now, 1));
          endDate = endOfDay(subDays(now, 1));
          break;
        case 'last7days':
          startDate = startOfDay(subDays(now, 6));
          break;
        case 'last30days':
          startDate = startOfDay(subDays(now, 29));
          break;
        default:
          startDate = new Date(0); // All time
      }

      filtered = filtered.filter(t => {
        if (!t.created) return false;
        const transactionDate = new Date(t.created);
        return transactionDate >= startDate && transactionDate <= endDate;
      });
    }

    return filtered;
  }, [transactions, selectedCurrencies, transactionType, searchTerm, dateRange]);

  const totalItems = filteredTransactions.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentTransactions: Transaction[] = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = currentPage * itemsPerPage;
    return filteredTransactions.slice(start, end);
  }, [filteredTransactions, currentPage, itemsPerPage]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
  }, [totalPages]);



  const handleTransactionDetail = useCallback(async (transaction: Transaction) => {
    setIsLoadingTransaction(true);
    try {
      const data = transaction; // Replace with actual API call if needed
      setSelectedTransaction(data);
      openModal({
        title: "Transaction Details",
        content: <TransactionDetailModal transaction={data} />,
      });
    } catch (error) {
      console.error('Error fetching transaction details:', error);
    } finally {
      setIsLoadingTransaction(false);
    }
  }, [openModal]);

  // Map transactions to the designer's format
  function mapTransaction(transaction: Transaction) {
    const isReceived = ['exchanged', 'added', 'received', 'completed'].includes(
      transaction.status.toLowerCase()
    );
    const isSent = ['sent', 'withdrawn'].includes(transaction.status.toLowerCase());

    return {
      ...transaction,
      mapped: {
        id: transaction.id,
        name: transaction.description || 'No Description',
        type: isReceived ? 'received' : 'sent',
        details: transaction.status,
        date: new Date(transaction.created).toLocaleDateString(),
        amount: transaction.amount_from,
        currency: transaction.currency_from,
        equivalentAmount: transaction.order_amount,
        equivalentCurrency: transaction.order_currency,
      },
    };
  }

  const mappedTransactions = currentTransactions.map(mapTransaction);

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      {walletsLoading ? (
        <div>Loading wallets...</div>
      ) : walletsError ? (
        <div>Error loading wallets: {walletsError}</div>
      ) : (
        <>
          {/* Balances Section */}
          <Card className="mb-8">
            <CardHeader>
              <CardTitle>Balances</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {wallets.map((wallet) => (
                  <CurrencyCard
                    key={wallet.id}
                    wallet={{
                      ...wallet,
                      balance: typeof wallet.balance === 'string' 
                        ? parseFloat(wallet.balance.replace(/[^\d.-]/g, ''))
                        : typeof wallet.balance === 'number'
                          ? wallet.balance
                          : 0,
                      currency: wallet.currency,
                      id: wallet.id,
                      transactions: wallet.transactions || []
                    }}
                    isSelectable
                    isSelected={selectedCurrencies.includes(wallet.currency)}
                    onClick={() => {
                      toggleCurrencySelection(wallet.currency);
                    }}
                  />
                ))}
              </div>
            </CardContent>
          </Card>

   

          {/* Transactions List */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>Transactions</CardTitle>
            </CardHeader>
            <CardContent>
              {/* Filters and Search */}
              <div className="flex flex-col sm:flex-row justify-between mb-4">
                {/* You can add additional filters here */}
                <div className="relative mt-2 sm:mt-0">
                  <Input
                    placeholder="Search transactions"
                    className="w-64 pl-8"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="h-4 w-4 text-gray-500 absolute left-2 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>

              {/* Transactions */}
              <div className="space-y-4">
                {mappedTransactions.map((transaction) => (
                  <div
                    key={`${transaction.walletId}-${transaction.id}`} // Ensure unique key
                    style={listItemStyles.container}
                    className={`hover:bg-gray-50 ${transaction.id === highlightedTransactionId ? 'bg-green-100' : ''}`}
                    onClick={() => handleTransactionDetail(transaction)}
                  >
                    <div style={listItemStyles.content}>
                      <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                        {transaction.mapped.type === 'sent' ? (
                          <ArrowUp className="w-5 h-5 text-gray-600" />
                        ) : (
                          <ArrowDown className="w-5 h-5 text-gray-600" />
                        )}
                      </div>
                      <div style={listItemStyles.textContainer}>
                        <span style={listItemStyles.title}>{transaction.mapped.name}</span>
                        <span style={listItemStyles.description}>
                          {transaction.mapped.details} - {transaction.mapped.date}
                        </span>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="text-base font-medium">
                        {transaction.mapped.type === 'received' && '+'}
                        {transaction.mapped.amount} {transaction.mapped.currency}
                      </div>
                      {transaction.mapped.equivalentAmount && (
                        <div className="text-sm text-gray-500">
                          Equivalent {transaction.mapped.equivalentAmount}{' '}
                          {transaction.mapped.equivalentCurrency}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className="flex items-center justify-between mt-4">
                <div>
                  <span className="text-sm text-gray-700">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} transactions
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Button variant="outline" size="icon" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                    {'<<'}
                  </Button>
                  <Button variant="outline" size="icon" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {'<'}
                  </Button>
                  <span className="text-sm text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>
                  <Button variant="outline" size="icon" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    {'>'}
                  </Button>
                  <Button variant="outline" size="icon" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                    {'>>'}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}

function TransactionDetailModal({ transaction }: { transaction: Transaction }) {
  // Updated to match the TransactionBreakdown design
  const buttonStyles = {
    primary: {
      backgroundColor: '#24EC7E',
      color: 'white',
      border: 'none',
      padding: '8px 16px',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer'
    },
    secondary: {
      backgroundColor: '#F5F5F5',
      color: '#666666',
      border: 'none',
      padding: '8px 16px',
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer'
    }
  };

  const isSuccessStatus = ['completed', 'exchanged', 'received'].includes(transaction.status.toLowerCase());
  const statusIcon = isSuccessStatus ? <CheckCircle2 className="h-6 w-6 text-[#097152]" /> : <AlertCircle className="h-6 w-6 text-red-600" />;
  const statusTextColor = isSuccessStatus ? '#097152' : '#DC2626';
  const statusBgColor = isSuccessStatus ? '#AFF8C8' : '#FECACA';

  return (
    <div className="min-h-screen bg-white p-8" style={{ overflowY: 'hidden' }}>
      <div className="mx-auto max-w-2xl rounded-xl bg-white p-8 shadow-lg">
        <h1 className="mb-6 text-2xl font-semibold text-gray-800">Transaction Details</h1>
        
        <div className="mb-6 flex items-center justify-between rounded-lg p-4" style={{ backgroundColor: statusBgColor }}>
          <div className="flex items-center gap-2">
            {statusIcon}
            <span className="font-medium" style={{ color: statusTextColor }}>
              {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
            </span>
          </div>
          <span className="text-lg font-semibold" style={{ color: statusTextColor }}>
            {typeof transaction.amount_from === 'string' ? parseFloat(transaction.amount_from).toFixed(2) : transaction.amount_from.toFixed(2)} {transaction.currency_from}
          </span>
        </div>
        
        <div className="mb-6 space-y-4">
          <div className="flex justify-between">
            <span className="text-gray-600">ID</span>
            <span className="font-medium">{transaction.id}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Description</span>
            <span className="font-medium">{transaction.description}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Date</span>
            <span className="font-medium">{new Date(transaction.created).toLocaleDateString()}</span>
          </div>
        </div>
        
        <div className="flex flex-wrap gap-4">
          <button style={buttonStyles.secondary} className="flex items-center gap-2">
            <Share2 className="h-4 w-4" />
            Share
          </button>
          <button style={buttonStyles.secondary} className="flex items-center gap-2">
            <Download className="h-4 w-4" />
            Download Receipt
          </button>
          <button style={buttonStyles.secondary} className="flex items-center gap-2">
            <AlertCircle className="h-4 w-4" />
            Report Issue
          </button>
        </div>
      </div>
    </div>
  );
}

// Helper Functions
function getCountryCodeFromCurrency(currency: string): string {
  const mapping: { [key: string]: string } = {
    'GBP': 'gb',
    'USD': 'us',
    'EUR': 'eu',
    'AUD': 'au',
    // Add more mappings as needed
  };
  return mapping[currency.toUpperCase()] || 'us';
}

const listItemStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'background-color 0.2s',
    marginBottom: '1px',
    border: '1px solid #E5E7EB',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
  },
  description: {
    fontSize: '14px',
    color: '#666666',
  },
};
